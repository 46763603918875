import Popup from "layouts/applications/popup/Popup";
import CloseIcon from "@mui/icons-material/Close";
import Payment from "layouts/applications/popup/Payment";
import React, { useEffect, useRef } from "react";

import { setPaymentPopup, useSoftUIController } from "context";
import useAuth from "layouts/authentication/hooks/useAuth";
import { SignUpCard } from "components/CustomComponents";

function PaymentPopup() {
	const [controller, dispatch] = useSoftUIController();
	const { paymentPopup } = controller;
	const autoAppearTimer = useRef();
	const { auth } = useAuth();

	useEffect(() => {
		startAppearTimer();
		return clearAppearTimer;
	}, []);

	useEffect(() => {
		if (auth?.email) clearAppearTimer();
	}, [auth]);

	const startAppearTimer = () => {
		//show plan popup after x seconds
		const appearInSeconds = 90;
		autoAppearTimer.current = setInterval(showPopup, appearInSeconds * 1000);
	};

	const clearAppearTimer = () => {
		if (autoAppearTimer.current) clearInterval(autoAppearTimer.current);
		autoAppearTimer.current = null;
	};

	const showPopup = () => {

		if (auth?.email) {
			if (!auth.plan?.plan_features.available.includes("gem_options_ranking") || !auth.plan?.plan_features.available.includes("gem_stock_ranking")) {
				setPaymentPopup(dispatch, { forStocks: paymentPopup.forStocks, on: true, isPaymentPopup: true });
			}
		} else {
			setPaymentPopup(dispatch, { forStocks: paymentPopup.forStocks, on: true, isPaymentPopup: false });
		}

		clearAppearTimer();
	};
	const handleClosePopup = () => {
		setPaymentPopup(dispatch, { forStocks: paymentPopup.forStocks, on: false, isPaymentPopup: false });

		// reset the auto appear timer
		clearAppearTimer();
		startAppearTimer();
	};
	return (
		<div>
			{(paymentPopup.on && (!auth.plan?.plan_features.available.includes("gem_options_ranking") || !auth.plan?.plan_features.available.includes("gem_stock_ranking"))) ? (
				<Popup transparent={true} style={{ zIndex: 10000 }}>
					<CloseIcon
						className="close-btn icon"
						style={{ color: 'white' }}
						onClick={() => {
							handleClosePopup();
						}}
					/>
					{!auth?.email ?
						<SignUpCard setPaymentPopup={setPaymentPopup} paraText={"Sign up for BullBearVector’s free membership and get back to explore all free features!"} headerText={"Unlimited access, instantly. At zero cost."}></SignUpCard> :
						(!auth.plan?.plan_features.available.includes("gem_options_ranking") || !auth.plan?.plan_features.available.includes("gem_stock_ranking")) ?
							<Payment forStocks={paymentPopup.forStocks}></Payment> : <div></div>
					}
				</Popup>
			) : (
				<div></div>
			)
			}
		</div>
	);
}

export default PaymentPopup;
