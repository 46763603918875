/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchPlans } from "services/plan";
import SoftDatePicker from "components/SoftDatePicker";
import moment from "moment";
import { updateUserSubscription } from "services/user";
import { useParams } from "react-router-dom";
import { setErrorMessage, useSoftUIController } from "context";

function UserPlan() {
	const [controller, dispatch] = useSoftUIController();
	const [plans, setPlans] = useState([]);
	const { userID } = useParams();

	useEffect(() => {
		fetchPlans().then(data => {
			if (data && data.length > 0) {
				setPlans(data);
			}
		});
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox py={3} sx={{ height: "100%" }}>
				<Formik
					initialValues={{ plan: null, plan_name: null, plan_expiry: null }}
					onSubmit={(values, actions) => {
						console.log(values);
						if (window.confirm(`Are you sure you want to apply ${values.plan_name} subscription plan?`)) {
							updateUserSubscription(userID, {
								planID: values.plan,
								expiryDate: values.plan_expiry
							})
							setErrorMessage(dispatch, "User's subscription plan has been updated", "success");
						}
						actions.setSubmitting(false);
					}}
				>
					{({ values, errors, touched, isSubmitting, setFieldValue }) => (
						<Form id="user_plan_update" autoComplete="off">
							<Card sx={{ height: "300px" }}>
								<SoftBox p={2}>
									<SoftBox>
										<SoftBox>
											<SoftBox lineHeight={0}>
												<SoftTypography variant="h5" fontWeight="bold">
													About me
												</SoftTypography>
											</SoftBox>
											<SoftBox my={1.625}>
												<SoftSelect
													error={Boolean(touched.plan && errors.plan)}
													fullWidth
													placeholder="Select Plan"
													name="plan"
													options={plans.map(item => ({
														value: item.id,
														label: item.name,
													}))}
													defaultValue={
														values.plan ? plans.find(item => item.id === values.plan) : null
													}
													onChange={selectedOption => {
														setFieldValue("plan", selectedOption.value);
														setFieldValue("plan_name", selectedOption.label);
													}}
												/>
											</SoftBox>
											<SoftDatePicker
												input={{
													placeholder:
														"Please choose a date till this plan will remain active",
												}}
												value={values.plan_expiry}
												onChange={([date]) => {
													setFieldValue("plan_expiry", moment(date).format("YYYY-MM-DD"));
												}}
											/>
										</SoftBox>
										<SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
											<SoftButton
												disabled={isSubmitting}
												type="submit"
												variant="gradient"
												color="dark"
											>
												Update
											</SoftButton>
										</SoftBox>
									</SoftBox>
								</SoftBox>
							</Card>
						</Form>
					)}
				</Formik>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
}

export default UserPlan;
