import React, { useEffect, useRef, memo } from "react";

function SmallSymbolOverviewWidget({ exchange, ticker, duration }) {
	const container = useRef();

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
		script.type = "text/javascript";
		script.async = true;
		script.innerHTML = `
        {
            "symbol": "${exchange}:${ticker}",
            "width": "100%",
            "height": "100%",
            "locale": "en",
            "dateRange": "${duration ?? "1D"}",
            "colorTheme": "light",
            "isTransparent": false,
            "autosize": true,
            "largeChartUrl": "",
            "chartOnly": true,
            "noTimeScale": false
          }`;
		container.current.appendChild(script);
	}, []);

	return (
		<div className="tradingview-widget-container" ref={container}>
			<div className="tradingview-widget-container__widget"></div>
		</div>
	);
}

export default memo(SmallSymbolOverviewWidget);