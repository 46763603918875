export const stockDetailsData = {
    currency: {
        header: "Currency",
        body: [`Currency is a medium of exchange for goods and services. In short, it's money, in the form of paper or coins, usually issued by a government and generally accepted at its face value as a method of payment.`,
            `Interest rates, inflation, and exchange rates are all highly correlated. By manipulating interest rates, central banks exert influence over both inflation and exchange rates, and changing interest rates impact inflation and currency values.`
        ]
    },
    country: {
        header: "Country",
        body: [`A country is defined as a nation, the people of the nation or land in a rural area. An example of a country is the United States. 
        `,
            `In 2021, stock markets in the United States accounted for nearly 56 percent of world stocks. The next largest country by stock market share was Japan, followed by China. The New York Stock Exchange (NYSE) and the NASDAQ are the largest stock exchange operators worldwide.
            `
        ]
    }
    ,
    exchange: {
        header: "Exchange",
        body: [`A stock exchange provides a platform where such trading can be easily conducted by matching buyers and sellers of stocks. For the average person to get access, they will go to their local stock brokers to buy and sell stocks.
        `,
            `As of 2016, there are 60 stock exchanges in the world. Of these, there are 16 exchanges with anmarket capitalization of $1 trillion or more, and they account for 87% of global market capitalization. Apart from the Australian Securities Exchange, these 16 exchanges are all in either North America, Europe, or Asia.
            `
        ]
    }, sector: {
        header: "Sectors",
        body: [`The term market sector is used in economics and finance to describe a part of the economy. It is usually a broader term than industry.
        `,
            `It breaks down the 11 market sectors as follows: Energy, Materials, Industrials, Consumer Discretionary, Consumer Staples, Health Care, Financials, Information Technology, Telecommunication Services, and Utilities.
            `
            ,
            `It is good to diversify your portfolio into different sectors.

            `
        ]
    },
    industry: {
        header: "Industry",
        body: [`The term industry refers to a series of companies that operate in a similar business sphere, and its categorization is more narrow.
        `,
            `Stocks of companies in the same industry will usually trade in the same direction, as their fundamentals are affected by market factors in the same way.
            `
            ,
            `Investors can easily compare companies within the same industry for investment opportunities.

            `
        ]
    }
    ,
    marketCap: {
        header: "Market Cap",
        body: [`Market capitalization is the total dollar value of all of a company's outstanding shares. It's determined by multiplying the company's stock price by its total number of outstanding shares. Investors can use market capitalization to assess the value of a stock they are considering buying.
        `,
            `Companies are typically divided according to market capitalization:`
            ,
            `Mega-cap (More than $200 billion)
            `
            ,
            `Large-cap ($10 billion or more)
            `
            ,
            `Mid-cap ($2 billion to $10 billion)
            `
            ,
            `Small-cap ($300 million to $2 billion).
            `
        ]
    },
    divYield: {
        header: "Annual Dividend Yield",
        body: [`Dividend yield is the percentage a company pays out annually in dividends per dollar you invest. 
        `,
            `For example, if a company's dividend yield is 8% and you own $10,000 of its stock, you would see an annual payout of $800 or quarterly installments of $200.
            `,
            `A good dividend yield will vary with interest rates and general market conditions, but typically a yield of 4 to 6 percent is considered quite good. 

            `
        ]
    },
    recentQuarter: {
        header: "Most Recent Quarter",
        body: [`The term most recent quarter (MRQ) refers to the fiscal quarter that most recently ended. MRQ figures are used to describe changes in company performance.
        `,
            `The standard calendar quarters that make up the year are as follows:`
            ,
            `January, February, and March (Q1)
            `
            ,
            `April, May, and June (Q2)
            `
            ,
            `July, August, and September (Q3)
            `
            ,
            `October, November, and December (Q4)`
        ]
    }
    ,
    PERatio: {
        header: "Price to Earnings (P/E) Ratio",
        body: [`The ratio is used for valuing companies and to find out whether they are overvalued or undervalued.
        `,
            `Eg. if a company has earnings of $10 billion & 2 billion shares outstanding, its EPS is $5. If its stock price is $100, its PE ratio would be 100 divided by 5, which is 20. Meaning, the stock is trading 20X higher than the company's earnings.
            `
            ,
            `The higher the P/E ratio, the more you are paying for each dollar of earnings. This makes a high PE ratio bad for investors.

            `
        ]
    }, PBRatio: {
        header: "Price to Book (P/B) Ratio",
        body: [`The ratio is used to evaluate a company’s current market value relative to its book value. The book value is the amount that would be left if the company liquidated all of its assets and repaid all of its liabilities.
        `,
            `If a company has $100 million dollars in net assets and 10 million shares outstanding, then the book value for that company is $10 a shares ($100 million in assets / 10 million shares)
            `, `A ratio less than 1 is considered good, meaning the stock may be undervalued. 
            `,

        ]
    },
    PEGRatio: {
        header: "Price to Earnings Growth (PEG) Ratio",
        body: [`The ratio is used for determining the relative trade-off between the price of a stock, the earnings generated per share, and the company's expected growth. 
        `,
            `If company's earnings per share (EPS)  continue to grow at 15% per year. By taking the P/E ratio (20) and dividing it by the growth rate (15), the PEG ratio is calculated as 1.33.
            `, `A ratio between 0.5 and less than 1 is considered good, meaning the stock may be undervalued. A ratio less than 0.5 is considered to be excellent.
 
            `
        ]
    }
    ,
    SPERatio: {
        header: "TBC",
        body: [`TBC`,
            `TBC`
        ]
    },
    ROE: {
        header: "Return on Equity (ROE) Ratio",
        body: [`The ratio is used to reveal how much profit a company earns with the money shareholders have invested
        `,
            `If the company's net income is $10 million and total equity equaled $20 million, the company's ROE as: this means that the company generated $0.50 of profit for every $1 of total equity, giving the company an ROE of 50%.
            `,
                `The higher the ROE, the better, but do take note if the company has too high financial leverage for high financial performance.
        `,
        ]
    },
    ROA: {
        header: "Return on Assets (ROA) Ratio",
        body: [`The ratio is used to reveal how much profit a company earns with the total assets invested.
        `,
            `If the company's net income is $1 million and total Assets equaled $20 million, the company's ROA as: this means that the company generated $0.05 of profit for every $1 of total assets, giving the company an ROA of 5%.
            `,`The higher the ROA, the better, but do take note if the company has too high financial leverage for high financial performance.
            `,
        ]
    }
    ,
    EPS: {
        header: "Earnings Per Share (EPS)",
        body: [`EPS indicates how much money a company makes for each share of its stock and is a widely used metric for estimating corporate value. 
        `,
            `A higher EPS indicates greater value because investors will pay more for a company's shares if they think the company has higher profits relative to its share price.
            `,`The higher the earnings per share of a company, the better is its profitability. 
            `,
        ]
    }, GPM: {
        header: "Gross Profit Margin Ratio",
        body: [`The margin shows the % of sales revenue a company keeps after it covers all direct costs associated with running the business. `,
            `Eg. if the ratio is 20%, that means for every dollar of revenue generated, $0.20 is retained while $0.80 is attributed to the cost of goods sold.
            `,`The higher the gross profit margin the better, meaning means that the company did well in managing its cost of sales. It also shows that the company has more to cover for operating, financing, and other costs.
            `,
        ]
    },
    OM: {
        header: "Operating Margin",
        body: [`The margin shows how much profit a company makes on a dollar of sales after paying for variable costs of production, such as wages and raw materials, but before paying interest or tax. `,
            `Eg. if the ratio is 10%, that means for every dollar of revenue generated, $0.10 is retained while $0.90 is attributed to the cost of goods sold and variable costs of production.`,
            `The higher the operating profit margin the better, a good indication of a well managed company.`,

        ]
    }
    ,
    NPM: {
        header: "Net Profit Margin",
        body: [`The margin shows how much profit is generated from every $1 in sales, after accounting for all business expenses involved in earning those revenues.`,
            `Eg. a company has $200,000 in sales and $50,000 in monthly net income. Net profit margin = $50,000 / $200,000 = 25%, meaning the company has $0.25 of net income for every dollar of sales.`,
            `10% net profit margin is average, a 20% margin is considered good, and a 5% margin is low.`,
        ]
    },
    NBAT: {
        header: "NPAT < EBIT ?",
        body: [`Net profit After Tax takes into consideration the interest and taxes paid by a company. `,
            `Earnings before interest and taxes (EBIT) does not take in interest and taxes.`,
            `We want to know if the interest and tax outweigh the profits, meaning if the company is collecting interest from other sources, and not focusing on the main business, which may not be healthy in the long run.`,
        ]
    },
    CurrentRatio: {
        header: "Current Ratio (CR)",
        body: [`Measures a company's ability to pay short-term obligations or those due within one year.`,
            `Current Assets like cash, stocks and short term debts. Current Liabilities like short term credits and short term loans. `,
            `Simply, businesses with very high short term loans or credits will be forced to liquidate because the creditor can demand money back, typically during a economic downturn.`,
            `CR Ratio > 1 will be a good Ratio.`,
        ]
    }
    ,
    DebtToEquity: {
        header: "Debt To Equity Ratio (D/E)",
        body: [`A good debt to equity ratio is around 0.5 to 1.5. However, the ideal debt to equity ratio will vary depending on the industry because some industries use more debt financing than others.`,
            `Capital-intensive industries like the financial and manufacturing industries often have higher ratios that can be greater than 2.`,
            `The lower the debt to equity, the better the ratio as the company is able to sustain without having any debts. Typically, is it a good debt or a bad debt?`
        ]
    }, DebtToAsset: {
        header: "Debt To Asset Ratio (D/A)",
        body: [`The debt to asset ratio, or total debt to total assets ratio, is an indication of a company's financial leverage. `,
            `A company's debt to asset ratio measures its assets financed by liabilities (debts) rather than its equity. This ratio can be used to measure a company's growth through its acquired assets over time.`,
            `Lower ratios (0.4 or lower) are considered better debt ratios. A higher debt ratio (0.6 or higher) makes it more difficult to borrow money.`,
        ]
    },
    NetToNTA: {
        header: "Net Debt to NTA Ratio",
        body: [`The Net Debt to Assets Ratio is a measure of the financial leverage of the company.`,
            `A lower debt-to-asset ratio suggests a stronger financial structure, just as a higher debt-to-asset ratio suggests higher risk. `,
            `Generally, a ratio of 0.4 – 40 percent – or lower is considered a good debt ratio.`,
        ]
    }
    ,
    QualityIncome: {
        header: "Quality Income",
        body: [`The quality of earnings refers to the proportion of income attributable to the core operating activities of a business. Thus, if a business reports an increase in profits due to improved sales or cost reductions, the quality of earnings is considered to be high.`,
            `The quality of income ratio is calculated with cash flow from operations being divided by net income. A ratio of greater than 1.0 indicates a company has high-quality earnings, and a ratio of less than 1.0 indicates a company has low-quality earnings.`

        ]
    },
    Orecta: {
        header: "ORecTa%",
        body: [`Indication that the business is focused on it’s core business, and not spending time dealing with others.`,
            `Basically looking at operating cashflow, receivables over it's tangible assets and determine it's focus. ORecTa% > 90% is considered a good percentage.`
        ]
    },
    greatReturns: {
        header: "Great Returns",
        body: [`To assess a company's efficiency at allocating the capital under its control to profitable investments.`,
            `A strong company makes good profit so that it is able to survive and grow.`,
            `A high double digit figure often means that the company has a defensible edge versus its competitors`,
        ]
    },
    cashflow: {
        header: "Efficient Cashflow",
        body: [`To assess a company's efficiency at managing cash flow to grow it's operation and pay dividends.`,
            `A Strong must have cashflow to scale, invest in research and development, and to pay dividends. Without positive cashflow, the company tend to borrow money and incur high interest rates.`,
            `This will lead to high debt, which potential be a huge problem in future if the company is able to pay off it's debt in the long run.`,
        ]
    },
    moneyvalue: {
        header: "Money Value",
        body: [`Represents Earnings Yield, that measure how undervalued a company is based on it’s earnings and company size`,
            `Taking into consideration on it's market cap, Debt, preferred share capital, minority interest, allow us better understand it's true value.`,
            `Earnings Yield > 10% shows that it is undervalued. Other Investors use a less stringent ratio, which is the PE Ratio to determine under/over value.`,
        ]
    },
    gemMarket: {
        header: "GEM Market Rank",
        body: [`Taking into consideration of the latest quarter financial data, the algorithm pulls in 50% wonderful company ( G & E ), 50% Fair Value (M)`,
            `The lower the rank, the better the opportunity of including this hidden GEM in your watchlist.`,
            `This ranking mechanism does not tell you when you enter into this position in your portfolio, please use technical analysis to determine the timing.`,
        ]
    },
    gemPrevious: {
        header: "GEM Previous Quarter",
        body: [`Taking into consideration of the previous quarter financial data, the algorithm pulls in 50% wonderful company ( G & E ), 50% Fair Value (M)`,
            `If this rank is higher than the current market rank, it shows that the company is not doing as well as the previous quarter.`,
            `You may want to consider looking at rebalancing your portfolio or watchlist.`
        ]
    },
    gemTwoQuarters: {
        header: "GEM Two Quarters ago",
        body: [`Taking into consideration of the previous quarter financial data, the algorithm pulls in 50% wonderful company ( G & E ), 50% Fair Value (M)`,
        `If this rank is higher than the current market rank, it shows that the company is not doing as well as the previous quarter.`,
        `You may want to consider looking at rebalancing your portfolio or watchlist.`
    ]
    },
    gemThreeQuarters: {
        header: "GEM Three Quarters ago",
        body: [`Taking into consideration of the previous quarter financial data, the algorithm pulls in 50% wonderful company ( G & E ), 50% Fair Value (M)`,
        `If this rank is higher than the current market rank, it shows that the company is not doing as well as the previous quarter.`,
        `You may want to consider looking at rebalancing your portfolio or watchlist.`
    ]
    },
}