import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { CircularProgress, Grid } from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { useState } from "react";

const ContactPage = () => {
	const [isProcessing, setIsProcessing] = useState(false);

	const handleSubmit = () => {
		setIsProcessing(true);
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox mt={3} mb={20}>
				<SoftBox p={3}>
					<SoftTypography style={{ textAlign: "center" }}>
						For further questions, including partnership opportunities,
						<br />
						contact using our contact form.
					</SoftTypography>
				</SoftBox>
				<Grid container style={{ maxWidth: "800px", margin: "0 auto" }}>
					<Grid item md={6} xs={12}>
						<SoftBox p={3}>
							<SoftTypography variant="body2">Your Name</SoftTypography>
							<SoftInput fullWidth placeholder="Please provide your name" />
						</SoftBox>
					</Grid>
					<Grid item md={6} xs={12}>
						<SoftBox p={3}>
							<SoftTypography variant="body2">Your Email</SoftTypography>
							<SoftInput fullWidth placeholder="Please provide your valid email" />
						</SoftBox>
					</Grid>
					<Grid item xs={12}>
						<SoftBox p={3}>
							<SoftTypography variant="body2">How can we help you?</SoftTypography>
							<SoftInput
								fullWidth
								multiline
								minRows={4}
								placeholder="Describe your problem in at least 250 characters"
							/>
						</SoftBox>
					</Grid>
					<Grid item xs={12} textAlign="center">
						<SoftBox p={3}>
							<SoftButton color="error" onClick={handleSubmit}>
								Send Message
								{isProcessing && (
									<CircularProgress color="white" size={12} style={{ marginLeft: "15px" }} />
								)}
							</SoftButton>
						</SoftBox>
					</Grid>
				</Grid>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
};

export default ContactPage;
