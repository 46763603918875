import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { ScrollToTopButton } from "components/CustomComponents";

const Financial = () => {
	const ListOfHeaders = [
      'Disclaimer',
      'Not Financial Advice',
      'Investment Risks',
      'No Guarantee',
      'No Fiduciary Relationship',
      'Intellectual Property',
      'Indemnification',
      'Modification of Terms',
      'Contact Us',

	]

	const scrollToSection = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox style={{display:"flex",justifyContent:"center"}}>
			<SoftBox p={3} mt={3} mb={20} className="tnc">
				<SoftTypography className = 'roboto' style = {{fontSize:'16px'}} fontWeight="bold">
					Legal Disclaimer - Not Financial Advice
				</SoftTypography>
				{/* <SoftTypography fontWeight="bold">
					This Financial Policy was last updated on 20 April 2023.
				</SoftTypography> */}
				<ul style={{marginBottom:"20px" }}>
					
					{ListOfHeaders.map((header, index) => {
						return <li style={{ fontSize: "18px" }} onClick={()=>{scrollToSection(header)}} className="underline roboto" key={index}>{index + 1}. {header}</li>
					})}
				</ul>
				<ScrollToTopButton></ScrollToTopButton>
				<SoftTypography>
					<strong id="Disclaimer">1. Disclaimer</strong>
					<br />
					Bull Bear Vector (BBV) provides information and predictions related to financial markets and stocks for informational purposes only. The use of our website and the information provided therein is subject to the following terms and conditions and all applicable laws.
				</SoftTypography>
				<SoftTypography>
					<strong id="Not Financial Advice">2. Not Financial Advice</strong>
					<br />
					All content available on our website, including but not limited to predictions, rankings, and other information, is for informational purposes only. We do not provide legal, tax, investment, financial, or other advice, nor should any information contained on our website or related social media posts be construed as such advice. Nothing on our website constitutes a solicitation, recommendation, endorsement, or offer by Bull Bear Vector (BBV) or any third-party service provider to buy or sell any securities or other financial instruments. The information provided on our website is of a general nature and does not address the circumstances of any individual or entity. We do not provide professional and/or financial advice, and nothing on our website constitutes a comprehensive or complete statement of the matters discussed or the law relating thereto. You assume the sole responsibility of evaluating the merits and risks associated with using any information or other content on our website before making any decisions based on such information or other content. By using our website, you agree not to hold Bull Bear Vector (BBV), its owners, officers, directors, employees, agents, representatives, suppliers, and service providers liable for any possible claim for damages arising from any decision you make based on information or other content made available to you through our website.
				</SoftTypography>
				<SoftTypography>
					<strong id="Investment Risks">3. Investment Risks</strong>
					<br />
					Investing in securities carries risks, including the risk of loss. Investing in stocks, bonds, exchange-traded funds, mutual funds, forex, indexes, futures, cryptocurrencies, Non-fungible Tokens (NFTs) and money market funds involves the risk of loss, and loss of principal is possible. Some high-risk investments may use leverage, which will accentuate gains and losses. Foreign investing involves special risks, including greater volatility and political, economic, currency risks, and differences in accounting methods. A security’s or a firm’s past investment performance is not a guarantee or predictor of future investment performance.
					<br></br>
					Third-Party Linked Sites & Social Media Posts
					<br></br>
					As a convenience to you, we may provide hyperlinks and content to websites or social media companies operated by third parties. When you select those hyperlinks, you will be leaving our website. When you consume content available on those sites or social media posts, even if posted by Bull Bear Vector (BBV), it is purely for informational purposes only and not as financial advice. Because we have no control over such sites or their content, we are not responsible for the availability of such external sites or their content, and we do not adopt, endorse, or nor are responsible or liable for any such sites or content, including advertising, products, or other materials, on or available through such sites or resources. Other websites may provide links to our website or content with or without our authorization. We do not endorse such sites and shall not be responsible or liable for any links from those sites to our website or content or for any content, advertising, products, or other materials available on or through such other sites, or any loss or damages incurred in connection therewith. We may, in our sole discretion, block links to our website and content without prior notice. Your use of third-party websites and content, including without limitation, your use of any information, data, advertising, products, or other materials on or available through such websites, is at your own risk and is subject to their terms of use.
				</SoftTypography>
				<SoftTypography>
					<strong id="No Guarantee">4. No Guarantee</strong>
					<br />
					While we strive to provide accurate and up-to-date information on our website, we do not guarantee the accuracy, completeness, or timeliness of the information provided. The information and content available on our website may be subject to errors or omissions, and we do not make any warranty or representation regarding the suitability, reliability, availability, timeliness, or accuracy of our website or the information or content available on it. We are not responsible for any errors or omissions, or for any loss or damages resulting from the use of the information or content available on our website.
				</SoftTypography>
				<SoftTypography>
					<strong id="No Fiduciary Relationship">5. No Fiduciary Relationship</strong>
					<br />
					We are not a fiduciary by virtue of any person’s use of or access to our website or content. Nothing on our website creates a fiduciary relationship between us and any user of our website. We do not owe any duty of loyalty, care, or disclosure to any user of our website.
				</SoftTypography>
				<SoftTypography>
					<strong id="Intellectual Property">6. Intellectual Property</strong>
					<br />
					All content available on our website, including but not limited to text, graphics, logos, images, videos, software, and other materials, is the property of Bull Bear Vector (BBV) or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may use the content available on our website for personal, non-commercial purposes only. Any other use of our content, including but not limited to reproduction, modification, distribution, transmission, republication, display, or performance, without our prior written consent, is strictly prohibited.
				</SoftTypography>
				<SoftTypography>
					<strong id="Indemnification">7. Indemnification</strong>
					<br />
					You agree to indemnify, defend, and hold harmless Bull Bear Vector (BBV), its owners, officers, directors, employees, agents, representatives, suppliers, and service providers from and against any and all claims, damages, liabilities, costs, and expenses, including but not limited to attorneys’ fees, arising from or related to your use of our website or your violation of these terms and conditions.
				</SoftTypography>
				<SoftTypography>
					<strong id="Modification of Terms">8. Modification of Terms</strong>
					<br />
					We reserve the right to modify these terms and conditions at any time, without prior notice. Your continued use of our website after any such modifications shall constitute your acceptance of the modified terms and conditions.
				</SoftTypography>
				<SoftTypography>
					<strong id="Contact Us">9. Contact Us</strong>
					<br />
					If you have any questions or concerns about these terms and conditions or our website, please contact us at support@bullbearvector.com
				</SoftTypography>

			</SoftBox>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
};

export default Financial;
