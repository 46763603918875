/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Settings page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/pages/profile/components/Header";
import ProfileHeader from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import SubscriptionAndPoints from "layouts/pages/account/settings/components/SubscriptionAndPoints";
import Notifications from "layouts/pages/account/settings/components/Notifications";
import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";
import { loadUserProfile } from "services/user";

function Settings() {
	const [isLoading, setIsLoading] = useState(true);
	const [profile, setProfile] = useState(null)

	useEffect(() => {
		loadUserProfile()
			.then((profile) => {
				setProfile(profile)
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<DashboardLayout>
			<Header />
			<SoftBox mb={3}>
				{isLoading ? (
					<SoftBox style={{ display: "flex", justifyContent: "center" }}>
						<CircularProgress color="inherit" />
					</SoftBox>
				) : (
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<ProfileHeader data={profile} />
						</Grid>
						<Grid item xs={12}>
							<BasicInfo data={profile} />
						</Grid>
						<Grid item xs={12} md={6}>
							<ChangePassword />
						</Grid>
						<Grid item xs={12} md={6}>
							<SubscriptionAndPoints />
						</Grid>
						{/* <Grid item xs={12}>
							<Authentication />
						</Grid>
						<Grid item xs={12}>
							<Accounts />
						</Grid>
						<Grid item xs={12}>
							<Notifications />
						</Grid>
						<Grid item xs={12}>
							<Sessions />
						</Grid>
						<Grid item xs={12}>
							<DeleteAccount />
						</Grid> */}
					</Grid>
				)}
			</SoftBox>
		</DashboardLayout>
	);
}

export default Settings;
