import axios from "layouts/authentication/api/axios";

export const getAutoLoginKey = async () => {
	try {
		const res = await axios().get(`/learn/login-key`);
		return res.data.key ?? null;
	} catch (error) {
		console.log(error);
	}

	return null
}

export const fetchCourses = async () => {
	try {
		const res = await axios().get(`/learn/courses`);
		return res.data.data ?? [];
	} catch (error) {
		console.log(error);
	}
	return [];
};
