import { useEffect } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { useSoftUIController, setInvalidAccess } from "context";

const RequireAuth = allowedRoles => {
	const { auth, tryLoginUser } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const roleList = allowedRoles["allowedRoles"];
	const [controller, dispatch] = useSoftUIController();

	useEffect(() => {
		if (!auth?.access_token) {
			const savedToken = localStorage.getItem("access_token");
			if (savedToken) {
				tryLogin();
			} else navigate("/dashboard/authentication/sign-in", { state: { from: location }, replace: true });
		} else if (!roleList.includes("*") && (!auth?.role || !roleList.includes(auth.role))) {
			console.log(roleList, auth.role);
			setInvalidAccess(dispatch, true);
			navigate("/dashboard", { replace: true });
		}
	}, [auth]);

	const tryLogin = async () => {
		await tryLoginUser();
	};

	//UNCOMMENT THIS TO DEACTIVATE role based authorization
	// return (<Outlet />
	// );

	//  UNCOMMENT THIS TO ACTIVATE role based authorization
	if (roleList.includes("*") || (auth?.role && roleList.includes(auth.role))) return <Outlet />;
	else return null;
};

export default RequireAuth;
