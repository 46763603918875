import React, { useMemo, useState } from "react";
import {
	useStripe,
	useElements,
	CardElement,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
	PaymentElement,
} from "@stripe/react-stripe-js";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { CircularProgress } from "@mui/material";
import StripeImg from "assets/images/powered-by-stripe.jpg";

const StripeCardInfo = ({ redirectToAfterPayment }) => {
	const stripe = useStripe();
	const elements = useElements();

	const [attemptingPayment, setAttemptingPayment] = useState(false);

	const handleSubmit = async event => {
		event.preventDefault();
		setAttemptingPayment(true);

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: redirectToAfterPayment,
			},
		});

		if (result.error) {
			// Show error to your customer (for example, payment details incomplete)
			console.log(result.error.message);
		} else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}

		setAttemptingPayment(false);
	};

	return (
		<form onSubmit={handleSubmit}>
			{/* <label>
				<SoftTypography variant="h6" style={{ textAlign: "left" }}>
					Card number
				</SoftTypography>
				<CardNumberElement
					options={options}
					onReady={() => {
						console.log("CardNumberElement [ready]");
					}}
					onChange={event => {
						console.log("CardNumberElement [change]", event);
					}}
					onBlur={() => {
						console.log("CardNumberElement [blur]");
					}}
					onFocus={() => {
						console.log("CardNumberElement [focus]");
					}}
				/>
			</label>
			<label>
				<SoftTypography variant="h6" style={{ textAlign: "left" }}>
					Expiration date
				</SoftTypography>
				<CardExpiryElement
					options={options}
					onReady={() => {
						console.log("CardNumberElement [ready]");
					}}
					onChange={event => {
						console.log("CardNumberElement [change]", event);
					}}
					onBlur={() => {
						console.log("CardNumberElement [blur]");
					}}
					onFocus={() => {
						console.log("CardNumberElement [focus]");
					}}
				/>
			</label>
			<label>
				<SoftTypography variant="h6" style={{ textAlign: "left" }}>
					CVC
				</SoftTypography>
				<CardCvcElement
					options={options}
					onReady={() => {
						console.log("CardNumberElement [ready]");
					}}
					onChange={event => {
						console.log("CardNumberElement [change]", event);
					}}
					onBlur={() => {
						console.log("CardNumberElement [blur]");
					}}
					onFocus={() => {
						console.log("CardNumberElement [focus]");
					}}
				/>
			</label> */}
			<label>
				{/* <CardElement
					options={options}
					onReady={() => {
						console.log("CardElement [ready]");
					}}
					onChange={event => {
						console.log("CardElement [change]", event);
					}}
					onBlur={() => {
						console.log("CardElement [blur]");
					}}
					onFocus={() => {
						console.log("CardElement [focus]");
					}}
				/> */}
				<PaymentElement />
			</label>
			<SoftButton fullWidth className="bbvGrey" type="submit" disabled={!stripe || attemptingPayment}>
				{attemptingPayment ? <CircularProgress size={18} /> : "Pay"}
			</SoftButton>
			<img src={StripeImg} alt="Powered by Stripe" style={{ width: "100%", maxWidth: "400px" }} />
		</form>
	);
};

export default StripeCardInfo;
