/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
// import Input from "components/Input";
import SoftButton from "components/SoftButton";
import bgImage from "assets/images/login-signup-bg.png";
import axios from "layouts/authentication/api/axios";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Authentication layout components
// import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";
import "../../../../styles.css";
import { useSoftUIController, setLayout } from "context";

//auth context
import useAuth from "layouts/authentication/hooks/useAuth";
import { Button, CircularProgress } from "@mui/material";
import SoftAlert from "components/SoftAlert";

// Images
// import { input } from "@testing-library/user-event/dist/types/utils";

function Basic() {
	const { setUserSession } = useAuth();
	const navigate = useNavigate();
	const [, dispatch] = useSoftUIController();
	const { state: locationState } = useLocation();
	const [isProcessing, setIsProcessing] = useState(false);
	const [onSuccess, setOnSuccess] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [inputText, setInputText] = useState({ email: "", password: "" });
	const [forgotInputText, setForgotInputText] = useState({
		verification_code: "",
		password: "",
		confirm_password: "",
	});
	const [errorMessage, setErrorMessage] = useState("");
	const [viewMode, setViewMode] = useState("login");
	// const location = useLocation()
	// const from = location.state?.from?.path || "/";

	useEffect(() => {
		if (locationState && locationState.from?.pathname) {
			localStorage.setItem("redirectAfterLogin", locationState.from.pathname);
		}
	}, []);

	useEffect(() => {
		//code from template to ensure dashboard doesnt popup
		setLayout(dispatch, "page");
	}, [dispatch]);

	function handleChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		setInputText(prevValue => {
			if (name === "email") {
				return {
					email: value,
					password: prevValue.password,
				};
			} else {
				return {
					email: prevValue.email,
					password: value,
				};
			}
		});
	}
	function handleForgotPasswordChange(event) {
		const name = event.target.name;
		const value = event.target.value;

		const currentValue = { ...forgotInputText };
		currentValue[name] = value;

		setForgotInputText({ ...currentValue });
	}
	const validate = values => {
		const errors = {};

		if (values.password === "") {
			errors.password = "password is required";
		}
		if (values.email === "") {
			errors.email = "Email is required";
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = "Invalid email address";
		}
		return errors;
	};
	const validateForgotPassword = () => {
		const errors = {};
		if (forgotInputText.verification_code === "") errors.verification_code = "Verification code is required";

		if (forgotInputText.password === "") errors.password = "Password is required";

		if (forgotInputText.confirm_password !== forgotInputText.password)
			errors.confirm_password = "Confirm password should match to your new password";

		return errors;
	};
	function submiteNote(e) {
		e.preventDefault();
		const error = validate(inputText);
		if (!error.email && !error.password) {
			setErrorMessage("");
			authenticateUser(inputText);
		} else {
			if (error.email) {
				setErrorMessage(error.email);
			} else {
				setErrorMessage(error.password);
			}
		}
	}
	const authenticateUser = async user => {
		try {
			setIsProcessing(true);
			setOnSuccess(false);
			const res = await axios().post("/auth/login", `username=${user.email}&password=${user.password}`);
			if (res.data?.access_token) {
				const access_token = res.data.access_token;
				await setUserSession({
					email: user.email,
					access_token: access_token,
					role: res.data.role ? res.data.role : "user",
					email_verified: res.data.email_verified,
					first_name: res.data.first_name,
					created_at: res.data.created_at,
					plan: res.data.plan,
				});
				setErrorMessage("");
				setInputText({ email: "", password: "" });
				const redirectTo = localStorage.getItem("redirectAfterLogin");
				setIsProcessing(false);
				if (res.data.role === "admin" && (!redirectTo || redirectTo === "/dashboard"))
					navigate("/admin", { replace: true });
				else if (redirectTo) {
					localStorage.removeItem("redirectAfterLogin");
					navigate(redirectTo, { replace: true });
				} else navigate("/dashboard", { replace: true });
			} else {
				setIsProcessing(false);
				//show error to user
				setErrorMessage("Invalid Email or Password");
				console.log(res);
			}
		} catch (error) {
			setIsProcessing(false);
			if (error.response?.data?.detail) setErrorMessage(error.response.data.detail);
			else setErrorMessage("Oops looks like the server is down, try again later!");
		}
	};

	const handleSetRememberMe = () => setRememberMe(!rememberMe);

	const handleForgotPassword = () => {
		if (viewMode === "forgotpassword") {
			setIsProcessing(false);
			setOnSuccess(false);
		}

		setViewMode(viewMode === "login" ? "forgotpassword" : "login");
	};

	const sendResetPasswordEmail = async e => {
		e.preventDefault();
		const error = validate(inputText);
		if (error.email) {
			setErrorMessage(error.email);
			return;
		}

		setErrorMessage("");
		try {
			setIsProcessing(true);
			const res = await axios().post("/auth/forgot-password", { email: inputText.email });
			setOnSuccess(true);
			setIsProcessing(false);
		} catch (e) {
			setIsProcessing(false);
		}
	};

	const handleResetPassword = async e => {
		e.preventDefault();

		const errors = validateForgotPassword();
		if (errors.verification_code) {
			setErrorMessage(errors.verification_code);
			return;
		}

		if (errors.password) {
			setErrorMessage(errors.password);
			return;
		}

		if (errors.confirm_password) {
			setErrorMessage(errors.confirm_password);
			return;
		}

		setErrorMessage("");
		try {
			setIsProcessing(true);
			const res = await axios().post("/auth/reset-password", {
				email: inputText.email,
				verification_code: forgotInputText.verification_code,
				password: forgotInputText.password,
			});

			if (res.data?.success) {
				setViewMode("login");
				setOnSuccess(true);
			} else {
				setErrorMessage("Invalid verification code. Please try again.");
			}

			setIsProcessing(false);
		} catch (e) {
			setIsProcessing(false);
		}
	};

	return (
		<div>
			<Grid className="topDiv" container>
				<Grid item className="dissapear" lg={6}>
					<div
						style={{ backgroundImage: `url('${bgImage}')` }}
						className="backgroundImage"
						alt="background"
					/>
				</Grid>
				<Grid className="outer" item lg={6} md={12} sm={12} xs={12}>
					<Card className="authCard">
						<SoftBox
							className="cardMargin bbvColour"
							borderRadius="lg"
							mx={2}
							p={2}
							mb={2}
							textAlign="center"
						>
							<SoftBox p={2} textAlign="center">
								<SoftTypography variant="h5" fontWeight="medium" color="white">
									{viewMode === "login" ? "Sign in" : "Reset your Password"}
								</SoftTypography>
							</SoftBox>
							{/* <Grid container spacing={3} justifyContent="center" sx={{ mb: 2 }}>
								<Grid item xs={2}>
									<SoftTypography component={MuiLink} href="#" variant="body1" color="white">
										<FacebookIcon className="icon" color="inherit" />
									</SoftTypography>
								</Grid>
								<Grid item xs={2}>
									<SoftTypography component={MuiLink} href="#" variant="body1" color="white">
										<GoogleIcon className="icon" color="inherit" />
									</SoftTypography>
								</Grid>
							</Grid> */}
						</SoftBox>
						{/* <Separator /> */}
						<SoftBox p={2}>
							<SoftBox component="form" role="form">
								{viewMode === "forgotpassword" && !onSuccess && (
									<SoftTypography variant="body2" color="text" fontWeight="regular" mb={1}>
										Please provide the email you used to signup your account with us
									</SoftTypography>
								)}
								{viewMode === "forgotpassword" && onSuccess ? (
									<>
										<SoftBox mb={2}>
											<SoftInput
												name="verification_code"
												onChange={handleForgotPasswordChange}
												placeholder="Verification Code"
												value={forgotInputText.verification_code}
											/>
										</SoftBox>
										<SoftBox mb={2}>
											<SoftInput
												name="password"
												type="password"
												onChange={handleForgotPasswordChange}
												placeholder="New Password"
												value={forgotInputText.password}
											/>
										</SoftBox>
										<SoftBox mb={2}>
											<SoftInput
												name="confirm_password"
												type="password"
												onChange={handleForgotPasswordChange}
												placeholder="Confirm Password"
												value={forgotInputText.confirm_password}
											/>
										</SoftBox>
										<SoftButton
											onClick={handleResetPassword}
											className="bbvColour"
											variant="gradient"
											color="info"
											fullWidth
											disabled={isProcessing}
										>
											Reset Password
											{isProcessing && (
												<CircularProgress
													size={15}
													style={{ marginLeft: "10px" }}
													color="secondary"
												/>
											)}
										</SoftButton>
									</>
								) : (
									<SoftBox mb={2}>
										<SoftInput
											name="email"
											type="email"
											onChange={handleChange}
											placeholder="Email"
											value={inputText.email}
										/>
									</SoftBox>
								)}
								{viewMode === "forgotpassword" ? (
									<SoftBox mb={1}>
										{onSuccess ? (
											<SoftAlert mt={2} color="success" dismissible>
												<SoftTypography variant="body2" color="inherit">
													Please check your email for verification code to reset your password
												</SoftTypography>
											</SoftAlert>
										) : (
											<>
												<SoftTypography
													variant="body2"
													color="text"
													fontWeight="regular"
													mb={2}
												>
													Please note if we found an account registered with the provided
													email, you should receive an email with instructions to reset your
													password. Please check your spam folder as well, in case the email
													not appear in your inbox.
												</SoftTypography>
												<SoftButton
													onClick={sendResetPasswordEmail}
													className="bbvColour"
													variant="gradient"
													color="info"
													fullWidth
													disabled={isProcessing}
												>
													Send Reset Instructions
													{isProcessing && (
														<CircularProgress
															size={15}
															style={{ marginLeft: "10px" }}
															color="secondary"
														/>
													)}
												</SoftButton>
											</>
										)}
									</SoftBox>
								) : (
									<>
										<SoftBox mb={2}>
											<SoftInput
												name="password"
												type="password"
												onChange={handleChange}
												placeholder="Password"
												value={inputText.password}
											/>
											<SoftTypography
												onClick={handleForgotPassword}
												variant="button"
												style={{ backgroundImage: "none" }}
												fontWeight="bold"
											>
												Forgot your Password?
											</SoftTypography>
										</SoftBox>
										<SoftBox display="flex" alignItems="center">
											<Switch checked={rememberMe} onChange={handleSetRememberMe} />
											<SoftTypography
												variant="button"
												fontWeight="regular"
												onClick={handleSetRememberMe}
												sx={{ cursor: "pointer", userSelect: "none" }}
											>
												&nbsp;&nbsp;Remember me
											</SoftTypography>
										</SoftBox>
										<SoftBox mt={4} mb={1}>
											<SoftButton
												onClick={submiteNote}
												className="bbvColour"
												variant="gradient"
												color="info"
												fullWidth
											>
												sign in
											</SoftButton>
										</SoftBox>
										{onSuccess && (
											<SoftAlert mt={2} color="success" dismissible>
												<SoftTypography variant="body2" color="inherit">
													Your password has been updated successfully. Please login using your new password.
												</SoftTypography>
											</SoftAlert>
										)}
									</>
								)}
								{errorMessage && <div className="error"> {errorMessage} </div>}

								<SoftBox textAlign="center" mt={2} mb={3}>
									{viewMode === "forgotpassword" ? (
										<SoftBox>
											<SoftTypography
												variant="button"
												onClick={handleForgotPassword}
												style={{ backgroundImage: "none" }}
												fontWeight="bold"
											>
												Back to Login
											</SoftTypography>
										</SoftBox>
									) : (
										<SoftTypography variant="body2" color="text" fontWeight="regular">
											Don't have an account?{" "}
											<SoftTypography
												component={Link}
												to="/dashboard/authentication/sign-up"
												variant="button"
												style={{ backgroundImage: "none" }}
												fontWeight="bold"
											>
												Sign up
											</SoftTypography>
										</SoftTypography>
									)}
								</SoftBox>
							</SoftBox>
						</SoftBox>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
}

export default Basic;
