/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import { Button } from "@mui/material";
import SoftButton from "components/SoftButton";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FearGreedIndexComponent from "./FearGreedIndexComponent";
import InfoIcon from "@mui/icons-material/Info";
//images
import IconLeft from "../../../assets/images/IconLeft.png";
import IconRight from "../../../assets/images/IconRight.png";
import IconMiddle from "../../../assets/images/IconMiddle.png";
import ClockIcon from "../../../assets/images/clock.gif";
import direction from "../../../assets/images/confidence.gif";
import Effort from "../../../assets/images/red.gif";
import lackMoney from "../../../assets/images/money.gif";
// import wrenchImg from "../../../assets/images/wrench.png";
// import cryptoImg from "../../../assets/images/crypto.png";
// import rocketImg from "../../../assets/images/rocket.png";

// Soft UI Dashboard PRO React components
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import BBVDashboardNavbar from "examples/Navbars/BBVDashboardNavbar";
import Slider from "./Slider";
import Footer from "examples/Footer";
// import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
// import SalesTable from "examples/Tables/SalesTable";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
// import Globe from "examples/Globe";

// Soft UI Dashboard PRO React base styles
// import typography from "assets/theme/base/typography";
// import breakpoints from "assets/theme/base/breakpoints";

// Data
// import salesTableData from "layouts/dashboards/default/data/salesTableData";
// import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
// import gradientLineChartData from "layouts/dashboards/default/data/gradientLineChartData";
import { getFearIndex } from "layouts/applications/data-tables/data/getData";
// import { useSoftUIController } from "context";
import { getFinnhubNews } from "layouts/applications/data-tables/data/getData";
import { NewsTable } from "components/CustomComponents";
import { DashboardInfoPopup } from "components/CustomPopups/InfoPopups";
import EconomicCalendar from "components/Widgets/EconomicCalendar";
import SmallSymbolOverviewWidget from "components/etc/SmallSymbolOverviewWidget";
// import { DashboardInfoPopup } from "components/InfoPopups";

function Default() {
	// const { values } = breakpoints;
	const label = { inputProps: { "aria-label": "Checkbox demo" } };
	const [defensiveSectors, setDefensiveSectors] = useState([
		{
			name: "Communication Services (XLC)",
			exchange: "AMEX",
			Ticker: "XLC",
		},
		{
			name: "Consumer Staples (XLP)",
			exchange: "AMEX",
			Ticker: "XLP",
		},
	]);
	const [cyclicalSectors, setCyclicalSectors] = useState([
		{
			name: "Consumer Discretionary (XLY)",
			exchange: "AMEX",
			Ticker: "XLY",
		},
		{
			name: "Financials (XLF)",
			exchange: "AMEX",
			Ticker: "XLF",
		},
	]);
	// const { size } = typography;
	// const { chart, items } = reportsBarChartData;
	// const [controller] = useSoftUIController();
	// const { sidenavColor } = controller;
	const [exchange, setExchange] = useState("1T HOF");
	const [newsCategory, setNewsCategory] = useState("general");
	const [newsData, setNewsData] = useState([]);
	const [infoPopup, toggleInfoPopup] = useState(false);
	const [popupAccessor, setPopupAccessor] = useState("");

	const [fearData, setFearData] = useState({
		lastUpdated: {
			epochUnixSeconds: 0,
			humanDate: "2023-02-10T01:00:00.000Z",
		},
		fgi: {
			now: {
				value: 0,
				valueText: "Greed",
			},
			previousClose: {
				value: 0,
				valueText: "Greed",
			},
			oneWeekAgo: {
				value: 0,
				valueText: "Extreme Greed",
			},
			oneMonthAgo: {
				value: 0,
				valueText: "Neutral",
			},
			oneYearAgo: {
				value: 0,
				valueText: "Fear",
			},
		},
	});
	useEffect(() => {
		handleFear();
		handleNews("general");
	}, []);

	const handleNews = async currCategory => {
		let fetchedNews;
		fetchedNews = await getFinnhubNews(currCategory);
		setNewsData(fetchedNews);
	};

	const handleFear = async () => {
		let fearData;
		fearData = await getFearIndex();

		if (fearData !== null) {
			setFearData(fearData);
		}
	};

	const handleInfoPop = popupAccessor => {
		console.log("pressed");
		toggleInfoPopup(true);
		setPopupAccessor(popupAccessor);
	};
	function BasicMenu(type) {
		// console.log(type)
		const [anchorEl, setAnchorEl] = useState(null);
		const open = Boolean(anchorEl);
		const handleClick = event => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};
		let currentData;
		if (type.type === "defensive") {
			currentData = sector_defensive;
		} else {
			currentData = sector_cyclical;
		}

		return (
			<div>
				<Button
					className="sectorButtons greyDropdown"
					id="basic-button"
					variant="contained"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					style={{ width: "80%", marginBottom: "10px", maxWidth: "190px" }}
				>
					{type.type === "defensive" ? "SECTOR DEFENSIVE" : "SECTOR CYCLICAL"}
					<KeyboardArrowDownIcon style={{ marginLeft: "6px" }}></KeyboardArrowDownIcon>
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					{currentData.map((sector, index) => {
						const found = checkSector(sector, type.type);
						return (
							<div key={index}>
								<MenuItem>
									<Checkbox
										checked={found}
										onChange={event => {
											handleCheckbox(event, sector, type.type);
										}}
										{...label}
									/>{" "}
									{sector.name}{" "}
								</MenuItem>
							</div>
						);
					})}
				</Menu>
			</div>
		);
	}

	const configureHTML = () => {
		if (exchange === "1T HOF") {
			return hof;
		} else if (exchange === "Indices") {
			return indicesHTML;
		} else if (exchange === "MONEY") {
			return moneyHTML;
		}
		return commodityHTML;
	};
	const sector_defensive = [
		{
			name: "Communication Services (XLC)",
			exchange: "AMEX",
			Ticker: "XLC",
		},
		{
			name: "Consumer Staples (XLP)",
			exchange: "AMEX",
			Ticker: "XLP",
		},
		{
			name: "Energy (XLE)",
			exchange: "AMEX",
			Ticker: "XLE",
		},
		{
			name: "Health Care (XLV)",
			exchange: "AMEX",
			Ticker: "XLV",
		},
		{
			name: "Utilities (XLU)",
			exchange: "AMEX",
			Ticker: "XLU",
		},
	];
	const sector_cyclical = [
		{
			name: "Consumer Discretionary (XLY)",
			exchange: "AMEX",
			Ticker: "XLY",
		},
		{
			name: "Financials (XLF)",
			exchange: "AMEX",
			Ticker: "XLF",
		},
		{
			name: "Industrials (XLI)",
			exchange: "AMEX",
			Ticker: "XLI",
		},
		{
			name: "Materials (XLB)",
			exchange: "AMEX",
			Ticker: "XLB",
		},
		{
			name: "Real Estate (XLRE)",
			exchange: "AMEX",
			Ticker: "XLRE",
		},
		{
			name: "Technology (XLK)",
			exchange: "AMEX",
			Ticker: "XLK",
		},
	];

	// const sectorData = [
	// 	{
	// 		name: "Communication Services (XLC)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLC",
	// 	},
	// 	{
	// 		name: "Consumer Discretionary (XLY)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLY",
	// 	},
	// 	{
	// 		name: "Consumer Staples (XLP)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLP",
	// 	},
	// 	{
	// 		name: "Energy (XLE)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLE",
	// 	},
	// 	{
	// 		name: "Financials (XLF)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLF",
	// 	},
	// 	{
	// 		name: "Health Care (XLV)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLV",
	// 	},
	// 	{
	// 		name: "Materials (XLB)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLB",
	// 	},
	// 	{
	// 		name: "Real Estate (XLRE)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLRE",
	// 	},
	// 	{
	// 		name: "Technology (XLK)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLK",
	// 	},
	// 	{
	// 		name: "Utilities (XLU)",
	// 		exchange: "AMEX",
	// 		Ticker: "XLU",
	// 	},
	// ];

	const checkSector = (sector, type) => {
		const sectorName = sector.name;
		let currentSectors;
		if (type === "defensive") {
			currentSectors = defensiveSectors;
		} else {
			currentSectors = cyclicalSectors;
		}
		const found = currentSectors.find(element => {
			if (element.name === sectorName) {
				return true;
			} else {
				return false;
			}
		});
		return found ? true : false;
	};
	const handleCheckbox = (event, sectorData, type) => {
		const isChecked = event.target.checked;
		const name = sectorData.name;
		if (name && isChecked) {
			if (type === "defensive") {
				defensiveSectors.push(sectorData);
				setDefensiveSectors(defensiveSectors);
			} else {
				cyclicalSectors.push(sectorData);
				setCyclicalSectors(cyclicalSectors);
			}

			return;
		} else if (name && !isChecked) {
			if (type === "defensive") {
				setDefensiveSectors(defensiveSectors.filter(sector => sector.name !== name));
			} else {
				setCyclicalSectors(cyclicalSectors.filter(sector => sector.name !== name));
			}

			return;
		}
	};
	const SectorButtons = () => {
		let className = "sectorButtons";
		return (
			<div className={className}>
				<SoftButton
					onClick={() => {
						setExchange("Indices");
					}}
					className={exchange === "Indices" ? "greyDropdown" : "blackText"}
					variant={exchange !== "Indices" ? "text" : "contained"}
				>
					Indices
				</SoftButton>
				<SoftButton
					onClick={() => {
						setExchange("COMMODITY");
					}}
					className={exchange === "COMMODITY" ? "greyDropdown" : "blackText"}
					variant={exchange !== "COMMODITY" ? "text" : "contained"}
				>
					COMMODITY
				</SoftButton>
				<SoftButton
					onClick={() => {
						setExchange("MONEY");
					}}
					className={exchange === "MONEY" ? "greyDropdown" : "blackText"}
					variant={exchange !== "MONEY" ? "text" : "contained"}
				>
					MONEY
				</SoftButton>
				<SoftButton
					onClick={() => {
						setExchange("1T HOF");
					}}
					className={exchange === "1T HOF" ? "greyDropdown" : "blackText"}
					variant={exchange !== "1T HOF" ? "text" : "contained"}
				>
					1T HOF
				</SoftButton>
			</div>
		);
	};

	const NewsButtons = () => {
		let className = "sectorButtons";
		return (
			<div className={className}>
				<SoftButton
					onClick={() => {
						setNewsCategory("general");
						handleNews("general");
					}}
					className={newsCategory === "general" ? "greyDropdown" : "blackText"}
					variant={newsCategory !== "general" ? "text" : "contained"}
				>
					General
				</SoftButton>
				<SoftButton
					onClick={() => {
						setNewsCategory("forex");
						handleNews("forex");
					}}
					className={newsCategory === "forex" ? "greyDropdown" : "blackText"}
					variant={newsCategory !== "forex" ? "text" : "contained"}
				>
					Forex
				</SoftButton>
				<SoftButton
					onClick={() => {
						setNewsCategory("crypto");
						handleNews("crypto");
					}}
					className={newsCategory === "crypto" ? "greyDropdown" : "blackText"}
					variant={newsCategory !== "crypto" ? "text" : "contained"}
				>
					Crypto
				</SoftButton>
				<SoftButton
					onClick={() => {
						setNewsCategory("merger");
						handleNews("merger");
					}}
					className={newsCategory === "merger" ? "greyDropdown" : "blackText"}
					variant={newsCategory !== "merger" ? "text" : "contained"}
				>
					Merger
				</SoftButton>
			</div>
		);
	};

	const sectorHTML = (exchange, ticker) => {
		return `<!-- TradingView Widget BEGIN -->
	<div class="tradingview-widget-container">
	  <div id="tradingview_fb944"></div>
	
	  <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
	  <script type="text/javascript">
	  new TradingView.MediumWidget(
	  {
	  "symbols": [
		[
		  " ${exchange}:${ticker}| 12M"
		]
	  ],
	  "chartOnly": true,
	  "width": "100%",
	  "height": "130",
	  "locale": "en",
	  "colorTheme": "light",
	  "gridLineColor": "rgba(42 ,46, 57, 0)",
	  "fontColor": "#787B86",
	  "isTransparent": false,
	  "autosize": true,
	  "showVolume": false,
	  "scalePosition": "no",
	  "scaleMode": "Normal",
	  "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
	  "noTimeScale": false,
	  "valuesTracking": "1",
	  "chartType": "line",
	  "container_id": "tradingview_fb944"
	}
	  );
	  </script>
	</div>
	<!-- TradingView Widget END -->`;
	};

	const moneyHTML = `<!-- TradingView Widget BEGIN -->
	<div class="tradingview-widget-container">
	  <div class="tradingview-widget-container__widget"></div>
	  <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com" rel="noopener" target="_blank"><span class="blue-text">Crypto</span></a> by TradingView</div>
	  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js" async>
	  {
	  "colorTheme": "light",
	  "dateRange": "12M",
	  "showChart": true,
	  "locale": "en",
	  "largeChartUrl": "",
	  "isTransparent": false,
	  "showSymbolLogo": true,
	  "showFloatingTooltip": false,
	  "width": "100%",
  	  "height": "400",
	  "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
	  "plotLineColorFalling": "rgba(41, 98, 255, 1)",
	  "gridLineColor": "rgba(240, 243, 250, 0)",
	  "scaleFontColor": "rgba(106, 109, 120, 1)",
	  "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
	  "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
	  "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
	  "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
	  "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
	  "tabs": [
		{
		  "title": "Bonds",
		  "symbols": [
			{
				"s": "CBOT:ZB1!",
				"d": "U.S. TREASURY BOND FUTURES"
			  },
			  {
				"s": "NASDAQ:TLT",
				"d": "iShares 20+ Year Treasury Bond"
			  },
			  {
				"s": "AMEX:BOND",
				"d": "PIMCO Active Bond ETF"
			  },
			  {
				"s": "FOREXCOM:EURUSD",
				"d": "EUR/USD"
			  }
		  ],
		  "originalTitle": "Bonds"
		},
		{
		  "title": "Forex",
		  "symbols": [
			{
			  "s": "FX:EURUSD",
			  "d": "EUR/USD"
			},
			{
			  "s": "FX:GBPUSD",
			  "d": "GBP/USD"
			},
			{
			  "s": "FX:USDJPY",
			  "d": "USD/JPY"
			},
			{
			  "s": "FX:USDCHF",
			  "d": "USD/CHF"
			},
			{
			  "s": "FX:AUDUSD",
			  "d": "AUD/USD"
			},
			{
			  "s": "FX:USDCAD",
			  "d": "USD/CAD"
			}
		  ],
		  "originalTitle": "Forex"
		},
		{
		  "title": "Crypto",
		  "symbols": [
			{
			  "s": "BITSTAMP:BTCUSD"
			},
			{
			  "s": "CRYPTOCAP:SHIB"
			},
			{
			  "s": "CRYPTOCAP:ETH"
			},
			{
			  "s": "CRYPTOCAP:BCH"
			},
			{
			  "s": "CRYPTOCAP:LTC"
			},
			{
			  "s": "CRYPTOCAP:XRP"
			},
			{
			  "s": "CRYPTOCAP:1INCH"
			},
			{
			  "s": "CRYPTOCAP:ADA"
			},
			{
			  "s": "CRYPTOCAP:AAVE"
			},
			{
			  "s": "CRYPTOCAP:ATOM"
			}
		  ]
		}
	  ]
	}
	  </script>
	</div>
	<!-- TradingView Widget END -->`;

	const commodityHTML = `
	<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js" async>
  {
  "colorTheme": "light",
  "dateRange": "12M",
  "showChart": true,
  "locale": "en",
  "width": "100%",
  "height": "400",
  "largeChartUrl": "",
  "isTransparent": false,
  "showSymbolLogo": true,
  "showFloatingTooltip": false,
  "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
  "plotLineColorFalling": "rgba(41, 98, 255, 1)",
  "gridLineColor": "rgba(240, 243, 250, 0)",
  "scaleFontColor": "rgba(120, 123, 134, 1)",
  "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
  "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
  "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
  "tabs": [
    {
      "title": "Commodities",
      "symbols": [
        {
          "s": "COMEX:GC1!",
          "d": "Gold"
        },
        {
          "s": "COMEX:SI1!",
          "d": "Silver"
        },
        {
          "s": "COMEX:HG1!",
          "d": "Copper"
        },
        {
          "s": "NYMEX:CL1!",
          "d": "Crude Oil"
        },
        {
          "s": "NYMEX:NG1!",
          "d": "Natural Gas"
        },
        {
          "s": "CBOT:ZC1!",
          "d": "Corn"
        }
      ],
      "originalTitle": "Futures"
    }
  ]
}
  </script>
</div>
<!-- TradingView Widget END -->
	`;
	const indicesHTML = `
	<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js" async>
  {
  "colorTheme": "light",
  "dateRange": "12M",
  "showChart": true,
  "locale": "en",
  "width": "100%",
  "height": "400",
  "largeChartUrl": "",
  "isTransparent": false,
  "showSymbolLogo": true,
  "showFloatingTooltip": false,
  "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
  "plotLineColorFalling": "rgba(41, 98, 255, 1)",
  "gridLineColor": "rgba(240, 243, 250, 0)",
  "scaleFontColor": "rgba(120, 123, 134, 1)",
  "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
  "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
  "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
  "tabs": [
    {
      "title": "Market Indices (US)",
      "symbols": [
        {
          "s": "FOREXCOM:SPXUSD",
          "d": "S&P 500"
        },
        {
          "s": "FOREXCOM:DJI",
          "d": "Dow 30"
        },
        {
          "s": "FXOPEN:NDQM",
          "d": "Nasdaq Composite"
        },
        {
          "s": "CAPITALCOM:RTY",
          "d": "Russell 2000 Index"
        },
        {
          "s": "PEPPERSTONE:VIX",
          "d": "Volatility Index"
        }
      ],
      "originalTitle": "Indices"
    },
    {
      "title": "Market Indices (Key Mkts)",
      "symbols": [
        {
          "s": "CAPITALCOM:J225",
          "d": "Nikkei 225 Japan"
        },
        {
          "s": "SSE:000001",
          "d": "SSE Composite Index China"
        },
        {
          "s": "HSI:HSI",
          "d": "Hang Seng Index Hong Kong"
        },
        {
          "s": "XETR:DAX",
          "d": "DAX Index Germany"
        },
        {
          "s": "FOREXCOM:UKXGBP",
          "d": "FTSE 100 Index United Kingdom"
        }
      ]
    },
    {
      "title": "Market Index (ASEAN)",
      "symbols": [
        {
          "s": "INDEX:STI",
          "d": "Straits Times Index Singapore"
        },
        {
          "s": "INDEX:KLSE",
          "d": "FTSE Bursa Malaysia KLCI"
        },
        {
          "s": "BSE:SENSEX",
          "d": "BSE Sensex India"
        }
      ]
    }
  ]
}
  </script>
</div>
<!-- TradingView Widget END -->
	`;
	const hof = `<!-- TradingView Widget BEGIN -->
	<div class="tradingview-widget-container">
	  <div class="tradingview-widget-container__widget"></div>
	  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js" async>
	  {
	  "colorTheme": "light",
	  "dateRange": "12M",
	  "showChart": true,
	  "locale": "en",
	  "width": "100%",
	  "height": "400",
	  "largeChartUrl": "",
	  "isTransparent": false,
	  "showSymbolLogo": true,
	  "showFloatingTooltip": false,
	  "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
	  "plotLineColorFalling": "rgba(41, 98, 255, 1)",
	  "gridLineColor": "rgba(240, 243, 250, 0)",
	  "scaleFontColor": "rgba(120, 123, 134, 1)",
	  "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
	  "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
	  "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
	  "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
	  "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
	  "tabs": [
		{
		  "title": "1T Hall of Fame LIST",
		  "symbols": [
			{
			  "s": "NASDAQ:AAPL",
			  "d": "Apple Inc."
			},
			{
			  "s": "NASDAQ:GOOG",
			  "d": "Alphabet (Google)"
			},
			{
			  "s": "NASDAQ:AMZN",
			  "d": "Amazon"
			},
			{
			  "s": "NASDAQ:FB",
			  "d": "Meta (Facebook)"
			},
			{
			  "s": "NASDAQ:MSFT",
			  "d": "Microsoft"
			},
			{
			  "s": "NASDAQ:TSLA",
			  "d": "Tesla"
			}
		  ]
		}
	  ]
	}
	  </script>
	</div>
	<!-- TradingView Widget END -->`;
	const spxHTMLTicker = `
	<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js" async>
  {
  "symbol": "OANDA:SPX500USD",
  "width": "100%",
  "height":"100%",
  "locale": "en",
  "colorTheme": "light",
  "isTransparent": false
}
  </script>
</div>
<!-- TradingView Widget END -->
	`;
	const spxHTMLMeter = `
	<div class="tradingview-widget-container">
	  <div class="tradingview-widget-container__widget"></div>
	  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js" async>
	  {
	  "interval": "1m",
	  "isTransparent": false,
	  "height":"370",
	  "width":"100%",
	  "symbol": "SP:SPX",
	  "showIntervalTabs": true,
	  "locale": "en",
	  "colorTheme": "light"
	}
	  </script>
	</div>
	`;

	// 	const htmlNews = `<html><body>
	// 	<script type="text/javascript">DukascopyApplet = {"type":"online_news","params":{"header":false,"borders":false,"defaultLanguage":"en","availableLanguages":["ar","bg","cs","de","en","es","fa","fr","he","hu","it","ja","ms","pl","pt","ro","ru","sk","sv","th","uk","zh"],"newsCategories":["finance","forex","stocks","company_news","commodities"],"width":"100%","height":"500","adv":"popup"}};</script><script type="text/javascript" src="https://freeserv-static.dukascopy.com/2.0/core.js"></script>
	// </body></html>`;
	//fred iframes
	const fredInflation = `<iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=OYov&width=100%&height=330" scrolling="no" frameborder="0" style="overflow:hidden; width:100%; height:350px;" allowTransparency="true" loading="lazy"></iframe>`;
	const fredInterestRates = `<iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=PxTa&width=100%&height=330" scrolling="no" frameborder="0" style="overflow:hidden; width:100%; height:350px;" allowTransparency="true" loading="lazy"></iframe>`;
	const fredTreasuryYield = `<iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=PxXI&width=100%&height=330" scrolling="no" frameborder="0" style="overflow:hidden; width:100%; height:350px;" allowTransparency="true" loading="lazy"></iframe>`;
	const fredCostPrice = `<iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=OYuh&width=100%&height=330" scrolling="no" frameborder="0" style="overflow:hidden; width:100%; height:350px;" allowTransparency="true" loading="lazy"></iframe>`;
	const fredProducerPrice = `<iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=OYwl&width=100%&height=330" scrolling="no" frameborder="0" style="overflow:hidden; width:100%; height:350px;" allowTransparency="true" loading="lazy"></iframe>`;
	const fredJobless = `<iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=OYuX&width=100%&height=330" scrolling="no" frameborder="0" style="overflow:hidden; width:100%; height:350px;" allowTransparency="true" loading="lazy"></iframe>`;
	const tickerTape =
		"https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22FOREXCOM%3ANSXUSD%22%2C%22title%22%3A%22US%20100%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22Ethereum%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A78%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fticker-tape%2F%22%7D";
	return (
		<DashboardLayout>
			{/* <DashboardNavbar  type="bbv" pageTitle="general statistics" /> */}
			{/* <div className="goldOutline">
        	</div> */}
			<BBVDashboardNavbar type="bbv" pageTitle="BBV Dashboard"></BBVDashboardNavbar>
			<Slider />
			<div style={{ backgroundColor: "white", borderRadius: "15px", marginTop: "20px" }}>
				{/* <FearGreed className="fearGreed"/> */}
				<Grid container style={{ width: "80%", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
					<Grid justifyContent="center" alignContent="center" item xl={12} lg={12} md={12} sm={12} xs={12}>
						<h3 className="roboto" style={{ paddingTop: "50px" }}>
							Is this for you?
						</h3>
					</Grid>
					<Grid justifyContent="center" alignContent="center" item xl={12} lg={12} md={12} sm={12} xs={12}>
						<h6
							className="roboto darkGrey"
							style={{ fontSize: "18px", paddingTop: "30px", paddingBottom: "40px" }}
						>
							Bull Bear Vector is build for investors by investors. The platform is created for beginners
							to seasoned investors, using methodologies backed by investment gurus like Warren Buffett,
							Ray Dalio, Joel Greenblatt, etc. Head over to the features page to know what suit you most.
						</h6>
					</Grid>
					<Grid
						style={{ paddingBottom: "46px" }}
						justifyContent="center"
						alignContent="center"
						spacing={4}
						container
					>
						<Grid justifyContent="center" alignContent="center" item xl={4} lg={4} md={4} sm={12} xs={12}>
							<img src={IconLeft} alt="clock"></img>
							<h4 className="roboto titleGrey" style={{ fontSize: "24px", height: "80px" }}>
								Don’t have time to research?
							</h4>
							<p className="roboto_medium darkGrey" style={{ fontSize: "16px", paddingTop: "6px" }}>
								We know that you might be busy with work, family, and a healthy lifestyle, BBV can help
								you cut short your time to find strong companies.
							</p>
						</Grid>
						<Grid justifyContent="center" alignContent="center" item xl={4} lg={4} md={4} sm={12} xs={12}>
							<img src={IconMiddle} alt="clock"></img>
							<h4 className="roboto titleGrey" style={{ fontSize: "24px", height: "80px" }}>
								Not sure what to do?
							</h4>
							<p className="roboto_medium darkGrey" style={{ fontSize: "16px", paddingTop: "6px" }}>
								Financial literacy is important! You don't want to speculate and invest whatever is out
								there, BBV can help you find strong stocks, scan fat options and even give you
								direction!
							</p>
						</Grid>
						<Grid justifyContent="center" alignContent="center" item xl={4} lg={4} md={4} sm={12} xs={12}>
							<img src={IconRight} alt="clock"></img>
							<h4 className="roboto titleGrey" style={{ fontSize: "24px", height: "80px" }}>
								How to profit with ease?
							</h4>
							<p className="roboto_medium darkGrey" style={{ fontSize: "16px", paddingTop: "6px" }}>
								Some of us might want to see profit immediately, but patience and due diligence is key
								to be profitable. BBV can help you identify opportunities, and market sentiments,
								showing you risks and rewards.
							</p>
						</Grid>
					</Grid>
				</Grid>
			</div>

			<Grid justifyContent="space-between" style={{ textAlign: "center" }} container>
				<Grid className="dashboardCards" item>
					<img className="dashboardCardsImg" src={ClockIcon} alt="clock"></img>
					<h4 className="roboto" style={{ fontSize: "24px" }}>
						Save Time
					</h4>
					<p className="roboto_medium darkGrey" style={{ fontSize: "16px", paddingTop: "10px" }}>
						You save time researching for strong companies in just less than 15 mins!
					</p>
				</Grid>
				<Grid className="dashboardCards" item>
					<img className="dashboardCardsImg" src={Effort} alt="clock"></img>
					<h4 className="roboto" style={{ fontSize: "24px" }}>
						Reduce Effort
					</h4>
					<p className="roboto_medium darkGrey" style={{ fontSize: "16px", paddingTop: "10px" }}>
						You dont need to be a geek of financially savvy to start researching and make informed decisions
						to invest
					</p>
				</Grid>
				<Grid className="dashboardCards" item>
					<img className="dashboardCardsImg" src={direction} alt="clock"></img>
					<h4 className="roboto" style={{ fontSize: "24px" }}>
						Give Direction
					</h4>
					<p className="roboto_medium darkGrey" style={{ fontSize: "16px", paddingTop: "10px" }}>
						You gain clarity and understood how to invest the right method
					</p>
				</Grid>
				<Grid className="dashboardCards" item>
					<img className="dashboardCardsImg" src={lackMoney} alt="clock"></img>
					<h4 className="roboto" style={{ fontSize: "24px" }}>
						Stay Safe
					</h4>
					<p className="roboto_medium darkGrey" style={{ fontSize: "16px", paddingTop: "10px" }}>
						You with clarity, invest safely with the help of BBV
					</p>
				</Grid>
			</Grid>
			<div style={{ backgroundColor: "white", paddingTop: "15px", marginTop: "20px", borderRadius: "15px" }}>
				<iframe
					className="tickerTape"
					title="technical3"
					style={{ width: "100%", border: "none" }}
					src={tickerTape}
				></iframe>
				{/* <p className="centerText" style={{ paddingBottom: "8px" }}>
					CII STREAMLINE (customized)
				</p> */}
			</div>

			<Grid style={{ marginTop: "20px" }}>
				<Grid className="sentimentsCard centerText" item>
					<div
						style={{
							paddingTop: "18px",
						}}
					>
						<h3 className="roboto">Sentiments</h3>
					</div>
					<Grid container style={{}} justifyContent="space-between">
						<Grid item className="dashboardCardsSentiments" style={{ maxWidth: "780px" }}>
							<div
								style={{
									marginTop: "18px",
									display: "flex",
									justifyContent: "center",
									textAlign: "center",
									alignItems: "center",
								}}
							>
								<p
									className="roboto"
									style={{ textAlign: "center", fontSize: "18px", marginRight: "5px" }}
								>
									S&P 500
								</p>
								<InfoIcon
									onClick={() => {
										handleInfoPop("snp");
									}}
									className="icon"
								></InfoIcon>
							</div>
							<iframe
								className="iframeDashboard"
								style={{ width: "100%", height: "247px" }}
								title="spx ticker info"
								srcDoc={spxHTMLTicker}
							></iframe>
							<iframe
								className="iframeDashboard spxMeter"
								style={{ width: "100%", height: "400px" }}
								title="spx meter"
								srcDoc={spxHTMLMeter}
							></iframe>
						</Grid>
						<Grid item className="dashboardCardsSentiments">
							<div
								style={{
									marginTop: "18px",
									display: "flex",
									justifyContent: "center",
									textAlign: "center",
									alignItems: "center",
								}}
							>
								<p
									className="roboto"
									style={{ textAlign: "center", fontSize: "18px", marginRight: "5px" }}
								>
									Fear & Greed Index
								</p>
								<InfoIcon
									onClick={() => {
										handleInfoPop("fearAndGreed");
									}}
									className="icon"
								></InfoIcon>
							</div>
							<div style={{ padding: "40px" }}>
								<FearGreedIndexComponent
									lastUpdated={fearData.lastUpdated.humanDate}
									previousClose={fearData.fgi.previousClose.value}
									oneMonthAgo={fearData.fgi.oneMonthAgo.value}
									oneWeekAgo={fearData.fgi.oneWeekAgo.value}
									oneYearAgo={fearData.fgi.oneYearAgo.value}
									fearIndex={fearData.fgi.now.value}
								></FearGreedIndexComponent>
							</div>
							{/* <iframe className="iframeDashboard" title="spx meter" srcDoc={spxHTMLMeter}></iframe> */}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				className="trend_economic_grid"
				justifyContent="space-between"
				style={{ textAlign: "center", height: "", marginTop: "20px" }}
				container
			>
				<Grid className="dashboardTrendCard" item>
					<div
						style={{
							marginTop: "18px",
							display: "flex",
							justifyContent: "center",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<h3 className="roboto" style={{ textAlign: "center", marginRight: "5px" }}>
							Trend
						</h3>
						<InfoIcon
							onClick={() => {
								handleInfoPop("trend");
							}}
							className="icon"
						></InfoIcon>
					</div>
					<Grid
						container
						justifyContent="space-between"
						style={{ textAlign: "left", marginTop: "15px", overflow: "auto" }}
					>
						<Grid item className="dashboardCardsSentiments" style={{ height: "auto" }}>
							<SectorButtons />
							<iframe
								style={{ width: "100%", height: "440px" }}
								className="iframeDashboard"
								title="indices"
								srcDoc={configureHTML()}
							></iframe>
						</Grid>
						<Grid item className="dashboardCardsSentiments" style={{ height: "auto" }}>
							<Grid item className="dashboardCardsSectors" style={{ height: "auto" }}>
								<div style={{ marginLeft: "10px" }}>
									<BasicMenu type="defensive" />
								</div>
								<div style={{ height: "400px", overflow: "auto", padding: "10px" }}>
									{defensiveSectors.map((sector, index) => {
										return (
											<div key={index} style={{ marginBottom: "15px" }}>
												<p
													className="roboto_medium"
													style={{ paddingLeft: "10px", fontSize: "16px" }}
												>
													{sector.name}
												</p>
												<SmallSymbolOverviewWidget
													exchange={sector.exchange}
													ticker={sector.Ticker}
													duration="12M"
												/>
											</div>
										);
									})}
								</div>
							</Grid>
							<Grid item className="dashboardCardsSectors" style={{ height: "auto" }}>
								<div style={{ marginLeft: "10px" }}>
									<BasicMenu type="cyclical" />
								</div>
								<div style={{ height: "400px", overflow: "auto", padding: "10px" }}>
									{cyclicalSectors.map((sector, index) => {
										return (
											<div key={index} style={{ marginBottom: "15px" }}>
												<p
													className="roboto_medium"
													style={{ paddingLeft: "10px", fontSize: "16px" }}
												>
													{sector.name}
												</p>
												<SmallSymbolOverviewWidget
													exchange={sector.exchange}
													ticker={sector.Ticker}
													duration="12M"
												/>
											</div>
										);
									})}
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				className="news_sentiment_grid"
				justifyContent="space-between"
				style={{ textAlign: "center", marginTop: "20px" }}
				container
			>
				<Grid
					className="dashboardCardsLg newsGrid"
					style={{ backgroundColor: "white", borderRadius: "15px" }}
					item
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<h3 className="roboto" style={{ textAlign: "center", marginRight: "5px" }}>
							News
						</h3>
						<InfoIcon
							onClick={() => {
								handleInfoPop("news");
							}}
							className="icon"
						></InfoIcon>
					</div>
					<NewsButtons />
					<NewsTable category={newsCategory} data={newsData} />
					{/* <iframe className="newsIframe iframeDashboard " srcDoc={htmlNews} title="efa"></iframe> */}
				</Grid>
				<Grid className="dashboardCardsSpecialSmOne economicCalendar" item>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<h3 className="roboto" style={{ textAlign: "center", marginRight: "5px" }}>
							Economic Calendar
						</h3>
						<InfoIcon
							onClick={() => {
								handleInfoPop("economicCalender");
							}}
							className="icon"
						></InfoIcon>
					</div>
					<EconomicCalendar />
				</Grid>
			</Grid>
			<Grid className="dashboardsCardsFull portfolioCard" style={{ overflowX: "auto", overflowY: "hidden" }} item>
				{/* <p className="roboto" style={{ fontSize: "18px", paddingTop: "18px",paddingLeft:'20px' }}>
						Portfolio
					</p>
					<div style={{ backgroundColor: "white", borderRadius: "16px" }}>
						<div className="portfolioHeader"></div>
						<div className="portfolioDiv">
							<img src={rocketImg} alt="wrench" />
							<p className="helvetica inline " style={{ fontSize: "14px", paddingLeft: "15px" }}>
								Total Stocks <br></br>
								<span className="portfolioAmt">250 in stock</span>{" "}
							</p>
							<span></span>
							<ChevronRightIcon />
						</div>
						<div className="portfolioDiv">
							<img src={wrenchImg} alt="wrench" />
							<p className="helvetica inline" style={{ fontSize: "14px", paddingLeft: "15px" }}>
								Total Options <br></br> <span className="portfolioAmt">250 in stock</span>
							</p>
							<span></span>
							<ChevronRightIcon />
						</div>
						<div className="portfolioDiv">
							<img src={cryptoImg} alt="crpyto" />
							<p className="helvetica inline" style={{ fontSize: "14px", paddingLeft: "15px" }}>
								Total Crypto <br></br>
								<span className="portfolioAmt">250 in stock</span>
							</p>
							<span></span>
							<ChevronRightIcon />
						</div>
					</div> */}
				<div
					style={{
						marginBottom: "40px",
						display: "flex",
						justifyContent: "center",
						textAlign: "center",
						alignItems: "center",
					}}
				>
					<h3 className="roboto" style={{ textAlign: "center", marginRight: "5px" }}>
						Macro Economics
					</h3>
					<InfoIcon
						onClick={() => {
							handleInfoPop("macroEconomic");
						}}
						className="icon"
					></InfoIcon>
				</div>
				<div>
					<div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
						<div style={{ minHeight: "420px" }}>
							<div className="iframeText">
								<div className="redDot" />
								<p style={{ display: "inline-block", marginLeft: "5px", fontSize: "16px" }}>
									Inflation
								</p>
							</div>
							<iframe
								className="iframeDashboard fredIframe"
								title="economic calendar"
								srcDoc={fredInflation}
							></iframe>
						</div>
						<div style={{ minHeight: "420px" }}>
							<div className="iframeText">
								<div className="redDot" />
								<p style={{ display: "inline-block", marginLeft: "5px", fontSize: "16px" }}>
									Interest Rates
								</p>
							</div>
							<iframe
								className="iframeDashboard fredIframe"
								title="economic calendar"
								srcDoc={fredInterestRates}
							></iframe>
						</div>
						<div style={{ minHeight: "420px" }}>
							<div className="iframeText">
								<div className="redDot" />
								<p style={{ display: "inline-block", marginLeft: "5px", fontSize: "16px" }}>
									Treasury Yields
								</p>
							</div>
							<iframe
								className="iframeDashboard fredIframe"
								title="economic calendar"
								srcDoc={fredTreasuryYield}
							></iframe>
						</div>
					</div>
				</div>
				<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
					<hr class="dashed" style={{ marginBottom: "20px" }}></hr>
				</div>
				<div>
					<div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
						<div style={{ minHeight: "420px" }}>
							<div className="iframeText">
								<div className="redDot" />
								<p style={{ display: "inline-block", marginLeft: "5px", fontSize: "16px" }}>
									Cost Price Index
								</p>
							</div>
							<iframe
								className="iframeDashboard fredIframe"
								title="economic calendar"
								srcDoc={fredCostPrice}
							></iframe>
						</div>
						<div style={{ minHeight: "420px" }}>
							<div className="iframeText">
								<div className="redDot" />
								<p style={{ display: "inline-block", marginLeft: "5px", fontSize: "16px" }}>
									Producer Price Index
								</p>
							</div>
							<iframe
								className="iframeDashboard fredIframe"
								title="economic calendar"
								srcDoc={fredProducerPrice}
							></iframe>
						</div>
						<div style={{ minHeight: "420px" }}>
							<div className="iframeText">
								<div className="greenDot" />
								<p style={{ display: "inline-block", marginLeft: "5px", fontSize: "16px" }}>
									Jobless Claims
								</p>
							</div>
							<iframe
								className="iframeDashboard fredIframe"
								title="economic calendar"
								srcDoc={fredJobless}
							></iframe>
						</div>
					</div>
				</div>
			</Grid>
			{/* <Grid className="dashboardsCardsFull portfolioCard" style={{overflowX:"auto",overflowY:"hidden"}} item>
					<div style={{height:"370px",minWidth:"1080px"}}>
						<div style={{marginTop:"10px"}}>
							<div style={{display:"inline-block",width:"33%"}}>
								<div className="redDot" />
								<p style={{display:"inline-block", marginLeft:"5px", fontSize:"16px"}}>Cost Price Index</p> 
							</div>
							<div style={{display:"inline-block",width:"33%"}}>
								<div className="redDot" />
								<p style={{display:"inline-block", marginLeft:"5px", fontSize:"16px"}}>Producer Price Index</p> 
							</div>
							<div style={{display:"inline-block",width:"33%"}}>
								<div className="greenDot" />
								<p style={{display:"inline-block", marginLeft:"5px", fontSize:"16px"}}>Jobless Claims</p> 
							</div>
						</div>
						<iframe
							className="iframeDashboard fredIframe"
							title="economic calendar"
							srcDoc={fredCostPrice}
						></iframe>
						<iframe
							className="iframeDashboard fredIframe"

							title="economic calendar"
							srcDoc={fredProducerPrice}
						></iframe>
						<iframe
							className="iframeDashboard fredIframe"
							title="economic calendar"
							srcDoc={fredJobless}
						></iframe>
					</div>
			</Grid> */}
			{/* <Grid className="dashboardsCardsFull portfolioCard" item>
					<iframe
						className="iframeDashboard"
						style={{ width: "", height: "100%" }}
						title="economic calendar"
						srcDoc={fredCostPrice}
					></iframe>
					<iframe
						className="iframeDashboard"
						style={{ width: "100%", height: "100%" }}
						title="economic calendar"
						srcDoc={fredProducerPrice}
					></iframe>
					<iframe
						className="iframeDashboard"
						style={{ width: "100%", height: "100%" }}
						title="economic calendar"
						srcDoc={fredJobless}
					></iframe>
			</Grid> */}
			<Grid
				justifyContent="center"
				style={{
					textAlign: "center",
					backgroundColor: "white",
					borderRadius: "15px",
					marginTop: "20px",
					paddingTop: "20px",
				}}
				container
			>
				<h3 className="roboto" style={{ marginBottom: "30px" }}>
					What our users are saying
				</h3>
				{/* <iframe title="testimonial" id="testimonialto-bull-bear-vector-light-animated" src="https://embed-v2.testimonial.to/w/bull-bear-vector?animated=on&theme=light&shadowColor=ffffff" frameBorder="0" scrolling="no" width="100%" height="800px"></iframe> */}
				<iframe
					title="testimonial"
					id="testimonialto-grid-selected-bull-bear-vector-light-animated"
					src="https://embed.testimonial.to/w/bull-bear-vector?animated=on&theme=light&shadowColor=ffffff"
					frameBorder="0"
					scrolling="no"
					width="100%"
					height="650px"
				></iframe>
			</Grid>

			<Grid
				className="bbvRed"
				container
				style={{
					marginTop: "20px",
					marginLeft: "auto",
					marginRight: "auto",
					textAlign: "center",
					padding: "35px",
					borderRadius: "15px",
				}}
			>
				<Grid justifyContent="center" alignContent="center" item xl={12} lg={12} md={12} sm={12} xs={12}>
					<h2
						className="footerText roboto"
						style={{
							color: "white",
							marginLeft: "auto",
							marginRight: "auto",
							fontSize: "36px",
							marginBottom: "26px",
						}}
					>
						Do you need help how to find a stock & option in 5 min
					</h2>
				</Grid>
				<Grid justifyContent="center" alignContent="center" item xl={12} lg={12} md={12} sm={12} xs={12}>
					<p
						className="footerText roboto"
						style={{
							color: "white",
							paddingBottom: "40px",
							fontSize: "18px",
							marginLeft: "auto",
							marginRight: "auto",
							marginBottom: "15px",
						}}
					>
						Complementary workshop: Discover 4 proven strategies to explode your wealth even if the market
						crashes this month
					</p>
				</Grid>
				<Grid justifyContent="center" alignContent="center" item xl={12} lg={12} md={12} sm={12} xs={12}>
					<Button className="bbvGold" variant="contained">
						Book your seat
					</Button>
				</Grid>
			</Grid>

			<Footer />
			{infoPopup ? (
				<DashboardInfoPopup toggleInfoPopup={toggleInfoPopup} popupAccessor={popupAccessor} />
			) : (
				<div></div>
			)}
		</DashboardLayout>
	);
}

export default Default;
