import Popup from 'layouts/applications/popup/Popup';
import CloseIcon from '@mui/icons-material/Close';
import SoftTypography from 'components/SoftTypography';
import RbsImage from '../../../assets/images/rbs.jpg'
import BpsImage from '../../../assets/images/bps.jpg'
import BcsImage from '../../../assets/images/bcs.jpg'
import FisImage from '../../../assets/images/fis.jpg'
import { RedBox } from 'components/CustomComponents';
import { Card } from '@mui/material';
export const FISPopup = ({ toggleInfoPopup }) => {
    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"Funded Income Strategy"}></RedBox>
            <SoftTypography className="popupTextStyle">Funded Income Strategy (FIS) is the enhanced version of the Wheel Strategy which takes in only strong companies with good entry settings on the price chart.</SoftTypography>
            <br></br>
            <SoftTypography className="popupTextStyle">FIS is a systematic way to sell option cash-secured puts and covered calls as part of a long-term trading methodology. In essence, you keep selling options on stocks that you are bullish on, to generate monthly income. Basically, you repeatedly sell monthly cash-secured puts to collect monthly income. If you happened to get assigned with stocks, you can then sell monthly stock-secured calls to collect income again.FIS helps you to collect monthly income and reduce the chances of getting assigned.</SoftTypography>
            <br></br>
            <strong className='popupGrey'>Sell Put (Covered)</strong>
            <br></br>
            <SoftTypography className="popupTextStyle">By selling a cash-covered put, you can collect money (the premium) from the option buyer. The buyer pays this premium for the right to sell you shares of stock, any time before expiration, at the strike price. The premium you receive allows you to lower your overall purchase price if you get assigned the shares.</SoftTypography>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img alt="rbs" src={FisImage} style={{ width: "280px" }} />
            </div>
        </Card>
    </Popup>
}

export const RBSPopup = ({ toggleInfoPopup }) => {
    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"Rolling Boat Strategy"}></RedBox>
            <SoftTypography className="popupTextStyle">Rolling Boat Strategy (RBS) is the enhanced version of the Long Calendar Spread which takes in only strong companies with good entry settings on the price chart.
            </SoftTypography>
            <br></br>
            <SoftTypography className="popupTextStyle">RBS is established by simultaneously entering a long and short position on the same underlying asset but with different delivery dates. In a typical calendar spread, one would buy a longer-term contract and go short a nearer-term option with the same strike price.Basically, you buy a call position with a longer expiry date for capital gains and then sell monthly calls to collect income.RBS helps you to collect monthly income and get protected at the same time.</SoftTypography>
            <br></br>

            <strong className='popupGrey'>Long Calendar Spread</strong>
            <br></br>
            <SoftTypography className="popupTextStyle">A calendar spread is an options or futures strategy established by simultaneously entering a long and short position on the same underlying asset but with different delivery dates. In a typical calendar spread, one would buy a longer-term contract and go short a nearer-term option with the same strike price.</SoftTypography>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img alt="rbs" src={RbsImage} style={{ width: "280px" }} />
            </div>
        </Card>
    </Popup>
}

export const BPSPopup = ({ toggleInfoPopup }) => {
    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"Multiple Tight Vertical Spread (Bull Put Spread)"}></RedBox>
            <SoftTypography className="popupTextStyle">Multiple Tight Vertical Spread (MTVS) is the enhanced version of the Bull Put Spread and Bear Call Spread which takes in only strong companies with good entry settings on the price chart.
            </SoftTypography>
            <br></br>
            <SoftTypography className="popupTextStyle">Bull Put spreads are credit spreads that consist of selling a put option & buying a put option at a lower price. The strategy takes advantage of a incline in price from the underlying asset before expiration.</SoftTypography>
            <br></br>
            <SoftTypography className="popupTextStyle">Bear call spreads are credit spreads that consist of selling a call option & buying a call option at a higher price. The strategy takes advantage of a decline in price from the underlying asset before expiration.
            </SoftTypography>
            <SoftTypography className="popupTextStyle">MTVS helps you to collect monthly income and get protected at the same time, with a tight spread.
            </SoftTypography>
            <br></br>
            <strong className='popupGrey'>Bull Put Spread</strong>
            <br></br>
            <SoftTypography className="popupTextStyle">The bull put spreads is a strategy that “collects option premium and limits risk at the same time.” They profit from both time decay and rising stock prices. A bull put spread is the strategy of choice when the forecast is for neutral to rising prices and there is a desire to limit risk.</SoftTypography>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img alt="rbs" src={BpsImage} style={{ width: "280px" }} />
            </div>
        </Card>
    </Popup>
}

export const BCSPopup = ({ toggleInfoPopup }) => {
    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"Multiple Tight Vertical Spread (Bear Call Spread)"}></RedBox>
            <SoftTypography className="popupTextStyle">Multiple Tight Vertical Spread (MTVS) is the enhanced version of the Bull Put Spread and Bear Call Spread which takes in only strong companies with good entry settings on the price chart.
            </SoftTypography>
            <br></br>
            <SoftTypography className="popupTextStyle">Bull Put spreads are credit spreads that consist of selling a put option & buying a put option at a lower price. The strategy takes advantage of a incline in price from the underlying asset before expiration.</SoftTypography>

            <br></br>
            <SoftTypography className="popupTextStyle">Bear call spreads are credit spreads that consist of selling a call option & buying a call option at a higher price. The strategy takes advantage of a decline in price from the underlying asset before expiration.</SoftTypography>

            <br></br>
            <SoftTypography className="popupTextStyle">MTVS helps you to collect monthly income and get protected at the same time, with a tight spread.</SoftTypography>
            <br></br>

            <strong className='popupGrey'>Bear Call spreads</strong>
            <br></br>
            <SoftTypography className="popupTextStyle">The bear call spreads is a strategy that “collects option premium and limits risk at the same time.” They profit from both time decay and falling stock prices. A bear call spread is the strategy of choice when the forecast is for neutral to falling prices and there is a desire to limit risk.</SoftTypography>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img alt="rbs" src={BcsImage} style={{ width: "280px" }} />
            </div>
        </Card>
    </Popup>
}


export const OptionInfoPopup = ({ toggleInfoPopup, popupAccessor }) => {

    switch (popupAccessor) {
        case 'fis':
            return <FISPopup toggleInfoPopup={toggleInfoPopup} />

        case 'rbs':
            return <RBSPopup toggleInfoPopup={toggleInfoPopup} />

        case 'bps':
            return <BPSPopup toggleInfoPopup={toggleInfoPopup} />

        case 'bcs':
            return <BCSPopup toggleInfoPopup={toggleInfoPopup} />

        default:
            return <BCSPopup toggleInfoPopup={toggleInfoPopup}></BCSPopup>;
    }
}
