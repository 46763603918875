import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, Typography, FormControl, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import { useWeb3 } from "context/web3";
import { networkName, shortAccount } from "layouts/Helper/blockchain.helper";
import { get as getValue } from "lodash";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

import { fetchCategories } from "services/marketplace";

const CollectionForm = ({ defaultValues, onCreate, isForFrontend, ...props }) => {
	const { web3Controller } = useWeb3();
	const { connected: connectedToBlockchain, ...blockchainInfo } = web3Controller;

	const [isLoading, setIsLoading] = useState(false);
	const [categories, setCategories] = useState([]);

	const thumbnail_path = useRef(null);

	useEffect(() => {
		setIsLoading(true);
		fetchCategories()
			.then(data => setCategories(data))
			.finally(() => setIsLoading(false));
	}, []);

	return (
		<Card>
			<SoftBox p={2}>
				{!isForFrontend && (
					<SoftBox px={3}>
						<SoftTypography mb={2} variant="h5">
							ERC 721 Collection
						</SoftTypography>
					</SoftBox>
				)}
				<Grid container justifyContent="flex-end" flexDirection={{ md: "row-reverse" }}>
					<Grid item xs={12} md={4}>
						<SoftBox px={3}>
							<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
								<SoftTypography variant="body2">Thumbnail</SoftTypography>
							</SoftBox>
							<SoftDropzone
								submissionURL={`${process.env.REACT_APP_API_BASE}/admin/uploads/marketplace/collections/thumbnail`}
								onUpload={(file, response) => {
									if (response.filepath) thumbnail_path.current = response.filepath;
								}}
								onRemove={() => {
									thumbnail_path.current = null;
								}}
								options={{
									addRemoveLinks: true,
									uploadMultiple: false,
									maxFiles: 1,
									acceptedFiles: "image/*",
								}}
							/>
						</SoftBox>
					</Grid>
					<Grid item xs={12} md={8}>
						<SoftBox px={3}>
							<Formik
								initialValues={{
									contract_type: "721",
									category: "",
									token_name: getValue(defaultValues, "name", ""),
									token_symbol: "",
									marketplace_address: "",
									description: "",
									external_link: "",
								}}
								validationSchema={Yup.object().shape({
									category: Yup.number().required("Category is required"),
									token_name: Yup.string().max(100).required("Name is required"),
									token_symbol: Yup.string().max(50).required("Symbol is required"),
								})}
								onSubmit={(values, actions) => {
									actions.setSubmitting(false);

									if (!thumbnail_path.current) return;

									onCreate &&
										onCreate({
											contractType: values.contract_type,
											category: values.category,
											name: values.token_name,
											symbol: values.token_symbol,
											owner: blockchainInfo.wallet_address,
											chainID: blockchainInfo.network_id,
											thumbnail: thumbnail_path.current,
											description: values.description,
											external_link: values.external_link,
										});
								}}
							>
								{({
									errors,
									handleBlur,
									handleChange,
									handleSubmit,
									isSubmitting,
									touched,
									values,
									setFieldValue,
								}) => (
									<form onSubmit={handleSubmit}>
										<FormControl margin="normal" fullWidth>
											<SoftTypography variant="body2">Category</SoftTypography>
											{isLoading ? (
												<CircularProgress size={14} />
											) : (
												<SoftSelect
													error={Boolean(touched.category && errors.category)}
													fullWidth
													placeholder="Select Category"
													name="category"
													options={categories.map(item => ({
														value: item.id,
														label: item.name,
													}))}
													defaultValue={
														values.category
															? categories.find(item => item.id === values.category)
															: null
													}
													onChange={selectedOption =>
														setFieldValue("category", selectedOption.value)
													}
												/>
											)}
										</FormControl>
										<FormControl margin="normal" fullWidth>
											<SoftTypography variant="body2">Token Name</SoftTypography>
											<SoftInput
												error={Boolean(touched.token_name && errors.token_name)}
												fullWidth
												placeholder="Token Name"
												name="token_name"
												onBlur={handleBlur}
												onChange={handleChange}
												type="text"
												value={values.token_name}
											/>
										</FormControl>
										<FormControl margin="normal" fullWidth>
											<SoftTypography variant="body2">Symbol</SoftTypography>
											<SoftInput
												error={Boolean(touched.token_symbol && errors.token_symbol)}
												fullWidth
												placeholder="Symbol"
												name="token_symbol"
												onBlur={handleBlur}
												onChange={handleChange}
												type="text"
												value={values.token_symbol}
											/>
										</FormControl>
										<FormControl margin="normal" fullWidth>
											<SoftTypography variant="body2">Description</SoftTypography>
											<SoftInput
												fullWidth
												multiline
												minRows={3}
												placeholder="Collection Description"
												name="description"
												onBlur={handleBlur}
												onChange={handleChange}
												type="text"
												value={values.description}
											/>
										</FormControl>
										<FormControl margin="normal" fullWidth>
											<SoftTypography variant="body2">External URL</SoftTypography>
											<SoftInput
												error={Boolean(touched.external_link && errors.external_link)}
												fullWidth
												placeholder="Link to collection page"
												name="external_link"
												onBlur={handleBlur}
												onChange={handleChange}
												type="text"
												value={values.external_link}
											/>
										</FormControl>
										{/* <SoftTypography mt={3} variant="h5">
								Token Meta Data
							</SoftTypography>
							<Box mb={3} sx={{ color: "#989898" }}>
								<MultilineField
									items={payouts.current}
									onChange={setPayouts}
									fields={[
										{ name: "name", title: "Meta Name" },
										{ name: "value", title: "Meta Value" },
									]}
									addButtonText="Add Meta"
									addButtonAlignment="left"
								/>
							</Box> */}
										<Box sx={{ pt: 2 }}>
											<Button
												color="primary"
												disabled={
													isSubmitting ||
													!thumbnail_path.current ||
													(!touched.token_name && !defaultValues) ||
													(errors.token_name && errors.token_name.length > 0) ||
													!touched.token_symbol ||
													(errors.token_symbol && errors.token_symbol.length > 0)
												}
												fullWidth
												size="large"
												type="submit"
												variant="contained"
											>
												Create
											</Button>
										</Box>
										<Typography
											variant="caption"
											component="p"
											sx={{ mt: 2 }}
											color="error"
											align="center"
										>
											You are connected to{" "}
											{networkName(blockchainInfo ? blockchainInfo.network_id : 0)} with account{" "}
											{shortAccount(blockchainInfo ? blockchainInfo.wallet_address : null)}
										</Typography>
									</form>
								)}
							</Formik>
						</SoftBox>
					</Grid>
				</Grid>
			</SoftBox>
		</Card>
	);
};

export default CollectionForm;
