import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import {
	fetchCategories,
	deleteCollection,
	fetchCollections,
	createCollection,
	createCollectionToken,
	setCollectionTokenMinted,
} from "services/marketplace";
import { showConfirmationAlert } from "layouts/Helper/swalAlerts";
import Popup from "layouts/applications/popup/Popup";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import { NETWORKS } from "config/networks";
import { CircularProgress, FormHelperText } from "@mui/material";
import ConnectWalletButton from "components/etc/ConnectWalletButton";
import { fetchCollectionInfo } from "plugins/Ethereum";

import { setErrorMessage, useSoftUIController } from "context";
import { loadJson } from "services/json";

const ImportCollectionForm = ({ onImport, onCancel }) => {
	const [inputText, setInputText] = useState({ category: null, chainID: null, address: null });
	const [isLoading, setIsLoading] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		fetchCategories()
			.then(data => setCategories(data))
			.finally(() => setIsLoading(false));
	}, []);

	const handleChange = event => {
		const name = event.target.name;
		const value = event.target.value;

		const updatedForm = { ...inputText };
		updatedForm[name] = value;
		setInputText(updatedForm);
	};

	const handleCancel = () => {
		onCancel && onCancel();
	};

	const importCollection = () => {
		if (inputText.category && inputText.chainID && inputText.address) {
			setIsProcessing(true);
			fetchCollectionInfo(721, parseInt(inputText.chainID), inputText.address)
				.then(collection => {
					onImport && onImport({ ...collection, category: inputText.category });
				})
				.catch(error => {
					onImport && onImport(null);
				})
				.finally(setIsProcessing(false));
		}
	};

	return (
		<>
			<SoftBox my={3}>
				<SoftTypography variant="body2">Category</SoftTypography>
				{isLoading ? (
					<CircularProgress size={14} />
				) : (
					<SoftSelect
						placeholder="Select Category"
						name="category"
						options={categories.map(item => ({
							value: item.id,
							label: item.name,
						}))}
						onChange={selectedOption =>
							handleChange({ target: { name: "category", value: selectedOption.value } })
						}
					/>
				)}
			</SoftBox>
			<SoftBox my={3}>
				<SoftSelect
					name="chainID"
					placeholder="Select Chain"
					options={NETWORKS.filter(item => !item.mainnet).map(item => ({ value: item.id, label: item.name }))}
					onChange={selected => handleChange({ target: { name: "chainID", value: selected.value } })}
					readOnly={isProcessing}
				/>
			</SoftBox>
			<SoftBox my={3}>
				<SoftInput
					name="address"
					type="email"
					onChange={handleChange}
					placeholder="Collection Address"
					defaultValue={inputText.address}
					readOnly={isProcessing}
				/>
				<FormHelperText>Copy/Paste the contract address in format "0x0...."</FormHelperText>
			</SoftBox>
			<SoftBox>
				<SoftButton color="error" fullWidth={true} disabled={isProcessing} onClick={importCollection}>
					Import
					{isProcessing && <CircularProgress style={{ marginLeft: 15 }} color="inherit" size={12} />}
				</SoftButton>
			</SoftBox>
			<SoftBox>
				<SoftButton disabled={isProcessing} variant="text" color="secondary" fullWidth onClick={handleCancel}>
					Cancel
				</SoftButton>
			</SoftBox>
		</>
	);
};

export default () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [isShowingAPopup, setIsShowingAPopup] = useState(null);
	const [rows, setRows] = useState([]);

	const navigate = useNavigate();
	const [controller, dispatch] = useSoftUIController();

	useEffect(() => {
		loadData();
	}, []);

	const handleAddNewButton = () => {
		setIsShowingAPopup("addNew");
	};

	const handleViewCollection = collection => {
		navigate(`/admin/marketplace/collections/${collection.id}/tokens`);
	};

	const continueWith = method => {
		handleClosePopup();

		if (method === "import") {
			setTimeout(() => setIsShowingAPopup("importCollection"), 500);
		} else {
			navigate("/admin/marketplace/collections/add-new");
		}
	};

	const handleDelete = collection => {
		showConfirmationAlert({
			title: "Are you sure you want to delete?",
			text: "The collection will be removed from the dashboard, however will remain on blockchain",
			onConfirm: () => deleteCollection(collection.id).then(loadData),
		});
	};

	const handleClosePopup = useCallback(() => {
		setIsShowingAPopup(null);
	}, []);

	const handleAfterImport = async collectionData => {
		handleClosePopup();

		console.log(collectionData);

		if (collectionData && collectionData.tokens.length > 0) {
			setIsProcessing(true);

			const collection = await createCollection({
				...collectionData,
				description: "",
				ipfs_thumbnail_hash: "",
				ipfs_contract_meta_hash: "",
			});
			if (collection?.id) {
				collectionData.tokens.map(async tokenData => {
					const tokenMeta = loadJson(
						tokenData.metadataURL.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
					);
					if (tokenMeta) {
						const token = await createCollectionToken(collection.id, {
							minter: tokenData.minter,
							owner: tokenData.owner,
							thumbnail: tokenMeta.image,
							metadataURL: tokenData.metadataURL,
							metadataCache: tokenMeta,
						});

						if (token?.id) {
							await setCollectionTokenMinted(collection.id, token.id, {
								tokenID: tokenData.tokenID,
							});
						}
					}
				});
			}
		} else {
			setErrorMessage(dispatch, "Sorry! Not able to get any tokens from this collection.");
		}
	};

	const loadData = () => {
		setIsLoading(true);
		fetchCollections()
			.then(data =>
				setRows(
					data.map(collection => ({
						...collection,
						category_name: collection.category?.name,
						listings: collection.tokens.filter(token => true && token.listingInfo?.listed),
					}))
				)
			)
			.finally(() => setIsLoading(false));
	};

	const columns = [
		{ Header: "Name", accessor: "name" },
		{ Header: "Category", accessor: "category_name" },
		{ Header: "Total NFT(s)", accessor: "tokens", type: "length" },
		{
			Header: "Total Listing(s)",
			accessor: "listings",
			type: "length",
		},
		{ Header: "Created On", accessor: "created_at", type: "date" },
		{
			Header: "Actions",
			accessor: "action_buttons",
			align: "right",
			buttons: [
				{ text: "View NFT(s)", action: handleViewCollection, color: "info" },
				{ text: "Delete", action: handleDelete, color: "error" },
			],
		},
	];

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox pb={3}>
				<SoftBox mb={3} style={{ textAlign: "right" }}>
					<ConnectWalletButton
						metamaskOnly
						textUponConnected="Add New Collection"
						actionAfterConnected={handleAddNewButton}
						restrictedToNetwork={80001}
					/>
				</SoftBox>
				<SoftBox mb={3}>
					<DataTable table={{ columns, rows }} entriesPerPage={null} loading={isLoading} />
				</SoftBox>
			</SoftBox>
			{isShowingAPopup === "addNew" && (
				<Popup>
					<CloseIcon
						className="close-btn icon"
						onClick={() => {
							handleClosePopup();
						}}
					/>
					<SoftBox mt={3} sx={{ minWidth: "300px" }}>
						<SoftButton
							color="error"
							style={{ marginBottom: 10 }}
							fullWidth={true}
							onClick={() => continueWith("create")}
						>
							Create New
						</SoftButton>
						<SoftButton color="secondary" fullWidth={true} onClick={() => continueWith("import")}>
							Import
						</SoftButton>
					</SoftBox>
				</Popup>
			)}
			{isShowingAPopup === "importCollection" && (
				<Popup>
					<SoftBox mt={3} sx={{ minWidth: "300px" }}>
						<SoftTypography variant="h4">Import Collection</SoftTypography>
						<ImportCollectionForm onImport={handleAfterImport} onCancel={handleClosePopup} />
					</SoftBox>
				</Popup>
			)}
			<Footer />
		</DashboardLayout>
	);
};
