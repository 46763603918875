import React, { useEffect, useRef } from "react";
import SoftAlert from "components/SoftAlert";

import { setInvalidAccess, useSoftUIController } from "context";

function InvalidAccess() {
	const [controller, dispatch] = useSoftUIController();
	const { showInvalidAccess } = controller;

	useEffect(() => {
		if (showInvalidAccess) {
			setTimeout(() => setInvalidAccess(dispatch, false), 3000);
		}
	}, [showInvalidAccess]);

	return showInvalidAccess ? (
		<div style={{ position: "fixed", bottom: "10px", left: "50%", zIndex: 1, transform: "translateX(-50%)" }}>
			<SoftAlert color="error" dismissible>
				You are not authorized to access this section!
			</SoftAlert>
		</div>
	) : null;
}

export default InvalidAccess;
