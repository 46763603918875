import { createContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "layouts/authentication/api/axios";
import { setLayout, useSoftUIController } from "context";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const [controller, dispatch] = useSoftUIController();
	const location = useLocation();
	const navigate = useNavigate();

	// check if auth token already saved
	useEffect(() => {
		const savedToken = localStorage.getItem("access_token");
		if (savedToken) {
			// fetch the user's details
			tryLoginUser();
		}

		const handleInvalidToken = e => {
			if (e.key === "access_token") {
				if (e.oldValue && !e.newValue) logoutUser();
				else if (!e.oldValue && e.newValue) window.location.href = window.location.href;
			}
		};

		window.addEventListener("storage", handleInvalidToken);

		return function cleanup() {
			window.removeEventListener("storage", handleInvalidToken);
		};
	}, []);

	const tryLoginUser = async () => {
		if (isLoading) return;
		try {
			setIsLoading(true);
			const res = await axios().get("/auth/authorizeAccessToken");
			setIsLoading(false);

			if (res.data?.access_token) {
				setAuth({
					email: res.data.email,
					access_token: res.data.access_token,
					role: res.data.role ? res.data.role : "user",
					email_verified: res.data.email_verified,
					created_at: res.data.created_at,
					plan: res.data.plan,
					first_name: res.data.first_name
				});
				console.log("user data")
				console.log(res.data)

				return;
			}
		} catch (e) {
			console.log("error fetching user data")
			setIsLoading(false);
		}

		logoutUser(false);
	};

	const logoutUser = (redirect = true) => {
		setAuth(null);
		localStorage.removeItem("access_token");
		localStorage.removeItem("redirectAfterLogin");

		setLayout(dispatch, "dashboard");

		if (redirect) navigate("/dashboard/authentication/sign-in");
	};

	const setUserSession = async (authData) => {
		if (authData.access_token) {
			localStorage.setItem("access_token", authData.access_token);
			setAuth(authData);
		}

		return true;
	};

	return (
		<AuthContext.Provider value={{ auth, setUserSession, logoutUser, tryLoginUser }}>
			{isLoading ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
					<CircularProgress color="inherit" />
				</div>
			) : (
				<>{children}</>
			)}
		</AuthContext.Provider>
	);
};

export default AuthContext;
