/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useRef } from "react";
import { Grid, TableHead } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
// prop-types is a library for typechecking of props
import PropTypes, {  } from "prop-types";
import Payment from "layouts/applications/popup/Payment";
import SearchIcon from "@mui/icons-material/Search";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";
import SoftButton from "components/SoftButton";
// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "./PortfolioStockDataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import buttonImage from "../../../assets/images/how-to-question 1.png";
import CloseIcon from "@mui/icons-material/Close";
import Popup from "layouts/applications/popup/Popup";
import Close from "@mui/icons-material/Close";
import popUP from "../../../assets/images/popUP.png";
import KeyIconOrData from "components/etc/KeyIconOrData";
import { getSingleStockData } from "layouts/applications/data-tables/data/getData";
import { getTickerList } from "layouts/applications/data-tables/data/getData";
import { SearchSymbolInput, ActionDropdown, TableRemarks } from "components/CustomComponents";
import axiosDefault from "layouts/authentication/api/axios";
import { getUserPortfolioStocks } from "layouts/applications/data-tables/data/getData";
import { updateUserCapital } from "layouts/applications/data-tables/data/getData";




function PortfolioStockTable({
	entriesPerPage,
	canSearch,
	showTotalEntries,
	table,
	pagination,
	isSorted,
	noEndBorder,
	loading,
	isDividend,
	exchange,
	setExchange,
	optionType,
	fullData,
	initialCapital,
	setTable,
	modifyTable,
	setInitialCapital,
}) {
	const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
	const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
	const columns = useMemo(() => table.columns, [table]);
	const data = useMemo(() => table.rows, [table]);
	// const [data,setData]= useState(table.rows)

	const [isModalOpen, setModal] = useState(false);
	const [ratio, setRatio] = useState();
	const [currentOption, setOption] = useState({});
	const [CurrentOptionType, setOptionType] = useState("");
	const [filteredData, setFiltered] = useState();
	const [stockCode, setStockCode] = useState("");
	const navigate = useNavigate();
	const [stockPopup, toggleStockPopup] = useState(false);
	const [capitalPopup, toggleCapitalPopup] = useState(false);
	const [isConfirmed, setConfirmed] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [transactionErrorMessage, setTransactionErrorMessage] = useState("");
	const [symbolObject, setSymbolObject] = useState({})

	//states for stock popup
	const [inputText, setInputText] = useState({ tradePrice: "", quantity: "", capital: 0, symbol: "", stockType: "", remarks: "none", action: "Hold" });

	//transaction type
	const [transaction, setTransaction] = useState(null);
	const openTransaction = Boolean(transaction);
	const [currentTransaction, setCurrentTransaction] = useState("Deposit");
	const [inputAmount, setInputAmount] = useState(0);

	//stock type
	const [stockType, setStockType] = useState(null);
	const openStock = Boolean(stockType);
	const [currentStock, setCurrentStock] = useState("CHOOSE STOCK TYPE");

	//actionType
	const [actionType, setActionType] = useState(null);
	const openAction = Boolean(actionType);
	const [currentAction, setCurrentAction] = useState()
	const lastIndexOfInputColumn = 5;

	//monitor scrolling
	const [scrollY, setScrollY] = useState(0);
	const scrollableRef = useRef(null);
	function handleScroll() {
		const scrollPos = scrollableRef.current.scrollTop;
		setScrollY(scrollPos);
		console.log(scrollY)
	}

	const sendStockInfo = async() => {
		const remainingCapital = initialCapital - inputText.capital;

		const res = await updateUserCapital(-(inputText.capital))
			if(res === null){
				return; 
			}
		setInitialCapital(remainingCapital);
		
		uploadStockInfo(inputText);
		deleteCurrentInfo();
		toggleStockPopup(false);
	};

	const uploadStockInfo = async (objectToUpload) => {
		objectToUpload = {...objectToUpload,name:inputText.name ,capital:(objectToUpload.capital).toString()}
		// console.log(objectToUpload)
		try {
			const res = await axiosDefault().post("portfolio/stocks", objectToUpload)
			console.log("res is here ")
			if(res.status === 200){
				setTable(inputText, "stocks");
			}
		} catch (error) {
			console.log(error)
			console.log("error uploading stock info")
		}
	}

	const getStockInfo = async () =>{
		try {
			const res = await getUserPortfolioStocks();
			console.log(res)
		} catch (error) {
			console.log(error)
			console.log('error getting stock info')
		}
	}



	const initialisePopupInputs = () => {
		const currentText = {};
		let numberOfInputs = lastIndexOfInputColumn;
		for (let i = 0; i < numberOfInputs; i++) {
			let name = table.columns[i].accessor;
			if (table.columns[i].type === "date") {
				const today = new Date();
				currentText[name] = today.toLocaleDateString("en-US");
			} else {
				currentText[name] = "";
			}
		}
		setInputText(currentText);
	};
	const deleteCurrentInfo = () => {
		setConfirmed(false);
		initialisePopupInputs();
		setCurrentStock("CHOOSE STOCK TYPE");
	};
	const closeTransaction = event => {
		const name = event.target.textContent;
		if (name !== "") {
			setCurrentTransaction(name);
		}

		setTransaction(null);
	};

	const isFieldsValid = async () => {
		console.log(inputText);
		if (inputText.symbol === "") {
			setErrorMessage("Invalid Symbol");
			return false;
		} else if (inputText.stockType === "") {
			setErrorMessage("Please Choose a Stock Type");
			return false;
		} else if (inputText.tradePrice === 0 || inputText.tradePrice === "") {
			setErrorMessage("please enter a trade price");
			return false;
		} else if (inputText.quantity === 0 || inputText.quantity === "") {
			setErrorMessage("Please enter a quantity");
			return false;
		} else if (inputText.tradePrice * inputText.quantity > initialCapital) {
			setErrorMessage("You have insufficient capital");
			return false;
		}


		let fetchedObject = await getTickerList(inputText["symbol"]);
		if (fetchedObject.data.length === 0) {
			setErrorMessage("Invalid Symbol: " + inputText["symbol"]);
			return false
		} else {
			console.log("here")
			setErrorMessage("");
			return true;
		}


	};
	const confirmButton = async () => {
		if (await isFieldsValid()) {
			setConfirmed(!isConfirmed);
		}
	};

	const addTransaction = async() => {
		// if
		let parsedInitialCapital = parseInt(initialCapital);
		let parsedInputAmount = parseInt(inputAmount);
		if (currentTransaction === "Withdraw" && parsedInputAmount > parsedInitialCapital) {
			setTransactionErrorMessage("You cannot withdraw more than what you have.");
			return;
		}
		if (currentTransaction === "Deposit") {
			const res = await updateUserCapital(parsedInputAmount)
			if(res === null){
				setTransactionErrorMessage("Seems to be a server error, please try again later!");
				return; 
			}
			setInitialCapital(parsedInitialCapital + parsedInputAmount);
		} else {
			const res = await updateUserCapital(-parsedInputAmount)
			if(res === null){
				setTransactionErrorMessage("Seems to be a server error, please try again later!");
				return; 
			}
			setInitialCapital(parsedInitialCapital - parsedInputAmount);
		}
		setTransactionErrorMessage("");
		toggleCapitalPopup(false);
		setInputAmount("");
	};
	const handleSymbolData = async (symbol) => {
		console.log(symbol)
		console.log("fetching symbol obj below")
		const fetchedSymbolObject = await getTickerList(symbol);
		setSymbolObject(fetchedSymbolObject);
		console.log(symbolObject)
	}
	function handleStockChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		setInputText(prevValue => {
			if (name === "tradePrice") {
				if (value * prevValue.quantity > initialCapital) {
					setErrorMessage("You have insufficient capital");
				} else {
					setErrorMessage("");
				}
				return { ...prevValue, tradePrice: value, capital: value * prevValue.quantity };
			} else if (name === "quantity") {
				if (value * prevValue.tradePrice > initialCapital) {
					setErrorMessage("You have insufficient capital");
				} else {
					setErrorMessage("");
				}
				return { ...prevValue, quantity: value, capital: value * prevValue.tradePrice };
			} else if (name === "symbol") {
				handleSymbolData(value);
				return { ...prevValue, [name]: value };
			} else {
				return { ...prevValue, [name]: value };
			}
		});
	}

	const closeStock = event => {
		const name = event.target.textContent;
		if (name !== "") {
			setCurrentStock(name);
			setInputText({ ...inputText, stockType: name });
		}
		setStockType(null);
	};

	const currentDate = new Date();

	const closeAction = event => {
		const name = event.target.textContent;
		if (name !== "") {
			//work on this logic where index 
			setCurrentStock(name);
			setInputText({ ...inputText, stockType: name });
		}
		setActionType(null);
	};
	const handleClick = event => {
		const name = event.currentTarget.id;
		if (name === "stock-button") {
			setStockType(event.currentTarget);
		} else if (name === "transaction-button") {
			setTransaction(event.currentTarget);
		} else if (name === "action-button") {
			setActionType(event.currentTarget)
		}
		// if (name === )
	};
	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0 } },
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		pageOptions,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,

		state: { pageIndex, pageSize, globalFilter },
	} = tableInstance;

	// Set the default value for the entries per page when component mounts
	// useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

	// Set the entries per page value based on the select value
	const setEntriesPerPage = ({ value }) => setPageSize(value);

	const parseData = (name, data) => {
		const theElement = data.find(element => element.Symbol === name);

		setFiltered(theElement);
	};

	// Render the paginations
	const renderPagination = pageOptions.map(option => (
		<SoftPagination
			item
			key={option}
			onClick={() => gotoPage(Number(option))}
			active={pageIndex === option}
			className={pageIndex === option ? "bbvRed" : ""}
		>
			{option + 1}
		</SoftPagination>
	));

	// Handler for the input to set the pagination index
	const handleInputPagination = ({ target: { value } }) =>
		value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

	// Customized page options starting from 1
	const customizedPageOptions = pageOptions.map(option => option + 1);

	// Setting value for the pagination input
	const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

	// Search input value state
	const [search, setSearch] = useState(globalFilter);

	// Search input state handle
	const onSearchChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 100);

	// A function that sets the sorted value for the table
	const setSortedValue = column => {
		let sortedValue;

		if (isSorted && column.isSorted) {
			sortedValue = column.isSortedDesc ? "desc" : "asce";
		} else if (isSorted) {
			sortedValue = "none";
		} else {
			sortedValue = false;
		}

		return sortedValue;
	};

	// Setting the entries starting point
	const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

	// Setting the entries ending point
	let entriesEnd;

	if (pageIndex === 0) {
		entriesEnd = Math.min(rows.length, pageSize);
	} else if (pageIndex === pageOptions.length - 1) {
		entriesEnd = rows.length;
	} else {
		entriesEnd = pageSize * (pageIndex + 1);
	}

	const RenderPlaceHolderText = () => {
		let text = [];
		let size = table.columns.length;
		for (let i = 1; i < size; i++) {
			let textToInsert = "Type here";
			let currentType = table.columns[i].type;
			if (currentType === "dropdown") {
				textToInsert = "Choose and Click";
			} else if (currentType === "date") {
				textToInsert = "Choose the Date";
			}

			text.push(
				<DataTableBodyCell key={i}>
					<p className="portfolioInput" onClick={() => toggleStockPopup(true)}>
						{textToInsert}
					</p>
				</DataTableBodyCell>
			);
		}
		return text;
	};

	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			<Grid
				container
				style={{ paddingLeft: "23px", paddingTop: "40px", display: "flex", justifyContent: "space-between" }}
			>
				<div style={{ fontSize: "20px" }}>
					<h1 className="roboto portfolioTableText">INITIAL CAPITAL (USD)</h1>
					<p className="roboto portfolioTableText"> {isNaN(initialCapital)? 0: Number(initialCapital).toLocaleString()}</p>
					<Button
						variant="outlined"
						className="greyOutline"
						onClick={() => {
							toggleCapitalPopup(true);
						}}
					>
						Add capital
					</Button>
				</div>
				<p style={{ paddingRight: "23px", color: "#8392AB", fontSize: "20px" }} className="roboto">
					DATE: {currentDate.toDateString()}
				</p>
			</Grid>
			{entriesPerPage || canSearch ? (
				<div>
					<SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
						{entriesPerPage && (
							<SoftBox display="flex" alignItems="center">
								<SoftSelect
									defaultValue={{ value: defaultValue, label: defaultValue }}
									options={entries.map(entry => ({ value: entry, label: entry }))}
									onChange={setEntriesPerPage}
									size="small"
								/>
								<SoftTypography variant="caption" color="secondary">
									&nbsp;&nbsp;entries per page
								</SoftTypography>
							</SoftBox>
						)}
						{canSearch && (
							<SoftBox width="12rem" ml="auto">
								<SoftInput
									placeholder="Search..."
									value={search}
									onChange={({ currentTarget }) => {
										setSearch(search);
										onSearchChange(currentTarget.value);
									}}
								/>
							</SoftBox>
						)}
					</SoftBox>
				</div>
			) : null}
			{loading ? (
				<div className="loadingIcon">
					<CircularProgress />
				</div>
			) : (
				<>
					<Table {...getTableProps()}>
						<SoftBox className="bbvLightGrey rowBoxes" component="thead">
							{headerGroups.map((headerGroup, key) => (
								<TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, key) => (
										<DataTableHeadCell
											key={key}
											{...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
											width={column.width ? column.width : "auto"}
											align="left"
											sorted={setSortedValue(column)}
										>
											{column.render("Header")}
										</DataTableHeadCell>
									))}
								</TableRow>
							))}
						</SoftBox>

						<TableBody {...getTableBodyProps()}>
							{page.map((row, pageKey) => {
								prepareRow(row);
								return (
									<TableRow className="alignCenter" key={pageKey} {...row.getRowProps()}>
										{row.cells.map((cell, key) => (
											<DataTableBodyCell
												key={key}
												className="alignCenter rowBoxes"
												noBorder={noEndBorder && rows.length - 1 === key}
												//align used to align the data content
												// align = {pageKey === page.length - 1?"right":"left"}
												align="left"
												{...cell.getCellProps()}
											>

												{cell.column.Header === "ACTION" ?
													<div>
														<ActionDropdown type="stocks" modifyTable={modifyTable} data={data} currentAction={cell.render("Cell")} rowIndex={pageKey} />
													</div>
													: cell.column.Header === "REMARKS" ? <TableRemarks type="stocks" rowIndex={pageKey} modifyTable={modifyTable} data={data} /> :
														<div className="leftAlign">
															<p
																style={{ maxWidth: "110px" }}
																className="helvetica portfolioCellText"
															>
																{cell.render("Cell")}
															</p>
														</div>
												}
											</DataTableBodyCell>
										))}
									</TableRow>
								);
							})}
							<TableRow>
								<DataTableBodyCell>
									<p className="portfolioInput" onClick={() => toggleStockPopup(true)}>
										{" "}
										<SearchIcon style={{ verticalAlign: "middle" }} /> Type to search
									</p>
								</DataTableBodyCell>
								<RenderPlaceHolderText></RenderPlaceHolderText>
							</TableRow>
						</TableBody>
					</Table>
					{rows.length > 0 ? (
						<SoftBox
							display="flex"
							flexDirection={{ xs: "column", sm: "row" }}
							justifyContent="space-between"
							alignItems={{ xs: "flex-start", sm: "center" }}
							p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
						>
							{showTotalEntries && (
								<SoftBox mb={{ xs: 3, sm: 0 }}>
									<SoftTypography variant="button" color="secondary" fontWeight="regular">
										Showing {entriesStart} to {entriesEnd} of {rows.length} entries
									</SoftTypography>
								</SoftBox>
							)}
							{pageOptions.length > 1 && (
								<SoftPagination
									variant={pagination.variant ? pagination.variant : "gradient"}
									color={pagination.color ? pagination.color : "info"}
								>
									{canPreviousPage && (
										<SoftPagination item onClick={() => previousPage()}>
											<Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
										</SoftPagination>
									)}
									{renderPagination.length > 6 ? (
										<SoftBox width="5rem" mx={1}>
											<SoftInput
												inputProps={{
													type: "number",
													min: 1,
													max: customizedPageOptions.length,
												}}
												value={customizedPageOptions[pageIndex]}
												onChange={(handleInputPagination, handleInputPaginationValue)}
											/>
										</SoftBox>
									) : (
										renderPagination
									)}
									{canNextPage && (
										<SoftPagination item onClick={() => nextPage()}>
											<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
										</SoftPagination>
									)}
								</SoftPagination>
							)}
						</SoftBox>
					) : (
						<SoftBox display="flex" style={{ textAlign: "center" }} py={3}>
							{/* <SoftTypography variant="p">No Data Found</SoftTypography> */}
						</SoftBox>
					)}
				</>
			)}
			{stockPopup ? (
				<Popup onScroll={handleScroll}>
					<CloseIcon
						className="close-btn icon"
						onClick={() => {
							toggleStockPopup(false);
							setModal(false);
						}}
					/>
					<div>
						<h1 style={{ textAlign: "center", fontSize: "18px", marginBottom: "20px" }} className="roboto">
							Order Entry
						</h1>


						<div>
							<Grid container alignItems="center">
								<div className="headerBox" style={{ display: "flex", justifyContent: "space-between" }} >
									<p className="helvetica" style={{ fontSize: "12px" }}>SYMBOL</p>
									<SearchIcon style={{ marginRight: "12px" }}></SearchIcon>
								</div>
								<div className={isConfirmed ? "inputBox" : "searchInputBox"}>
									{isConfirmed ? (<p className="helvetica" style={{ fontSize: "12px" }}>{inputText.symbol}</p>) : (
										<SearchSymbolInput withIcon={false} inputText={inputText} setInputText={setInputText} />
									)}
								</div>
							</Grid>
							<Grid container alignItems="center">
								<div className="headerBox">
									<p className="helvetica" style={{ fontSize: "12px" }}>STOCK TYPE</p>
								</div>
								<div className="buttonInputBox">
									<div>
										{isConfirmed ? (
											<p className="helvetica" style={{ fontSize: "12px", paddingLeft: "10px" }}>{inputText.stockType}</p>
										) : (
											<SoftButton
												id="stock-button"
												className="portfolioDropdown"
												aria-controls={openStock ? "index-menu" : undefined}
												aria-haspopup="true"
												aria-expanded={openStock ? "true" : undefined}
												onClick={handleClick}
												variant="text"
											>
												{currentStock}
												<ExpandMoreIcon />
											</SoftButton>
										)}

										<Menu
											id="stock-menu"
											anchorEl={stockType}
											open={openStock}
											onClose={closeStock}
											style={{ position: "", zIndex: "10000" }}
											MenuListProps={{
												"aria-labelledby": "basic-button",
											}}
										>
											<MenuItem onClick={closeStock}>Buy/Long</MenuItem>
											<MenuItem onClick={closeStock}>Sell/Short</MenuItem>
										</Menu>
									</div>
								</div>
							</Grid>
							<Grid container alignItems="center">
								<div className="headerBox" >
									<p className="helvetica" style={{ fontSize: "12px" }}>TRADE PRICE</p>
								</div>
								<div className="inputBox">
									{isConfirmed ? (
										<p className="helvetica" style={{ fontSize: "12px" }}>{inputText.tradePrice}</p>
									) : (
										<input
											name="tradePrice"
											type="number"
											placeholder="Type here"
											onChange={handleStockChange}
											value={inputText.tradePrice}
											className="underlinedInput"
										/>
									)}
								</div>
							</Grid>
							<Grid container alignItems="center">
								<div className="headerBox" >
									<p className="helvetica" style={{ fontSize: "12px" }}>QUANTITY</p>
								</div>
								<div className="inputBox">
									{isConfirmed ? (
										<p className="helvetica" style={{ fontSize: "12px" }}>{inputText.quantity}</p>
									) : (
										<input
											name="quantity"
											type="number"
											placeholder="Type here"
											onChange={handleStockChange}
											value={inputText.quantity}
											className="underlinedInput"
										/>
									)}
								</div>
							</Grid>
							<Grid container alignItems="center">
								<div className="headerBox" >
									<p className="helvetica" style={{ fontSize: "12px" }}>CAPITAL</p>
								</div>
								<div className="inputBox">
									<p className="helvetica" style={{ fontSize: "12px" }}>{inputText.capital}</p>
								</div>
							</Grid>
							<Grid container alignItems="center">
								<div className="headerBox" >
									<p className="helvetica" style={{ fontSize: "12px" }}>INITIAL CAPITAL</p>
								</div>
								<div className="inputBox">
									<p className="helvetica" style={{ fontSize: "12px" }}>{initialCapital}</p>
								</div>
							</Grid>

						</div>

						{isConfirmed ? (
							<div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
								<div>
									<Button
										className="greyOutline"
										variant="outlined"
										onClick={deleteCurrentInfo}
										style={{ marginRight: "20px" }}
										color="info"
									>
										Delete
									</Button>
									<Button
										className="greyOutline"
										variant="outlined"
										onClick={confirmButton}
										color="info"
									>
										Edit
									</Button>
								</div>
								<Button className="bbvGreen" onClick={sendStockInfo} variant="gradient" color="info">
									Send
								</Button>
							</div>
						) : (
							<div style={{ float: "right", marginTop: "25px" }}>
								<div >
									<Button className="greyOutline" variant="outlined" onClick={deleteCurrentInfo}>
										DELETE
									</Button>
									<Button
										className="bbvGrey"
										style={{ marginLeft: "20px" }}
										onClick={confirmButton}
										variant="gradient"
										color="info"
									>
										Confirm & Send
									</Button>
								</div>
								<p style={{ color: "red", marginTop: "10px", fontSize: "16px", textAlign: "right" }}>{errorMessage}</p>
							</div>

						)}
					</div>
				</Popup>
			) : (
				<div></div>
			)}

			{capitalPopup ? (
				<Popup>
					<CloseIcon
						className="close-btn icon"
						onClick={() => {
							toggleCapitalPopup(false);
							setModal(false);
						}}
					/>
					<div>
						<h1 style={{ textAlign: "center", fontSize: "18px", marginBottom: "20px" }} className="roboto">
							Add cash transaction
						</h1>
						<TableContainer>
							<Table {...getTableProps()}>
								<SoftBox className="bbvLightGrey rowBoxes" component="thead">
									<TableRow>
										<DataTableHeadCell>
											<p className="helvetica" style={{ fontSize: "12px" }}>
												TRANSACTION TYPE
											</p>
										</DataTableHeadCell>
										<DataTableHeadCell>
											<p className="helvetica" style={{ fontSize: "12px" }}>
												AMOUNT
											</p>
										</DataTableHeadCell>
									</TableRow>
								</SoftBox>
								<TableBody {...getTableBodyProps()}>
									<TableRow>
										<DataTableBodyCell>
											<div>
												<SoftButton
													id="transaction-button"
													className="portfolioDropdown"
													aria-controls={openTransaction ? "index-menu" : undefined}
													aria-haspopup="true"
													aria-expanded={openTransaction ? "true" : undefined}
													onClick={handleClick}
													variant="text"
												>
													{currentTransaction}
													<ExpandMoreIcon></ExpandMoreIcon>
												</SoftButton>
												<Menu
													id="transaction-menu"
													anchorEl={transaction}
													open={openTransaction}
													onClose={closeTransaction}
													style={{ position: "", zIndex: "10000" }}
													MenuListProps={{
														"aria-labelledby": "basic-button",
													}}
												>
													<MenuItem onClick={closeTransaction}>Deposit</MenuItem>
													<MenuItem onClick={closeTransaction}>Withdraw</MenuItem>
												</Menu>
											</div>
										</DataTableBodyCell>
										<DataTableBodyCell>
											<TextField
												id="standard-basic"
												placeholder="Enter an amount"
												type="number"
												value={inputAmount}
												onChange={event => {
													setInputAmount(event.target.value);
												}}
												variant="outlined"
											/>
										</DataTableBodyCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<p>{transactionErrorMessage}</p>
						<div style={{ float: "right", paddingTop: "25px" }}>
							<Button
								className="greyOutline"
								variant="outlined"
								onClick={() => {
									toggleCapitalPopup(false);
									setModal(false);
								}}
							>
								Cancel
							</Button>
							<Button
								className="bbvGreen"
								style={{ marginLeft: "20px" }}
								onClick={addTransaction}
								variant="gradient"
								color="info"
							>
								Complete
							</Button>
						</div>
					</div>
				</Popup>
			) : (
				<div></div>
			)}
		</TableContainer>
	);
}

// Setting default values for the props of DataTable
PortfolioStockTable.defaultProps = {
	entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
	canSearch: false,
	showTotalEntries: true,
	pagination: { variant: "gradient", color: "info" },
	isSorted: true,
	noEndBorder: false,
};

// Typechecking props for the DataTable
PortfolioStockTable.propTypes = {
	entriesPerPage: PropTypes.oneOfType([
		PropTypes.shape({
			defaultValue: PropTypes.number,
			entries: PropTypes.arrayOf(PropTypes.number),
		}),
		PropTypes.bool,
	]),
	canSearch: PropTypes.bool,
	showTotalEntries: PropTypes.bool,
	table: PropTypes.objectOf(PropTypes.array).isRequired,
	pagination: PropTypes.shape({
		variant: PropTypes.oneOf(["contained", "gradient"]),
		color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
	}),
	isSorted: PropTypes.bool,
	noEndBorder: PropTypes.bool,
};

export default PortfolioStockTable;
