import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftProcessing from "components/SoftProcessing";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchCategories, fetchCollections } from "services/marketplace";
import { Grid, Typography } from "@mui/material";
import { toSlug } from "layouts/Helper/string.helper";
import { numberFormat } from "layouts/Helper/number.helper";

import avatar from "assets/images/avatar/avatar-bullbearworld.png";

const MarketplaceCollectionDetails = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [filterBy, setFilterBy] = useState("");
	const [collections, setCollections] = useState([]);
	const [selectedCollection, setSelectedCollection] = useState(null);
	const [filteredTokens, setFilteredTokens] = useState([]);

	const navigate = useNavigate();

	const { collectionID } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		const collections = await fetchCollections();
		setCollections(collections);

		const selectedCollection = collections.find(collection => collection.id === parseInt(collectionID));

		if (selectedCollection) {
			setSelectedCollection(selectedCollection);
			setFilteredTokens(selectedCollection.tokens);
		} else {
			navigate("/dashboard/error/404");
		}

		setIsLoading(false);
	};

	const handleChangeFilter = selectedFilter => {
		if (selectedFilter === "listing") {
			setFilteredTokens(selectedCollection.tokens.filter(token => true && token.listingInfo?.listed));
			setFilterBy("listing");
		} else {
			setFilteredTokens(selectedCollection.tokens);
			setFilterBy("");
		}
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox my={3}>
				{isLoading ? (
					<SoftProcessing overlay={false} />
				) : (
					<Card>
						<SoftBox className="marketplaceNav" p={3}>
							<Stack gap={1} direction="row" flexWrap={"wrap"} justifyContent="center">
								<SoftButton
									variant="text"
									color={filterBy === "" ? "black" : "white"}
									onClick={() => handleChangeFilter("")}
								>
									All Tokens
								</SoftButton>
								<SoftButton
									variant="text"
									color={filterBy === "listing" ? "black" : "white"}
									onClick={() => handleChangeFilter("listing")}
								>
									For Sale
								</SoftButton>
							</Stack>
						</SoftBox>
						<SoftBox p={3} sx={{ backgroundColor: "#220B05" }}>
							{filteredTokens.filter(token => token.tokenID && token.tokenID > 0).length > 0 ? (
								<Grid container spacing={3} sx={{ alignItems: "flex-start" }}>
									{filteredTokens
										.filter(token => token.tokenID && token.tokenID > 0)
										.map(token => (
											<Grid
												key={token.id}
												item
												sm={6}
												md={4}
												lg={3}
												style={{
													cursor: "pointer",
													maxWidth: "300px",
												}}
											>
												<Link
													to={`/dashboard/marketplace/collection/${selectedCollection.id}/${token.id}/tokenID-${token.tokenID}`}
												>
													<Card
														style={{
															background:
																"linear-gradient(138.87deg, #423633 7.81%, #141727 95.71%)",
														}}
													>
														<img
															src={`${
																process.env.REACT_APP_GCLOUD_STORAGE_BASE_URL
															}${token.imageURL.replace("ipfs://", "")}`}
															style={{
																width: "100%",
																height: "295px",
																objectFit: "cover",
															}}
														/>
														<SoftBox p={2}>
															<SoftTypography variant="h6" color="white">
																{token.metadataCache?.name}
															</SoftTypography>
															<Grid
																display="flex"
																justifyContent="flex-start"
																alignItems="center"
																gap={1}
																my={1}
															>
																<Grid item>
																	<img
																		src={avatar}
																		width="30"
																		style={{ margin: "0", verticalAlign: "top" }}
																	/>
																</Grid>
																<Grid item>
																	<SoftTypography variant="h6" color="white">
																		Bullbearworld
																	</SoftTypography>
																</Grid>
															</Grid>
															<SoftTypography
																variant="body2"
																color="white"
																mt={3}
																opacity={0.5}
															>
																Price
															</SoftTypography>
															<SoftTypography variant="h6" color="white">
																{token.listingInfo?.listed
																	? "USDC " + numberFormat(token.listingInfo.price)
																	: "N/A"}
															</SoftTypography>
														</SoftBox>
													</Card>
												</Link>
											</Grid>
										))}
								</Grid>
							) : (
								<SoftBox p={2} mb={3}>
									<Typography align="center" variant="h4">
										Sorry! No NFT found under this category.
									</Typography>
								</SoftBox>
							)}
						</SoftBox>
					</Card>
				)}
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
};

export default MarketplaceCollectionDetails;
