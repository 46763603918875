/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom styles for the SoftDropzone
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";
import { setErrorMessage, useSoftUIController } from "context";

function SoftDropzone({ submissionURL, onUpload, onRemove, options }) {
	const dropzoneRef = useRef();
	const [controller, dispatch] = useSoftUIController();

	useEffect(() => {
		Dropzone.autoDiscover = false;

		function createDropzone() {
			const dz = new Dropzone(dropzoneRef.current, {
				...options,
				url: submissionURL,
				headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
				init: function () {
					console.log("asfd");
				},
			});
			dz.on("complete", function (file) {
				if (file.status === "error") {
					setErrorMessage(
						dispatch,
						"Sorry! Could not upload this file. Please check if selected a correct format"
					);
					dz.removeFile(file);
				}
			});
			dz.on("removedfile", function () {
				if (onRemove) {
					onRemove();
				}
			});
			dz.on("success", function (file, response) {
				if (onUpload) {
					onUpload(file, response);
				}
			});

			return dz;
		}

		function removeDropzone() {
			if (Dropzone.instances.length > 0) Dropzone.instances.forEach(dz => dz.destroy());
		}

		createDropzone();

		return () => removeDropzone();
	}, [options]);

	return (
		<SoftDropzoneRoot component="div" ref={dropzoneRef} className="form-control dropzone">
			<SoftBox className="fallback">
				<SoftBox component="input" name="file" type="file" />
			</SoftBox>
		</SoftDropzoneRoot>
	);
}

// Typechecking props for the SoftDropzone
SoftDropzone.propTypes = {
	options: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SoftDropzone;
