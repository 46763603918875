import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { getSetting, updateSetting } from "services/setting";
import { useEffect, useState } from "react";

export default () => {
	const [isLoading, setIsLoading] = useState(true);
	const [stockRankingText, setStockRankingText] = useState(null);
	useEffect(() => {
		getSetting("stock_ranking_text")
			.then(data => {
				if (data) {
					setStockRankingText({ title: data.title ?? "", body: data.body ?? "" });
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox pb={3}>
				<SoftBox mb={3}>
					{isLoading ? (
						<SoftBox style={{ display: "flex", justifyContent: "center" }}>
							<CircularProgress color="inherit" />
						</SoftBox>
					) : (
						<Formik
							initialValues={{ title: stockRankingText?.title, body: stockRankingText?.body }}
							onSubmit={(values, actions) => {
								updateSetting("stock_ranking_text", { title: values.title, body: values.body });

								actions.setSubmitting(false);
							}}
						>
							{({ values, errors, touched, isSubmitting, handleChange }) => (
								<Form id="user_plan_update" autoComplete="off">
									<Card>
										<SoftBox p={2}>
											<SoftBox>
												<SoftBox>
													<SoftTypography variant="h5" fontWeight="bold">
														Stock Ranking Text
													</SoftTypography>
												</SoftBox>
												<SoftBox mt={2}>
													<SoftTypography variant="body">Title</SoftTypography>
													<SoftInput
														name="title"
														defaultValue={values.title}
														onChange={handleChange}
													/>
												</SoftBox>
												<SoftBox mt={2}>
													<SoftTypography variant="body">Text</SoftTypography>
													<SoftInput
														multiline
														rows={5}
														name="body"
														defaultValue={values.body}
														onChange={handleChange}
													/>
												</SoftBox>
												<SoftBox
													mt={2}
													width="100%"
													display="flex"
													justifyContent="space-between"
												>
													<SoftButton
														disabled={isSubmitting}
														type="submit"
														variant="gradient"
														color="dark"
													>
														Update
													</SoftButton>
												</SoftBox>
											</SoftBox>
										</SoftBox>
									</Card>
								</Form>
							)}
						</Formik>
					)}
				</SoftBox>
			</SoftBox>
		</DashboardLayout>
	);
};
