import Popup from 'layouts/applications/popup/Popup';
import CloseIcon from '@mui/icons-material/Close';
import SoftTypography from 'components/SoftTypography';
import { RedBox } from 'components/CustomComponents';
import SoftBox from 'components/SoftBox';
import { Card, Grid } from '@mui/material';
import SoftButton from 'components/SoftButton';

export const SNPInfoPop = ({ toggleInfoPopup }) => {
	return <Popup transparent={true} >
		<CloseIcon
			className="close-btn icon"
			style={{ color: 'white' }}
			onClick={() => {
				toggleInfoPopup(false);
			}}
		/>
		<Card className="popupCard popupWidth">
			<RedBox Header={"S&P 500"}></RedBox>
			<SoftTypography className="popupTextStyle" >The S&P 500 Index measures the value of the stocks of the 500
				largest corporations by market capitalization listed on the New York Stock Exchange or Nasdaq. The intention of Standard & Poor's is to have a price that provides a quick look at the stock market and economy.</SoftTypography>
		</Card>
	</Popup>
}

export const DisclaimerPopup = ({ toggleInfoPopup }) => {
	return <Popup transparent={true} >
		<CloseIcon
			className="close-btn icon"
			style={{ color: 'white' }}
			onClick={() => {
				toggleInfoPopup(false);
			}}
		/>
		<Card className="popupCard popupWidth">
			<RedBox Header={"Disclaimer"}></RedBox>
			<SoftTypography className="popupTextStyle" >
				1. All contents in BullBearVector® platform are not meant to be and do not constitute any financial, investment or any form of advice or recommendation to buy or sell any shares, securities, derivatives or other investment instruments.
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >
				2. All users of BullBearVector® Platform are suggested to use the knowledge and skills gained from the contents and course with your personal due diligence and be responsible for your own investments.
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >
				3. Stock Ranking is updated every quarter due to quarterly reporting, which is mandatory by the U.S. Securities and Exchange Commission (SEC).
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >
				4. All option data is historical and updated regularly on a daily basis.
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >
				5. All expiry of options are updated based on market conditions.
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >
				6. All trading decisions on your broker platform need to be adjusted based on market conditions.
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >
				7. If you are not sure on what to do, please sign up for our programs.
			</SoftTypography>
			<SoftBox mt={4} mb={1}>
			
						<SoftButton
							onClick={()=>{toggleInfoPopup(false)}}
							className="bbvGreen"
							variant="gradient"
							color="dark"
							fullWidth
						>
							CONTINUE
						</SoftButton>
					</SoftBox>
		</Card>
	</Popup>
}

export const EconomicCalendarPopup = ({ toggleInfoPopup }) => {
	return <Popup transparent={true} >
		<CloseIcon
			className="close-btn icon"
			style={{ color: 'white' }}
			onClick={() => {
				toggleInfoPopup(false);
			}}
		/>
		<Card className="popupCard popupWidth">
			<RedBox Header={"What Is the Economic Calendar?"}></RedBox>
			<SoftTypography className="popupTextStyle" >The economic calendar refers to the scheduled dates of significant releases or events that may affect the movement of individual security prices or markets as a whole. Investors and traders use the economic calendar to plan trades and portfolio reallocations and to be alert for chart patterns and indicators that may be caused or affected by these events. The economic calendar for various countries is available for free on many financial and market websites.
			</SoftTypography>
		</Card>
	</Popup>
}

export const NewsPopup = ({ toggleInfoPopup }) => {
	return <Popup transparent={true} >
		<CloseIcon
			className="close-btn icon"
			style={{ color: 'white' }}
			onClick={() => {
				toggleInfoPopup(false);
			}}
		/>
		<Card className="popupCard popupWidth">
			<RedBox Header={"News"}></RedBox>
			<SoftTypography className="popupTextStyle" >The latest analysis and insights into current events and economic indicators such as central bank news, unemployment numbers, GDP, and more
			</SoftTypography>
		</Card>
	</Popup>
}

export const FearAndGreedPopup = ({ toggleInfoPopup }) => {
	return <Popup transparent={true}  >
		<CloseIcon
			className="close-btn icon"
			style={{ color: 'white' }}
			onClick={() => {
				toggleInfoPopup(false);
			}}
		/>
		<Card className="popupCard popupWidth">
			<RedBox Header={"Fear & Greed Index"}></RedBox>
			<strong className='popupGrey'>What is the Fear & Greed Index?</strong>
			<SoftTypography className="popupTextStyle" >The Fear & Greed Index is a way to gauge stock market movements and whether stocks are fairly priced. The theory is based on the logic that excessive fear tends to drive down share prices, and too much greed tends to have the opposite effect.
			</SoftTypography>
			<br></br>
			<strong className='popupGrey'>How often is the Fear & Greed Index calculated?</strong>
			<SoftTypography className="popupTextStyle" >Every component and the Index are calculated as soon as new data becomes available.
			</SoftTypography>
			<br></br>
			<strong className='popupGrey'>How to use Fear & Greed Index?</strong>
			<SoftTypography className="popupTextStyle" >The Fear & Greed Index is used to gauge the mood of the market. Many investors are emotional and reactionary, and fear and greed sentiment indicators can alert investors to their own emotions and biases that can influence their decisions. When combined with fundamentals and other analytical tools, the Index can be a helpful way to assess market sentiment.
			</SoftTypography>
		</Card>
	</Popup>
}

export const MacroeconomicsPopup = ({ toggleInfoPopup }) => {
	return <Popup transparent={true} >
		<CloseIcon
			className="close-btn icon"
			style={{ color: 'white' }}
			onClick={() => {
				toggleInfoPopup(false);
			}}
		/>
		<Card className="popupCard popupWidth">
			<RedBox Header={"Macroeconomics"}></RedBox>
			<SoftTypography className="popupTextStyle" >Macroeconomics is a branch of economics that studies how an overall economy—the markets, businesses, consumers, and governments—behave. Macroeconomics examines economy-wide phenomena such as inflation, price levels, rate of economic growth, national income, gross domestic product (GDP), and changes in unemployment.
			</SoftTypography>
			<br></br>
			<strong className='popupGrey'>Some of the key questions addressed by macroeconomics include: </strong>
			<SoftTypography className="popupTextStyle" >
				What causes unemployment?
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >
				What causes inflation?
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >
				What creates or stimulates economic growth?
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >
				Macroeconomics attempts to measure how well an economy is performing, understand what forces drive it, and project how performance can improve.
			</SoftTypography>
		</Card>
	</Popup>
}

export const TrendInfoPopup = ({ toggleInfoPopup }) => {
	return <Popup transparent={true}  >
		<CloseIcon
			className="close-btn icon"
			style={{ color: 'white' }}
			onClick={() => {
				toggleInfoPopup(false);
			}}
		/>
		<Card className="popupCard popupWidth">
			<RedBox Header={"Trend"}></RedBox>
			<SoftTypography className="popupTextStyle" >Trend is the direction that prices are moving in, based on where they have been in the past. Trends are made up of peaks and troughs. It is the direction of those peaks and troughs that constitute a market's trend. Whether those peaks and troughs are moving up, down, or sideways indicates the direction of the trend. Did you heard of this phase, "Make the trend your friend?", usually when you go against the trend, there is a very high chance you will lose money.
			</SoftTypography>
			<br></br>
			<strong className='popupGrey'>Typically, there are three different types of trends given below:
			</strong>
			<SoftTypography className="popupTextStyle" >Uptrend
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >Downtrend.
			</SoftTypography>
			<SoftTypography className="popupTextStyle" >Sideways trend.
			</SoftTypography>
			<br></br>
			<strong className='popupGrey'>Indices:
			</strong>
			<SoftTypography className="popupTextStyle">Investors follow different market indexes to gauge market movements. The three most popular stock indexes for tracking the performance of the U.S. market are the Dow Jones Industrial Average (DJIA), S&P 500 Index, and Nasdaq Composite Index.</SoftTypography>
			<br></br>
			<strong className='popupGrey'>Commodities:
			</strong>
			<SoftTypography className="popupTextStyle">Investing in commodities can provide investors with diversification, a hedge against inflation, and excess positive returns. Investors may experience volatility when their investments track a single commodity or one sector of the economy. Supply, demand, and geopolitics all affect commodity prices.</SoftTypography>
			<br></br>
			<strong className='popupGrey'>Money Market:
			</strong>
			<SoftTypography className="popupTextStyle">The money market is a financial market wherein short-term assets and open-ended funds are traded between institutions and traders. It is important to track where the money is moving, what currencies are being used, therefore affecting the stock prices.</SoftTypography>
		</Card>
	</Popup>
}

export const DashboardInfoPopup = ({ toggleInfoPopup, popupAccessor }) => {

	switch (popupAccessor) {
		case 'fearAndGreed':
			return <FearAndGreedPopup toggleInfoPopup={toggleInfoPopup} />

		case 'news':
			return <NewsPopup toggleInfoPopup={toggleInfoPopup} />

		case 'economicCalender':
			return <EconomicCalendarPopup toggleInfoPopup={toggleInfoPopup} />

		case 'macroEconomic':
			return <MacroeconomicsPopup toggleInfoPopup={toggleInfoPopup} />

		case 'snp':
			return <SNPInfoPop toggleInfoPopup={toggleInfoPopup} />

		case 'trend':
			return <TrendInfoPopup toggleInfoPopup={toggleInfoPopup} />
		default:
			return <FearAndGreedPopup toggleInfoPopup={toggleInfoPopup}></FearAndGreedPopup>;
	}
}


