import { ethers } from "ethers";

export const web3provider = window.ethereum
	? new ethers.providers.Web3Provider(window.ethereum, "any")
	: new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CHAIN_RPC_URL);
	
web3provider.getNetwork().then(response => console.log("getNetwork", response));

export const detectNetworkAccount = () =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			window.alert("Non-Ethereum browser detected. You should consider trying MetaMask!");
			reject();
			return;
		}

		try {
			// Load account
			const accounts = await web3provider.send("eth_requestAccounts");
			if (!accounts.length) {
				reject("Unable to find any account");
			}

			const { chainId } = await web3provider.getNetwork();

			console.log("changed to", chainId, accounts);

			resolve({
				account: accounts[0],
				networkId: chainId,
			});
		} catch (e) {
			console.log(e);
			reject();
		}
	});
