/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";
import Pagination from "./pagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import buttonImage from "../../../assets/images/how-to-question 1.png";
import SoftButton from "components/SoftButton";
import { Navigate, Route } from "react-router-dom";
import { components } from "react-select";
import { getSNPData, getStocksData } from "layouts/applications/data-tables/data/getData";

import useAuth from "layouts/authentication/hooks/useAuth";
import blurredGraph from "assets/images/blurred-graph-long.png";
import { StockInfoPopup } from "components/CustomPopups/StockPopups";

function DataTable({
	entriesPerPage,
	canSearch,
	showTotalEntries,
	table,
	pagination,
	isSorted,
	noEndBorder,
	shariah,
	index,
	market,
	sector,
}) {
	const { auth } = useAuth();

	const [currentPage, setCurrentPage] = useState(1);
	const navigate = useNavigate();

	const [postsPerPage, setPostsPerPage] = useState(50);
	const [loading, setLoading] = useState(false);

	const [random, setRandom] = useState(0);

	const [filteredData, setFilteredData] = useState([]);
	const [totalPosts, setTotalPosts] = useState(0);
	const [infoPopup, toggleInfoPopup] = useState(false)
	const [popupAccessor, setPopupAccessor] = useState("")
	useEffect(() => {
		handleStock();
	}, [currentPage, index, sector, shariah, market]);

	const handleStock = async () => {
		setLoading(true);
		let stocksData;
		if (index === "INDEX") {
			stocksData = await getStocksData({ page: currentPage, sector, shariah, market });
		} else if (index === "S&P 500") {
			// console.log("hello")
			stocksData = await getSNPData({ page: currentPage, sector, shariah, market });
			// console.log(stocksData)
		} else {
			console.log("else");
		}

		try {
			setFilteredData(formatStocks(stocksData.data));
			setTotalPosts(stocksData.total);
		} catch {
			console.log("error with stocks");
		}
		setLoading(false);
	};

	const formatStocks = stocksData => {
		const tableData = stocksData.map(options => {
			return {
				General_Exchange: options.exchange,
				General_Code: options.symbol,
				GEM_Rank: validateGEM(options.GEM_Rank),
				Highlights_MarketCapitalizationMln: options.marketCapitalization,
				General_Sector: options.gsector,
				Sharia_Compliance: false,
			};
		});
		return tableData;
	};

	const validateGEM = rank => {
		if (rank === null) {
			return 9999;
		}
		return rank;
	};

	//resets iframe
	const resetIframe = () => {
		setRandom(random + 1);
	};

	function filteredExchange(exhange) {
		if (exhange === "NYSE MKT") {
			return "AMEX";
		}
		return exhange;
	}

	const handleInfoPop = (popupAccessor) => {
		console.log("pressed")
		toggleInfoPopup(true)
		setPopupAccessor(popupAccessor)
	}

	const paginate = pageNumber => setCurrentPage(pageNumber);

	const convertNA = gem => {
		if (gem === 9999) {
			return "N.A.";
		}
		return gem;
	};

	const stockPressed = stockCode => {
		navigate(`./${stockCode}`);
	};

	return loading ? (
		<div className="loadingIcon">
			<CircularProgress />
		</div>
	) : totalPosts > 0 ? (
		<div style={{ borderRadius: "15px" }} className="stocksDiv">
			<div className="pagination">
			
			<InfoIcon style={{position:"absolute",marginTop:"10px" }} onClick={() => { handleInfoPop("sopTwo") }} className="icon"></InfoIcon>
				<Pagination
					postsPerPage={postsPerPage}
					currentPage={currentPage}
					totalPosts={totalPosts}
					paginate={paginate}
					resetIframe={resetIframe}
				/>
			</div>

			<p className="searchResults">
				Search Results ({(currentPage - 1) * postsPerPage + 1}-{Math.min(totalPosts, currentPage * postsPerPage)}):{" "}
			</p>
			<div className="fullSize">
				{filteredData.map((row, key) => {
					const filteredExange = filteredExchange(row.General_Exchange);

					const source = `https://s.tradingview.com/embed-widget/mini-symbol-overview/?locale=en#%7B%22symbol%22%3A%22${filteredExange}%3A${row.General_Code}%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22dateRange%22%3A%2212M%22%2C%22colorTheme%22%3A%22light%22%2C%22trendLineColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%201)%22%2C%22underLineColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%200.3)%22%2C%22underLineBottomColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%200)%22%2C%22isTransparent%22%3Afalse%2C%22autosize%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22bullbearvector.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%7D`;
					return (
						<div key={key}>
							<Grid container>
								<Grid item xl={9} lg={9} md={9} sm={9} xs={7}>
									{false && (!auth || !auth.plan?.plan_features.available.includes("gem_ranking")) ? (
										<img
											src={blurredGraph}
											style={{ maxWidth: "100%", objectFit: "fill" }}
											alt="blurred chart"
										/>
									) : (
										<iframe
											key={random}
											className="fullWidth "
											title={row.GEM_Rank}
											frameBorder={0}
											src={source}
										/>
									)}
								</Grid>
								<Grid className="rightColumn" item xl={3} lg={3} md={3} sm={3} xs={5}>
									<p className="inline gem">GEM </p>
									<p className="inline rank">RANK {convertNA(row.GEM_Rank)}</p>
									<h4 className="marketCap">Market Cap:</h4>
									<h4 className="marketCapValue">
										{Math.round(row.Highlights_MarketCapitalizationMln * 100) / 100}M
									</h4>
									<p className="sector">Sector:</p>
									<p className="sectorValue">{row.General_Sector}</p>
									{/* <Link className="analysisButton" to={"/GEM/stocks-ranking/" + row.General_Code}>Press me</Link> */}
									<SoftButton
										className="analysisButton"
										onClick={() => {
											stockPressed(row.General_Code, row.General_Exchange);
										}}
									>
										See Analysis
									</SoftButton>
								</Grid>
							</Grid>
						</div>
					);
				})}

				{infoPopup ? <StockInfoPopup toggleInfoPopup={toggleInfoPopup} popupAccessor={popupAccessor} /> : <div></div>}
			</div>
		</div>
	) : (
		<SoftTypography style={{ textAlign: "center" }}>No records found</SoftTypography>
	);
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
	entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
	canSearch: false,
	showTotalEntries: true,
	pagination: { variant: "gradient", color: "info" },
	isSorted: true,
	noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
	entriesPerPage: PropTypes.oneOfType([
		PropTypes.shape({
			defaultValue: PropTypes.number,
			entries: PropTypes.arrayOf(PropTypes.number),
		}),
		PropTypes.bool,
	]),
	canSearch: PropTypes.bool,
	showTotalEntries: PropTypes.bool,
	table: PropTypes.objectOf(PropTypes.array).isRequired,
	pagination: PropTypes.shape({
		variant: PropTypes.oneOf(["contained", "gradient"]),
		color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
	}),
	isSorted: PropTypes.bool,
	noEndBorder: PropTypes.bool,
};

export default DataTable;
