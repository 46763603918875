import { CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";

import PropTypes from "prop-types";

const SoftProcessing = ({ overlay }) => {
	return (
		<SoftBox
			style={{
				position: overlay ? "fixed" : "static",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				background: overlay ? "rgba(0,0,0,0.25)" : "transparent",
				zIndex: "100000",
			}}
		>
			<CircularProgress color="error" />
		</SoftBox>
	);
};

SoftProcessing.defaultProps = {
	overlay: true,
}

SoftProcessing.propTypes = {
	overlay: PropTypes.bool
}

export default SoftProcessing;
