/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavCard from "examples/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import { sidenavLogoLabel, sidenavIcon } from "examples/Sidenav/styles/sidenav";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "context";

import { navbarDesktopMenu, navbarMobileMenu } from "examples/Navbars/DashboardNavbar/styles";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
	const [openCollapse, setOpenCollapse] = useState(false);
	const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
	const [collapsedSidebar, setCollapsedSidebar] = useState(false);
	const [controller, dispatch] = useSoftUIController();
	const { miniSidenav, transparentSidenav } = controller;
	const location = useLocation();
	const { pathname } = location;
	const collapseName = pathname.split("/").slice(1)[0];
	const itemName = pathname.split("/").slice(1).pop();

	const toggleSidenav = () => {
		setMiniSidenav(dispatch, !collapsedSidebar);
		setCollapsedSidebar(!collapsedSidebar);
	};

	const closeSidenav = () => {
		setMiniSidenav(dispatch, true);
	};

	useEffect(() => {
		// A function that sets the mini state of the sidenav.
		function handleMiniSidenav() {
			setMiniSidenav(dispatch, window.innerWidth < 1200);
		}

		/** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
		window.addEventListener("resize", handleMiniSidenav);

		// Call the handleMiniSidenav function to set the state with the initial value.
		handleMiniSidenav();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleMiniSidenav);
	}, [dispatch, location]);

	// Render all the nested collapse items from the routes.js
	const renderNestedCollapse = collapse => {
		const template = collapse.map(({ name, route, key, href }) =>
			href ? (
				<Link key={key} href={href} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
					<SidenavItem name={name} nested />
				</Link>
			) : (
				<NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
					<SidenavItem name={name} active={route === pathname} nested />
				</NavLink>
			)
		);

		return template;
	};

	// Render the all the collpases from the routes.js
	const renderCollapse = collapses =>
		collapses
			.filter(({ hideInNavbar }) => !hideInNavbar)
			.map(({ name, collapse, route, href, key }) => {
				let returnValue;

				if (collapse) {
					returnValue = (
						<SidenavItem
							key={key}
							name={name}
							active={key === itemName}
							open={openNestedCollapse === name}
							onClick={() =>
								openNestedCollapse === name ? setOpenNestedCollapse(false) : setOpenNestedCollapse(name)
							}
						>
							{renderNestedCollapse(collapse)}
						</SidenavItem>
					);
				} else {
					returnValue = href ? (
						<Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
							<SidenavItem name={name} active={route === pathname} />
						</Link>
					) : (
						<NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
							<SidenavItem name={name} active={route === pathname} />
						</NavLink>
					);
				}
				return <SidenavList key={key}>{returnValue}</SidenavList>;
			});

	// Render all the routes from the routes.js (All the visible items on the Sidenav)
	const renderRoutes = routes
		.filter(({ hideInNavbar }) => !hideInNavbar)
		.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
			let returnValue;

			if (type === "collapse") {
				if (href) {
					returnValue = (
						<Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
							<SidenavCollapse
								name={name}
								icon={icon}
								active={key === collapseName}
								noCollapse={noCollapse}
							/>
						</Link>
					);
				} else if (noCollapse && route) {
					returnValue = (
						<NavLink to={route} key={key}>
							<SidenavCollapse
								name={name}
								icon={icon}
								noCollapse={noCollapse}
								active={route === pathname}
							>
								{collapse ? renderCollapse(collapse) : null}
							</SidenavCollapse>
						</NavLink>
					);
				} else {
					returnValue = (
						<SidenavCollapse
							key={key}
							name={name}
							icon={icon}
							active={route === pathname}
							open={openCollapse === key}
							onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
						>
							{collapse ? renderCollapse(collapse) : null}
						</SidenavCollapse>
					);
				}
			} else if (type === "title") {
				returnValue = (
					<SoftTypography
						key={key}
						display="block"
						variant="caption"
						fontWeight="bold"
						textTransform="uppercase"
						opacity={0.6}
						pl={3}
						mt={2}
						mb={1}
						ml={1}
					>
						{title}
					</SoftTypography>
				);
			} else if (type === "divider") {
				returnValue = <Divider key={key} />;
			}

			return returnValue;
		});

	return (
		<SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
			<SoftBox pt={3} pb={1} px={2} textAlign="center">
				<SoftBox
					display={{ xs: "block", xl: "none" }}
					position="absolute"
					top={0}
					right={0}
					p={1.625}
					onClick={closeSidenav}
					sx={{ cursor: "pointer" }}
				>
					<SoftTypography variant="h6" color="secondary">
						<Icon sx={{ fontWeight: "bold" }}>close</Icon>
					</SoftTypography>
				</SoftBox>
				<SoftBox display="flex" alignItems="center">
					<SoftBox
						display="flex"
						alignItems="center"
						mr={2}
						sx={theme => sidenavIcon(theme, { miniSidenav })}
					>
						<Icon fontSize="medium" sx={navbarDesktopMenu} onClick={toggleSidenav}>
							{miniSidenav ? "menu_open" : "menu"}
						</Icon>
					</SoftBox>
					<SoftBox component={NavLink} to="/" sx={theme => sidenavLogoLabel(theme, { miniSidenav })}>
						{brand && <SoftBox component="img" src={brand} alt="Soft UI Logo" width="2rem" />}
						<SoftTypography component="h6" variant="button" fontWeight="medium">
							{brandName}
						</SoftTypography>
					</SoftBox>
				</SoftBox>
			</SoftBox>
			<Divider />
			<List>{renderRoutes}</List>

			{pathname.search("/admin") < 0 && (
				<SoftBox pt={2} my={2} mx={2}>
					<SidenavCard />
				</SoftBox>
			)}
		</SidenavRoot>
	);
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
	color: "info",
	brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
	color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
	brand: PropTypes.string,
	brandName: PropTypes.string.isRequired,
	routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
