export const NETWORKS = [
    {
      id: 1,
      name: "Ethereum (Mainnet)",
      baseURL: "https://etherscan.io/",
      mainnet: true
    },
    {
      id: 5,
      name: "Goerli Test Network",
    },
    {
      id: 56,
      name: "Binance Smart Chain",
      baseURL: "https://bscscan.com/",
      currencies: ["WETH", "USDT", "BUSD", "WBNB", "TRON"],
      mainnet: true
    },
    {
      id: 97,
      name: "BSC (Testnet)",
      baseURL: "https://testnet.bscscan.com/",
      currencies: ["MUSD", "BUSD"],
    },
    {
      id: 137,
      name: "Matic (Mainnet)",
      baseURL: "https://polygonscan.com/",
      currencies: ["WETH", "USDT"],
      mainnet: true
    },
    {
      id: 80001,
      name: "Matic Test Network",
      baseURL: "https://mumbai.polygonscan.com/",
      currencies: ["DERC20", "TST"],
    },
  ];
  