import React from 'react';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import rocketImg from "../../../../assets/images/rocket.png"
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import redArrow from "../../../../assets/images/redArrow.png"
import greenArrow from "../../../../assets/images/greenArrow.png"
import { getStockData } from 'layouts/applications/data-tables/data/getData';
import { getUserPortfolioStocks } from 'layouts/applications/data-tables/data/getData';

let dummyUserData = {
    data: [
        { name: "TSLA", stockName: "Tesla", exchange: "NASDAQ", quantity: 10, totalCost: 1400 },
        { name: "AAPL", stockName: "Apple", exchange: "NASDAQ", quantity: 5, totalCost: 2000 },
        { name: "ABNB", stockName: "Airbnb", exchange: "NASDAQ", quantity: 8, totalCost: 5000 },
    ],
};

const PortfolioSummary = ({ renderUseEffect, setChartProfit, chartProfit }) => {
    //user data should contain quantity of each stock, as well as average price of purchase/net cost
    //we fetch current market price

    const [totalPortfolioAmount, setTotalPortfolioAmount] = useState(0);
    const [theInitialCost, setInitialCost] = useState(0);
    const [portfolio, setPortfolio] = useState([]);
    // let portfolio = [];
    // const [finalPortfolio, setPortfolio] = useState([]);
    const [isProfit, setProfit] = useState(true)
    const [equity, setEquity] = useState(0)
    let overallProfitPercentage = Math.abs((((totalPortfolioAmount - theInitialCost) / theInitialCost) * 100)).toFixed(0)
    const [profitPercent, setProfitPercent] = useState(0)
    let netProfit = 0;
    useEffect(() => {
        handlePortfolio();
    }, [renderUseEffect])

    const handleProfit = (portfolioValue, portfolioCost) => {
        // if (portfolioValue < portfolioCost) {
        //     setProfit(false)
        // }
        console.log('to change is: ' + (portfolioValue - portfolioCost))
        netProfit += (portfolioValue - portfolioCost);
        setEquity(netProfit)
        console.log(overallProfitPercentage)

    }

    const resetInputs = () => {
        setEquity(0)
        // const blankObject = []
        while (portfolio.length !== 0) {
            portfolio.pop()
        }

        setTotalPortfolioAmount(0)
        console.log("portfolio is")
        console.log(portfolio)
        setInitialCost(0)
        console.log("resetted")
    }

    const handlePortfolio = async () => {
        let portfolioAmt = 0;
        let initalCost = 0;
        resetInputs();
        const portfolioData = await getUserPortfolioStocks();
        portfolioData.map(async (data, index) => {
            console.log("data is ")
            console.log(data)
            const profit = parseFloat(data.pAndL)
            const profitPercentage = parseFloat(data.pAndLPercentage)
            const totalValue = parseFloat(data.quantity) * parseFloat(data.currentMktPrice)
            const currentCost = parseFloat(data.quantity) * parseFloat(data.tradePrice)
            initalCost += currentCost;
            portfolioAmt += totalValue;
            setInitialCost(initalCost)
            setTotalPortfolioAmount(portfolioAmt)
            handleProfit(totalValue, currentCost)
            let isProfit = profit > 0;
            const newPortfolioItem = {
                name: data.symbol,
                stockName: data.name,
                quantity: data.quantity,
                totalCost: currentCost,
                totalValue: totalValue,
                isProfit: isProfit,
                profitPercentage: profitPercentage,
                profit: profit,
                companyDomain: data.companyDomain
            }
            portfolio.push(newPortfolioItem);
        })

        setPortfolio(portfolio);

        let sortedPortfolio = portfolio;
        sortData(sortedPortfolio)

        if (netProfit >= 0) {
            setProfit(true)
        }else(
            setProfit(false)
        )

        setProfitPercent(netProfit && initalCost > 0 ? parseInt((netProfit / initalCost) * 100) : 0)
        setChartProfit({ profit: (netProfit).toFixed(2), profitPercentage: netProfit && initalCost > 0 ? parseInt((netProfit / initalCost) * 100) : 0, initialCost: initalCost })
    };
    const sortData = (sortedPortfolio) => {
        sortedPortfolio.sort((a, b) => {
            return (b.totalValue - a.totalValue)
        })
        setPortfolio(sortedPortfolio)
    }


    return (
        <Grid style={{ paddingLeft: "15px", paddingRight: "15px" }} justifyContent='space-between' className='bbvWhiteCard'>
            <h1 className='roboto' style={{ textAlign: "left", fontSize: "18px", paddingTop: "20px" }}>Portfolio Summary</h1>
            <Grid container justifyContent='space-between'>
                <h1 style={{ display: "inline", fontSize: "36px" }} className="roboto">${totalPortfolioAmount.toFixed(2)}</h1>
                <p style={{ display: "inline", color: isProfit ? "green" : "red", fontSize: '14px' }} className='helvetica' >{profitPercent} %<img alt='arrow' style={{ width: '20px' }} src={isProfit ? greenArrow : redArrow} /></p>
            </Grid>
            {/* <p className='portfolioGainedText roboto' style={{ textAlign: "left", color: "#7B7B7B" }}>You {isProfit ? <span>gained</span> : <span>lost</span>} {<span style={{ color: isProfit ? "green" : "red" }} >${Math.abs(equity.toFixed(2))}</span>} in October 2022</p> */}
            <p className='portfolioGainedText roboto' style={{ textAlign: "left", color: "#7B7B7B" }}>You {isProfit ? <span>gained</span> : <span>lost</span>} {<span style={{ color: isProfit ? "green" : "red" }} >${Math.abs(equity.toFixed(2))}</span>} in 2022</p>
            <hr></hr>
            <h1 style={{ textAlign: "left", fontSize: "18px", marginTop: "18px" }} className='roboto'>Top Movers</h1>
            {/* array of the top 3 ranked stocks  */}
            {portfolio.slice(0, 3).map((data, index) => {
                const companyDomain = data.companyDomain
                return <Grid key={index}>
                    <Grid item className="portfolioSummaryDiv">
                        <img src={`https://logo.clearbit.com/${companyDomain}`} style={{ width: "40px", borderRadius: "10px" }} alt="wrench" />
                        <div className="helvetica inline" style={{ paddingLeft: "15px" }} >
                            <p style={{ fontSize: "14px" }}>{data.stockName}</p>
                            <p style={{ fontWeight: "400", color: "#7B7B7B", fontSize: "12px" }}>{data.name}</p>
                        </div>

                        <div>
                            {/* <p className="helvetica inline test" style={{ fontSize: "14px",textAlign:"left" }}>${data.profit} <br></br><span style={{color:data.isProfit?"green":"red"}}>{data.profitPercentage}%</span> <img alt='arrow'style={{width:'14px'}} src={data.isProfit?greenArrow:redArrow}/></p> */}
                            <div className="helvetica">
                                <p style={{ fontSize: "14px" }}>${data.totalValue.toFixed(2)}</p>
                                <p style={{ fontWeight: "400", fontSize: "14px", color: data.isProfit ? "green" : "red" }}>{data.profitPercentage.toFixed(0)}% <img alt='arrow' style={{ width: '14px' }} src={data.isProfit ? greenArrow : redArrow} /></p>
                            </div>
                        </div>

                    </Grid>
                </Grid>
            })}

        </Grid>



    );
};

export default PortfolioSummary;