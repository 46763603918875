import React, { useEffect } from "react";
import SoftSnackbar from "components/SoftSnackbar";

import { setErrorMessage, useSoftUIController } from "context";

function ErrorMessage() {
	const [controller, dispatch] = useSoftUIController();
	const { gotError, errorMessage, messageType } = controller;

	useEffect(() => {
		console.log(gotError)
		
		if (gotError) {
			setTimeout(() => setErrorMessage(dispatch, null), 5000);
		}
	}, [gotError, dispatch]);

	return errorMessage ? <SoftSnackbar color={messageType === "success" ? "success" : "error"} icon={messageType === "success" ? "warning" : "warning"} title={messageType === "success" ? "Success!" : "Error!"} content={errorMessage} open={gotError} bgWhite /> : null;
}

export default ErrorMessage;
