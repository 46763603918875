import KeyIcon from "@mui/icons-material/Key";
import { setPaymentPopup, useSoftUIController } from "context";
import useAuth from "layouts/authentication/hooks/useAuth";
import { TLSText } from "components/CustomComponents";
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value, requiredFeature, colour }) => {
	//config code for payment popup
	//ToDo: need to check the least value plan which includes the required feature
	const showStocksPopup = requiredFeature === "gem_ranking";
	const [controller, dispatch] = useSoftUIController();
	const { paymentPopup } = controller;

	const { auth } = useAuth();
	// console.log("colour is " + colour)
	// console.log(auth)
	const keyPressed = () => {
		//toggle popup
		setPaymentPopup(dispatch, { forStocks: showStocksPopup, on: !paymentPopup.on, isPaymentPopup: true });
	};

	return !auth || !auth.plan?.plan_features.available.includes(requiredFeature) ? (
		<KeyIcon
			onClick={() => {
				keyPressed();
			}}
			className="redKey"
		/>
	) : (
		<p style={{ color: colour }}>{value}</p>
	);
	// return <p style={{color:colour}}>{value}</p>
};
