import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";

import PageLayout from "examples/LayoutContainers/PageLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useParams, useSearchParams } from "react-router-dom";
import { validateTransaction, validateStripeTransaction } from "services/payment";
import SoftButton from "components/SoftButton";

function PaymentProcessPage() {
	const [processing, setProcessing] = useState(true);
	const [transactionStatus, setTransactionStatus] = useState(null);
	const { invoiceNo } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		// fetch the transaction info to verify if the transaction was completed
		setTimeout(verifyTransaction, 100);
	}, [invoiceNo]);

	const verifyTransaction = () => {
		if (invoiceNo === "stripe") {
			const paymentIntent = searchParams.get("payment_intent");
			const clientSecret = searchParams.get("payment_intent_client_secret");
			const redirectStatus = searchParams.get("redirect_status");

			if (redirectStatus !== "succeeded") {
				setTransactionStatus("failed");
			} else {
				validateStripeTransaction(paymentIntent).then(verified => {
					if (verified) {
						setTransactionStatus("success");
					} else {
						setTransactionStatus("failed");
					}
					setProcessing(false);
				})
			}
		} else {
			validateTransaction(invoiceNo).then(verified => {
				if (verified) {
					setTransactionStatus("success");
				} else {
					setTransactionStatus("failed");
				}

				setProcessing(false);
			});
		}
	};

	const LoadingBlock = () => (
		<>
			<CircularProgress color="inherit" />
			<SoftTypography my={3}>Please wait, while we are processing your payment</SoftTypography>
		</>
	);

	const SuccessBlock = () => (
		<>
			<SoftTypography my={3} component="h1">
				Thank You!
			</SoftTypography>
			<SoftTypography my={3}>
				Your payment has been processed successfully. Please click below to continue to the dashboard
			</SoftTypography>
			<SoftBox>
				<SoftButton component="a" href="/">
					Continue
				</SoftButton>
			</SoftBox>
		</>
	);

	const FailedBlock = () => (
		<>
			<SoftTypography my={3} component="h1">
				Sorry!
			</SoftTypography>
			<SoftTypography my={3}>
				Your payment could not be processed. Please check with your card/bank to confirm if the payment has been
				deducted. Please feel free to contact us for any issues.
			</SoftTypography>
			<SoftBox>
				<SoftButton component="a" href="/pages/pricing-page">
					Try Again
				</SoftButton>
			</SoftBox>
		</>
	);

	return (
		<PageLayout>
			<Container
				sx={{
					height: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<SoftBox
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					{processing && <LoadingBlock />}
					{transactionStatus === "success" && <SuccessBlock />}
					{transactionStatus === "failed" && <FailedBlock />}
				</SoftBox>
			</Container>
		</PageLayout>
	);
}

export default PaymentProcessPage;
