/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { useSoftUIController, setLayout } from "context";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Images
import error500 from "assets/images/illustrations/error-500.png";
import background from "assets/images/illustrations/trading_market.jpg";

function ComingSoon() {
	const { d1, d3, d4, d5 } = typography;
	const [, dispatch] = useSoftUIController();

	useEffect(() => {
		//code from template to ensure dashboard doesnt popup
		setLayout(dispatch, "page");
	}, [dispatch]);

	return (
		<SoftBox style={{ background: `url(${background}) no-repeat center`, backgroundSize: "cover" }}>
			<SoftBox style={{ background: "rgba(0,0,0,0.25)", height: "calc(100vh + 0px)", display: "flex" }}>
				<Grid container spacing={3} justifyContent="center" alignItems="center">
					<Grid item xs={11} sm={9} container alignItems="center">
						<Grid item xs={12} style={{ textAlign: "center" }}>
							<SoftBox
								fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
								lineHeight={1.2}
							>
								<SoftTypography variant="inherit" color="white" fontWeight="bold">
									Coming Soon!
								</SoftTypography>
							</SoftBox>
							<SoftTypography variant="h2" color="white" fontWeight="bold">
								We're working on something special.
							</SoftTypography>
							<SoftBox mt={1} mb={2}>
								<SoftTypography variant="body1" color="white">
									In the meantime please feel free to explore the current version.
								</SoftTypography>
							</SoftBox>
							<SoftBox mt={4} mb={2}>
								<SoftButton
									component={Link}
									to="/"
									variant="gradient"
									color="success"
									style={{ width: "30%", maxWidth: "300px" }}
								>
									go to BBV
								</SoftButton>
							</SoftBox>
						</Grid>
					</Grid>
				</Grid>
			</SoftBox>
		</SoftBox>
	);
}

export default ComingSoon;
