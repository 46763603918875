
import useAuth from "./useAuth"
import axios from "../api/axios";

const useRefreshToken = () => {
    const refresh = async () => {
        try {
            const response = await axios().get("/refreshAccessToken",{
                withCredentials:true
            })
            console.log("email is" + response.data.email)
            console.log("access_token is")
            console.log(response.data.access_token)
            return{email:response.data.email, access_token: response.data.access_token}
        }catch (error) {
            console.log(error)
        }}
    return refresh
    }

export default useRefreshToken