/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import Automotive from "layouts/dashboards/automotive";
import SmartHome from "layouts/dashboards/smart-home";
import BBV from "layouts/dashboards/BBV";
import BBVAdmin from "layouts/dashboards/BBVAdmin";
import VRDefault from "layouts/dashboards/virtual-reality/vr-default";
import VRInfo from "layouts/dashboards/virtual-reality/vr-info";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Security from "layouts/pages/account/security";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import NewProject from "layouts/pages/projects/new-project";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import SweetAlerts from "layouts/pages/sweet-alerts";
import Notifications from "layouts/pages/notifications";
import PricingPage from "layouts/pages/pricing-page";
import PaymentProcessPage from "layouts/pages/payment/process";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import OptionsDataTables from "layouts/applications/GEM-options-tables";
import DividendTable from "layouts/applications/Dividend-Tables";

import StocksDataTables from "layouts/applications/GEM-stocks-tables";
import Calendar from "layouts/applications/calendar";
import Analytics from "layouts/applications/analytics";
import Overview from "layouts/ecommerce/overview";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import ProductsList from "layouts/ecommerce/products/products-list";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import Referral from "layouts/ecommerce/referral";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import ResetBasic from "layouts/authentication/reset-password/basic";
import ResetCover from "layouts/authentication/reset-password/cover";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import LockBasic from "layouts/authentication/lock/basic";
import LockCover from "layouts/authentication/lock/cover";
import LockIllustration from "layouts/authentication/lock/illustration";
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import VerificationCover from "layouts/authentication/2-step-verification/cover";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";
import MarketplaceCollections from "layouts/marketplace/collections"
import MarketplaceCollectionDetails from "layouts/marketplace/collection"
import MarketplaceCollectionToken from "layouts/marketplace/token"
import MarketplaceConnectPage from "layouts/marketplace/connect"
import TermsServices from "layouts/pages/terms/services"
import PrivacyPolicy from "layouts/pages/terms/privacy"

import StockDetails from "layouts/applications/Stock-Details/StockDetails";
import OptionsFullscreen from "layouts/applications/Options-Fullscreen";

// admin componenets
import AdminUsersList from "layouts/pages/users/reports";
import AdminUserPlan from "layouts/pages/users/update-user/UserPlan";
import AdminCategoriesList from "layouts/pages/admin/marketplace/categories";
import AdminCollectionsList from "layouts/pages/admin/marketplace/collections";
import AdminCollectionForm from "layouts/pages/admin/marketplace/collections/form";
import AdminCollectionToken from "layouts/pages/admin/marketplace/collections/tokens";
import AdminMintCollectionToken from "layouts/pages/admin/marketplace/collections/token-form";
import AdminSettings from "layouts/pages/admin/Settings";
import ComingSoon from "layouts/coming-soon";

// Soft UI Dashboard PRO React icons
import ShopIcon from "examples/Icons/Shop";
import OfficeIcon from "examples/Icons/Office";
import UsersIcon from "examples/Icons/CustomerSupport";
import SettingsIcon from "examples/Icons/Settings";
import BasketIcon from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCardIcon from "examples/Icons/CreditCard";
import PortfolioPage from "layouts/applications/Portfolio";

import SearchIcon from "@mui/icons-material/Search";
import InsightsIcon from "@mui/icons-material/Insights";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SchoolIcon from "@mui/icons-material/School";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import MailIcon from "@mui/icons-material/Mail";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import InfoIcon from '@mui/icons-material/Info';
import ContactPage from "layouts/pages/contact";
import EarningsPage from "layouts/applications/earnings-call";
import NftMarketplace from "layouts/pages/terms/nft";
import Financial from "layouts/pages/terms/financial";
import Learn from "layouts/pages/learn";
import Heatmap from "layouts/applications/Heatmap";

const routes = [
	{
		type: "collapse",
		name: "Dashboard",
		key: "dashboard",
		icon: <ShopIcon size="12px" />,
		route: "/dashboard",
		component: <BBV />,
		noCollapse: true,
	},
	{ type: "title", title: "Pages", key: "title-pages" },
	{
		type: "collapse",
		name: "Research",
		key: "research",
		icon: <SearchIcon size="12px" />,
		collapse: [
			{
				name: "Dividend Dates",
				key: "dividend-dates",
				route: "/dashboard/research/dividend-dates",
				component: <DividendTable />,
			},
			{
				name: "Earnings Call",
				key: "earnings-call",
				route: "/dashboard/research/earnings-call",
				component: <EarningsPage />,
			},
			{
				name: "Heatmap",
				key: "heatmaps",
				route: "/dashboard/research/heatmaps",
				component: <Heatmap />,
			},
		],
	},
	{
		type: "collapse",
		name: "GEM Ranking",
		key: "GEM",
		icon: <InsightsIcon size="12px" />,
		collapse: [
			{
				name: "Stock Ranking",
				key: "stocks-ranking",
				route: "/dashboard/GEM/stocks-ranking",
				component: <StocksDataTables />,
			},
			{
				name: "Option Ranking",
				key: "options-ranking",
				route: "/dashboard/GEM/options-ranking",
				component: <OptionsDataTables />,
			},
		],
	},
	{
		type: "collapse",
		name: "Portfolio",
		key: "personal",
		icon: <DonutLargeIcon size="12px" />,
		route: "/dashboard/portfolio/personal",
		component: <PortfolioPage />,
		noCollapse: true,
		protected: true,
		roles: ["*"],

	},
	// {
	// 	type: "collapse",
	// 	name: "Dashboards",
	// 	key: "Dashboards",
	// 	icon: <Office size="12px" />,
	// 	collapse: [
	// 		{
	// 			name: "Automotive",
	// 			key: "Automotive",
	// 			route: "/Automotive",
	// 			component: <Automotive />,
	// 			noCollapse: true,
	// 		},
	// 		{
	// 			name: "SmartHome",
	// 			key: "SmartHome",
	// 			route: "/SmartHome",
	// 			component: <SmartHome />,
	// 			noCollapse: true,
	// 		},
	// 		{
	// 			name: "CRM",
	// 			key: "CRM",
	// 			route: "/CRM",
	// 			component: <CRM />,
	// 			noCollapse: true,
	// 		},
	// 		{
	// 			name: "Default",
	// 			key: "Default",
	// 			route: "/Default",
	// 			component: <Default />,
	// 			noCollapse: true,
	// 		},
	// 	],
	// },
	// {
	// 	type: "collapse",
	// 	name: "Marketplace",
	// 	key: "marketplace",
	// 	icon: <AccountBalanceWalletIcon size="12px" />,
	// 	route: "/dashboard/marketplace",
	// 	component: <MarketplaceCollections />,
	// 	// collapse: [
	// 	// 	// {
	// 	// 	// 	name: "Overview",
	// 	// 	// 	key: "overview",
	// 	// 	// 	route: "/ecommerce/overview",
	// 	// 	// 	component: <Overview />,
	// 	// 	// },
	// 	// 	{
	// 	// 		name: "Products",
	// 	// 		key: "products",
	// 	// 		collapse: [
	// 	// 			// {
	// 	// 			// 	name: "New Product",
	// 	// 			// 	key: "new-product",
	// 	// 			// 	route: "/ecommerce/products/new-product",
	// 	// 			// 	component: <NewProduct />,
	// 	// 			// },
	// 	// 			// {
	// 	// 			// 	name: "Edit Product",
	// 	// 			// 	key: "edit-product",
	// 	// 			// 	route: "/ecommerce/products/edit-product",
	// 	// 			// 	component: <EditProduct />,
	// 	// 			// },
	// 	// 			{
	// 	// 				name: "Product Page",
	// 	// 				key: "product-page",
	// 	// 				route: "/marketplace/products/product-page",
	// 	// 				component: <ProductPage />,
	// 	// 			},
	// 	// 			// {
	// 	// 			// 	name: "Products List",
	// 	// 			// 	key: "products-list",
	// 	// 			// 	route: "/ecommerce/products/products-list",
	// 	// 			// 	component: <ProductsList />,
	// 	// 			// },
	// 	// 		],
	// 	// 	},
	// 	// 	{
	// 	// 		name: "Orders",
	// 	// 		key: "orders",
	// 	// 		collapse: [
	// 	// 			{
	// 	// 				name: "Order List",
	// 	// 				key: "order-list",
	// 	// 				route: "/marketplace/orders/order-list",
	// 	// 				component: <OrderList />,
	// 	// 			},
	// 	// 			{
	// 	// 				name: "Order Details",
	// 	// 				key: "order-details",
	// 	// 				route: "/marketplace/orders/order-details",
	// 	// 				component: <OrderDetails />,
	// 	// 			},
	// 	// 		],
	// 	// 	},
	// 	// 	{
	// 	// 		name: "Loyalty Rewards",
	// 	// 		key: "rewards",
	// 	// 		route: "/marketplace/rewards",
	// 	// 		component: <Referral />,
	// 	// 	},
	// 	// ],
	// 	noCollapse: true,
	// },
	{
		type: "collapse",
		name: "Membership",
		key: "membership",
		icon: <VpnKeyIcon size="12px" />,
		route: "/dashboard/membership",
		component: <PricingPage />,
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Account",
		key: "account",
		icon: <ManageAccountsIcon size="12px" />,
		route: "/dashboard/pages/account/settings",
		component: <Settings />,
		protected: true,
		roles: ["*"],
		// collapse: [
		// 	{
		// 		name: "Overview",
		// 		key: "overview",
		// 		route: "/pages/account/overview",
		// 		component: <ProfileOverview />,
		// 		protected: true,
		// 		roles: ["*"],
		// 	},
		// 	{
		// 		name: "Settings",
		// 		key: "settings",
		// 		route: "/pages/account/settings",
		// 		component: <Settings />,
		// 		protected: true,
		// 		roles: ["*"],
		// 	},
		// 	{
		// 		name: "Billing",
		// 		key: "billing",
		// 		route: "/pages/account/billing",
		// 		component: <Billing />,
		// 		protected: true,
		// 		roles: ["*"],
		// 	},
		// 	{
		// 		name: "Invoice",
		// 		key: "invoice",
		// 		route: "/pages/account/invoice",
		// 		component: <Invoice />,
		// 		protected: true,
		// 		roles: ["*"],
		// 	},
		// 	// {
		// 	// 	name: "Security",
		// 	// 	key: "security",
		// 	// 	route: "/pages/account/security",
		// 	// 	component: <Security />,
		// 	// },
		// ],
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Learn",
		key: "learn",
		icon: <SchoolIcon size="12px" />,
		route: "/dashboard/learn",
		component: <Learn />,
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Referral",
		key: "referral",
		icon: <VolunteerActivismIcon size="12px" />,
		route: "/dashboard/referral",
		component: <Referral />,
		noCollapse: true,
	},
	// {
	// 	type: "collapse",
	// 	name: "Partners",
	// 	key: "partners",
	// 	icon: <RocketLaunchIcon size="12px" />,
	// 	route: "/dashboard/partners",
	// 	component: <ComingSoon />,
	// 	noCollapse: true,
	// },
	// {
	// 	type: "collapse",
	// 	name: "Marketplace",
	// 	key: "marketplace",
	// 	icon: <RocketLaunchIcon size="12px" />,
	// 	route: "/dashboard/marketplace",
	// 	component: <MarketplaceCollections />,
	// 	noCollapse: true,
	// },
	{
		type: "collapse",
		name: "Contact Us",
		key: "contact-us",
		icon: <MailIcon size="12px" />,
		route: "/dashboard/contact-us",
		component: <ContactPage />,
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Terms & Conditions",
		key: "terms-conditions",
		icon: <InfoIcon size="12px" />,
		collapse: [
			{
				name: "Terms of Service",
				key: "terms",
				route: "/dashboard/terms-and-conditions/terms-of-service",
				component: <TermsServices />
			},
			{
				name: "Privacy Policy",
				key: "privacy",
				route: "/dashboard/terms-and-conditions/privacy-policy",
				component: <PrivacyPolicy />
			}, {
				name: "Legal Disclaimer - Not Financial Advice",
				key: "financial",
				route: "/dashboard/terms-and-conditions/financial-policy",
				component: <Financial />
			},
			{
				name: "NFT Marketplace Terms and Conditions",
				key: "nft",
				route: "/dashboard/terms-and-conditions/nft-marketplace",
				component: <NftMarketplace />
			}
		]
	},
	// {
	// 	type: "collapse",
	// 	name: "Pages",
	// 	key: "pages",
	// 	icon: <Office size="12px" />,
	// 	collapse: [
	// 		{
	// 			name: "Profile",
	// 			key: "profile",
	// 			collapse: [
	// 				{
	// 					name: "Profile Overview",
	// 					key: "profile-overview",
	// 					route: "/pages/profile/profile-overview",
	// 					component: <ProfileOverview />,
	// 				},
	// 				{
	// 					name: "Teams",
	// 					key: "teams",
	// 					route: "/pages/profile/teams",
	// 					component: <Teams />,
	// 				},
	// 				{
	// 					name: "All Projects",
	// 					key: "all-projects",
	// 					route: "/pages/profile/all-projects",
	// 					component: <AllProjects />,
	// 				},
	// 			],
	// 		},
	// 		{
	// 			name: "Users",
	// 			key: "users",
	// 			collapse: [
	// 				{
	// 					name: "Reports",
	// 					key: "reports",
	// 					route: "/pages/users/reports",
	// 					component: <Reports />,
	// 				},
	// 				{
	// 					name: "New User",
	// 					key: "new-user",
	// 					route: "/pages/users/new-user",
	// 					component: <NewUser />,
	// 				},
	// 			],
	// 		},
	// 		{
	// 			name: "Projects",
	// 			key: "projects",
	// 			collapse: [
	// 				{
	// 					name: "General",
	// 					key: "general",
	// 					route: "/pages/projects/general",
	// 					component: <General />,
	// 				},
	// 				{
	// 					name: "Timeline",
	// 					key: "timeline",
	// 					route: "/pages/projects/timeline",
	// 					component: <Timeline />,
	// 				},
	// 				{
	// 					name: "New Project",
	// 					key: "new-project",
	// 					route: "/pages/projects/new-project",
	// 					component: <NewProject />,
	// 				},
	// 			],
	// 		},
	// 		{ name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
	// 		{ name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
	// 		{ name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
	// 		{
	// 			name: "Sweet Alerts",
	// 			key: "sweet-alerts",
	// 			route: "/pages/sweet-alerts",
	// 			component: <SweetAlerts />,
	// 		},
	// 		{
	// 			name: "Notfications",
	// 			key: "notifications",
	// 			route: "/pages/notifications",
	// 			component: <Notifications />,
	// 		},
	// 	],
	// },
	// {
	// 	type: "collapse",
	// 	name: "Applications",
	// 	key: "applications",
	// 	icon: <SettingsIcon size="12px" />,
	// 	collapse: [
	// 		{
	// 			name: "Kanban",
	// 			key: "kanban",
	// 			route: "/applications/kanban",
	// 			component: <Kanban />,
	// 		},
	// 		{
	// 			name: "Wizard",
	// 			key: "wizard",
	// 			route: "/applications/wizard",
	// 			component: <Wizard />,
	// 		},
	// 		{
	// 			name: "Data Tables",
	// 			key: "data-tables",
	// 			route: "/applications/data-tables",
	// 			component: <DataTables />,
	// 		},
	// 		{
	// 			name: "Calendar",
	// 			key: "calendar",
	// 			route: "/applications/calendar",
	// 			component: <Calendar />,
	// 		},
	// 		{
	// 			name: "Analytics",
	// 			key: "analytics",
	// 			route: "/applications/analytics",
	// 			component: <Analytics />,
	// 		},
	// 	],
	// },
	{
		type: "collapse",
		name: "Authentication",
		key: "authentication",
		icon: <Document size="12px" />,
		collapse: [
			{
				name: "Sign In",
				key: "sign-in",
				route: "/dashboard/authentication/sign-in",
				component: <SignInBasic />,
			},
			{
				name: "Sign Up",
				key: "sign-up",
				route: "/dashboard/authentication/sign-up",
				component: <SignUpBasic />,
			},
			{
				name: "Reset Password",
				key: "reset-password",
				collapse: [
					{
						name: "Basic",
						key: "basic",
						route: "/dashboard/authentication/reset-password/basic",
						component: <ResetBasic />,
					},
					{
						name: "Cover",
						key: "cover",
						route: "/dashboard/authentication/reset-password/cover",
						component: <ResetCover />,
					},
					{
						name: "Illustration",
						key: "illustration",
						route: "/dashboard/authentication/reset-password/illustration",
						component: <ResetIllustration />,
					},
				],
			},
			{
				name: "Lock",
				key: "lock",
				collapse: [
					{
						name: "Basic",
						key: "basic",
						route: "/dashboard/authentication/lock/basic",
						component: <LockBasic />,
					},
					{
						name: "Cover",
						key: "cover",
						route: "/dashboard/authentication/lock/cover",
						component: <LockCover />,
					},
					{
						name: "Illustration",
						key: "illustration",
						route: "/dashboard/authentication/lock/illustration",
						component: <LockIllustration />,
					},
				],
			},
			{
				name: "2-Step Verification",
				key: "2-step-verification",
				collapse: [
					{
						name: "Basic",
						key: "basic",
						route: "/dashboard/authentication/verification/basic",
						component: <VerificationBasic />,
					},
					{
						name: "Cover",
						key: "cover",
						route: "/dashboard/authentication/verification/cover",
						component: <VerificationCover />,
					},
					{
						name: "Illustration",
						key: "illustration",
						route: "/dashboard/authentication/verification/illustration",
						component: <VerificationIllustration />,
					},
				],
			},
			{
				name: "Error",
				key: "error",
				collapse: [
					{
						name: "Error 404",
						key: "error-404",
						route: "/dashboard/error/404",
						component: <Error404 />,
					},
					{
						name: "Error 500",
						key: "error-500",
						route: "/dashboard/error/500",
						component: <Error500 />,
					},
				],
			},
		],
		hideInNavbar: true
	},
	// { type: "divider", key: "divider-1" },
	// { type: "title", title: "Docs", key: "title-docs" },
	// {
	// 	type: "collapse",
	// 	name: "Basic",
	// 	key: "basic",
	// 	icon: <SpaceShip size="12px" />,
	// 	collapse: [
	// 		{
	// 			name: "Getting Started",
	// 			key: "getting-started",
	// 			collapse: [
	// 				{
	// 					name: "Overview",
	// 					key: "overview",
	// 					href: "https://www.creative-tim.com/learning-lab/react/overview/soft-ui-dashboard/",
	// 				},
	// 				{
	// 					name: "License",
	// 					key: "license",
	// 					href: "https://www.creative-tim.com/learning-lab/react/license/soft-ui-dashboard/",
	// 				},
	// 				{
	// 					name: "Quick Start",
	// 					key: "quick-start",
	// 					href: "https://www.creative-tim.com/learning-lab/react/quick-start/soft-ui-dashboard/",
	// 				},
	// 				{
	// 					name: "Build Tools",
	// 					key: "build-tools",
	// 					href: "https://www.creative-tim.com/learning-lab/react/build-tools/soft-ui-dashboard/",
	// 				},
	// 			],
	// 		},
	// 		{
	// 			name: "Foundation",
	// 			key: "foundation",
	// 			collapse: [
	// 				{
	// 					name: "Colors",
	// 					key: "colors",
	// 					href: "https://www.creative-tim.com/learning-lab/react/colors/soft-ui-dashboard/",
	// 				},
	// 				{
	// 					name: "Grid",
	// 					key: "grid",
	// 					href: "https://www.creative-tim.com/learning-lab/react/grid/soft-ui-dashboard/",
	// 				},
	// 				{
	// 					name: "Typography",
	// 					key: "base-typography",
	// 					href: "https://www.creative-tim.com/learning-lab/react/base-typography/soft-ui-dashboard/",
	// 				},
	// 				{
	// 					name: "Borders",
	// 					key: "borders",
	// 					href: "https://www.creative-tim.com/learning-lab/react/borders/soft-ui-dashboard/",
	// 				},
	// 				{
	// 					name: "Box Shadows",
	// 					key: "box-shadows",
	// 					href: "https://www.creative-tim.com/learning-lab/react/box-shadows/soft-ui-dashboard/",
	// 				},
	// 				{
	// 					name: "Functions",
	// 					key: "functions",
	// 					href: "https://www.creative-tim.com/learning-lab/react/functions/soft-ui-dashboard/",
	// 				},
	// 				{
	// 					name: "Routing System",
	// 					key: "routing-system",
	// 					href: "https://www.creative-tim.com/learning-lab/react/routing-system/soft-ui-dashboard/",
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	// {
	// 	type: "collapse",
	// 	name: "Components",
	// 	key: "components",
	// 	icon: <CustomerSupport size="12px" />,
	// 	collapse: [
	// 		{
	// 			name: "Alerts",
	// 			key: "alerts",
	// 			href: "https://www.creative-tim.com/learning-lab/react/alerts/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Avatar",
	// 			key: "avatar",
	// 			href: "https://www.creative-tim.com/learning-lab/react/avatar/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Badge",
	// 			key: "badge",
	// 			href: "https://www.creative-tim.com/learning-lab/react/badge/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Badge Dot",
	// 			key: "badge-dot",
	// 			href: "https://www.creative-tim.com/learning-lab/react/badge-dot/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Box",
	// 			key: "box",
	// 			href: "https://www.creative-tim.com/learning-lab/react/box/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Buttons",
	// 			key: "buttons",
	// 			href: "https://www.creative-tim.com/learning-lab/react/buttons/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Date Picker",
	// 			key: "date-picker",
	// 			href: "https://www.creative-tim.com/learning-lab/react/datepicker/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Dropzone",
	// 			key: "dropzone",
	// 			href: "https://www.creative-tim.com/learning-lab/react/dropzone/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Editor",
	// 			key: "editor",
	// 			href: "https://www.creative-tim.com/learning-lab/react/quill/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Input",
	// 			key: "input",
	// 			href: "https://www.creative-tim.com/learning-lab/react/input/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Pagination",
	// 			key: "pagination",
	// 			href: "https://www.creative-tim.com/learning-lab/react/pagination/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Progress",
	// 			key: "progress",
	// 			href: "https://www.creative-tim.com/learning-lab/react/progress/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Select",
	// 			key: "select",
	// 			href: "https://www.creative-tim.com/learning-lab/react/select/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Snackbar",
	// 			key: "snackbar",
	// 			href: "https://www.creative-tim.com/learning-lab/react/snackbar/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Social Button",
	// 			key: "social-button",
	// 			href: "https://www.creative-tim.com/learning-lab/react/social-buttons/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Tag Input",
	// 			key: "tag-input",
	// 			href: "https://www.creative-tim.com/learning-lab/react/tag-input/soft-ui-dashboard/",
	// 		},
	// 		{
	// 			name: "Typography",
	// 			key: "typography",
	// 			href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
	// 		},
	// 	],
	// },
	// {
	// 	type: "collapse",
	// 	name: "Change Log",
	// 	key: "changelog",
	// 	href: "https://github.com/creativetimofficial/ct-soft-ui-dashboard-pro-react/blob/main/CHANGELOG.md",
	// 	icon: <CreditCard size="12px" />,
	// 	noCollapse: true,
	// },
	{
		type: "internal",
		name: "Process Payment",
		key: "process-payment",
		route: "/dashboard/payment/process/:invoiceNo",
		component: <PaymentProcessPage />,
	},
	{
		type: "internal",
		name: "Stock Analysis",
		key: "stock-analysis",
		route: "/dashboard/GEM/stocks-ranking/:stockCode",
		component: <StockDetails />,
	},
	{
		type: "internal",
		name: "Stock Analysis",
		key: "stock-analysis",
		route: "/dashboard/GEM/options-ranking/:optionType/:stockCode",
		component: <OptionsFullscreen />,
	},
	{
		type: "internal",
		name: "Collection Details",
		key: "collection-details",
		route: "/dashboard/marketplace/collection/:collectionID/:collectionName",
		component: <MarketplaceCollectionDetails />,
	},
	{
		type: "internal",
		name: "Collection Token Details",
		key: "collection-token-details",
		route: "/dashboard/marketplace/collection/:collectionID/:tokenID/:collectionName",
		component: <MarketplaceCollectionToken />,
	},
	{
		type: "internal",
		name: "Collection Token Details",
		key: "collection-token-details",
		route: "/dashboard/marketplace/connect",
		component: <MarketplaceConnectPage />,
	},
	{
		type: "internal",
		name: "Admin Dashboard",
		key: "admin-dashboard",
		route: "/admin",
		component: <BBVAdmin />,
		protected: true,
		roles: ["admin"],
	},
];

export const admin_routes = [
	{
		type: "collapse",
		name: "Dashboard",
		key: "dashboard",
		icon: <OfficeIcon size="12px" />,
		route: "/admin",
		component: <BBVAdmin />,
		noCollapse: true,
	},
	{ type: "title", title: "Pages", key: "title-pages" },
	{
		type: "collapse",
		name: "Users",
		key: "users",
		icon: <UsersIcon size="12px" />,
		route: "/admin/users",
		component: <AdminUsersList />,
		noCollapse: true,
	},
	{
		type: "internal",
		name: "Users",
		key: "users",
		route: "/admin/users/:userID/update-plan",
		component: <AdminUserPlan />,
	},
	{
		type: "collapse",
		name: "Marketplace",
		key: "marketplace",
		icon: <ShopIcon size="12px" />,
		collapse: [
			{
				name: "Categories",
				key: "categories",
				route: "/admin/marketplace/categories",
				component: <AdminCategoriesList />,
			},
			{
				name: "Collections",
				key: "collections",
				route: "/admin/marketplace/collections",
				component: <AdminCollectionsList />,
			},
			{
				name: "Collection Tokens",
				key: "collection-tokens",
				route: "/admin/marketplace/collections/:collectionID/tokens",
				component: <AdminCollectionToken />,
				hideInNavbar: true
			},
			{
				name: "Add Collection Tokens",
				key: "add-collection-tokens",
				route: "/admin/marketplace/collections/:collectionID/tokens/mint",
				component: <AdminMintCollectionToken />,
				hideInNavbar: true
			},
			// {
			// 	name: "Listings",
			// 	key: "listings",
			// 	route: "/admin/marketplace/listings",
			// 	component: <DividendTable />,
			// },
		],
	},
	{
		type: "collapse",
		name: "Orders",
		key: "orders",
		icon: <BasketIcon size="12px" />,
		route: "/admin/orders",
		component: <ComingSoon />,
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Transactions",
		key: "transactions",
		icon: <CreditCardIcon size="12px" />,
		route: "/admin/transactions",
		component: <ComingSoon />,
		noCollapse: true,
	},
	{
		type: "collapse",
		name: "Settings",
		key: "settings",
		icon: <SettingsIcon size="12px" />,
		route: "/admin/settings",
		component: <AdminSettings />,
		noCollapse: true,
	},
	{
		type: "internal",
		name: "Add Collection",
		key: "add-collection",
		route: "/admin/marketplace/collections/add-new",
		component: <AdminCollectionForm />,
	},
];

export default routes;
