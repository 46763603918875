import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import BackToButton from "components/CustomComponents";
import TradingViewWidget from "./widgets/TradingViewWidget";
import ForexViewWidget from "./widgets/ForexViewWidget";
import CryptoWidget from "./widgets/CryptoWidget";

export default () => {
	return (
		<DashboardLayout>
			<DashboardNavbar pageTitle="Heatmaps" />
			<BackToButton text="Back to Research" destination="/dashboard/GEM/stocks-ranking"></BackToButton>
			<h3 className="roboto" style={{ marginBottom: "18px", textAlign: "center" }}>
				Stock Heatmap
			</h3>
			<Grid
				className="news_sentiment_grid"
				justifyContent="space-between"
				style={{ textAlign: "center", marginTop: "20px" }}
				container
			>
				<Grid style={{ backgroundColor: "white", borderRadius: "15px", width: "100%", padding: "20px" }} item>
					<TradingViewWidget />
				</Grid>
			</Grid>
			<h3 className="roboto" style={{ margin: "36px 0 18px", textAlign: "center" }}>
				Forex Heatmap
			</h3>
			<Grid
				className="news_sentiment_grid"
				justifyContent="space-between"
				style={{ textAlign: "center", marginTop: "20px" }}
				container
			>
				<Grid style={{ backgroundColor: "white", borderRadius: "15px", width: "100%", padding: "20px" }} item>
					<ForexViewWidget />
				</Grid>
			</Grid>
			<h3 className="roboto" style={{ margin: "36px 0 18px", textAlign: "center" }}>
				Crypto Heatmap
			</h3>
			<Grid
				className="news_sentiment_grid"
				justifyContent="space-between"
				style={{ textAlign: "center", marginTop: "20px" }}
				container
			>
				<Grid style={{ backgroundColor: "white", borderRadius: "15px", width: "100%", padding: "20px" }} item>
					<CryptoWidget />
				</Grid>
			</Grid>
		</DashboardLayout>
	);
};
