import moment from "moment";

export function roundToTwo(num) {
    try {
        return parseFloat(num).toFixed(2);
    } catch {
        return 0;
    }
    
}

export const validateValue = value => {
    if (value === null) {
        return "N.A.";
    }
    return value;
};

export const formatDate = date => {
    const parsed = Date.parse(date);
    if (parsed) {
        return new Intl.DateTimeFormat('en-SG').format(parsed) 
    } else
        return date
}

export const getMonthDateFromDate = date => {
    const parsed = new Date(date)
    if (date && parsed) {
        return parsed.getDate()
    } else
        return null
}

export const setMonthDateInDate = (date, day) => {
    const parsed = new Date(date)
    if (date && parsed) {
        const newDate = parsed.setDate(day)
        console.log(newDate)
        return moment(newDate).format("YYYY-MM-DD")
    } else
        return date
}

export const getMonthFromDate = date => {
    const parsed = new Date(date)
    if (date && parsed) {
        return parsed.getMonth()
    } else
        return null
}

export const setMonthInDate = (date, month) => {
    const parsed = new Date(date)
    if (date && parsed) {
        const newDate = parsed.setMonth(month - 1)
        console.log(newDate)
        return moment(newDate).format("YYYY-MM-DD")
    } else
        return date
}

export const getYearFromDate = date => {
    const parsed = new Date(date)
    if (date && parsed) {
        return parsed.getFullYear()
    } else
        return null
}

export const setYearInDate = (date, year) => {
    const parsed = new Date(date)
    if (date && parsed) {
        const newDate = parsed.setFullYear(year)
        console.log(newDate)
        return moment(newDate).format("YYYY-MM-DD")
    } else
        return date
}