import React, { useEffect, useRef, memo } from "react";

function EconomicCalendarWidget() {
	const container = useRef();

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
		script.type = "text/javascript";
		script.async = true;
		script.innerHTML = `
        {
            "width": "100%",
            "height": "90%",
            "colorTheme": "light",
            "isTransparent": false,
            "locale": "en",
            "importanceFilter": "-1,0,1",
            "countryFilter": "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu"
        }`;
		container.current.appendChild(script);
	}, []);

	return (
		<div className="tradingview-widget-container" ref={container}>
			<div className="tradingview-widget-container__widget"></div>
		</div>
	);
}

export default memo(EconomicCalendarWidget);