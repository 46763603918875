import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Popup from "layouts/applications/popup/Popup";
import { Card } from "@mui/material";

import { Formik, Form } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";

import initialValues from "./schemas/initialValues";
import form from "./schemas/form";
import validationSchema from "./schemas/validations";
import { creteCategory, updateCategory, deleteCategory, fetchCategories } from "services/marketplace";
import { showConfirmationAlert } from "layouts/Helper/swalAlerts";

export default () => {
	const [showCategoryForm, setShowCategoryForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const { formId, formField } = form;

	useEffect(() => {
		loadData();
	}, []);

	const handleAddNewButton = () => {
		setShowCategoryForm(true);
	};

	const handleEdit = values => {
		setShowCategoryForm(true);
		setEditMode(values.id);

		initialValues.name = values.name;
	};

	const handleDelete = category => {
		// show confirm message
		showConfirmationAlert({
			title: "Are you sure you want to delete?",
			text: "You won't be able to revert this!",
			onConfirm: () => deleteCategory(category.id).then(loadData),
		});
	};

	const handleClosePopup = () => {
		setShowCategoryForm(false);

		initialValues.name = "";
		setEditMode(false);
	};

	const handleSubmit = (values, actions) => {
		if (editMode) updateCategory(editMode, values).then(() => loadData());
		else creteCategory(values).then(() => loadData());

		actions.setSubmitting(false);
		actions.resetForm();

		setShowCategoryForm(false);
	};

	const loadData = () => {
		setIsLoading(true);
		fetchCategories()
			.then(data => setRows(data))
			.finally(() => setIsLoading(false));
	};

	const columns = [
		{ Header: "Name", accessor: "name" },
		{
			Header: "Actions",
			accessor: "action_buttons",
			align: "right",
			buttons: [
				{ text: "Edit", action: handleEdit, color: "info" },
				{ text: "Delete", action: handleDelete, color: "error" },
			],
		},
	];

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox pb={3}>
				<SoftBox mb={3} style={{ textAlign: "right" }}>
					<SoftButton color="success" onClick={handleAddNewButton}>
						Add New Category
					</SoftButton>
				</SoftBox>
				<SoftBox mb={3}>
					<DataTable table={{ columns, rows }} entriesPerPage={null} loading={isLoading} />
				</SoftBox>
			</SoftBox>
			<Footer />
			{showCategoryForm && (
				<Popup transparent={true}>
					<CloseIcon
						className="close-btn icon"
						onClick={() => {
							handleClosePopup();
						}}
					/>
					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
						{({ values, errors, touched, isSubmitting }) => (
							<Form id={formId} autoComplete="off" style={{ width: "360px", padding: "0 20px" }}>
								<Card sx={{ height: "100%" }}>
									<SoftBox p={3} pb={0}>
										<SoftTypography variant="h5" fontWeight="medium">
											{editMode ? "Edit" : "Add new"} Category
										</SoftTypography>
									</SoftBox>
									<SoftBox p={2}>
										<SoftBox>
											<FormField
												type={formField.name.type}
												label={formField.name.label}
												name={formField.name.name}
												value={values.name}
												placeholder={formField.name.placeholder}
												error={errors.name && touched.name}
												success={values.name.length > 0 && !errors.name}
											/>
										</SoftBox>
										<SoftButton
											disabled={isSubmitting}
											type="submit"
											variant="gradient"
											color="dark"
											fullWidth
										>
											Save
										</SoftButton>
									</SoftBox>
								</Card>
							</Form>
						)}
					</Formik>
				</Popup>
			)}
		</DashboardLayout>
	);
};
