import { useEffect, useState } from "react";
import { getSetting } from "services/setting";

export default () => {
	const [stockRankingText, setStockRankingText] = useState(null);

	useEffect(() => {
		getSetting("stock_ranking_text").then(data => {
			if (data) {
				setStockRankingText({ title: data.title ?? "", body: data.body ?? "" });
			}
		});
	}, []);

	return stockRankingText?.title ? (
		<div className="bbvGold">
			<div className="innerCard">
				<h5>{stockRankingText.title}</h5>
				<p style={{ whiteSpace: "pre-wrap" }}>{stockRankingText.body}</p>
			</div>
		</div>
	) : null;
};
