import axios from "layouts/authentication/api/axios";

export const updateSetting = async (key, value) => {
	try {
		if (typeof value !== "string") value = JSON.stringify(value);

		const res = await axios().post(`/admin/settings`, {
			key,
			value,
		});
		return res.data;
	} catch (error) {
		console.log(error);
	}

	return null;
};

export const getSetting = async (key, defaultValue) => {
    const res = await axios().get(`/admin/settings/${key}`);

    if (res.data[key]) {
        const maybeJson = JSON.parse(res.data[key]);
        if (maybeJson) return maybeJson;

        return res.data[key];
    } else if (defaultValue)
        return defaultValue;

    return "";
}