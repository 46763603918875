/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo, useEffect } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

// The Soft UI Dashboard PRO React main context
const SoftUI = createContext(null);

// Setting custom name for the context which is visible on react dev tools
SoftUI.displayName = "SoftUIContext";

// Soft UI Dashboard PRO React reducer
function reducer(state, action) {
	switch (action.type) {
		case "MINI_SIDENAV": {
			return { ...state, miniSidenav: action.value };
		}
		case "TRANSPARENT_SIDENAV": {
			return { ...state, transparentSidenav: action.value };
		}
		case "SIDENAV_COLOR": {
			return { ...state, sidenavColor: action.value };
		}
		case "TRANSPARENT_NAVBAR": {
			return { ...state, transparentNavbar: action.value };
		}
		case "FIXED_NAVBAR": {
			return { ...state, fixedNavbar: action.value };
		}
		case "OPEN_CONFIGURATOR": {
			return { ...state, openConfigurator: action.value };
		}
		case "SHOW_INVALID_ACCESS": {
			return { ...state, showInvalidAccess: action.value };
		}
		case "DIRECTION": {
			return { ...state, direction: action.value };
		}
		case "LAYOUT": {
			return { ...state, layout: action.value };
		}
		case "PAYMENT_POPUP": {
			let forStocks = action.value.forStocks;
			return { ...state, paymentPopup: { forStocks: forStocks, on: action.value.on,isPaymentPopup: action.value.isPaymentPopup  } };
		}
		case "SHOW_ERROR_MESSAGE": {
			return { ...state, gotError: true && action.value, errorMessage: action.value, messageType: action.messageType };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

// Soft UI Dashboard PRO React context provider
function SoftUIControllerProvider({ children }) {
	const { pathname } = useLocation();

	const initialState = {
		miniSidenav: false,
		transparentSidenav: true,
		sidenavColor: "error",
		transparentNavbar: true,
		fixedNavbar: true,
		openConfigurator: false,
		showInvalidAccess: false,
		gotError: false,
		errorMessage: null,
		messageType: "error",
		direction: "ltr",
		layout: "dashboard",
		paymentPopup: { forStocks: true, on: false,isPaymentPopup:false },
	};

	const [controller, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		// close plan popup if opened another page
		const { paymentPopup } = controller;
		if (paymentPopup?.on) setPaymentPopup(dispatch, { ...paymentPopup, on: false });
	}, [pathname]);

	const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

	return <SoftUI.Provider value={value}>{children}</SoftUI.Provider>;
}

// Soft UI Dashboard PRO React custom hook for using context
function useSoftUIController() {
	const context = useContext(SoftUI);

	if (!context) {
		throw new Error("useSoftUIController should be used inside the SoftUIControllerProvider.");
	}

	return context;
}

// Typechecking props for the SoftUIControllerProvider
SoftUIControllerProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setInvalidAccess = (dispatch, value) => dispatch({ type: "SHOW_INVALID_ACCESS", value });
const setErrorMessage = (dispatch, value, messageType="error") => dispatch({ type: "SHOW_ERROR_MESSAGE", value, messageType });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setPaymentPopup = (dispatch, value) => dispatch({ type: "PAYMENT_POPUP", value });

export {
	SoftUIControllerProvider,
	useSoftUIController,
	setMiniSidenav,
	setTransparentSidenav,
	setSidenavColor,
	setTransparentNavbar,
	setFixedNavbar,
	setOpenConfigurator,
	setInvalidAccess,
	setErrorMessage,
	setDirection,
	setLayout,
	setPaymentPopup,
};
