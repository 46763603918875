import { Button } from "@mui/material";
import React from "react";

const Popup = ({ transparent, style, ...props }) => {

	console.log(transparent)
	return (
		<div className={`popup `} style={style}>
			<div className={props.optionsPopup ?transparent?"transparent popup-inner optionsPopup":"popup-inner optionsPopup" :transparent?"transparent popup-inner": "popup-inner"}>
				{/* <Button variant='outlined' onClick={()=>{
                        props.trigger(false)
                        props.modal(false)}
                    } 
                    className='close-btn'>Close</Button> */}
				{props.children}
			</div>
		</div>
	);
};

export default Popup;
