import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, FormControl, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { useWeb3 } from "context/web3";
import { get as getValue } from "lodash";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import SoftInput from "components/SoftInput";
import MultilineField from "components/etc/MultilineField";

const TokenForm = ({ collection, onCreate, ...props }) => {
	const { web3Controller } = useWeb3();
	const { connected: connectedToBlockchain, ...blockchainInfo } = web3Controller;

	const defaultValues = collection.tokens.length > 0 ? collection.tokens.pop() : null;

	const properties = useRef([]);
	const thumbnail_path = useRef(null);

	const [isLoading, setIsLoading] = useState(false);

	const setProperties = newValues => {
		properties.current = newValues;
	};

	return (
		<Card>
			<SoftBox p={2}>
				<SoftBox px={3}>
					<SoftTypography mb={2} variant="h5">
						Add {collection.symbol} Token
					</SoftTypography>
				</SoftBox>
				<Grid container justifyContent="flex-end" flexDirection={{ md: "row-reverse" }}>
					<Grid item xs={12} md={4}>
						<SoftBox px={3}>
							<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
								<SoftTypography variant="body2">Thumbnail</SoftTypography>
							</SoftBox>
							<SoftDropzone
								submissionURL={`${process.env.REACT_APP_API_BASE}/admin/uploads/marketplace/collections/thumbnail`}
								onUpload={(file, response) => {
									if (response.filepath) thumbnail_path.current = response.filepath;
								}}
								onRemove={() => {
									thumbnail_path.current = null;
								}}
								options={{
									addRemoveLinks: true,
									uploadMultiple: false,
									maxFiles: 1,
									acceptedFiles: "image/*",
								}}
							/>
						</SoftBox>
					</Grid>
					<Grid item xs={12} md={8}>
						<SoftBox px={3}>
							<Formik
								initialValues={{
									token_name: getValue(defaultValues, "token_name", ""),
									description: getValue(defaultValues, "description", ""),
									external_link: "",
								}}
								validationSchema={Yup.object().shape({
									token_name: Yup.string().max(100).required("Name is required"),
									description: Yup.string().required("Description is required"),
								})}
								onSubmit={(values, actions) => {
									actions.setSubmitting(false);

									if (!thumbnail_path.current) return;

									onCreate &&
										onCreate({
											name: values.token_name,
											description: values.description,
											external_link: values.external_link,
											thumbnail: thumbnail_path.current,
											properties: properties.current,
											owner: blockchainInfo.wallet_address,
										});
								}}
							>
								{({
									errors,
									handleBlur,
									handleChange,
									handleSubmit,
									isSubmitting,
									touched,
									values,
									setFieldValue,
								}) => (
									<form onSubmit={handleSubmit}>
										<FormControl margin="normal" fullWidth>
											<SoftTypography variant="body2">Token Name</SoftTypography>
											<SoftInput
												error={Boolean(touched.token_name && errors.token_name)}
												fullWidth
												placeholder="Token Name"
												name="token_name"
												onBlur={handleBlur}
												onChange={handleChange}
												type="text"
												value={values.token_name}
											/>
										</FormControl>
										<FormControl margin="normal" fullWidth>
											<SoftTypography variant="body2">Description</SoftTypography>
											<SoftInput
												fullWidth
												multiline
												minRows={3}
												placeholder="Collection Description"
												name="description"
												onBlur={handleBlur}
												onChange={handleChange}
												type="text"
												value={values.description}
											/>
										</FormControl>
										<FormControl margin="normal" fullWidth>
											<SoftTypography variant="body2">External URL</SoftTypography>
											<SoftInput
												error={Boolean(touched.external_link && errors.external_link)}
												fullWidth
												placeholder="Link to collection page"
												name="external_link"
												onBlur={handleBlur}
												onChange={handleChange}
												type="text"
												value={values.external_link}
											/>
										</FormControl>
										<SoftTypography my={3} variant="body2">
											Properties
										</SoftTypography>
										<Box mb={3} sx={{ color: "#989898" }}>
											<MultilineField
												items={properties.current}
												onChange={setProperties}
												fields={[
													{ name: "name", title: "Meta Name" },
													{ name: "value", title: "Meta Value" },
												]}
												addButtonText="Add Property"
												addButtonAlignment="left"
											/>
										</Box>
										<Box sx={{ py: 2 }}>
											<Button
												disabled={
													isSubmitting ||
													!thumbnail_path.current ||
													(!touched.token_name && !defaultValues) ||
													(errors.token_name && errors.token_name.length > 0)
												}
												color="primary"
												fullWidth
												size="large"
												type="submit"
												variant="contained"
											>
												Mint
											</Button>
										</Box>
									</form>
								)}
							</Formik>
						</SoftBox>
					</Grid>
				</Grid>
			</SoftBox>
		</Card>
	);
};

export default TokenForm;
