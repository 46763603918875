import Popup from 'layouts/applications/popup/Popup';
import CloseIcon from '@mui/icons-material/Close';
import SoftTypography from 'components/SoftTypography';
import { stockDetailsData } from './StockInfoData';
import TrafficLight from '../../../assets/images/traffic-light.gif'
import { Card, Grid } from '@mui/material';
import SoftBox from 'components/SoftBox';
import { RedBox } from 'components/CustomComponents';

export const ProfitAndLoss = ({ toggleInfoPopup }) => {
    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"What Is a Profit and Loss (P&L) Statement?"}></RedBox>
            <SoftTypography className="popupTextStyle" >Profit and loss (P&L) statement refers to a financial statement that summarizes the revenues, costs, and expenses incurred during a specified period, usually a quarter or fiscal year. These records provide information about a company’s ability or inability to generate profit by increasing revenue, reducing costs, or both. P&L statements are often presented on a cash or accrual basis. Company managers and investors use P&L statements to analyze the financial health of a company.</SoftTypography>
            <br></br>
            <strong className='popupGrey' >KEY TAKEAWAYS</strong>
            <ul style={{ listStyleType: 'disc', marginLeft: "20px" }}>
                <li className="popupTextStyle" >The profit and loss (P&L) statement is a financial statement that summarizes the revenues, costs, and expenses incurred during a specified period.</li>
                <li className="popupTextStyle" >The P&L statement is one of three financial statements that every public company issues quarterly and annually, along with the balance sheet and the cash flow statement.</li>
                <li className="popupTextStyle" >When used together, the P&L statement, balance sheet, and cash flow statement provide an in-depth look at a company’s overall financial performance.</li>
                <li className="popupTextStyle" >Statements are prepared using the cash method or accrual method of accounting.</li>
                <li className="popupTextStyle" >It is important to compare P&L statements from different accounting periods, as any changes over time become more meaningful than the numbers themselves.</li>
            </ul>
        </Card>
    </Popup>
}



export const CashFlowStatement = ({ toggleInfoPopup }) => {
    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"What Is a Cash Flow Statement?"}></RedBox>
            <SoftTypography className="popupTextStyle" >A cash flow statement is a financial statement that provides aggregate data regarding all cash inflows that a company receives from its ongoing operations and external investment sources. It also includes all cash outflows that pay for business activities and investments during a given period.</SoftTypography>
            <br></br>
            <strong className='popupGrey'>KEY TAKEAWAYS</strong>
            <ul style={{ listStyleType: 'disc', marginLeft: "20px" }}>
                <li className="popupTextStyle" >A cash flow statement provides data regarding all cash inflows that a company receives from its ongoing operations and external investment sources.</li>
                <li className="popupTextStyle" >The cash flow statement includes cash made by the business through operations, investment, and financing—the sum of which is called net cash flow.</li>
                <li className="popupTextStyle" >The first section of the cash flow statement is cash flow from operations, which includes transactions from all operational business activities.</li>
                <li className="popupTextStyle" >A balance sheet is a financial statement that reports a company's assets, liabilities, and shareholder equity.</li>
                <li className="popupTextStyle" >Cash flow from financing is the final section, which provides an overview of cash used from debt and equity.</li>

            </ul>
        </Card>
    </Popup>
}

export const TechnicalAnalysisPopup = ({ toggleInfoPopup }) => {
    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"What Is Technical Analysis?"}></RedBox>
            <SoftTypography className="popupTextStyle" >Technical analysis is a trading discipline employed to evaluate investments and identify trading opportunities by analyzing statistical trends gathered from trading activity, such as price movement and volume. Unlike fundamental analysis, which attempts to evaluate a security's value based on business results such as sales and earnings, technical analysis focuses on the study of price and volume.</SoftTypography>
            <br></br>
            <strong className='popupGrey'>KEY TAKEAWAYS</strong>
            <ul style={{ listStyleType: 'disc', marginLeft: "20px" }}>
                <li className="popupTextStyle" >Technical analysis is a trading discipline employed to evaluate investments and identify trading opportunities in price trends and patterns seen on charts.</li>
                <li className="popupTextStyle" >Technical analysts believe past trading activity and price changes of a security can be valuable indicators of the security's future price movements.</li>
                <li className="popupTextStyle" >Technical analysis may be contrasted with fundamental analysis, which focuses on a company's financials rather than historical price patterns or stock trends.</li>
            </ul>
        </Card>
    </Popup>
}

export const BalancesheetPopup = ({ toggleInfoPopup }) => {
    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"What Is a Balance Sheet?"}></RedBox>
            <SoftTypography className="popupTextStyle" >The term balance sheet refers to a financial statement that reports a company's assets, liabilities, and shareholder equity at a specific point in time. Balance sheets provide the basis for computing rates of return for investors and evaluating a company's capital structure.</SoftTypography>
            <br></br>
            <SoftTypography className="popupTextStyle" >In short, the balance sheet is a financial statement that provides a snapshot of what a company owns and owes, as well as the amount invested by shareholders. Balance sheets can be used with other important financial statements to conduct fundamental analysis or calculate financial ratios.</SoftTypography>
            <br></br>
            <strong className='popupGrey'>KEY TAKEAWAYS</strong>
            <ul style={{ listStyleType: 'disc', marginLeft: "20px" }}>
                <li className="popupTextStyle" >A balance sheet is a financial statement that reports a company's assets, liabilities, and shareholder equity.</li>
                <li className="popupTextStyle" >The balance sheet is one of the three core financial statements that are used to evaluate a business.</li>
                <li className="popupTextStyle" >It provides a snapshot of a company's finances (what it owns and owes) as of the date of publication.</li>
                <li className="popupTextStyle" >The balance sheet adheres to an equation that equates assets with the sum of liabilities and shareholder equity.</li>
                <li className="popupTextStyle" >Fundamental analysts use balance sheets to calculate financial ratios.</li>
            </ul>
        </Card>
    </Popup>
}

export const FundamentalAnalysis = ({ toggleInfoPopup }) => {

    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"GEM Traffic Light System"}></RedBox>
            <SoftBox style={{ display: "flex", justifyContent: "center" }}>
                <img style={{ width: "50%" }} alt='traffic light system' src={TrafficLight}></img>
            </SoftBox>
            <SoftTypography className="popupTextStyle" >BBV's tailored Gem Traffic Light System to make your investment journey safe and successful!
            </SoftTypography>
            <SoftTypography className="popupTextStyle" >
                <span style={{ color: "red" }}>RED</span>  - Weak Indication of a company's fundamentals where you take more risk.
            </SoftTypography>
            <SoftTypography className="popupTextStyle" >
                <span style={{ color: "rgb(240, 184, 24)" }}>YELLOW</span>  - Fair Indication of a company's fundamentals where you take fair risk.
            </SoftTypography>
            <SoftTypography className="popupTextStyle" >
                <span style={{ color: "green" }}>GREEN</span>  - Strong Indication of a company's fundamentals where you take less risk.
            </SoftTypography>


        </Card>
    </Popup>
}

export const SopOne = ({ toggleInfoPopup }) => {

    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"Session A - SOP 1 (Understanding the economy)"}></RedBox>
            <SoftTypography className="popupTextStyle" >
                This dashboard is a summary of the Macro economy indicators of United States. As US is the largest stock market economy, it has an impact to many other countries as well. Economic indicators provide information about an economy and whether it is expanding or contracting.
            </SoftTypography>
            <SoftTypography className="popupTextStyle" >
                In addition to company and industry data, the state of the overall economy can provide insight to investors for their decision-making. For instance, when considering whether to invest in a company that depends on consumer spending, it's useful to know whether the economy faces a recession.
            </SoftTypography>
            <br></br>
            <strong className='popupGrey'>KEY TAKEAWAYS</strong>
            <ul style={{ listStyleType: 'disc', marginLeft: "20px" }}>
                <li className="popupTextStyle" >Individuals who measure economic activity and predict future trajectories rely on the analysis of key pieces of macroeconomic data.</li>
                <li className="popupTextStyle" >Known as economic indicators, these data quantify various aspects of an economy.</li>
                <li className="popupTextStyle" >Economic indicators measure everything from economic growth to changes in prices to unemployment.</li>
                <li className="popupTextStyle" >The markets can move on news about key economic indicators.</li>
                <li className="popupTextStyle" >Information provided by economic indicators can help people make decisions about their investments</li>
            </ul>
            {/* <br></br> */}
            <SoftTypography mt={2} className="popupTextStyle">To learn more, click on the Learn button on our app to see our courses</SoftTypography>
        </Card>
    </Popup>
}

export const SopTwo = ({ toggleInfoPopup }) => {

    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={"Session C - SOP 2 (Entry & Exit Your Trades)"}></RedBox>
            <SoftTypography mb={2} className="popupTextStyle" >
                A good entry point is often the first step in achieving a successful trade.
            </SoftTypography>
            <SoftTypography mb={2} className="popupTextStyle" >
                One of the most important step is to compile a watchlist of good companies with strong fundamentals to trade. The reason why we need to invest in strong companies only, because that is the 1st step to reduce risk due to market manipulation. Good companies statically have bigger market capital, and higher price to prevent pump and dump in the stock market.
            </SoftTypography>
            <SoftTypography mb={2} className="popupTextStyle" >
                As stocks can be capital intensive, you can learn and use Options trading to your advantage, as you need very little capital to make even higher profits. However, Options trading is not so straight forward, therefore get yourself trained, before trading Options.
            </SoftTypography>
            <SoftTypography mb={2} className="popupTextStyle" >
                Investors can use trendlines, moving averages, and indicators to help determine a good entry to buy or sell.
            </SoftTypography>
            <SoftTypography mb={2} className="popupTextStyle" >
                The entry point is usually a component of a predetermined trading strategy for minimizing investment risk and removing the emotion from trading decisions. A good entry point is often the next step in achieving a successful trade.
            </SoftTypography>
            <SoftTypography className="popupTextStyle" >
                Often people plan to enter trades, but did not plan to exit. The stock market has a cycle, and if you don't leverage on the cycle, you often find yourself buying at a higher, and hold. Cash is King, therefore, reward yourself by exiting the trade and collect the profits.
            </SoftTypography>
        </Card>
    </Popup>
}

const StockDetailsPagePopup = ({ toggleInfoPopup, popupAccessor }) => {
    let detailsData = stockDetailsData[popupAccessor]
    return <Popup transparent={true}>
        <CloseIcon
            className="close-btn icon"
            style={{ color: 'white' }}
            onClick={() => {
                toggleInfoPopup(false);
            }}
        />
        <Card className="popupCard popupWidth">
            <RedBox Header={detailsData.header}></RedBox>
            {detailsData.body.map((currBody) => {
                return (
                    <SoftBox mb={2}>
                        <SoftTypography className="popupTextStyle" >
                            {currBody}
                        </SoftTypography>
                    </SoftBox>
                )
            })}
        </Card>
    </Popup>
}



export const StockInfoPopup = ({ toggleInfoPopup, popupAccessor }) => {

    switch (popupAccessor) {
        case 'profitAndLoss':
            return <ProfitAndLoss toggleInfoPopup={toggleInfoPopup} />
        case 'cashFlowStatement':
            return <CashFlowStatement toggleInfoPopup={toggleInfoPopup} />
        case 'balanceSheet':
            return <BalancesheetPopup toggleInfoPopup={toggleInfoPopup} />
        case 'technicalAnalysis':
            return <TechnicalAnalysisPopup toggleInfoPopup={toggleInfoPopup} />
        case 'fundamentalAnalysis':
            return <FundamentalAnalysis toggleInfoPopup={toggleInfoPopup} />
        case 'sopOne':
            return <SopOne toggleInfoPopup={toggleInfoPopup} />
        case 'sopTwo':
            return <SopTwo toggleInfoPopup={toggleInfoPopup} />
        default:
            return <StockDetailsPagePopup popupAccessor={popupAccessor} toggleInfoPopup={toggleInfoPopup} />;
    }
}





