import axios from "layouts/authentication/api/axios";

export const creteCategory = async ({ name }) => {
	try {
		const res = await axios().post(`/admin/marketplace/categories/`, {
			name,
		});
		return res.data?.success;
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const updateCategory = async (id, { name }) => {
	try {
		const res = await axios().post(`/admin/marketplace/categories/${id}`, {
			name,
		});
		return res.data?.success;
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const deleteCategory = async id => {
	try {
		const res = await axios().delete(`/admin/marketplace/categories/${id}`);
		return res.data?.success;
	} catch (error) {
		console.log(error);
	}

	return false;
};

export const fetchCategories = async () => {
	try {
		const res = await axios().get(`/marketplace/categories/`);
		return res.data;
	} catch (error) {
		console.log(error);
	}
	return [];
};

export const deleteCollection = async id => {
	try {
		const res = await axios().delete(`/admin/marketplace/collections/${id}`);
		return res.data?.success;
	} catch (error) {
		console.log(error);
	}

	return false;
};

export const fetchCollections = async () => {
	try {
		const res = await axios().get(`/marketplace/collections/`);
		return res.data;
	} catch (error) {
		console.log(error);
	}
	return [];
};

export const fetchCollection = async id => {
	try {
		const res = await axios().get(`/marketplace/collections/${id}`);
		return res.data;
	} catch (error) {
		console.log(error);
	}
	return null;
};

export const createCollection = async data => {
	try {
		const res = await axios().post(`/admin/marketplace/collections/`, {
			name: data.name,
			symbol: data.symbol,
			chainID: data.chainID,
			contractType: data.contractType,
			ownerAddress: data.owner,
			category_id: data.category,
			description: data.description,
			ipfs_thumbnail_hash: data.thumbnail,
			ipfs_contract_meta_hash: data.ipfs_contract_meta_hash,
		});

		if (res.data?.id) return res.data;
	} catch (error) {
		console.log(error);
	}

	return false;
};

export const setCollectionContractDeployed = async (collectionID, data) => {
	try {
		const res = await axios().post(`/admin/marketplace/collections/${collectionID}`, data);

		if (res.data?.success) return true;
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const uploadContractMeta = async data => {
	try {
		const res = await axios().post(`/admin/uploads/marketplace/collections/contract_meta`, data);

		if (res.data?.filepath) return res.data.filepath;
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const copyContractTokenMeta = async (collectionID, collectionSymbol, tokenID, ipfsHash) => {
	try {
		const res = await axios().post(`/admin/uploads/marketplace/collections/copy_token_meta`, {
			collectionID,
			collectionSymbol,
			tokenID,
			ipfsHash
		});

		if (res.data?.filepath) return res.data.filepath;
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const createCollectionToken = async (collectionID, data) => {
	try {
		const res = await axios().post(`/admin/marketplace/collections/${collectionID}/tokens`, {
			minter: data.minter,
			owner: data.owner,
			imageURL: data.thumbnail,
			metadataURL: data.metadataURL,
			metadataCache: data.metadataCache,
			collection_id: collectionID,
		});

		if (res.data?.id) return res.data;
	} catch (error) {
		console.log(error);
	}

	return false;
};

export const setCollectionTokenMinted = async (collectionID, tokenID, data) => {
	try {
		const res = await axios().post(`/admin/marketplace/collections/${collectionID}/tokens/${tokenID}`, data);

		if (res.data?.success) return true;
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const setCollectionTokenListed = async (collectionID, tokenID, data) => {
	try {
		const res = await axios().post(`/admin/marketplace/collections/${collectionID}/tokens/${tokenID}/listed`, data);

		if (res.data?.success) return true;
	} catch (error) {
		console.log(error);
	}
	return false;
};

export const markSold = async(collectionID, tokenID, data) => {
	try {
		const res = await axios().post(`/marketplace/collections/${collectionID}/tokens/${tokenID}/sold`, data);

		if (res.data?.success) return true;
	} catch (error) {
		console.log(error);
	}
	return false;
}