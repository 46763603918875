import React, { useEffect, useRef, memo } from "react";

function ForexViewWidget() {
	const container = useRef();

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js";
		script.type = "text/javascript";
		script.async = true;
		script.innerHTML = `
        {
            "width": "100%",
            "height": "100%",
            "currencies": [
              "EUR",
              "USD",
              "JPY",
              "GBP",
              "CHF",
              "AUD",
              "CAD",
              "NZD",
              "CNY"
            ],
            "isTransparent": false,
            "colorTheme": "light",
            "locale": "en",
            "backgroundColor": "#ffffff"
        }`;
		container.current.appendChild(script);
	}, []);

	return (
		<div className="tradingview-widget-container" ref={container}>
			<div className="tradingview-widget-container__widget"></div>
		</div>
	);
}

export default memo(ForexViewWidget);
