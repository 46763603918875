import React from 'react'
import SoftPagination from "components/SoftPagination";
import Icon from "@mui/material/Icon";
const Pagination = ({postsPerPage, totalPosts,paginate,currentPage,resetIframe}) =>{
  const pageNumbers = [];
  for(let i = 1; i<= Math.ceil(totalPosts/postsPerPage); i++){
    pageNumbers.push(i);
  }
  return (
    <SoftPagination  >
    <div className="pagination">
          <SoftPagination item>
            <Icon onClick={()=>{
                        if(currentPage > 1){
                          paginate(currentPage - 1)
                          resetIframe()
                        }
                    }}>keyboard_arrow_left</Icon>
          </SoftPagination>
          {pageNumbers.map(number =>{
            return <SoftPagination className = {currentPage === number?"bbvRed whiteFont ":""} key={number} item onClick={()=>{
                        resetIframe()
                        paginate(number)
                        
                    }}>{number}</SoftPagination>
          })}
          <SoftPagination item>
            <Icon  onClick={()=>{
                        if(currentPage !== Math.ceil(totalPosts/postsPerPage))
                        paginate(currentPage + 1)
                        resetIframe()
                    }}>keyboard_arrow_right</Icon>
        </SoftPagination>
        </div>
  </SoftPagination>
  )

}

export default Pagination