import { axiosOldEndpoint } from "layouts/authentication/api/axios";
import { axiosWithCache, axiosPrivate } from "layouts/authentication/api/axios";
import axiosDefault from "layouts/authentication/api/axios";
export const getStockData = async string => {
	var option = string + ".US";
	try {
		let price = await axiosWithCache(`/listing/eod/real-time?ticker_code=${option}`);
		// console.log("price is here")
		// console.log(price)
		return price;
	} catch {
		console.log("error in fetching stock data");
		return "NIL";
	}
};

function findLink(string) {
	const start = string.indexOf("https");
	const end = string.indexOf("tsrc=rss");
	// console.log(string.slice(start,end+8))//8 is length of tsrc=rss
	const finalString = string.slice(start, end + 8);
	return finalString;
}

export const getFearIndex = async () => {
	try {
		const formattedRes = await axiosWithCache("/external/fearindex?x-access-token=changeme_apikey&x-username=test");

		if (formattedRes.length !== 0) {
			return formattedRes;
		}
		return null;
	} catch {
		console.log("error fetching fear greed index");
		return null;
	}
};

export const getUserPortfolioStocks = async() =>{
	try {
		// const formattedRes = await axiosWithCache('portfolio/stocks')
		const formattedRes = await axiosDefault().get('portfolio/stocks')
		if (formattedRes.length !== 0) {
			return formattedRes.data.data;
		}
		return null;
	} catch (error) {
		console.log("error fetching user portfolio stocks");
		return null;
	}
}

export const getUserCapital = async () =>{
	try {
		const response = await axiosDefault().get('portfolio/capital')
		
		if (response.length !== 0) {
			return response.data.data;
		}
		return null;
	} catch (error) {
		console.log("error returning user capital")
	}
}

export const updateStockData = async(columnToUpdate,stockItemID,newValue,type) =>{
	try {
		const res = await axiosDefault().patch(
			`/portfolio/updatePortfolio/${type}/${columnToUpdate}`,{
				valueToUpdate:newValue,
				rowID:stockItemID
			}
		)
		if(res.data.data === 'success'){
			return res.data.data
		}else{
			return null
		}
	} catch (error) {
		console.log(`error updating ${columnToUpdate}`)
	}
}

export const updateUserCapital = async (amount) =>{
	try {
		const res = await axiosDefault().patch(
			'/portfolio/capital',{
				captialToUpdate:amount
			}
		)
		console.log(res)
		if(res.data.data === 'user capital updated'){
			return res
		}else{
			return null
		}
	} catch (error) {
		console.log("error updating user capital")
		console.log(error)
		return null
	}
}

export const getUserPortfolioOptions = async() =>{
	try {
		// const formattedRes = await axiosWithCache('portfolio/options')
		const formattedRes = await axiosDefault().get('portfolio/options')
		
		if (formattedRes.length !== 0) {
			console.log(formattedRes.data.data)
			return formattedRes.data.data;
		}
		return null;
	} catch (error) {
		console.log("error fetching user portfolio options");
		return null;
	}
}


export const getEarningsRelease = async () => {
	try {
		const formattedRes = await axiosWithCache("/external/earnings_calendar");

		if (formattedRes.length !== 0) {
			// console.log(formattedRes)
			return formattedRes;
		}
		return null;
	} catch {
		console.log("error fetching fear greed index");
		return null;
	}
};

export const getOptionCallData = async (underlying,putOrCall) => {
	try {
		const formattedRes = await axiosOldEndpoint(`/api/option-strikes/${underlying}/${putOrCall}?x-access-token=changeme_apikey&x-username=test`);

		if (formattedRes.data.status !== 401) {
			console.log(formattedRes)
			return formattedRes.data;
		}
		return null;
	} catch {
		console.log("error fetching fear greed index");
		return null;
	}
};




export const getNewsData = async code => {
	try {
		const rss = await axiosWithCache(`/external/yahoo/underlying?ticker_code=${code}`);
		// console.log("news json")
		// console.log(rss)
		var el = document.createElement("html");
		el.innerHTML = rss;
		const length = el.getElementsByTagName("item").length;
		if (length < 1) {
			//return something
			return [];
		}
		var json = [];
		for (var i = 0; i < length - 1; i++) {
			const item = el.getElementsByTagName("item")[i];
			const link = findLink(item.textContent);
			const title = item.getElementsByTagName("title")[0].textContent;
			const description = item.getElementsByTagName("description")[0].textContent;
			const pubDate = item.getElementsByTagName("pubdate")[0].textContent;
			const jsonItem = { link: link, title: title, description: description, pubDate: pubDate };
			json.push(jsonItem);
		}
		
		// console.log(json)
		return json;
	} catch (error) {
		console.log("error retrieving news data: " + error);
	}
};

export const getTickerList = async symbol => {
	try {
		const formattedRes = await axiosPrivate(`/listing/autocomplete/${symbol}`);
		console.log(formattedRes)
		return formattedRes;
	} catch (error) {
		console.log("error in getting autocompelte data");
	}
};

export const getStocksData = async ({ page, sector, shariah, market }) => {
	try {
		const formattedRes = await axiosWithCache(
			`/listing/gems/country?country_code=US&top_x=500&page=${page ?? 1}&sector=${sector ?? ""}&shariah=${
				shariah ?? ""
			}&market_cap=${market ?? ""}`,
			"data",
			"data.data"
		);
		return formattedRes;
	} catch (error) {
		console.log("error in retreiving top 500 stock data");
	}
};

export const getSNPData = async ({ page, sector, shariah, market }) => {
	try {
		const formattedRes = await axiosWithCache(
			`/listing/gems/list?list_name=SP500&page=${page ?? 1}&sector=${sector ?? ""}&shariah=${
				shariah ?? ""
			}&market_cap=${market ?? ""}`,
			"data",
			"data.data"
		);
		return formattedRes;
	} catch (error) {
		console.log("error in retreiving top 500 stock data");
	}
};

export const getSingleOption = async (optionType, code) => {
	try {
		const formattedRes = await axiosWithCache(`/listing/${optionType}?mode=underlying&symbol=${code}`);
		return formattedRes;
	} catch (error) {
		console.log("error in single option");
	}
};

export const getSingleStockData = async code => {
	try {
		const formatStocks = await axiosWithCache(`/listing/gems/underlying?ticker_code=${code}`);
		if(!formatStocks[0].name){
			console.log('error')
			return 
		}
		return formatStocks;
	} catch (error) {
		console.log(`error in retreiving ${code} data`);
	}
};
// export const getEODName = async(code) => {
//     try{
//         const res = await axiosWithCache(
//             `api/react/eod/fundamentals/${code}?x-access-token=changeme_apikey&x-username=test`
//         )
//         const formatStocks = JSON.parse(res.data.data)
//         return formatStocks
//     }catch(error){
//         console.log(`error in retreiving ${code} data`)
//     }
// }

export const getDividendData = async exchange => {
	try {
		const formatDividend = await axiosWithCache(`/listing/eod/dividend?exchange_code=${exchange}`);
		return formatDividend;
	} catch {
		console.log(`error in retreiving dividend data`);
	}
};

export const getFinnhubNews = async category => {
	try {
		const formatNews = await axiosWithCache(`/external/finnhub/news/${category}`);
		return formatNews;
	} catch {
		console.log(`error in retreiving dividend data`);
	}
};

export const getOptionsData = async string => {
	var option = "";
	switch (string) {
		case "Funded Income Strategy (FIS)":
			option = "sput";
			break;
		case "Rolling Boat Strategy (RBS)":
			option = "rbs";
			break;
		case "Multiple Tight Vertical Strategy (BPS)":
			option = "bps";
			break;
		case "Multiple Tight Vertical Strategy (BCS)":
			option = "bcs";
			break;
		default:
			return "error";
	}
	try {
		const formattedOptions = await axiosWithCache(`/listing/${option}?mode=distinct&realtime_data=1`);
		console.log(formattedOptions)
		return formattedOptions;
	} catch (error) {
		console.log("error in retreiving option data", error);
	}
};
