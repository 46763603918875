
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { toSlug } from "layouts/Helper/string.helper";
import avatar from "assets/images/avatar/avatar-bullbearworld.png";

const CollectionCard = ({ collection }) => (
	<Link
		to={
			collection.tokens.filter(token => token.tokenID && token.tokenID > 0).length === 1
				? `/dashboard/marketplace/collection/${collection.id}/${collection.tokens[0].tokenID}/${toSlug(
						collection.name
				  )}`
				: `/dashboard/marketplace/collection/${collection.id}/${toSlug(collection.name)}`
		}
	>
		<Card
			style={{
				background: "linear-gradient(138.87deg, #423633 7.81%, #141727 95.71%)",
			}}
		>
			<img
				src={`${process.env.REACT_APP_GCLOUD_STORAGE_BASE_URL}${collection.ipfs_thumbnail_hash}`}
				style={{ width: "100%", height: "295px", objectFit: "cover" }}
			/>
			<SoftBox p={2}>
				<SoftTypography variant="h6" color="white">
					{collection.name}
				</SoftTypography>
				<Grid display="flex" justifyContent="flex-start" alignItems="center" gap={1} my={1}>
					<Grid item>
						<img src={avatar} width="30" style={{ margin: "0", verticalAlign: "top" }} />
					</Grid>
					<Grid item>
						<SoftTypography variant="h6" color="white">
							Bullbearworld
						</SoftTypography>
					</Grid>
				</Grid>
				<SoftTypography variant="body2" color="white" opacity={0.5} style={{ fontWeight: "700" }} mt={2}>
					{/* {
                collection.tokens.filter(
                    token => token.tokenID && token.tokenID > 0
                ).length
            }{" "} */}
					{collection.max_tokens} Token(s)
				</SoftTypography>
			</SoftBox>
		</Card>
	</Link>
);

export default CollectionCard;