import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3 } from "context/web3";
import { setErrorMessage, useSoftUIController } from "context";
import CollectionForm from "components/Marketplace/CollectionForm";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import { createCollection, uploadContractMeta, setCollectionContractDeployed } from "services/marketplace";
import { deploy } from "plugins/Ethereum";
import SoftProcessing from "components/SoftProcessing";
import { deleteCollection } from "services/marketplace";

export default () => {
	const { web3Controller } = useWeb3();
	const [controller, dispatch] = useSoftUIController();
	const [processing, setProcessing] = useState(false);

	const { connected: connectedToBlockchain } = web3Controller;
	const navigate = useNavigate();

	useEffect(() => {
		if (!connectedToBlockchain) {
			navigate("/admin/marketplace/collections", { replace: true });
		}
	}, [connectedToBlockchain]);

	const handleCreateCollection = async collectionData => {
		setProcessing(true);
		// create contract meta JSON
		const contractMeta = {
			name: collectionData.name,
			description: collectionData.description,
			image: "ipfs://" + collectionData.thumbnail,
			external_link: collectionData.external_link,
			symbol: collectionData.symbol,
		};

		try {
			const contractMetaUploaded = await uploadContractMeta(contractMeta);
			if (contractMetaUploaded) {
				const created = await createCollection({
					...collectionData,
					ipfs_contract_meta_hash: contractMetaUploaded,
				});
				if (created?.id) {
					// send to metamask for deployment
					deploy(721, [
						collectionData.name,
						collectionData.symbol,
						"ipfs://" + contractMetaUploaded,
						process.env.REACT_APP_TOKEN_BASE_URL + created.id + "_" + collectionData.symbol + "/",
					])
						.then(deployedAt => {
							// update the collection with blockchain address
							setCollectionContractDeployed(created.id, {
								contractAddress: deployedAt,
							});

							navigate("/admin/marketplace/collections");
						})
						.catch(() => {
							setErrorMessage(dispatch, "Sorry! Not able to create this collection. Please try again");
							deleteCollection(created.id);
						})
						.finally(() => {
							setProcessing(false);
						});

					return;
				} else {
					setProcessing(false);
				}
			} else {
				setErrorMessage(dispatch, "Sorry! Not able to create this collection. Please try again");
				setProcessing(false);
			}
		} catch (e) {
			setProcessing(false);
		}

		setErrorMessage(dispatch, "Sorry! Not able to create this collection. Please try again");
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox pb={3}>
				<CollectionForm onCreate={handleCreateCollection} />
				{processing && <SoftProcessing />}
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
};
