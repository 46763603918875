import { useEffect } from "react";
import { useConnectModal, useDisconnect } from "@web3modal/react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftTypography from "components/SoftTypography";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

import { useWeb3 } from "context/web3";

import metamaskIcon from "assets/images/metamask-icon.svg";
import walletconnectIcon from "assets/images/walletconnect.svg";

const ConnectWallet = () => {
	const { web3Controller, connectToBlockchain } = useWeb3();
	const { connected: connectedToBlockchain, ...blockchainInfo } = web3Controller;

	const { open: openWalletConnect } = useConnectModal();
	const disconnectWalletConnect = useDisconnect();
	const navigate = useNavigate();

	useEffect(() => {
		if (connectedToBlockchain) navigate("/dashboard/marketplace");
	}, [connectedToBlockchain]);

	const handleConnect = mode => {
		switch (mode) {
			case "metamask":
				connectToBlockchain();
				break;
			case "walletconnect":
				//disconnectWalletConnect();
				openWalletConnect();
				break;
		}
	};
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox my={3}>
				<Card className="main-content">
					<SoftBox p={3}>
						<SoftTypography variant="h3" align="center">
							Connect to your Wallet
						</SoftTypography>
					</SoftBox>
					<SoftBox style={{ width: "100%", maxWidth: "400px", margin: "0 auto" }}>
						<List
							sx={{
								width: "100%",
								my: 3,
								bgcolor: "#eee",
								color: "#333",
								borderRadius: "20px",
								padding: "10px",
							}}
							mb={3}
						>
							<ListItem onClick={() => handleConnect("metamask")} className="anchor">
								<ListItemAvatar>
									<Avatar>
										<img
											alt="Metamask"
											src={metamaskIcon}
											style={{
												display: "inline-block",
												maxWidth: "100%",
												width: 22,
												verticalAlign: "middle",
											}}
										/>
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Metamask"
									secondary="Desktop only, using chrome metamask widget"
								/>
							</ListItem>
						</List>
						<List
							sx={{
								width: "100%",
								my: 3,
								bgcolor: "#eee",
								color: "#333",
								borderRadius: "20px",
								padding: "10px",
							}}
							mb={3}
						>
							<ListItem onClick={() => handleConnect("walletconnect")} className="anchor">
								<ListItemAvatar>
									<Avatar>
										<img
											alt="WalletConnect"
											src={walletconnectIcon}
											style={{
												display: "inline-block",
												maxWidth: "100%",
												width: 22,
												verticalAlign: "middle",
											}}
										/>
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="WalletConnect"
									secondary="Desktop and Mobile, can use the mobile wallet app to connect"
								/>
							</ListItem>
						</List>
					</SoftBox>
				</Card>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
};

export default ConnectWallet;
