import axios from "layouts/authentication/api/axios";

export const fetchUsers = async () => {
	try {
		const res = await axios().get(`/admin/users/`);
		return res.data;
	} catch (error) {
		console.log(error);
	}
	return [];
};

export const loadUserProfile = async () => {
	try {
		const res = await axios().get("/me/");
		return res.data;
	} catch (error) {
		console.log(error);
	}
	return null;
};

export const saveUserProfile = async (profile) => {
	try {
		const res = await axios().post("/me/", profile)
		return res.data;
	} catch (error) {
		console.log(error)
	}

	return null;
}

export const updateUserSubscription = async (user_id, data) => {
	try {
		const res = await axios().post(`/admin/users/${user_id}/apply-subscription`, {
			plan_id: data.planID,
			expiry_date: data.expiryDate
		});
		return res.data;
	} catch (error) {
		console.log(error)
	}

	return null
}