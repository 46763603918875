/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

// Settings page components
import axios from "layouts/authentication/api/axios";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useState } from "react";
import { CircularProgress, Typography } from "@mui/material";

function ChangePassword() {
	const [isProcessing, setIsProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isSuccess, setIsSuccess] = useState(false);

	const passwordRequirements = [
		"One special characters",
		"Min 6 characters",
		"One number (2 are recommended)",
		"Change it often",
	];

	const renderPasswordRequirements = passwordRequirements.map((item, key) => {
		const itemKey = `element-${key}`;

		return (
			<SoftBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
				<SoftTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
					{item}
				</SoftTypography>
			</SoftBox>
		);
	});

	return (
		<Card id="change-password">
			<SoftBox p={3}>
				<SoftTypography variant="h5">Change Password</SoftTypography>
			</SoftBox>
			<Formik
				initialValues={{
					current_password: "",
					new_password: "",
					confirm_password: "",
				}}
				validationSchema={Yup.object().shape({
					current_password: Yup.string().required("Current Password is required"),
					new_password: Yup.string().min(6).max(20).required("New Password is required"),
					confirm_password: Yup.string()
						.required("Confirm Password is required")
						.test("passwords-match", "Passwords must match", function (value) {
							return this.parent.new_password === value;
						}),
				})}
				onSubmit={async (values, actions) => {
					setIsSuccess(false);
					setIsProcessing(true);
					setErrorMessage(null);

					try {
						const res = await axios().post("/me/update-password", {
							current_password: values.current_password,
							new_password: values.new_password,
						});

						if (res.data?.success) {
							setIsSuccess(true);
						} else {
							setErrorMessage("Invalid current password. Please try again.");
						}

						setIsProcessing(false);
					} catch (e) {
						setIsProcessing(false);
					}

					actions.resetForm();
					actions.setSubmitting(false);
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
					<form>
						<SoftBox component="form" pb={3} px={3}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<SoftInput
										error={Boolean(touched.current_password && errors.current_password)}
										fullWidth
										placeholder="Current Password"
										name="current_password"
										onBlur={handleBlur}
										onChange={handleChange}
										type="password"
										value={values.current_password}
									/>
								</Grid>
								<Grid item xs={12}>
									<SoftInput
										error={Boolean(touched.new_password && errors.new_password)}
										fullWidth
										placeholder="New Password"
										name="new_password"
										onBlur={handleBlur}
										onChange={handleChange}
										type="password"
										value={values.new_password}
									/>
								</Grid>
								<Grid item xs={12}>
									<SoftInput
										error={Boolean(touched.confirm_password && errors.confirm_password)}
										fullWidth
										placeholder="Confirm Password"
										name="confirm_password"
										onBlur={handleBlur}
										onChange={handleChange}
										type="password"
										value={values.confirm_password}
									/>
								</Grid>
							</Grid>
							<SoftBox mt={6} mb={1}>
								<SoftTypography variant="h5">Password requirements</SoftTypography>
							</SoftBox>
							<SoftBox mb={1}>
								<SoftTypography variant="body2" color="text">
									Please follow this guide for a strong password
								</SoftTypography>
							</SoftBox>
							<SoftBox
								display="flex"
								justifyContent="space-between"
								alignItems="flex-end"
								flexWrap="wrap"
							>
								<SoftBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
									{renderPasswordRequirements}
								</SoftBox>
								<SoftBox ml="auto">
									<SoftButton
										disabled={
											isSubmitting ||
											isProcessing ||
											!touched.current_password ||
											!touched.new_password ||
											!touched.confirm_password ||
											(errors.current_password && errors.current_password.length > 0) ||
											(errors.new_password && errors.new_password.length > 0) ||
											(errors.confirm_password && errors.confirm_password.length > 0)
										}
										onClick={handleSubmit}
										variant="gradient"
										color="dark"
										size="small"
									>
										update password
										{isProcessing && (
											<CircularProgress size={12} color="inherit" sx={{ marginLeft: "10px" }} />
										)}
									</SoftButton>
								</SoftBox>
								{errorMessage && (
									<SoftBox>
										<SoftTypography variant="body2" color="error">
											{" "}
											{errorMessage}{" "}
										</SoftTypography>
									</SoftBox>
								)}
								{isSuccess && (
									<SoftBox>
										<SoftTypography variant="body2" color="success">
											You password has been updated successfully
										</SoftTypography>
									</SoftBox>
								)}
							</SoftBox>
						</SoftBox>
					</form>
				)}
			</Formik>
		</Card>
	);
}

export default ChangePassword;
