import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getDividendData } from "../data-tables/data/getData";
import DataTable from "examples/Tables/DataTable";
import { dividendTableData } from "../data-tables/data/dataTableData";
import { roundToTwo, validateValue } from "layouts/Helper/helper";
import BackToButton from "components/CustomComponents";

const DividendTable = () => {
	const [inputExchange, setExchange] = useState("NASDAQ");
	const [inputData, setData] = useState(dividendTableData);
	const [isLoading, setLoading] = useState(true);
	useEffect(() => {
		// setLoading(true)
		handleDividend(inputExchange);
	}, [inputExchange]);

	const handleDividend = async exchange => {
		setLoading(true);
		const dividendData = await getDividendData(exchange);
		const formattedDividendData = await formatDividend(dividendData)
		setTimeout(() => {
			setData(formattedDividendData);
			setLoading(false);
		}, 100)
		// console.log(dividendData)
	};
	const final = [
		{ Header: "Name", accessor: "name" },
		{ Header: "Exchange", accessor: "exchange" },
		{ Header: "Market Cap", accessor: "marketCap" },
		{ Header: "Period", accessor: "period" },
		{ Header: "Div Yield", accessor: "divYield" },
		{ Header: "Declaration Date", accessor: "declarDate" },
		{ Header: "Record Date", accessor: "recordDate" },
		{ Header: "Payment Date", accessor: "paymentDate" },
	];

	const formatDividend = async dividendData => {
		// console.log("dividend data is here")
		// console.log(dividendData)
		let theTableData = await Promise.all(
			dividendData.map(async data => {
				let filteredMarketCap = 0;
				if (data.Highlights_MarketCapitalization) {
					filteredMarketCap = data.Highlights_MarketCapitalization.toLocaleString();
				} else {
					filteredMarketCap = "N.A.";
				}
				return {
					name: `${data.General_Name ? data.General_Name+" ": ""}(${data.code})`,
					exchange: data.exchange,
					marketCap: filteredMarketCap,
					recordDate: data.recordDate,
					paymentDate: data.paymentDate,
					period: data.period,
					declarDate: validateValue(data.declarationDate),
					divYield: roundToTwo(data.dividend),
				};
			})
		);
		const dividendDataTable = {
			columns: final.map(header => {
				return { Header: header.Header, accessor: header.accessor };
			}),
			rows: theTableData,
		};
		return dividendDataTable;
	};

	return (
		<DashboardLayout>
			<DashboardNavbar pageTitle="Dividend Dates" />
			<BackToButton text="Back to Research" destination="/dashboard/GEM/stocks-ranking"></BackToButton>
			<DataTable
				table={inputData}
				isDividend={"yes"}
				exchange={inputExchange}
				setExchange={setExchange}
				canSearch
				loading={isLoading}
			/>
		</DashboardLayout>
	);
};

export default DividendTable;
