import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { ScrollToTopButton } from "components/CustomComponents";

const Privacy = () => {
	const ListOfHeaders = [
		'Collection of Personal Data',
		'Purpose and Use of Personal Data',
		'Disclosure of Personal Data',
		'Cookies',
		'Third Parties',
		'Access to, correction of and retention of Personal Data',
		'General',
		'User Content',
		'Right to monitor content.',
		'Code of conduct',
		'Disclaimer ',
		'Links to other sites',
		'Intellectual property ',
		'Termination',
		'Limitation of liability',
		'Data protection and Privacy Policy',
		'Electronic Communications',
		'Password Account, password and security',
		'General Provisions',
		'Governing Law',
		'Changes and updates to these term of service'
	]
	const scrollToSection = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox style={{ display: "flex", justifyContent: "center" }}>
				<SoftBox p={3} mt={3} mb={20} className="tnc">
					<SoftTypography fontWeight="bold">
						This Privacy Policy was last updated on 20 April 2023.
					</SoftTypography>
					<ul style={{ marginBottom: "20px" }}>
						{ListOfHeaders.map((header, index) => {
							return <li style={{ fontSize: "18px" }}  onClick={()=>{scrollToSection(header)}} className="roboto underline" key={index}>{index + 1}. {header}</li>
						})}
					</ul>
					<ScrollToTopButton></ScrollToTopButton>
					<SoftTypography>
						This Privacy Policy is to inform you that the Core Biz Holdings Pte. Ltd. and its group of entities
						have or will collect, record, hold, store, use, disclose and/or process (collectively "Process"
						or"Processing") your Personal Data (as deﬁned below) in a manner consistent with its philosophy of
						respect for individuals, sounds business practices and in accordance with the applicable laws of
						Singapore.By interacting with Core Biz Holdings Pte. Ltd. (including its related corporations and
						business units) (collectively, the "Companies"), as well as the Companies' respective
						representatives and/or agents (collectively referred to herein as "us", "we" or "our"), submitting
						information to us, using any of the websites of the Companies (collectively, "Website"), subscribing
						or registering for any event or account ("Account") with us ("subscribers"), providing us with your
						personal data or signing up for any products and services offered by us ("Products" and "Services"),
						you agree and consent to our Processing of your Personal Data and the terms of our Privacy
						Policy.For the avoidance of doubt, your consents given under this Privacy Policy supplement (but do
						not supersede or replace) any other consents that you may have previously provided or will provide
						to us, and are additional to any rights which we may have at law to collect, use or disclose your
						Personal Data. If you have any questions relating to our Privacy Policy or your Personal Data,
						please contact us as follows:
					</SoftTypography>
					<SoftTypography>
						Data Protection Ofﬁcer Core Biz Holdings Pte. Ltd.
						<br />
						Email: admin@bullbearvector.com or
						<br />
						Write in: 28 Tai Seng Street Sakae Building (Bitespace) #04-05, 2 Lift Lobby, 534106.
					</SoftTypography>
					<SoftTypography>
						We may update this Privacy Policy from time to time through our Website in accordance with future
						developments and/or changes in legal or regulatory requirements. By continuing to interact with us,
						you consent to be bound by the prevailing terms of our Privacy Policy as updated from time to time,
						subject to applicable law.
					</SoftTypography>
					<SoftTypography>
						<strong id="Collection of Personal Data">1. Collection of Personal Data</strong>
						<br />
						1.1. “Personal Data” refers to any data, whether true or not, about an individual who can be
						identiﬁed from that data and other information to which we have or are likely to have access,
						including data in our records as may be updated from time to time which may include, but is not
						limited to: (a) contact details: home address, correspondence address, billing address, home phone
						number and mobile number, e-mail address, work address, work number; (b) compensation/payment
						details: bank account details, credit card details, banking information, credit records; (c)
						veriﬁcation details: information pertaining to the veriﬁcation of identity, including identiﬁcation
						type, NRIC No., and identiﬁcation number (to the extent permitted by the law); (d) optional details:
						includes, but is not limited to age, gender, salary range and employment details, education and
						profession, hobbies and leisure activities, other related products and services subscribed to, and
						family and household demographics; (e) data relating to online session: browser type and version,
						operating system, domain name; and (f) any such information as we deem necessary or appropriate from
						time to time in connection with your commercial relationship with us.
					</SoftTypography>
					<SoftTypography>
						1.2. Scope: When you interact with us, either online or ofﬂine, such as by registering for an
						Account with us, registering with us generally, making bookings for seminars, or enquiring into or
						purchasing our Products or Services, we may collect a variety of Personal Data from you, including
						your name, mailing address, phone number, email address, contact preferences and credit card
						information. When you attend a seminar or event with us, photographs or videos may also be taken,
						and depending on the location of such seminar or event, closed circuit television cameras may also
						be in use for security and surveillance purposes. Other additional sources of personal data that may
						fall within this policy include: (a) information provided or submitted by you through among others,
						the use of our Website, and attending our seminars ; (b) information gathered through promotional
						activities; (c) cookies that your web browser store on your computer when you visit a website; (d)
						as applicable, publicly available or publicly accessible information; and (e) such other written or
						verbal communications or documents delivered to us prior to and during the course of our contractual
						or pre-contractual dealings with you.
					</SoftTypography>
					<SoftTypography>
						1.3. In certain situations, you may be providing us with Personal Data relating to other
						individuals, e.g. your employees, spouse, children, etc., such as when you register for a seminar on
						their behalf. Where you provide us with any Personal Data relating to a third party, you warrant and
						represent to us that you have obtained the consent of that third party to provide us with that
						party’s Personal Data in accordance with our Privacy Policy.
					</SoftTypography>
					<SoftTypography>
						1.4. You should ensure that all Personal Data submitted to us is complete, accurate, true and
						correct to the best of your knowledge. If you are a person under the age of 18, please ensure that
						your Personal Data is submitted by a person who is lawfully able to do so on your behalf.
					</SoftTypography>
					<SoftTypography>
						1.5. In relation to particular Products and Services or in your interactions with us, we may also
						have speciﬁcally notiﬁed you of other purposes for which we collect, use or disclose your Personal
						Data. Should you consent, we will collect, use and disclose your Personal Data for these additional
						purposes as well.
					</SoftTypography>
					<SoftTypography>
						<strong id="Purpose and Use of Personal Data">2. Purpose and Use of Personal Data</strong>
						<br />
					</SoftTypography>
					<SoftTypography>
						2.1. We may use your Personal Data to communicate with you such as by sending you messages, notices
						or information relating to your Account with us, any payment you have made for our seminars or other
						Products and Services, any conﬁrmation of or changes to details of our seminars or events that you
						have signed up for, any changes to our Privacy Policy, or other matters directly related to the
						purposes for which you have provided us your Personal Data.
					</SoftTypography>
					<SoftTypography>
						2.2. We may also use your personal data to keep you posted on our latest announcements, promotions,
						seminar updates and upcoming events by emails, telecommunications means (telephone calls, SMS
						messages or social chat applications) or internet social media. If you do not wish to receive such
						information, you can unsubscribe from our mailing list, change your preferences in your Account with
						us, or inform us in writing or via email. We will put this into effect within 10 business days.
					</SoftTypography>
					<SoftTypography>
						2.3. Where photographs or videos of our seminar or events are taken, these may be used for updates
						on our Website, on our Facebook page or sent to our subscribers.
					</SoftTypography>
					<SoftTypography>
						2.4. We will collect, use and store Personal Data fairly and lawfully and in accordance with this
						Privacy Policy. We will only collect Personal Data for legitimate and reasonable purposes, and use
						Personal Data for the purposes for which it was collected- which include but is not limited to
						Personal Data collected:
					</SoftTypography>
					<SoftTypography>
						(a) for you to enter into the necessary agreement and/or contract to purchase the products and/or
						services from us;
						<br />
						(b) user or customer relationship management procedures;
						<br />
						(c) processing of orders and payment clearing;
						<br />
						(d) those purposes speciﬁcally provided for in any particular service or product offered by us;
						<br />
						(e) credit assessments and other background checks of customers as we may determine to be necessary
						or appropriate;
						<br />
						(f) customising advertising and content so as to streamline the online experience of customers and
						to provide personalised marketing and promotional information;
						<br />
						(g) customer care and/or defect rectiﬁcation works; <br />
						(h) in order to respond to the inquiries of customers;
						<br />
						(i) membership registration and offering beneﬁts and privileges;
						<br />
						(j) conducting marketing, promotional and client proﬁling activities in connection with our Services
						and related Products for us, our vendors and business partners and other selected companies;
						<br />
						(k) use for internal purposes such as auditing, data analysis, and research to improveany Companies’
						Products, Services and customers’ communications;
						<br />
						(l) prevention or detection of crime, for the purposes of investigations, apprehension or
						prosecution of offenders, or assessment or collection of any tax or duty or other similar
						impositions; and
						<br />
						(m) meeting any legal or regulatory requirements relating to our provision of services and products
						and to make disclosure under any statutory legislations, court order, by-laws and relevant
						regulations applicable to us.
					</SoftTypography>
					<SoftTypography>
						<strong id="Disclosure of Personal Data">3. Disclosure of Personal Data</strong>
						<br />
						We generally do not disclose, share your Personal Data with any third parties (except where
						permitted or required by law), but you hereby consent and authorize use to provide and/or disclose
						your Personal Data to the following categories of parties:
					</SoftTypography>
					<SoftTypography>
						(a) any person to whom we are compelled or required to do so under law or in response to a
						legitimate instruction from a competent or government agency;(b) pursuant to an order of a court of
						competent jurisdiction;
						<br />
						(c) any Companies, including those established in the future, whether within or outside ofSingapore;
						<br />
						(d) where applicable, third parties who provide related services or products in connection with our
						business, whether within or outside of Singapore;
						<br />
						(e) government agencies, statutory authorities and industry regulators;
						<br />
						(f) to organisations including their professional service providers and relevant regulatory
						authorities whether within or outside Singapore in connection with the acquisition, disposition
						and/or intended acquisition or disposition of any of the Companies’ assets and/or of any of the
						Companies itself whether directly or indirectly;
						<br />
						(g) our auditors, consultants, accountants, lawyers or other ﬁnancial or professional advisers;
						and/or
						<br />
						(h) our sub-contractors or third party service or product providers as we may determine to be
						necessary or appropriate including without limitation, our mailing vendors, as well as vendors that
						carry out promotional operations on our behalf, whether within or outside of Singapore.
						<br />
						Please be rest assured that consistent with the law, we will only disclose the minimum amount of
						information which we deem necessary for the purpose and that we will take all appropriate safeguards
						to ensure the integrity and security of the personal data.
					</SoftTypography>
					<SoftTypography>
						<strong id="Cookies">4. Cookies</strong>
						<br />
						A cookie is a small piece of data that a website can store on your computer. We may use cookies on
						our Website for the purposes of <br />
						(i) enabling or facilitating certain features and functions of our Website in the provision of our
						online services to you, or <br />
						(ii) tracking the use of links between our Website and the websites of our partners or afﬁliates.{" "}
						<br />
						You may choose to conﬁgure your internet browser settings to manage or restrict the use of cookies,
						but this may result in delay or loss of functionality in the use of our Website.
					</SoftTypography>
					<SoftTypography>
						<strong id="Third Parties">5. Third Parties</strong>
						<br />
						We may also occasionally include or offer third party products, services or links on our Website.
						Each of these third parties/their sites has its own separate and independent privacy policy. We are
						not responsible for any such third parties, the content and activities of their sites, or any
						Personal Data that you may provide to them
					</SoftTypography>
					<SoftTypography>
						<strong id="Protection of Personal Data">6. Protection of Personal Data</strong>
						<br />
						We take all reasonable precautions, including administrative, technical and physical measures, to
						safeguard your Personal Data against accidental loss, theft, misuse, unlawful processing and damage
						as well as against unauthorised or accidental access, disclosure, alteration and destruction.
					</SoftTypography>
					<SoftTypography>
						<strong id="Access to, correction of and retention of Personal Data">7. Access to, correction of and retention of Personal Data</strong>
						<br />
						7.1 Subject to the exceptions referred to in section 21(2) of the Personal Data Protection Act 2012
						(No. 26 of 2012) of Singapore (“PDPA”), you have the right to request a copy of the information that
						we hold about you.
					</SoftTypography>
					<SoftTypography>
						7.2. Generally, you can keep your Personal Data accurate, complete and up to date by updating your
						details in your Account with us if you are a subscriber, or by contacting us as described at the
						beginning of this Privacy Policy.
					</SoftTypography>
					<SoftTypography>
						7.3. We will retain your Personal Data only for the period necessary to fulﬁl the purposes outlined
						in this Privacy Policy, unless a longer retention period is required or permitted by law.
					</SoftTypography>
					<SoftTypography>
						<strong id="General">8. General</strong>
						<br />
						8.1. If you wish to access, verify, correct, withdraw your consent or communicate with us on any
						matter relating to your Personal Data, please contact us as described at the beginning of this
						Privacy Policy.
					</SoftTypography>

					<SoftTypography>
						8.2. If you withdraw your consent to any or all use of your Personal Data, any existing contractual
						relationships that we have with you may be affected, including our not being able to continue
						providing our Products or Services to you or your being in breach of your contractual obligations to
						us. In any such event, our legal rights and remedies are expressly reserved.
					</SoftTypography>

					<SoftTypography>
						8.3. We reserve the right to add to, revise or delete any part or all of this Privacy Policy as
						permitted under the applicable laws of Singapore.
						<br />
						<br />
						Please read these Terms of Service carefully before using https://bullbearvector.com/ (&ldquo;the
						Site&rdquo;) operated by Core Biz Holdings Pte. Ltd. (&ldquo;BBV&rdquo;, &ldquo;us&rdquo;,
						&ldquo;we&rdquo; or &ldquo;our&rdquo;).&nbsp;
					</SoftTypography>

					<SoftTypography>
						These Terms of Service sets forth the legally binding terms and conditions for your use of the Site
						at https://bullbearvector.com/.&nbsp;
						<br />
						By accessing or using the Site in any manner, including, but not limited to, visiting or browsing
						the Site or contributing content or other materials to the Site or signing up as a member of the
						Site or a premium subscription service offered by the Site, you agree to be bound by these Terms of
						Service. Capitalised terms have the meaning given to them in these Terms of Service.
					</SoftTypography>

					<SoftTypography>
						<strong id="User Content">9. User Content</strong>
						<br />
						You are responsible for any content you post to the Site and for any consequences therof. The
						content you post will be able to be viewed by other users of the Site and through third party
						websites. You should only provide content that you are comfortable sharing with others. Your linking
						to any other websites is done at your own risk.
					</SoftTypography>

					<SoftTypography>
						<strong id="Right to monitor content.">10. Right to monitor content.&nbsp;</strong>
						<br />
						BBV reserves the right, in our sole discretion, to edit, refuse to publish or remove any content
						from the Site for any reason whatsoever, including but not limited to, that we believe part or all
						of the content:
						<br />
						(i) is in need of editing for grammar, spelling or overall readability;&nbsp;
						<br />
						(ii) is off topic;&nbsp;
						<br />
						(iii) is inaccurate;&nbsp;
						<br />
						(iv) is false, misleading or defamatory;&nbsp;
						<br />
						(v) is offensive, abusive or uncivil in tone;&nbsp;
						<br />
						(vi) is harmful, threatening or intimidating;&nbsp;
						<br />
						(vii) contains profanity, hate messages or personal attacks against others;&nbsp;
						<br />
						(viii) constitutes spam, phishing or any form of advertising or solicitation;&nbsp;
						<br />
						(ix) is used in conjunction with any automated or other means to artificially drive user traffic to
						the Site;&nbsp;
						<br />
						(x) disguises the origin of any user content transmitted to the Site;&nbsp;
						<br />
						(xi) impersonates another person or misrepresents your affiliation with a person or entity;&nbsp;
						<br />
						(xii) violates another person&rsquo;s or entity&rsquo;s intellectual property rights, publicity or
						privacy rights or other legal rights;&nbsp;
						<br />
						(xiii) contains software viruses or any other malicious code, files or programs which may interrupt,
						destroy or limit the functionality of computer software or hardware or telecommunications equipment;
						or&nbsp;
						<br />
						(xiv) is otherwise inappropriate or undesirable for the Site.
					</SoftTypography>

					<SoftTypography>
						Without affecting the generality of the foregoing, you shall NOT collect any other users&rsquo;
						information or content, or otherwise access this Site using automated means (including but not
						limited to harvesting bots, robots, spiders or scrapers) without our prior express written
						permission.
					</SoftTypography>

					<SoftTypography>
						<strong id="Code of conduct">11. Code of conduct</strong>
						<br />
						Users are expected to observe BBV&rsquo;s community guidelines at
						https://www.investingnote.com/posts/39956. A failure to observe the community guidelines may result
						in termination and/or legal action against the relevant user.
					</SoftTypography>

					<SoftTypography>
						<strong id="Disclaimer">12. Disclaimer&nbsp;</strong>
						<br />
						You understand that all postings, messages and other content posted on the Site whether publicly
						posted or privately transmitted, is the sole responsibility of the person who originated such
						content.
					</SoftTypography>

					<SoftTypography>
						BBV may not monitor or control the content posted on the Site and we cannot take responsibility for
						such content. Any use or reliance on any content or materials posted on the Site is at your own
						risk. All information provided are for informational purposes only, not intended for trading
						purposes or advice. Neither BBV, its affiliates nor any of the independent providers of content or
						third party suppliers to this Site (collectively, &ldquo;Our Contributors&rdquo;) is liable for any
						informational errors, incompleteness, or delays, or for any actions taken in reliance on information
						contained herein.
					</SoftTypography>

					<SoftTypography>
						INFORMATION CONTAINED ON THIS SITE HAS BEEN PREPARED BY OUR CONTRIBUTORS AS A CONVENIENCE TO
						BBV&rsquo;S USERS AND IS NOT INTENDED TO CONSTITUTE ADVICE OR RECOMMENDATIONS UPON WHICH A USER MAY
						RELY. NOTHING ON THIS SITE CONSTITUTES LEGAL, TAX, FINANCIAL OR OTHER PROFESSIONAL OR SPECIALIST
						ADVICE. BBV MAKES NO WARRANTY OR GUARANTEE ABOUT THE ACCURACY, COMPLETENESS, OR ADEQUACY OF THE
						INFORMATION CONTAINED IN OR LINKED TO THIS WEB SITE OR ANY OTHER WEB SITE MAINTAINED BY THE OWNER.
						USERS RELYING ON INFORMATION FROM THIS WEB SITE DO SO AT THEIR OWN RISK.THE INFORMATION AND
						DESCRIPTIONS CONTAINED HEREIN ARE NOT INTENDED TO BE COMPLETE DESCRIPTIONS OF THE TERMS, EXCLUSIONS
						AND CONDITIONS APPLICABLE TO THE FINANCIAL PRODUCTS, EQUITIES OR FIXED-INCOME / DEBT INSTRUMENTS,
						BUT ARE PROVIDED SOLELY FOR GENERAL INFORMATIONAL PURPOSES.
						<br />
						THIS SITE MAY PROVIDE YOU WITH PLANNING AND EDUCATIONAL TOOLS, INCLUDING EDUCATIONAL MATERIALS,
						COURSES AND ANALYSES. SUCH TOOLS, MATERIALS, COURSES AND ANALYSES MAY ALLOW YOU TO MODEL
						&ldquo;WHAT-IF&rdquo; SCENARIOS, THE RESULTS OF WHICH ARE ILLUSTRATIVE AND ARE BASED ON THE
						INFORMATION AND ASSUMPTIONS IDENTIFIED. THERE IS NO GUARANTEE THAT THE RESULTS SHOWN ARE NECESSARILY
						ACCURATE OR WILL BE ACHIEVED.
						<br />
						THIS SITE MAY FROM TIME TO TIME CONTAIN FORWARD-LOOKING STATEMENTS. ANY WRITTEN OR ORAL STATEMENTS
						MADE BY OR ON BEHALF OF BBV, ANY OF ITS SUBSIDIARIES OR ANY CONTRIBUTORS TO THE SITE REFLECT CURRENT
						VIEWS WITH RESPECT TO FUTURE EVENTS AND FINANCIAL PERFORMANCE. THESE FORWARD-LOOKING STATEMENTS ARE
						SUBJECT TO UNCERTAINTIES AND INHERENT RISKS THAT COULD CAUSE ACTUAL RESULTS TO DIFFER MATERIALLY
						FROM THOSE CONTAINED IN ANY FORWARD-LOOKING STATEMENT.
					</SoftTypography>

					<SoftTypography>
						YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. ALL CONTENT AND SOFTWARE ARE PROVIDED ON AN &ldquo;AS
						IS&rdquo; OR &ldquo;AS AVAILABLE&rdquo; BASIS, AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, OUR
						CONTRIBUTORS (INCLUDING BBV) EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND WHETHER
						EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
						PARTICULAR PURPOSE. BBV MAKES NO WARRANTY THAT THE CONTENT AND SOFTWARE ARE ACCURATE, TIMELY,
						UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE, OR THAT ANY SUCH PROBLEMS WILL BE CORRECTED.
					</SoftTypography>

					<SoftTypography>
						<strong id="Links to other sites">13. Links to other sites</strong>
						<br />
						Our Site may contain links to third-party sites that are not owned or controlled by BBV.
					</SoftTypography>

					<SoftTypography>
						BBV has no control over, and assumes no responsibility for, the content, privacy policies, or
						practices of any third party sites or services. We strongly advise you to read the terms and
						conditions and privacy policy of any third-party site that you visit.
						<br />
						<br />
						<strong id="Intellectual property">14. Intellectual property&nbsp;</strong>
						<br />
						BBV respects the intellectual property rights of others and expects users of the Services to do the
						same. We will respond to notices of alleged copyright infringement that comply with applicable law
						and are properly provided to us. If you believe that your content has been copied in a way that
						constitutes copyright infringement, please provide us with the following information:
					</SoftTypography>

					<SoftTypography>
						A physical or electronic signature of the copyright owner or a person authorised to act on their
						behalf of identification of the copyrighted work claimed to have been infringed-identification of
						the material that is claimed to be infringing or to be the subject of infringing activity and that
						is to be removed or access to which is to be disabled, and information reasonably sufficient to
						permit us to locate the material-your contact information, including your address, telephone number,
						and an email addressstatement by you that you have a good faith belief that use of the material in
						the manner complained of is not authorised by the copyright owner, its agent, or the law a statement
						that the information in the notification is accurate, and, under penalty of perjury, that you are
						authorised to act on behalf of the copyright owner.We reserve the right to remove content alleged to
						be infringing without prior notice, at our sole discretion, and without liability to you. In
						appropriate circumstances, BBV will also terminate a user&rsquo;s account if the user is determined
						to be a repeat infringer.
					</SoftTypography>

					<SoftTypography>
						Except as otherwise expressly stated, all content and software appearing on this Site (and their
						respective compilation) are the copyrighted work of Our Contributors and are protected by local and
						international copyright laws. You may NOT download information from this Site save for your personal
						use provided that you keep intact and do not remove or alter any copyright or other notice (e.g.,
						trademark, patent, etc.) contained in the information. Except as otherwise expressly stated herein,
						you may not alter, modify, copy, distribute (for compensation or otherwise), transmit, display,
						perform, reproduce, reuse, post, publish, license, frame, download, store for subsequent use, create
						derivative works from, transfer, or sell any information or content obtained from this Site
						(&ldquo;Content&rdquo;), in whole or in part, including any text, images, audio, and video in any
						manner, without the prior written authorisation of Our Contributors. The use of Content, including
						images, by you, or anyone else authorised by you, is prohibited unless specifically permitted by Our
						Contributors. Any unauthorised use of text or images may violate copyright laws, trademark laws, the
						laws of privacy and publicity, and applicable regulations and statutes. Neither BBV nor any of Our
						Contributors warrant or represent that your use of Content or any other materials displayed on this
						Site will not infringe rights of third parties. For Content that is covered by intellectual property
						rights, like photos and videos (&ldquo;IP Content&rdquo;), you expressly grant us a non-exclusive,
						transferable, sub-licensable, royalty-free, worldwide license to use any IP Content that you post on
						or in connection with the Site (&ldquo;IP License&rdquo;). This IP License ends when you delete your
						IP Content or your account unless such IP Content has been shared with others, and they have not
						deleted it (in which case, the IP License continues to remain effective).
					</SoftTypography>

					<SoftTypography>
						<strong id="Termination">15. Termination&nbsp;</strong>
						<br />
						We may terminate your access to the Site, without cause or notice, which may result in the
						forfeiture and destruction of all information associated with you.
					</SoftTypography>

					<SoftTypography>
						All provisions of these Terms of Service that by their nature should survive termination shall
						survive termination, including, without limitation, ownership provisions, warranty disclaimers,
						indemnity, and limitations of liability.15.&nbsp;
					</SoftTypography>

					<SoftTypography>
						<strong id="Limitation of liability">16. Limitation of liability</strong>
						<br />
						SUBJECT TO APPLICABLE LAW, IN NO EVENT SHALL BBV NOR ANY OF OUR CONTRIBUTORS BE LIABLE FOR ANY
						INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST
						PROFITS OR LOST DATA, USE, GOOD-WILL OR OTHER INTANGIBLE LOSSES, ARISING FROM YOUR USE OF THE SITE,
						OR ANY OF THE CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE SITE, EVEN IF
						BBV HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES, RESULTING FROM YOUR USE OR
						INABILITY TO USE THIS SITE OR ITS HOSTED PRODUCTS AND SERVICES, CONTENT OR SOFTWARE.
					</SoftTypography>

					<SoftTypography>
						YOU UNDERSTAND AND AGREE THAT YOUR USE OF THIS SITE IS SUBJECT TO YOUR WAIVER OF ANY RIGHT TO SUE
						BBV OR ANY OF OUR CONTRIBUTORS DIRECTLY OR TO PARTICIPATE IN ANY CLASS ACTION SUIT FOR ANY LOSSES OR
						DAMAGES RESULTING FROM YOUR USE OF THIS SITE. NOTHING IN THIS TERMS OF USE OPERATES TO LIMIT ANY
						LIABILITY OF BBV ARISING FROM (I) DEATH OR PERSONAL INJURY CAUSED BY OR ARISING FROM NEGLIGENCE AND
						(II) FRAUD.
					</SoftTypography>

					<SoftTypography>
						<strong id="Data protection and Privacy Policy">17. Data protection and Privacy Policy&nbsp;</strong>
						<br />
						Our data protection and privacy policy (&ldquo;Privacy Policy&rdquo;) describes BBV&rsquo;s
						collection, use and protection of personal data and will help you understand how we secure and treat
						customer information that we obtain. We urge you to read our Privacy Policy found at link. Your
						submission of any personal data to BBV in connection with this Site constitutes your consent to the
						use, disclosure, collection and processing of personal data by BBV and/or its affiliates that are
						consistent with the Privacy Policy. Some information is collected automatically when you visit the
						Site because your IP address needs to be recognised by the server. We may use your IP address
						information to monitor and analyse how parts of the Site is being used.
					</SoftTypography>

					<SoftTypography>
						<strong id="Electronic Communications">18. Electronic Communications</strong>
						<br />
						When you visit this Site or send e-mails to us, you are communicating with us electronically. You
						consent to receive communications from us electronically. We will communicate with you by e-mail or
						by posting notices on this site. You agree that all agreements, notices, disclosures and other
						communications that we provide to you electronically satisfy any legal requirement that such
						communications be in writing.
					</SoftTypography>

					<SoftTypography>
						<strong id="Password Account, password and security">19. Password Account, password and security</strong>
						<br />
						If you have been given the option to open an account on this Site which will provide you with access
						to password-protected portions of the Site and you elect to do so, you must complete the
						registration process by providing us with current, complete and accurate information as prompted by
						the applicable registration form, and choose a password and user name. You are entirely responsible
						for maintaining the confidentiality of your password and account information and for any and all
						activities that occur under your account.
					</SoftTypography>

					<SoftTypography>
						<strong id="General Provisions">20. General Provisions&nbsp;</strong>
						<br />
						Entire Agreement &ndash; These Terms of Use, the Privacy Policy, and other policies we may post on
						this Site constitute the entire agreement between BBV and you in connection with your use of this
						Site and its content, services and software and supersedes any prior agreements between BBV and you
						regarding use of this Site, including prior versions of these Terms of Use. These Terms of Use will
						be deemed to include all other notices, policies, disclaimers and other terms contained in this
						Site; provided, however, that in the event of a conflict between such other terms and the terms of
						these Terms of Use, these Terms of Use will prevail.
					</SoftTypography>

					<SoftTypography>
						Age and Location of User &ndash; All information and content available on this Site are solely
						directed to individuals 18 years of age or older.
					</SoftTypography>

					<SoftTypography>
						<strong id="Governing Law">21. Governing Law</strong>
						<br />
						These Terms of Service (and any further rules, polices, or guidelines incorporated by reference)
						shall be governed and construed in accordance with the laws of Singapore, without giving effect to
						any principles of conflict of law. If any provision is deemed by a court of competent jurisdiction
						to be unlawful or unenforceable, it will not affect the validity and enforceability of the remaining
						provisions.
					</SoftTypography>

					<SoftTypography>
						<strong id="Changes and updates to these term of service">22. Changes and updates to these terms of service&nbsp;</strong>
						<br />
						We reserve the right, at our sole discretion, to modify or replace these Terms of Service by posting
						the updated terms on the Site. Your continued use of the Site after any such changes constitutes
						your acceptance of the new Terms of Service.
					</SoftTypography>

					<SoftTypography>
						Please review these Terms of Service periodically for changes. If you do not agree to any provision
						of these Terms of Service or any changes to these Terms of Service, do not use or access or continue
						to use or access the Site immediately.
					</SoftTypography>
				</SoftBox>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
};

export default Privacy;
