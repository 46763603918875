/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftBadgeDot from "components/SoftBadgeDot";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "examples/Tables/Table";
import DataTable from "examples/Tables/DataTable";

// Reports page components
import Reviews from "layouts/pages/users/reports/components/Reviews";

// Data
import tableData from "layouts/pages/users/reports/data/tableData";
import { fetchUsers } from "services/user";

function Reports() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [rows, setRows] = useState([]);

	const handleEditPlan = (user) => {
		console.log(user)
		navigate(`/admin/users/${user.id}/update-plan`);
	}

	const columns = [
		{ Header: "Name", align: "left", accessor: "name" },
		{ Header: "Email", align: "left", accessor: "email" },
		{ Header: "Email Verified", align: "center", accessor: "email_verified" },
		{ Header: "Plan", align: "left", accessor: "plan" },
		{ Header: "Created", align: "left", accessor: "created", type: "date" },
		{
			Header: "Actions",
			accessor: "action_buttons",
			align: "right",
			buttons: [
				{ text: "Edit Plan", action: handleEditPlan, color: "info" },
			],
		},
	];

	useEffect(() => {
		fetchUsers().then(resp => {
			const users = [];
			resp.map(user => {
				users.push({
					id: user.id,
					name: user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : "-",
					email: user.email,
					email_verified: (
						<SoftBox ml={-1.325}>
							<SoftBadgeDot
								size="xs"
								color={user.email_verified ? "success" : "error"}
								badgeContent={user.email_verified ? "YES" : "NO"}
							/>
						</SoftBox>
					),
					plan: user.plan ? user.plan.name : "Basic",
					created: user.created_at ?? '10/03/2023',
				});
			});
			setRows(users);
			setIsLoading(false);
		});
	}, []);

	// ComplexStatisticsCard dropdown menu state
	const [usersActiveMenu, setUsersActiveMenu] = useState(null);
	const [clickEventsMenu, setClickEventsMenu] = useState(null);
	const [purchasesMenu, setPurchasesMenu] = useState(null);
	const [likesMenu, setLikesMenu] = useState(null);

	// ComplexStatisticsCard dropdown menu handlers
	const openUsersActiveMenu = event => setUsersActiveMenu(event.currentTarget);
	const closeUsersActiveMenu = () => setUsersActiveMenu(null);
	const openClickEventsMenu = event => setClickEventsMenu(event.currentTarget);
	const closeClickEventsMenu = () => setClickEventsMenu(null);
	const openPurchasesMenu = event => setPurchasesMenu(event.currentTarget);
	const closePurchasesMenu = () => setPurchasesMenu(null);
	const openLikesMenu = event => setLikesMenu(event.currentTarget);
	const closeLikesMenu = () => setLikesMenu(null);

	// Dropdown menu template for the ComplexProjectCard
	const renderMenu = (state, close) => (
		<Menu
			anchorEl={state}
			anchorOrigin={{ vertical: "top", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={Boolean(state)}
			onClose={close}
			keepMounted
		>
			<MenuItem onClick={close}>Action</MenuItem>
			<MenuItem onClick={close}>Another action</MenuItem>
			<MenuItem onClick={close}>Something else here</MenuItem>
		</Menu>
	);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox pb={3}>
				<SoftBox mb={3}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6} md={3}>
									<ComplexStatisticsCard
										icon="account_circle"
										count={{ number: 1600, label: "users active" }}
										percentage="+55%"
										dropdown={{
											action: openUsersActiveMenu,
											menu: renderMenu(usersActiveMenu, closeUsersActiveMenu),
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={3}>
									<ComplexStatisticsCard
										icon="touch_app"
										count={{ number: 357, label: "click events" }}
										percentage="+124%"
										dropdown={{
											action: openClickEventsMenu,
											menu: renderMenu(clickEventsMenu, closeClickEventsMenu),
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={3}>
									<ComplexStatisticsCard
										icon="shopping_cart"
										count={{ number: 2300, label: "purchases" }}
										percentage="+55%"
										dropdown={{
											action: openPurchasesMenu,
											menu: renderMenu(purchasesMenu, closePurchasesMenu),
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={3}>
									<ComplexStatisticsCard
										icon="thumb_up"
										count={{ number: 940, label: "likes" }}
										percentage="+90%"
										dropdown={{
											action: openLikesMenu,
											menu: renderMenu(likesMenu, closeLikesMenu),
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						{/* <Grid item xs={12} md={6}>
              <Reviews />
            </Grid> */}
					</Grid>
				</SoftBox>
				{isLoading ? (
					<SoftBox style={{ display: "flex", justifyContent: "center" }}>
						<CircularProgress color="inherit" />
					</SoftBox>
				) : (
					<DataTable fullData={rows} optionType="" table={{columns, rows}} loading={isLoading} canSearch />
				)}
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
}

export default Reports;
