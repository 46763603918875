import { ContractFactory, Contract, BigNumber, providers } from "ethers";
import { web3provider } from "./Web3Connection";

function getContractCodes(contractType) {
	const compiledToken721 = require("contracts/Token721.json");
	const compiledToken1155 = null; //require("build/contracts/Token1155.json");

	const compiledToken20 = require("contracts/ERC20.json");
	const compiledMarketplace = require("contracts/Marketplace.json");

	switch (contractType) {
		// case 1155:
		// 	return compiledToken1155;
		case 20:
			return compiledToken20;
		case "marketplace":
			return compiledMarketplace;
		// case "auction":
		// 	const compileAuction = require("src/contracts/Auction.json");
		// 	return compileAuction;
	}

	return compiledToken721;
}

async function getValueInContractDecimals(amount, ERC20ContractAddress) {
	try {
		const compiledERC20Contract = getContractCodes(20);

		const ERC20Contract = new Contract(ERC20ContractAddress, compiledERC20Contract.abi, web3provider);
		let decimals = await ERC20Contract.decimals();

		if (!decimals) decimals = 18;

		return parseFloat(amount) * Math.pow(10, parseInt(decimals)) + "";
	} catch (e) {
		console.log(e);
	}
}

export const deploy = async (contractType, deployArgs) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		try {
			const compiledContract = getContractCodes(contractType);
			const signer = web3provider.getSigner();

			const factory = new ContractFactory(compiledContract.abi, compiledContract.bytecode, signer);

			// If your contract requires constructor args, you can specify them here
			const contract = await factory.deploy(...deployArgs);

			if (contract?.address) resolve(contract.address);
			else reject("Unable to deploy contract");
		} catch (e) {
			console.log(e);
			reject("Error: " + e);
		}
	});

export const fetchCollectionInfo = async (contractType, chainID, contractAddress) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		try {
			const compiledContract = getContractCodes(contractType);
			const provider = new providers.Web3Provider(window.ethereum, chainID);

			const contract = new Contract(contractAddress, compiledContract.abi, provider);
			const totalSupply = await contract.totalSupply();
			const name = await contract.name();
			const symbol = await contract.symbol();
			const owner = await contract.owner();

			const tokens = [];

			if (totalSupply > 0) {
				for (let i = 0; i <= totalSupply; i++) {
					let tokenURI = null;
					try {
						tokenURI = await contract.tokenURI(i);
					} catch (e) {}
					if (tokenURI) {
						const tokenOwner = await contract.ownerOf(i);

						tokens.push({
							tokenID: i,
							metadataURL: tokenURI,
							minter: owner,
							owner: tokenOwner,
						});
					}
				}
			}

			resolve({ name, symbol, owner, chainID, contractType, tokens });
		} catch (e) {
			console.log(e);
			reject();
		}
	});

export const mint = async (contractType, contractAddress) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		try {
			const compiledContract = getContractCodes(contractType);
			const signer = web3provider.getSigner();

			const contract = new Contract(contractAddress, compiledContract.abi, signer);
			const tx = await contract.mint(1);
			await tx.wait();

			const totalSupply = await contract.totalSupply();

			if (totalSupply) resolve(BigNumber.from(totalSupply).toNumber());
			else reject("Unable to mint a token");
		} catch (e) {
			console.log(e);
			reject("Error: " + e);
		}
	});

export const list = async (contractType, contractAddress, tokenID, price, ERC20ContractAddress) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		try {
			const compiledMarketplaceContract = getContractCodes("marketplace");

			const signer = web3provider.getSigner();

			const marketplaceContract = new Contract(
				process.env.REACT_APP_MARKETPLACE_CONTRACT,
				compiledMarketplaceContract.abi,
				signer
			);

			const amountInTokenDecimals = await getValueInContractDecimals(price, ERC20ContractAddress);

			// for contractType === 721
			const tx = await marketplaceContract.sellERC721NFT(
				contractAddress,
				tokenID,
				BigNumber.from(amountInTokenDecimals).toString(),
				0,
				ERC20ContractAddress
			);
			await tx.wait();

			resolve();
		} catch (e) {
			console.log(e);
			reject("Error: " + e);
		}
	});

export const buy = async (contractType, contractAddress, tokenID, price, ERC20ContractAddress) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		try {
			const compiledMarketplaceContract = getContractCodes("marketplace");

			const signer = web3provider.getSigner();

			const marketplaceContract = new Contract(
				process.env.REACT_APP_MARKETPLACE_CONTRACT,
				compiledMarketplaceContract.abi,
				signer
			);

			const amountInTokenDecimals = await getValueInContractDecimals(price, ERC20ContractAddress);

			// for contractType === 721
			const tx = await marketplaceContract.buyERC721NFT(
				contractAddress,
				tokenID,
				BigNumber.from(amountInTokenDecimals).toString(),
				0,
				ERC20ContractAddress
			);
			await tx.wait();

			resolve();
		} catch (e) {
			console.log(e);
			reject("Error: " + e);
		}
	});

export const markApprovedForAll = (contractType, contractAddress, marketplaceAddress) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(contractType);
		const signer = web3provider.getSigner();

		const contract = new Contract(contractAddress, compiledContract.abi, signer);

		const wallet_address = await signer.getAddress();

		//check if already approved for all
		try {
			const approved = await contract.isApprovedForAll(wallet_address, marketplaceAddress);

			if (approved) {
				resolve();
				return;
			}
		} catch (e) {
			console.log("error", e);
		}

		try {
			const tx = await contract.setApprovalForAll(marketplaceAddress, true);
			await tx.wait();

			resolve();
		} catch (e) {
			reject(e);
		}
	});

export const approveFunds = (amount, ERC20ContractAddress) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(20);
		const signer = web3provider.getSigner();

		if (!ERC20ContractAddress) {
			reject("Could not find currency on this Network");
			return;
		}

		const contract = new Contract(ERC20ContractAddress, compiledContract.abi, signer);
		const amountInTokenDecimals = await getValueInContractDecimals(amount, ERC20ContractAddress);

		const wallet_address = await signer.getAddress();

		const payableTo = process.env.REACT_APP_MARKETPLACE_CONTRACT;

		// check if this is already approved
		const approvedFunds = await contract.allowance(wallet_address, payableTo);
		if (approvedFunds && BigNumber.from(approvedFunds).gte(BigNumber.from(amountInTokenDecimals))) {
			resolve(true);
			return;
		}

		try {
			const tx = await contract.approve(payableTo, BigNumber.from(amountInTokenDecimals).toString());
			await tx.wait();

			resolve(true);
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});

export const gotSufficientBalance = (amountRequired, ERC20ContractAddress) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getContractCodes(20);
		const signer = web3provider.getSigner();

		if (!ERC20ContractAddress) {
			reject("Could not find currency on this Network");
			return;
		}

		const contract = new Contract(ERC20ContractAddress, compiledContract.abi, signer);
		const amountInTokenDecimals = await getValueInContractDecimals(amountRequired, ERC20ContractAddress);
		const wallet_address = await signer.getAddress();

		try {
			const walletBalance = await contract.balanceOf(wallet_address);
			if (walletBalance && walletBalance >= amountInTokenDecimals) {
				resolve(true);
			} else {
				resolve(false);
			}
		} catch (e) {
			reject(e);
		}
	});
