import axios from "axios";
import { get, now } from "lodash";

const BASE_URL = process.env.REACT_APP_API_BASE
// const BASE_URL = "https://api-dot-coreinvest.df.r.appspot.com";

const axiosDefault = () => axios.create({
	baseURL: BASE_URL,
	headers: {
		Authorization: `Bearer ${localStorage.getItem("access_token")}`
	},
});

export const axiosOldEndpoint = axios.create({
	baseURL: "https://bullbearvectorflex.df.r.appspot.com",
	headers: { "Content-Type": "application/json" },
	withCredentials: true,
});

export const axiosPrivate = axios.create({
	baseURL: BASE_URL,
	headers: { "Content-Type": "application/json" },
	withCredentials: true,
});

// can be used for listing API to cache the response for 10 mins
export const axiosWithCache = async (url, key = "data.data", dataKey = "") => {
	// check if the URL is in cache

	const data = localStorage.getItem(`${encodeURIComponent(url)}`);
	if (data) {
		const response = JSON.parse(data);

		// cache for 10 mins only
		if (response.cachedAt > now() - 600000) return response.data;
	}

	try {
		const response = await axiosDefault().get(url);

		const formattedData = get(response, dataKey !== "" ? dataKey : key, []);
		const formattedResonse = get(response, key, []);
		if (formattedData.length > 0)
			localStorage.setItem(
				`${encodeURIComponent(url)}`,
				JSON.stringify({ cachedAt: now(), data: formattedResonse })
			);
		return formattedResonse;
	} catch (error) {
		console.log("error in retreiving", url, error);
	}

	return [];
};

export default axiosDefault;
