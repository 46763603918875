import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWeb3 } from "context/web3";
import { setErrorMessage, useSoftUIController } from "context";
import TokenForm from "components/Marketplace/TokenForm";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import { createCollectionToken, uploadContractMeta, setCollectionTokenMinted, copyContractTokenMeta } from "services/marketplace";
import { mint } from "plugins/Ethereum";
import SoftProcessing from "components/SoftProcessing";
import { fetchCollection } from "services/marketplace";
// import { BigNumber } from "ethers";

export default () => {
	const { web3Controller } = useWeb3();
	const [controller, dispatch] = useSoftUIController();

	const [isLoading, setIsLoading] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [collection, setCollection] = useState(null);

	const { connected: connectedToBlockchain } = web3Controller;

	const { collectionID } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!connectedToBlockchain) {
			navigate("/admin/marketplace/collections", { replace: true });
		}
	}, [connectedToBlockchain]);

	useEffect(() => {
		loadCollectionData();
	}, [collectionID]);

	const loadCollectionData = async () => {
		const data = await fetchCollection(collectionID);
		if (data?.id) {
			setCollection(data);
		} else {
			navigate("/dashboard/error/404", { replace: true });
		}
		setIsLoading(false);
	};

	const handleMintToken = async tokenData => {
		setProcessing(true);
		// create contract meta JSON
		const tokenMeta = {
			name: tokenData.name,
			description: tokenData.description,
			image: "ipfs://" + tokenData.thumbnail,
			external_link: tokenData.external_link,
			attributes: tokenData.properties.map(item => ({
				trait_type: item.name,
				value: item.value,
			})),
			symbol: collection.symbol,
			compiler: collection.name,
		};

		try {
			const tokenMetaUploaded = await uploadContractMeta(tokenMeta);
			if (tokenMetaUploaded) {
				const created = await createCollectionToken(collectionID, {
					minter: tokenData.owner,
					owner: tokenData.owner,
					thumbnail: "ipfs://" + tokenData.thumbnail,
					metadataURL: "ipfs://" + tokenMetaUploaded,
					metadataCache: tokenMeta,
				});
				if (created?.id) {
					// mint through metamask
					mint(collection.contractType, collection.contractAddress)
						.then(async tokenID => {
							await setCollectionTokenMinted(collectionID, created.id, {
								tokenID,
							});
							await copyContractTokenMeta(collectionID, collection.symbol, tokenID, tokenMetaUploaded)
							
							navigate(`/admin/marketplace/collections/${collectionID}/tokens`);
						})
						.catch(() => {
							setErrorMessage(dispatch, "Sorry! Not able to mint this token. Please try again");
						})
						.finally(() => {
							setProcessing(false);
						});

					return;
				} else {
					setProcessing(false);
				}
			} else {
				setErrorMessage(dispatch, "Sorry! Not able to mint this token. Please try again");
				setProcessing(false);
			}
		} catch (e) {
			setProcessing(false);
		}

		setErrorMessage(dispatch, "Sorry! Not able to mint this token. Please try again");
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{isLoading ? (
				<SoftProcessing overlay={false} />
			) : (
				<SoftBox pb={3}>
					<TokenForm collection={collection} onCreate={handleMintToken} />
					{processing && <SoftProcessing />}
				</SoftBox>
			)}
			<Footer />
		</DashboardLayout>
	);
};
