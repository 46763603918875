import Swal from "sweetalert2";

export const showConfirmationAlert = ({
	title,
	text,
	confirmButtonText = "Yes",
	cancelButtonText = "No",
	successTitle,
	successMessage,
	onConfirm,
}) => {
	const newSwal = Swal.mixin({
		customClass: {
			confirmButton: "button button-error",
			cancelButton: "button button-success",
		},
		buttonsStyling: false,
	});

	newSwal
		.fire({
			title,
			text,
			showCancelButton: true,
			confirmButtonText,
			cancelButtonText,
		})
		.then(result => {
			if (result.value) {
				successTitle && successMessage && newSwal.fire(successTitle, successMessage, "success");
				onConfirm && onConfirm();
			}
		});
};
