/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const selectData = {
	gender: [
		{ value: "male", label: "Male" },
		{ value: "female", label: "Female" },
	],
	maritalStatus: [
		{ value: "married", label: "Married" },
		{ value: "single", label: "Single" },
	],
	birthDate: [
		{ value: 1, label: "January" },
		{ value: 2, label: "February" },
		{ value: 3, label: "March" },
		{ value: 4, label: "April" },
		{ value: 5, label: "May" },
		{ value: 6, label: "June" },
		{ value: 7, label: "July" },
		{ value: 8, label: "August" },
		{ value: 9, label: "September" },
		{ value: 10, label: "October" },
		{ value: 11, label: "November" },
		{ value: 12, label: "December" },
	],
	days: [
		{ value: 1, label: 1 },
		{ value: 2, label: 2 },
		{ value: 3, label: 3 },
		{ value: 4, label: 4 },
		{ value: 5, label: 5 },
		{ value: 6, label: 6 },
		{ value: 7, label: 7 },
		{ value: 8, label: 8 },
		{ value: 9, label: 9 },
		{ value: 10, label: 10 },
		{ value: 11, label: 11 },
		{ value: 12, label: 12 },
		{ value: 13, label: 13 },
		{ value: 14, label: 14 },
		{ value: 15, label: 15 },
		{ value: 16, label: 16 },
		{ value: 17, label: 17 },
		{ value: 18, label: 18 },
		{ value: 19, label: 19 },
		{ value: 20, label: 20 },
		{ value: 21, label: 21 },
		{ value: 22, label: 22 },
		{ value: 23, label: 23 },
		{ value: 24, label: 24 },
		{ value: 25, label: 25 },
		{ value: 26, label: 26 },
		{ value: 27, label: 27 },
		{ value: 28, label: 28 },
		{ value: 29, label: 29 },
		{ value: 30, label: 30 },
		{ value: 31, label: 31 },
	],
	years: [],
};

const d = new Date();
console.log(d.getFullYear() - 18);
for (let i = 1970; i <= d.getFullYear() - 18; i++) {
	selectData.years.push({ value: i, label: i });
}

export default selectData;
