/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import AppBar from "@mui/material/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { map as pluck } from "lodash";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import { fetchPlans } from "services/plan";
import useAuth from "layouts/authentication/hooks/useAuth";
import useAxiosPrivate from "layouts/authentication/hooks/useAxiosPrivate";
import SoftDialog from "components/SoftDialog";
import StripeCardInfo from "components/etc/StripeCardInfo";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function PricingCards({ currentPlanID, currentPlanLevel, isPopup, forStocks }) {
	const [tabValue, setTabValue] = useState(0);
	const [plans, setPlans] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isPreparingPayment, setIsPreparingPayment] = useState(false);
	const [isPaymentPopupVisible, setIsPaymentPopupVisible] = useState(false);

	const clientSecret = useRef(null);

	const { auth } = useAuth();
	const axiosPrivate = useAxiosPrivate();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		// console.log("if stocks bewlow")
		// console.log(forStocks)
		fetchPlans().then(data => {
			if (data && data.length > 0) {
				setPlans(data);

				//filter the data to pricing card that you want to show
				if (isPopup) {
					if (forStocks.forStocks) {
						let newPlans = data.filter(plan => {
							return plan.name === "PRO Stocks";
						});
						setPlans(newPlans);
					} else {
						//options popup
						let newPlans = data.filter(plan => {
							return plan.name === "PRO Options +";
						});
						setPlans(newPlans);
					}
				}
			}
			setIsLoading(false);
		});
	}, []);

	const handleSetTabValue = (event, newValue) => {
		setTabValue(parseInt(newValue));
	};

	const getLowerPlanSpecifications = currentPlan => {
		if (currentPlan.level <= 1) return [];

		const lowerPlans = plans.filter(plan => plan.level < currentPlan.level);
		const planNames = pluck(lowerPlans, "name");

		return planNames;
	};

	const cancelPayment = () => {
		setIsPaymentPopupVisible(false);
	};

	const join = async (planID, planLevel) => {
		//check if user already logged in
		//redirect to login disabled for now
		if (auth?.access_token) {
			if (planLevel < auth.plan?.level) {
				if (!window.confirm("Are you sure you want to downgrade your subscription?")) return;
			}

			setIsPreparingPayment(true);

			try {
				// get the payment URL
				// const response = await axiosPrivate.post(
				// 	"/payment/plan_checkout_url",
				// 	{ plan_id: planID, subscription_mode: tabValue === 1 ? "annual" : "monthly" },
				// 	{
				// 		withCredentials: true,
				// 	}
				// );

				const response = await axiosPrivate.post(
					"/payment/stripe/getClientSecret",
					{ plan_id: planID, subscription_mode: tabValue === 1 ? "annual" : "monthly" },
					{
						withCredentials: true,
					}
				);

				if (!response.data?.success) {
					return;
				}

				if (response.data?.payment_url) {
					window.location.replace(response.data.payment_url);
				}

				clientSecret.current = response.data.client_secret;

				// stripe, get the payment intent
				setIsPaymentPopupVisible(true);
				setIsPreparingPayment(false);

				// if (response.data?.success) {
				// 	window.location.replace(response.data.payment_url);
				// }

				return;
			} catch (e) {
				setIsPreparingPayment(false);
			}
		}

		navigate("/dashboard/authentication/sign-in", {
			// state: { from: { ...location, pathname: location.pathname + "/join/" + planID } },
			state: { from: location },
			replace: true,
		});
	};

	const handlePayment = async paymentData => {
		// const clientSecret = await getClientSecret();
		// const paymentResult = await stripe.confirmCardPayment(clientSecret, {
		// 	payment_method: {
		// 		...paymentData,
		// 		billing_details: {
		// 			name: auth.first_name,
		// 		},
		// 	},
		// });
		console.log(paymentData);
	};

	if (isLoading)
		return (
			<SoftBox px={{ display: "flex", justifyContent: "center" }}>
				<CircularProgress color="inherit" />
			</SoftBox>
		);

	return (
		<SoftBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
			{!isPopup && (
				<Grid container item xs={12} sm={6} lg={3} sx={{ mx: "auto", justifyContent: "center" }}>
					<SoftBox width="100%" my={6}>
						<AppBar position="static">
							<Tabs value={tabValue} onChange={handleSetTabValue} sx={{ maxWidth: "250px", mx: "auto" }}>
								<Tab
									id="monthly"
									label={
										<SoftBox py={0.5} px={2}>
											Monthly
										</SoftBox>
									}
								/>
								<Tab
									id="yearly"
									label={
										<SoftBox py={0.5} px={2}>
											Yearly
										</SoftBox>
									}
								/>
							</Tabs>
						</AppBar>
					</SoftBox>
				</Grid>
			)}

			{plans.length > 0 && (
				<Grid container spacing={3} justifyContent="center">
					{plans.map(plan => (
						<Grid item xs={12} lg={Math.ceil(12 / plans.length)} key={plan.id}>
							<DefaultPricingCard
								isPopup={isPopup}
								badge={{ color: "secondary", label: plan.name }}
								price={{
									currency: "S$ ",
									value: tabValue === 1 ? plan.price_annually : plan.price_monthly,
								}}
								mode={tabValue}
								specifications={plan.plan_features.available}
								specificationsFromLowerPlans={getLowerPlanSpecifications(plan)}
								action={
									currentPlanID === plan.id
										? { type: "none", label: "your current plan" }
										: {
												type: "callback",
												callback: () => join(plan.id, plan.level),
												label:
													currentPlanID === 0
														? "join now"
														: currentPlanLevel > plan.level
														? "Downgrade"
														: "Upgrade",
										  }
								}
							/>
						</Grid>
					))}
				</Grid>
			)}
			{isPreparingPayment && (
				<SoftBox
					px={{
						display: "flex",
						justifyContent: "center",
						position: "absolute",
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						alignItems: "center",
					}}
				>
					<CircularProgress color="inherit" />
				</SoftBox>
			)}
			<SoftDialog
				isOpen={isPaymentPopupVisible}
				title="Please provide your payment info"
				// description="Please do not refresh or click back button"
				hideCloseIcon
				handleClose={cancelPayment}
			>
				<Elements stripe={stripe} options={{ clientSecret: clientSecret.current }}>
					<StripeCardInfo
						redirectToAfterPayment={`${process.env.REACT_APP_WEBSITE_BASE_URL}/payment/process/stripe`}
					/>
				</Elements>
			</SoftDialog>
		</SoftBox>
	);
}

export default PricingCards;
