/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";
import { Grid, Popover, TableHead } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoIcon from "@mui/icons-material/Info";
// prop-types is a library for typechecking of props
import PropTypes, { number } from "prop-types";
import Payment from "layouts/applications/popup/Payment";
import SearchIcon from "@mui/icons-material/Search";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// import DatePicker from "react-flatpickr";
import SoftDatePicker from "components/SoftDatePicker";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";
import SoftButton from "components/SoftButton";
// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "./PortfolioStockDataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import buttonImage from "../../../assets/images/how-to-question 1.png";
import CloseIcon from "@mui/icons-material/Close";
import Popup from "layouts/applications/popup/Popup";
import Close from "@mui/icons-material/Close";
import popUP from "../../../assets/images/popUP.png";
import KeyIconOrData from "components/etc/KeyIconOrData";
// import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

import { toInteger } from "lodash";
import { SearchSymbolInput, TableRemarks, ActionDropdown } from "components/CustomComponents";
import { getTickerList } from "layouts/applications/data-tables/data/getData";
import { DateOfExpiryDropdown } from "components/CustomComponents";
import { StrikeDropdown } from "components/CustomComponents";
import axiosDefault from "layouts/authentication/api/axios";
import { getUserPortfolioOptions } from "layouts/applications/data-tables/data/getData";
import { updateUserCapital } from "layouts/applications/data-tables/data/getData";


const RenderOptionInputsVertical = (
	lastIndexOfInputColumn,
	table,
	isConfirmed,
	inputText,
	handleDateChange,
	handleOptionChange,
	currentDOE,
	setDOE,
	setStrikeList,
	strikeList,
	currentStrike,
	setStrike,
	setInputText,
	extraStrike,
	currentSecondStrike,
	setSecondStrike,
	setErrorMessage
) => {
	const RenderHeader = ({ columnData }) => {
		// console.log("accessor")
		// console.log(columnData)
		if (columnData.accessor === "strike_1") {
			if (extraStrike === "BPS") {
				return <div className="headerBox">
					{/* <p className="helvetica" style={{ fontSize: "12px" }}> SELL PUT STRIKE</p> */}
					<p className="helvetica" style={{ fontSize: "12px" }}> STRIKE 1</p>
				</div>
			}
			if (extraStrike === "BCS") {
				return <div className="headerBox">
					{/* <p className="helvetica" style={{ fontSize: "12px" }}>SELL CALL STRIKE</p> */}
					<p className="helvetica" style={{ fontSize: "12px" }}> STRIKE 1</p>
				</div>
			}
		}
		if (columnData.accessor === "strike_2") {
			if (extraStrike === "BPS") {
				return <div className="headerBox">
					{/* <p className="helvetica" style={{ fontSize: "12px" }}> BUY PUT STRIKE</p> */}
					<p className="helvetica" style={{ fontSize: "12px" }}> STRIKE 2</p>
				</div>
			}
			if (extraStrike === "BCS") {
				return <div className="headerBox">
					{/* <p className="helvetica" style={{ fontSize: "12px" }}>BUY CALL STRIKE   </p> */}
					<p className="helvetica" style={{ fontSize: "12px" }}> STRIKE 2</p>
				</div>
			}

			if (!extraStrike && !isConfirmed) {
				return <div></div>
			}
		}

		return (
			<div className="headerBox">
				<p className="helvetica" style={{ fontSize: "12px" }}>{columnData.Header}</p>
			</div>)
	}


	let text = [];
	let size = lastIndexOfInputColumn;
	// console.log(inputText)
	for (let i = 2; i < size; i++) {
		// console.log(table.columns[i])
		let textToInsert = "Type here";
		let currentType = table.columns[i].type;
		if (currentType === "date") {
			textToInsert = "Choose the Date";
			text.push(
				<Grid container key={i}>
					<div className="headerBox">
						<p className="helvetica" style={{ fontSize: "12px" }}>{table.columns[i].Header}</p>
					</div>
					<div >
						{isConfirmed ?
							<div className="inputBox">
								<p className="helvetica" style={{ fontSize: "12px" }} >{inputText[table.columns[i].accessor]}</p>
							</div>
							: <DateOfExpiryDropdown underlying={inputText.symbol} putOrCall={inputText.optionType} currentDOE={currentDOE} setDOE={setDOE} setStrikeList={setStrikeList} inputText={inputText} setInputText={setInputText} />
						}
					</div>
				</Grid>
			);
		} else if (currentType === "number") {
			// console.log(table.columns[i])
			text.push(
				<Grid container alignItems='center' key={i}>
					{/* <div className="headerBox">
						<p className="helvetica" style={{ fontSize: "12px" }}>{table.columns[i].Header}</p>
					</div> */}
					<RenderHeader columnData={table.columns[i]} ></RenderHeader>
					<div >
						{isConfirmed ? (
							<div className="inputBox">
								<p className="helvetica" style={{ fontSize: "12px" }}>{inputText[table.columns[i].accessor]}</p>
							</div>
						) : table.columns[i].accessor === 'buyingPowerrr' ?
							<div className="inputBox">
								<p className="helvetica" style={{ fontSize: "12px" }}>{inputText[table.columns[i].accessor]}</p>
							</div> : (
								table.columns[i].accessor === 'strike_1' ?
									<StrikeDropdown setStrikeErrorMessage={setErrorMessage} name={'strike_1'} strikeList={strikeList} currentStrike={currentStrike} setStrike={setStrike} setInputText={setInputText} inputText={inputText} />
									: table.columns[i].accessor === 'strike_2' ?
										extraStrike ?
											<StrikeDropdown setStrikeErrorMessage={setErrorMessage} name={'strike_2'} strikeList={strikeList} currentStrike={currentSecondStrike} setStrike={setSecondStrike} setInputText={setInputText} inputText={inputText} />
											: <div></div>
										:
										<div className="inputBox">
											<input
												autoComplete="off"
												name={table.columns[i].accessor}
												type="number"
												placeholder={table.columns[i].accessor === 'delta' ? "Enter a value from 0.01 - 1" : "Type here"}
												onChange={handleOptionChange}
												value={inputText[table.columns[i].accessor]}
												className="underlinedInput"
												max={table.columns[i].accessor === 'delta' ? 1 : null}
												min={0}
												step={table.columns[i].accessor === 'delta' ? 0.01 : null}
											/>
										</div>


							)}
					</div>
				</Grid>
			);
		} else {
			text.push(
				<Grid container key={i}>
					<div className="headerBox">
						<p className="helvetica" style={{ fontSize: "12px" }}>{table.columns[i].Header}</p>
					</div>
					<div className="inputBox">
						{isConfirmed ? (
							<p className="helvetica" style={{ fontSize: "12px" }}>{inputText[table.columns[i].accessor]}</p>
						) : (
							<input
								autoComplete="off"
								name={table.columns[i].accessor}
								type="text"
								placeholder={textToInsert}
								onChange={handleOptionChange}
								value={inputText[table.columns[i].accessor]}
								className="underlinedInput"
							/>
						)}
					</div>
				</Grid>
			);
		}
	}
	return text;
};
function PortfolioOptionTable({
	entriesPerPage,
	canSearch,
	showTotalEntries,
	table,
	pagination,
	isSorted,
	noEndBorder,
	loading,
	isDividend,
	exchange,
	setExchange,
	optionType,
	fullData,
	initialCapital,
	setTable,
	setInitialCapital,
	modifyTable
}) {
	const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
	const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
	const columns = useMemo(() => table.columns, [table]);

	const data = useMemo(() => table.rows, [table]);

	const [isModalOpen, setModal] = useState(false);
	const [ratio, setRatio] = useState();
	const [filteredData, setFiltered] = useState();
	const [stockCode, setStockCode] = useState("");
	const navigate = useNavigate();
	const [optionPopup, toggleOptionPopup] = useState(false);
	const [capitalPopup, toggleCapitalPopup] = useState(false);
	const [isConfirmed, setConfirmed] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [transactionErrorMessage, setTransactionErrorMessage] = useState("");
	const [strikeList, setStrikeList] = useState([]);
	const lastIndexOfInputColumn = 11; //we -1 because last column is initial captial which is not an input

	//popup
	const handleDateChange = (date, name) => {
		setInputText({ ...inputText, [name]: date[0].toLocaleDateString("en-US") });
	};

	const calculateBuyingPower = (currentInput,name ,value) => {
		if (currentInput === "Buy") {
			if (name === "premiumPerQty") {
				let toAdd;
				if(inputText.quantity === ""){
					toAdd = 0;
				}else{
					toAdd = parseInt(inputText.quantity)
				}
				return parseInt(value) * toAdd
			} else if (name === "quantity") {
				let toAdd;
				if(inputText.premiumPerQty === ""){
					toAdd = 0;
				}else{
					toAdd = parseInt(inputText.premiumPerQty)
				}
				return parseInt(value) * toAdd

			}
		} else if (currentInput === 'Bear Call Spread' || currentInput === "Bull Put Spread") {
			let strike_1 = inputText.strike_1;
			let strike_2 = inputText.strike_2;
			let premiumPerQty = inputText.premiumPerQty;
			let quantity = inputText.quantity;
			if(name === "strike_1"){
				strike_1 = value
			}else if(name === "strike_2"){
				strike_2 = value
			}else if(name === "premiumPerQty"){
				premiumPerQty = value
			}else if(name === "quantity"){
				quantity = value
			}
			if(currentInput === 'Bear Call Spread'){
				return (((strike_2 - strike_1) * 100) - premiumPerQty) * quantity
			}
			return (((strike_1 - strike_2) * 100) - premiumPerQty) * quantity
			
		} 
		return -1;

	}

	function handleOptionChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		if (name === 'delta') {
			if (toInteger(value) <= 1 && toInteger(value) >= 0) {
				setInputText({ ...inputText, [name]: value });
			}
			return;
		}

		const optionType = inputText.optionType
		if (optionType === "Buy Put" || optionType === "Buy Call") {
			setInputText({ ...inputText, [name]: value, buyingPower: calculateBuyingPower("Buy",name,value) });
			return;
		} else if (optionType === 'Bear Call Spread') {
			setInputText({ ...inputText, [name]: value, buyingPower: calculateBuyingPower("Bear Call Spread",name,value) });
			return;
		} else if (optionType === "Bull Put Spread") {
			setInputText({ ...inputText, [name]: value, buyingPower: calculateBuyingPower("Bull Put Spread",name,value) });
			return;
		}



		setInputText({ ...inputText, [name]: value });
	}


	//states for stock popup
	const [inputText, setInputText] = useState({});

	//transaction type
	const [transaction, setTransaction] = useState(null);
	const openTransaction = Boolean(transaction);
	const [currentTransaction, setCurrentTransaction] = useState("Deposit");
	const [inputAmount, setInputAmount] = useState(0);

	//stock type
	const [stockType, setStockType] = useState(null);
	const openStock = Boolean(stockType);
	const [currentOption, setCurrentOption] = useState("CHOOSE OPTION TYPE");


	//Bear put spread menu
	const [bearPutType, setBearPutType] = useState(null);
	const openBearPut = Boolean(bearPutType);

	//dateDropDown
	const [currentDOE, setDOE] = useState("CHOOSE DATE")

	//strike DropDown
	const [currentStrike, setStrike] = useState("Choose Strike")

	const [currentSecondStrike, setSecondStrike] = useState("Choose Strike")

	const [extraStrike, setExtraStrike] = useState(false)

	const sendOptionInfo = async () => {
		
		const remainingCapital = initialCapital - inputText.buyingPower;

		const res = await updateUserCapital(-(inputText.buyingPower))
			if(res === null){
				return; 
			}
		setInitialCapital(remainingCapital)
		

		let formattedInputText = formatInputText(inputText);
		const uploadSuccess = await uploadOptionInfo(inputText)
		if (uploadSuccess) {
			setTable(formattedInputText, "options");
			deleteCurrentInfo();
			toggleOptionPopup(false);
		} else {
			setErrorMessage("Something went wrong, please try again later!")
		}
	};

	const uploadOptionInfo = async (objectToUpload) => {
		objectToUpload = { ...objectToUpload, remarks: "None", action: "Hold" }
		const buyingPower = (objectToUpload.buyingPower).toString()
		objectToUpload.buyingPower = buyingPower
		try {
			console.log("object to upload is here")
			console.log(objectToUpload)
			const res = await axiosDefault().post("portfolio/options", objectToUpload)
			console.log(res)
			console.log("option uploaded")
			return true
		} catch (error) {
			console.log(error)
			console.log("error uploading option info")
			return false;
		}
	}

	//adds the e
	const formatInputText = (input) => {
		return input
	}



	const currentDate = new Date();

	const deleteCurrentInfo = () => {
		setConfirmed(false);
		initialisePopupInputs();
		setCurrentOption("CHOOSE OPTION TYPE");
	};
	const closeTransaction = event => {
		const name = event.target.textContent;
		if (name !== "") {
			setCurrentTransaction(name);
		}

		setTransaction(null);
	};
	const isFieldsValid = async () => {
		console.log(inputText);
		for (let i = 0; i < lastIndexOfInputColumn; i++) {
			//could add check for negative numbers
			// console.log(table.columns[i].accessor)

			if (inputText[table.columns[i].accessor] === "") {
				console.log("error occcured for " + table.columns[i].accessor);
				setErrorMessage("Please input a " + table.columns[i].accessor);
				return false;
			} else if (table.columns[i].accessor === "buyingPower") {
				console.log(initialCapital)
				if (inputText.buyingPower > initialCapital) {
					setErrorMessage("Insufficient capital");
					return false;
				}
				if(inputText.buyingPower < 0){
					setErrorMessage("Buying power cannot be negative");
					return false;
				}
				console.log(inputText)
				console.log("here")
			}
			else if (table.columns[i].type === "number") {
				console.log(toInteger(inputText[table.columns[i].accessor]))
				if (Number(inputText[table.columns[i].accessor]) <= 0) {
					setErrorMessage("Invalid number for " + table.columns[i].accessor);
					return false;
				}
			}


		}
		let fetchedObject = await getTickerList(inputText["symbol"]);
		if (fetchedObject.data.length === 0) {
			setErrorMessage("Invalid Symbol: " + inputText["symbol"]);
			return false
		} else {
			// console.log("here")
			setErrorMessage("");
			return true;
		}


	};
	const confirmButton = async () => {
		if (await isFieldsValid()) {
			setConfirmed(!isConfirmed);
		}
	};



	const addTransaction = async () => {
		let parsedInitialCapital = parseInt(initialCapital);
		let parsedInputAmount = parseInt(inputAmount);
		if (currentTransaction === "Withdraw" && parsedInputAmount > parsedInitialCapital) {
			setTransactionErrorMessage("You cannot withdraw more than what you have.");
			return;
		}
		if (currentTransaction === "Deposit") {
			const res = await updateUserCapital(parsedInputAmount)
			if (res === null) {
				setTransactionErrorMessage("Seems to be a server error, please try again later!");
				return;
			}
			setInitialCapital(parsedInitialCapital + parsedInputAmount);
		} else {
			const res = await updateUserCapital(-parsedInputAmount)
			if (res === null) {
				setTransactionErrorMessage("Seems to be a server error, please try again later!");
				return;
			}
			setInitialCapital(parsedInitialCapital - parsedInputAmount);

		}

		setTransactionErrorMessage("");
		toggleCapitalPopup(false);
		setInputAmount("");
	};

	const closeOptions = event => {
		const name = event.target.textContent;
		if (name !== "") {
			// console.log(name)
			if (name === "Bear Call Spread") {
				setExtraStrike("BCS")
			} else if (name === "Bull Put Spread") {
				setExtraStrike("BPS")
			} else {
				setExtraStrike(false)
			}

			setCurrentOption(name);
			console.log(inputText)
			setInputText({ ...inputText, optionType: name,dateToExpiry:"",strike_1:"N.A.",strike_2:"N.A." });
			setStrike("CHOOSE STRIKE")
			setSecondStrike("CHOOSE STRIKE")
			setDOE("CHOOSE DATE")


		}

		setStockType(null);
		setBearPutType(null);
	};

	const handleSubMenu = event => {
		setBearPutType(event.currentTarget)
	}

	const handleClick = event => {
		const name = event.currentTarget.id;
		if (name === "stock-button") {
			setStockType(event.currentTarget);
		} else if (name === "transaction-button") {
			setTransaction(event.currentTarget);
		}
		// if (name === )
	};
	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0 } },
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		pageOptions,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,

		state: { pageIndex, pageSize, globalFilter },
	} = tableInstance;

	// Set the default value for the entries per page when component mounts
	// useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
	// useEffect(() => {
	// 	setPageSize(defaultValue || 10);
	// 	initialisePopupInputs();
	// }, [defaultValue]);
	// useEffect(() => {
	// 	setPageSize(defaultValue || 10);
	// 	initialisePopupInputs();
	// }, []);

	useEffect(() => {
		initialisePopupInputs()
	}, [])
	const source3 = `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_fc027&symbol=${stockCode}&interval=D&saveimage=0&toolbarbg=f1f3f6&details=1&studies=CCI%40tv-basicstudies%1FRSI%40tv-basicstudies&theme=light&style=2&timezone=Etc%2FUTC&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=&utm_medium=widget_new&utm_campaign=chart&utm_term=MSTR#%7B%22page-uri%22%3A%22__NHTTP__%22%7D`;

	const initialisePopupInputs = () => {
		const currentText = {};
		let numberOfInputs = lastIndexOfInputColumn;
		for (let i = 0; i < numberOfInputs; i++) {
			let name = table.columns[i].accessor;
			if (table.columns[i].type === "date") {
				const today = new Date();
				currentText[name] = today.toLocaleDateString("en-US");
			} else if (name === "strike_2") {
				currentText["strike_2"] = "N.A."
			}
			else if(name === "strike_1"){
				currentText["strike_1"] = "N.A."
			}
			else {
				currentText[name] = "";
			}
		}
		setDOE('CHOOSE DATE');
		setStrike('CHOOSE STRIKE');
		setStrikeList([]);
		setInputText(currentText);
	};
	// Set the entries per page value based on the select value
	const setEntriesPerPage = ({ value }) => setPageSize(value);

	const parseData = (name, data) => {
		const theElement = data.find(element => element.Symbol === name);

		setFiltered(theElement);
	};

	// Render the paginations
	const renderPagination = pageOptions.map(option => (
		<SoftPagination
			item
			key={option}
			onClick={() => gotoPage(Number(option))}
			active={pageIndex === option}
			className={pageIndex === option ? "bbvRed" : ""}
		>
			{option + 1}
		</SoftPagination>
	));

	// console.log(table.columns)

	// Handler for the input to set the pagination index
	const handleInputPagination = ({ target: { value } }) =>
		value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

	// Customized page options starting from 1
	const customizedPageOptions = pageOptions.map(option => option + 1);

	// Setting value for the pagination input
	const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

	// Search input value state
	const [search, setSearch] = useState(globalFilter);

	// Search input state handle
	const onSearchChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 100);

	// A function that sets the sorted value for the table
	const setSortedValue = column => {
		let sortedValue;

		if (isSorted && column.isSorted) {
			sortedValue = column.isSortedDesc ? "desc" : "asce";
		} else if (isSorted) {
			sortedValue = "none";
		} else {
			sortedValue = false;
		}

		return sortedValue;
	};

	// Setting the entries starting point
	const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

	// Setting the entries ending point
	let entriesEnd;

	if (pageIndex === 0) {
		entriesEnd = Math.min(rows.length, pageSize);
	} else if (pageIndex === pageOptions.length - 1) {
		entriesEnd = rows.length;
	} else {
		entriesEnd = pageSize * (pageIndex + 1);
	}

	const RenderPlaceHolderText = () => {
		let text = [];
		let size = table.columns.length;
		for (let i = 1; i < size; i++) {
			//we start with index 1 as symbol is always Type To Search With icon
			let textToInsert = "Type here";
			let currentType = table.columns[i].type;

			if (currentType === "dropdown") {
				textToInsert = "Choose and Click";
			} else if (currentType === "date") {
				textToInsert = "Choose the Date";
			}

			text.push(
				<DataTableBodyCell key={i}>
					<p key={i} className="portfolioInput" onClick={() => toggleOptionPopup(true)}>
						{textToInsert}
					</p>
				</DataTableBodyCell>
			);
		}
		return text;
	};

	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			<Grid container style={{ paddingLeft: "23px", paddingTop: "40px", display: "flex", justifyContent: "space-between" }}>
				<div style={{ fontSize: "20px" }}>
					<h1 className='roboto portfolioTableText' >INITIAL CAPITAL (USD)</h1>
					{/* <SoftButton onClick={() => {
						getUserPortfolioOptions()
					}}>get option info</SoftButton> */}
					<p className='roboto portfolioTableText'> {isNaN(initialCapital) ? 0 : Number(initialCapital).toLocaleString()}</p>
					<Button variant="outlined" className='greyOutline' onClick={() => { toggleCapitalPopup(true) }}>Add capital</Button>
				</div>
				<p style={{ paddingRight: "23px", color: "#8392AB", fontSize: "20px" }} className="roboto" >DATE: {currentDate.toDateString()}</p>
			</Grid>
			{entriesPerPage || canSearch ? (
				<div>
					<SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
						{entriesPerPage && (
							<SoftBox display="flex" alignItems="center">
								<SoftSelect
									defaultValue={{ value: defaultValue, label: defaultValue }}
									options={entries.map(entry => ({ value: entry, label: entry }))}
									onChange={setEntriesPerPage}
									size="small"
								/>
								<SoftTypography variant="caption" color="secondary">
									&nbsp;&nbsp;entries per page
								</SoftTypography>
							</SoftBox>
						)}
						{canSearch && (
							<SoftBox width="12rem" ml="auto">
								<SoftInput
									placeholder="Search..."
									value={search}
									onChange={({ currentTarget }) => {
										setSearch(search);
										onSearchChange(currentTarget.value);
									}}
								/>
							</SoftBox>
						)}
					</SoftBox>
				</div>
			) : null}
			{loading ? (
				<div className="loadingIcon">
					<CircularProgress />
				</div>
			) : (
				<>
					<Table {...getTableProps()}>
						<SoftBox className="bbvLightGrey rowBoxes" component="thead">
							{headerGroups.map((headerGroup, key) => (
								<TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, key) => (
										<DataTableHeadCell
											key={key}
											{...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
											width={column.width ? column.width : "auto"}
											align="left"
											sorted={setSortedValue(column)}
										>
											{column.render("Header")}
										</DataTableHeadCell>
									))}
								</TableRow>
							))}
						</SoftBox>

						<TableBody {...getTableBodyProps()}>
							{page.map((row, pageKey) => {
								prepareRow(row);
								return (
									<TableRow className="alignCenter" key={pageKey} {...row.getRowProps()}>
										{row.cells.map((cell, key) => (
											<DataTableBodyCell
												key={key}
												className="alignCenter rowBoxes"
												noBorder={noEndBorder && rows.length - 1 === key}
												//align used to align the data content
												// align = {pageKey === page.length - 1?"right":"left"}
												align="left"
												{...cell.getCellProps()}
											>
												{cell.column.Header === "ACTION" ?
													<div>
														<ActionDropdown type="options" modifyTable={modifyTable} data={data} currentAction={cell.render("Cell")} rowIndex={pageKey} />
													</div>
													: cell.column.Header === "REMARKS" ? <TableRemarks type="options" rowIndex={pageKey} modifyTable={modifyTable} data={data} /> :
														<div className="leftAlign">
															<p
																style={{ maxWidth: "110px" }}
																className="helvetica portfolioCellText"
															>
																{cell.render("Cell")}
															</p>
														</div>
												}
											</DataTableBodyCell>
										))}
									</TableRow>
								);
							})}
							<TableRow>
								<DataTableBodyCell>
									<p className="portfolioInput" onClick={() => toggleOptionPopup(true)}>
										{" "}
										<SearchIcon style={{ verticalAlign: "middle" }} /> Type to search
									</p>
								</DataTableBodyCell>
								<RenderPlaceHolderText></RenderPlaceHolderText>
							</TableRow>
						</TableBody>
					</Table>
					{rows.length > 0 ? (
						<SoftBox
							display="flex"
							flexDirection={{ xs: "column", sm: "row" }}
							justifyContent="space-between"
							alignItems={{ xs: "flex-start", sm: "center" }}
							p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
						>
							{showTotalEntries && (
								<SoftBox mb={{ xs: 3, sm: 0 }}>
									<SoftTypography variant="button" color="secondary" fontWeight="regular">
										Showing {entriesStart} to {entriesEnd} of {rows.length} entries
									</SoftTypography>
								</SoftBox>
							)}
							{pageOptions.length > 1 && (
								<SoftPagination
									variant={pagination.variant ? pagination.variant : "gradient"}
									color={pagination.color ? pagination.color : "info"}
								>
									{canPreviousPage && (
										<SoftPagination item onClick={() => previousPage()}>
											<Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
										</SoftPagination>
									)}
									{renderPagination.length > 6 ? (
										<SoftBox width="5rem" mx={1}>
											<SoftInput
												inputProps={{
													type: "number",
													min: 1,
													max: customizedPageOptions.length,
												}}
												value={customizedPageOptions[pageIndex]}
												onChange={(handleInputPagination, handleInputPaginationValue)}
											/>
										</SoftBox>
									) : (
										renderPagination
									)}
									{canNextPage && (
										<SoftPagination item onClick={() => nextPage()}>
											<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
										</SoftPagination>
									)}
								</SoftPagination>
							)}
						</SoftBox>
					) : (
						<SoftBox display="flex" style={{ textAlign: "center" }} py={3}>
							{/* <SoftTypography variant="p">No Data Found</SoftTypography> */}
						</SoftBox>
					)}
				</>
			)}
			{optionPopup ? (
				<Popup >
					<CloseIcon
						className="close-btn icon"
						onClick={() => {
							toggleOptionPopup(false);
							setModal(false);
						}}
					/>
					<div >
						<h1 style={{ textAlign: "center", fontSize: "18px", marginBottom: "20px" }} className="roboto">
							Order Entry
						</h1>


						<div >
							<Grid container alignItems="center">
								<div className="headerBox" style={{ display: "flex", justifyContent: "space-between" }} >
									<p className="helvetica" style={{ fontSize: "12px" }}>SYMBOL</p>
									<SearchIcon style={{ marginRight: "12px" }}></SearchIcon>
								</div>
								<div className={isConfirmed ? "inputBox" : "searchInputBox"} >
									{isConfirmed ? (<p className="helvetica" style={{ fontSize: "12px" }}>{inputText.symbol}</p>) : (
										<div >
											<SearchSymbolInput inputText={inputText} setInputText={setInputText} withIcon={false} />
										</div>
									)}
								</div>
							</Grid>
							<Grid container alignItems='center'>
								<div className="headerBox">
									<p className="helvetica" style={{ fontSize: "12px" }}>OPTION TYPE</p>
								</div>
								<div className="buttonInputBox">
									<div id="portfolioDropdown">
										{isConfirmed ? (
											<p className="helvetica" style={{ fontSize: "12px", paddingLeft: "13px" }}>{inputText.optionType}</p>
										) : (
											<SoftButton
												id="stock-button"
												className="portfolioDropdown"
												aria-controls={openStock ? "index-menu" : undefined}
												aria-haspopup="true"
												aria-expanded={openStock ? "true" : undefined}
												onClick={handleClick}
												variant="text"
											>
												{currentOption}
												<ExpandMoreIcon></ExpandMoreIcon>
											</SoftButton>
										)}
										<Menu
											id="stock-menu"
											anchorEl={stockType}
											open={openStock}
											onClose={closeOptions}
											style={{ position: "", zIndex: "10000" }}
											MenuListProps={{
												"aria-labelledby": "basic-button",
											}}
										>
											<MenuItem onClick={closeOptions}>Buy Call</MenuItem>
											<MenuItem onClick={closeOptions}>Sell Call</MenuItem>
											<MenuItem onClick={closeOptions}>Buy Put</MenuItem>
											<MenuItem onClick={closeOptions}>Sell Put</MenuItem>
											{/* <MenuItem onClick={closeOptions}>Bull Call Spread</MenuItem> */}
											<MenuItem onClick={closeOptions}>Bear Call Spread</MenuItem>
											<MenuItem onClick={closeOptions}>Bull Put Spread</MenuItem>
											{/* <MenuItem onClick={handleSubMenu} style={{ display: 'flex', justifyContent: "space-between" }}>Bear Put Spread <ArrowForwardIosIcon></ArrowForwardIosIcon></MenuItem> */}
										</Menu>
										<Popover
											open={openBearPut}
											anchorEl={bearPutType}
											onClose={closeOptions}
										// anchorOrigin={{
										// vertical: 'center',
										// horizontal: 'right',
										// }}
										// transformOrigin={{
										// vertical: 'top',
										// horizontal: 'left',
										// }}

										>
											<Menu
												id="sub-menu"
												anchorEl={bearPutType}
												open={openBearPut}
												onClose={closeOptions}
												MenuListProps={{
													'aria-labelledby': 'stock-button',
												}}
												// className="optionSubmenu"
												style={{ zIndex: "10000", left: "165px", top: "-30px" }}
											>
												<MenuItem onClick={closeOptions}>Buy Put</MenuItem>
												<MenuItem onClick={closeOptions}>Sell Put</MenuItem>
											</Menu>
										</Popover>

									</div>
								</div>
							</Grid>
							{RenderOptionInputsVertical(
								lastIndexOfInputColumn,
								table,
								isConfirmed,
								inputText,
								handleDateChange,
								handleOptionChange,
								currentDOE,
								setDOE,
								setStrikeList,
								strikeList,
								currentStrike,
								setStrike,
								setInputText,
								extraStrike,
								currentSecondStrike,
								setSecondStrike,
								setErrorMessage

							)}
							<Grid container alignItems="center">
								<div className="headerBox" style={{ display: "flex", alignItems: "center" }}>
									<p className="helvetica" style={{ fontSize: "12px" }}>INITIAL CAPITAL</p>
								</div>
								<div className="inputBox">
									<p className="roboto" style={{ fontSize: "12px", color: "#344767" }}>{initialCapital}</p>
								</div>
							</Grid>

						</div>
						{isConfirmed ? (
							<div>
								<div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
									<div>
										<Button
											className="greyOutline"
											variant="outlined"
											onClick={deleteCurrentInfo}
											style={{ marginRight: "20px" }}
											color="info"
										>
											Delete
										</Button>
										<Button
											className="greyOutline"
											variant="outlined"
											onClick={confirmButton}
											color="info"
										>
											Edit
										</Button>
									</div>
									<Button className="bbvGreen" onClick={sendOptionInfo} variant="gradient" color="info">
										Send
									</Button>
								</div>
								<p style={{ color: "red", marginTop: "10px", fontSize: "16px", textAlign: "right" }}>{errorMessage}</p>
							</div>
						) : (
							<div style={{ float: "right", paddingTop: "25px" }}>

								<Button className="greyOutline" variant="outlined" onClick={deleteCurrentInfo}>
									DELETE
								</Button>
								<Button
									className="bbvGrey"
									style={{ marginLeft: "20px" }}
									onClick={confirmButton}
									variant="gradient"
									color="info"
								>
									Confirm & Send
								</Button>
								<p style={{ color: "red", marginTop: "10px", fontSize: "16px", textAlign: "right" }}>{errorMessage}</p>
							</div>
						)}
					</div>
				</Popup>
			) : (
				<div></div>
			)}

			{capitalPopup ? (
				<Popup>
					<CloseIcon
						className="close-btn icon"
						onClick={() => {
							toggleCapitalPopup(false);
							setModal(false);
						}}
					/>
					<div>
						<h1 style={{ textAlign: "center", fontSize: "18px", marginBottom: "20px" }} className="roboto">
							Add cash transaction
						</h1>
						<TableContainer>
							<Table {...getTableProps()}>
								<SoftBox className="bbvLightGrey rowBoxes" component="thead">
									<TableRow>
										<DataTableHeadCell>
											<p className="helvetica" style={{ fontSize: "12px" }}>
												TRANSACTION TYPE
											</p>
										</DataTableHeadCell>
										<DataTableHeadCell>
											<p className="helvetica" style={{ fontSize: "12px" }}>
												AMOUNT
											</p>
										</DataTableHeadCell>
									</TableRow>
								</SoftBox>
								<TableBody {...getTableBodyProps()}>
									<TableRow>
										<DataTableBodyCell>
											<div>
												<SoftButton
													id="transaction-button"
													className="portfolioDropdown"
													aria-controls={openTransaction ? "index-menu" : undefined}
													aria-haspopup="true"
													aria-expanded={openTransaction ? "true" : undefined}
													onClick={handleClick}
													variant="text"
												>
													{currentTransaction}
													<ExpandMoreIcon></ExpandMoreIcon>
												</SoftButton>
												<Menu
													id="transaction-menu"
													anchorEl={transaction}
													open={openTransaction}
													onClose={closeTransaction}
													style={{ position: "", zIndex: "10000" }}
													MenuListProps={{
														"aria-labelledby": "basic-button",
													}}
												>
													<MenuItem onClick={closeTransaction}>Deposit</MenuItem>
													<MenuItem onClick={closeTransaction}>Withdraw</MenuItem>
												</Menu>
											</div>
										</DataTableBodyCell>
										<DataTableBodyCell>
											<TextField
												id="standard-basic"
												placeholder="Enter an amount"
												type="number"
												value={inputAmount}
												onChange={event => {
													setInputAmount(event.target.value);
												}}
												variant="outlined"
											/>
										</DataTableBodyCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<p>{transactionErrorMessage}</p>
						<div style={{ float: "right", paddingTop: "25px" }}>
							<Button
								className="greyOutline"
								variant="outlined"
								onClick={() => {
									toggleCapitalPopup(false);
									setModal(false);
								}}
							>
								Cancel
							</Button>
							<Button
								className="bbvGreen"
								style={{ marginLeft: "20px" }}
								onClick={addTransaction}
								variant="gradient"
								color="info"
							>
								Complete
							</Button>
						</div>
					</div>
				</Popup>
			) : (
				<div></div>
			)}
		</TableContainer>
	);
}

// Setting default values for the props of DataTable
PortfolioOptionTable.defaultProps = {
	entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
	canSearch: false,
	showTotalEntries: true,
	pagination: { variant: "gradient", color: "info" },
	isSorted: true,
	noEndBorder: false,
};

// Typechecking props for the DataTable
PortfolioOptionTable.propTypes = {
	entriesPerPage: PropTypes.oneOfType([
		PropTypes.shape({
			defaultValue: PropTypes.number,
			entries: PropTypes.arrayOf(PropTypes.number),
		}),
		PropTypes.bool,
	]),
	canSearch: PropTypes.bool,
	showTotalEntries: PropTypes.bool,
	table: PropTypes.objectOf(PropTypes.array).isRequired,
	pagination: PropTypes.shape({
		variant: PropTypes.oneOf(["contained", "gradient"]),
		color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
	}),
	isSorted: PropTypes.bool,
	noEndBorder: PropTypes.bool,
};

export default PortfolioOptionTable;
