/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
	navbar,
	navbarContainer,
	navbarRow,
	navbarIconButton,
	navbarDesktopMenu,
	navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setTransparentNavbar, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { SearchSymbolInput } from "components/CustomComponents";
import { RightSideNavBar } from "components/CustomComponents";

function DashboardNavbar({ absolute, light, isMini, showSearchBox, pageTitle, type }) {
	const [navbarType, setNavbarType] = useState("sticky");
	const [controller, dispatch] = useSoftUIController();
	const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
	const [openMenu, setOpenMenu] = useState(false);
	const route = useLocation().pathname.split("/").slice(1);
	const [inputText,setInputText] = useState("");
	// useEffect(() => {
	// 	// Setting the navbar type
	// 	if (fixedNavbar) {
	// 		setNavbarType("sticky");
	// 	} else {
	// 		setNavbarType("static");
	// 	}

	// 	// A function that sets the transparent state of the navbar.
	// 	function handleTransparentNavbar() {
	// 		setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
	// 	}

	// 	/** 
    //  The event listener that's calling the handleTransparentNavbar function when 
    //  scrolling the window.
    // */
	// 	window.addEventListener("scroll", handleTransparentNavbar);

	// 	// Call the handleTransparentNavbar function to set the state with the initial value.
	// 	handleTransparentNavbar();

	// 	// Remove event listener on cleanup
	// 	return () => window.removeEventListener("scroll", handleTransparentNavbar);
	// }, [dispatch, fixedNavbar]);

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
	const handleOpenMenu = event => setOpenMenu(event.currentTarget);
	const handleCloseMenu = () => setOpenMenu(false);

	// Render the notifications menu
	const renderMenu = () => (
		<Menu
			anchorEl={openMenu}
			anchorReference={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			open={Boolean(openMenu)}
			onClose={handleCloseMenu}
			sx={{ mt: 2 }}
		>
			<NotificationItem
				image={<img src={team2} alt="person" />}
				title={["New message", "from Laur"]}
				date="13 minutes ago"
				onClick={handleCloseMenu}
			/>
			<NotificationItem
				image={<img src={logoSpotify} alt="person" />}
				title={["New album", "by Travis Scott"]}
				date="1 day"
				onClick={handleCloseMenu}
			/>
			<NotificationItem
				color="secondary"
				image={
					<Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
						payment
					</Icon>
				}
				title={["", "Payment successfully completed"]}
				date="2 days"
				onClick={handleCloseMenu}
			/>
		</Menu>
	);

	return (
		<AppBar
			className={type === "bbv" ? "bbvNavbar bbvRed" : ""}
			position={absolute ? "absolute" : navbarType}
			color="inherit"
			sx={theme => navbar(theme, { transparentNavbar, absolute, light })}
		>
			{/* <div className="overlay" /> */}
			<Toolbar sx={theme => navbarContainer(theme)}>
				<SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={theme => navbarRow(theme, { isMini })}>
					<Breadcrumbs
						icon="home"
						title={pageTitle ? pageTitle : route[route.length - 1]}
						route={route}
						light={light}
					/>
				</SoftBox>
				<RightSideNavBar  isMini = {isMini} navbarRow = {navbarRow} inputText = {inputText} light={light} handleMiniSidenav = {handleMiniSidenav} navbarIconButton = {navbarIconButton} miniSidenav = {miniSidenav} handleConfiguratorOpen = {handleConfiguratorOpen} setInputText = {setInputText} handleOpenMenu = {handleOpenMenu} renderMenu = {renderMenu} />
			</Toolbar>
		</AppBar>
	);
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: true,
	isMini: false,
	showSearchBox: true,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
	absolute: PropTypes.bool,
	light: PropTypes.bool,
	isMini: PropTypes.bool,
	showSearchBox: PropTypes.bool,
};

export default DashboardNavbar;
