/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo, useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import ExpandLessIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SoftDialog
	from "components/SoftDialog"
	;
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
import CategoriesList from "examples/Lists/CategoriesList";
import MessageCard from "examples/Cards/MessageCard";
import RankingsList from "examples/Lists/RankingsList";
import Calendar from "examples/Calendar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StockDataTable from "examples/Tables/StockTable";

// Data
import miniGradientLineChartData from "layouts/dashboards/crm/data/miniGradientLineChartData";
import categoriesListData from "layouts/dashboards/crm/data/categoriesListData";
import rankingsListData from "layouts/dashboards/crm/data/rankingsListData";
import calendarEventsData from "layouts/dashboards/crm/data/calendarEventsData";
import SoftButton from "components/SoftButton";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// Images
import ivancik from "assets/images/ivancik.jpg";
import kalVisualsSquare from "assets/images/kal-visuals-square.jpg";
import mmmIMG from "assets/images/MMM.png";
import wrenchImg from "assets/images/wrench.png";
import cryptoImg from "assets/images/crypto.png";
import rocketImg from "assets/images/rocket.png";
import SideNavStocks from "../stockTableSideNav";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PortfolioSummary from "../Portfolio/PortfolioSummary";
import { DisclaimerPopup } from "components/CustomPopups/InfoPopups";
import StockRankingText from "components/etc/StockRankingText";

function CRM() {
	const { visitorsChart, incomeChart } = miniGradientLineChartData;
	const { transactionsData, revenueData } = rankingsListData;
	const [initialData, setData] = useState(dataTableData);
	const [disclaimerPopup, setDisclaimerPopup] = useState(true);

	//opens and closes the dropdown menus
	const [index, setIndex] = useState(null);
	const [shariah, setShariah] = useState(null);
	const [market, setMarket] = useState(null);
	const [sector, setSector] = useState(null);
	const openIndex = Boolean(index);
	const openShariah = Boolean(shariah);
	const openMarket = Boolean(market);
	const openSector = Boolean(sector);

	const [isSideMenu, setSideMenu] = useState(false);
	//sets the filters to be passed into the table
	const [theIndex, setCurrentIndex] = useState("INDEX");
	const [theShariah, setCurrentShariah] = useState(null);
	const [theMarket, setCurrentMarket] = useState(null);
	const [theSector, setCurrentSector] = useState(null);
	const [moveUpIcon, setMoveUpIcon] = useState(false);
	const [closeMainRight, setCloseMainRight] = useState(false);

	const changeBackground = () => {
		if (window.scrollY > 4000) {
			setMoveUpIcon(true);
		} else {
			setMoveUpIcon(false);
		}
	};
	const monitorWidth = () => {
		const width = window.innerWidth;
		if (isSideMenu && width > 992) {
			setSideMenu(!isSideMenu);
		}
		if (!closeMainRight && width < 992) {
			setCloseMainRight(true);
		} else if (closeMainRight && width > 992) {
			setCloseMainRight(false);
		}
	};
	useEffect(() => {
		monitorWidth();

		// adding the event when scroll change background
		window.addEventListener("resize", monitorWidth);
	});
	const keyPressed = () => {
	}
	useEffect(() => {
		changeBackground();

		// adding the event when scroll change background
		window.addEventListener("scroll", changeBackground);
	});

	useEffect(() => {
		showDisclaimerPopup();
	}, [])

	const showDisclaimerPopup = () => {
		setDisclaimerPopup(true)
	}

	const handleClick = event => {
		// console.log(event.currentTarget.id)
		const name = event.currentTarget.id;

		if (name === "index-button") {
			setIndex(event.currentTarget);
		} else if (name === "shariah-button") {
			setShariah(event.currentTarget);
		} else if (name === "market-button") {
			setMarket(event.currentTarget);
		} else {
			//sector button
			setSector(event.currentTarget);
		}
	};
	const closeIndex = event => {
		const name = event.target.textContent;
		if (name === "ALL") {
			setCurrentIndex("INDEX");
		} else if (name !== "") {
			setCurrentIndex(name);
		}

		setIndex(null);
	};
	const closeSector = event => {
		const name = event.target.textContent;
		if (name === "ALL") {
			setCurrentSector(null);
		} else if (name !== "") {
			setCurrentSector(name);
		}
		setSector(null);
	};
	const closeMarket = event => {
		const name = event.target.textContent;

		if (name === "ALL") {
			setCurrentMarket(null);
		} else if (name !== "") {
			setCurrentMarket(name);
		}
		setMarket(null);
	};
	const closeShariah = event => {
		const name = event.target.textContent;
		if (name === "No") {
			setCurrentShariah(null);
		} else if (name !== "") {
			setCurrentShariah(name);
		}
		setShariah(null);
	};

	return (
		<DashboardLayout>
			<SoftButton
				className={moveUpIcon ? "toTopButton" : "none"}
				onClick={() => {
					window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
				}}
			>
				<ExpandLessIcon className="upArrow" />
			</SoftButton>
			{/* uncomment code to use sidemenu */}
			{/* {isSideMenu?<SideNavStocks setSideMenu = {setSideMenu}  isSideMenu = {isSideMenu}/>:<div></div>} */}
			{/* {!isSideMenu?
      <SoftButton className="rightArrowIcon"
        onClick={() => {
          setSideMenu(!isSideMenu);
        }}
      >
        <ChevronLeftIcon className="upArrow" />
      </SoftButton>:<div></div>} */}
			<DashboardNavbar />
			<SoftBox py={3}>
				<SoftBox mb={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={8} xl={8}>
							<Grid container className="dropDownBox" justifyContent="space-between">
								<Grid item xs={12} md={3} sm={3} lg={3}>
									<SoftButton
										id="index-button"
										className="dropDownButtons"
										aria-controls={openIndex ? "index-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={openIndex ? "true" : undefined}
										onClick={handleClick}
									>
										{theIndex}
										<ExpandMoreIcon></ExpandMoreIcon>
									</SoftButton>

									<Menu
										id="index-menu"
										anchorEl={index}
										open={openIndex}
										onClose={closeIndex}
										MenuListProps={{
											"aria-labelledby": "basic-button",
										}}
									>
										<MenuItem onClick={closeIndex}>ALL</MenuItem>
										<MenuItem onClick={closeIndex}>S&P 500</MenuItem>
									</Menu>
								</Grid>
								<Grid item xs={12} md={3} sm={3} lg={3}>
									<SoftButton
										id="sector-button"
										className="dropDownButtons"
										aria-controls={openSector ? "sector-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={openSector ? "true" : undefined}
										onClick={handleClick}
									>
										{theSector ?? "SECTOR"}
										<ExpandMoreIcon></ExpandMoreIcon>
									</SoftButton>
									<Menu
										id="sector-menu"
										anchorEl={sector}
										open={openSector}
										onClose={closeSector}
										MenuListProps={{
											"aria-labelledby": "basic-button",
										}}
									>
										<MenuItem onClick={closeSector}>ALL</MenuItem>
										<MenuItem onClick={closeSector}>Basic Materials</MenuItem>
										<MenuItem onClick={closeSector}>Communication Services</MenuItem>
										<MenuItem onClick={closeSector}>Consumer Cyclical</MenuItem>
										<MenuItem onClick={closeSector}>Consumer Defensive</MenuItem>
										<MenuItem onClick={closeSector}>Energy</MenuItem>
										<MenuItem onClick={closeSector}>Financial Services</MenuItem>
										<MenuItem onClick={closeSector}>Healthcare</MenuItem>
										<MenuItem onClick={closeSector}>Industrials</MenuItem>
										<MenuItem onClick={closeSector}>Real Estate</MenuItem>
										<MenuItem onClick={closeSector}>Technology</MenuItem>
										<MenuItem onClick={closeSector}>Utilities</MenuItem>
									</Menu>
								</Grid>
								<Grid item xs={12} md={3} sm={3} lg={3}>
									<SoftButton
										id="market-button"
										className="dropDownButtons"
										aria-controls={openMarket ? "basic-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={openMarket ? "true" : undefined}
										onClick={handleClick}
									>
										{theMarket ?? "MARKET CAP"}
										<ExpandMoreIcon></ExpandMoreIcon>
									</SoftButton>
									<Menu
										id="basic-menu"
										anchorEl={market}
										open={openMarket}
										onClose={closeMarket}
										MenuListProps={{
											"aria-labelledby": "basic-button",
										}}
									>
										<MenuItem onClick={closeMarket}>ALL</MenuItem>
										<MenuItem onClick={closeMarket}>LARGE CAP ({">"}10 BILLION)</MenuItem>
										<MenuItem onClick={closeMarket}>MEDIUM CAP (2-10 BILLION)</MenuItem>
										<MenuItem onClick={closeMarket}>SMALL CAP ({"<"}2 BILLION)</MenuItem>
									</Menu>
								</Grid>
								<Grid item xs={12} md={3} sm={3} lg={3}>
									<SoftButton
										id="shariah-button"
										className="dropDownButtons smallFont"
										aria-controls={openShariah ? "basic-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={openShariah ? "true" : undefined}
										onClick={handleClick}
									>
										{theShariah ?? "SHARIAH"}
										<ExpandMoreIcon></ExpandMoreIcon>
									</SoftButton>
									<Menu
										id="basic-menu"
										anchorEl={shariah}
										open={openShariah}
										onClose={closeShariah}
										MenuListProps={{
											"aria-labelledby": "basic-button",
										}}
									>
										<MenuItem onClick={closeShariah}>Yes</MenuItem>
										<MenuItem onClick={closeShariah}>No</MenuItem>
									</Menu>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<SoftBox mt={3}>
									<StockDataTable
										shariah={theShariah}
										index={theIndex}
										market={theMarket}
										sector={theSector}
										table={initialData}
										isSorted={false}
									/>
								</SoftBox>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={4} xl={4}>
							<div className="sticky">
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<StockRankingText />
									</Grid>
									<Grid item xs={12} sm={6} lg={12}>
										<div style={{ backgroundColor: "white", borderRadius: "16px" }}>
											<div className="moneyMakingDiv">
												<SoftButton onClick={() => {
													window.open('https://t.me/bullbearvector', '_blank')
												}}
													className="inline bbvGrey makeMoneyButton">
													JOIN NOW
												</SoftButton>
												<div style={{ width: "40%" }}>
													<p className="mmmText">Money Making Wheel </p>
													<p className="roboto_medium" style={{ fontSize: "12px", textAlign: "right" }}>Join CII community to know more about the wheel</p>
												</div>
											</div>
											<img
												className="centerImage"
												style={{ width: "80%", alignItems: "center", paddingBottom: "15px" }}
												src={mmmIMG}
												alt="money making method"
											/>
										</div>
									</Grid>
									<Grid item xs={12} sm={6} lg={12}>
										<div style={{ backgroundColor: "white", borderRadius: "16px" }}>
											<PortfolioSummary></PortfolioSummary>
										</div>
									</Grid>
								</Grid>
							</div>
						</Grid>

						{/* Uncomment below to use sidemenu feature */}
						{/* {!closeMainRight?
          <Grid  item xs={12} lg={5} xl={4}>
            <div className="sticky">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                 <div className="bbvGold">
                 <div className="innerCard">
                  <h5 >TOP ACCOUNCEMENT</h5>
                  <p >Wealth creation is an evolutionarily recent <br />
                        positive-sum game. It is all about who take the opportunity first.</p>
                  <Button className="noColourButton"  style={{textAlign: "left",color:"white"}}>Read More <ArrowForwardIcon /></Button>
                  </div>
                 </div>
                </Grid>
                <Grid item xs={12} sm={6} lg={12}>
                  <div style={{backgroundColor:"white",borderRadius:"16px"}}>
                    <div className="moneyMakingDiv">
                      <SoftButton className="inline bbvGrey makeMoneyButton">MAKE MONEY</SoftButton>
                      <p className="inline mmmText">Money Making Wheel</p>
                    </div>
                    <img className = "centerImage" style={{width:"80%", alignItems:"center",paddingBottom:"15px"}} src={mmmIMG} alt="money making method" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} lg={12}>
                <div style={{backgroundColor:"white",borderRadius:"16px"}}>
                     <div className="portfolioHeader">
                        <p className="inline mmmText">Portfolio</p>
                        <SoftButton className="inline bbvGrey">Manage Portfolio</SoftButton>
                      </div>
                      <div className="portfolioDiv">
                        <img src={rocketImg} alt="wrench" />
                        <p className="helvetica inline " style={{fontSize:"14px",paddingLeft:"15px"}}>Total Stocks <br></br><span className="portfolioAmt">250 in stock</span>  </p>
                        <span></span>
                        <ChevronRightIcon />
                      </div>
                    
                      
                      <div className="portfolioDiv">
                        <img src={wrenchImg} alt="wrench" />
                        <p className="helvetica inline" style={{fontSize:"14px" ,paddingLeft:"15px"}}>Total Options <br></br> <span className="portfolioAmt">250 in stock</span></p>
                        <span></span>
                        <ChevronRightIcon />
                      </div>
                      <div className="portfolioDiv">
                        <img src={cryptoImg} alt="crpyto" />
                        <p className="helvetica inline" style={{fontSize:"14px",paddingLeft:"15px"}}>Total Crypto <br></br><span className="portfolioAmt">250 in stock</span></p>
                        <span></span>
                        <ChevronRightIcon />
                      </div>
                  </div>
                </Grid>
              </Grid>
              </div>
            </Grid>
            :<div></div>}  */}
					</Grid>
				</SoftBox>
			</SoftBox>
			{disclaimerPopup ?
				<DisclaimerPopup toggleInfoPopup={setDisclaimerPopup}></DisclaimerPopup>:
				<div></div>}
			<Footer />
		</DashboardLayout>
	);
}

export default CRM;
