/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Pricing page components
import PricingCards from "layouts/pages/pricing-page/components/PricingCards";
import Faq from "layouts/pages/pricing-page/components/Faq";
import Bundles from "layouts/pages/pricing-page/components/Bundles";
import SoftTypography from "components/SoftTypography";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import useAuth from "layouts/authentication/hooks/useAuth";

function PricingPage() {
  const { auth } = useAuth();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Container>
            <PricingCards
              currentPlanID={auth && auth.plan ? auth.plan.id : auth && auth.access_token ? 1 : 0}
              currentPlanLevel={auth && auth.plan ? auth.plan.level : auth && auth.access_token ? 1 : 0}
            />
          </Container>
          <SoftBox mt={5}>
            <SoftBox
              sx={{
                background: "rgba(103, 116, 142, 0.2)",
                borderRadius: "20px",
                color: "#7E8BA9",
                justifyContent: "center",
              }}
            >
              <SoftBox p={3} sx={{ width: "100%" }} display="flex" justifyContent="center" alignItems="center" gap={1}>
                <ViewListOutlinedIcon fontSize="medium" color="inherit" />
                <SoftTypography variant="body2" color="inherit" sx={{ textTransform: "uppercase", cursor: "pointer" }}>
                  Investment Bundles
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <Bundles />
            </SoftBox>
          </SoftBox>
          <Container>
            <Faq />
          </Container>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PricingPage;
