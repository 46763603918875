import { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TextField, Typography, InputAdornment } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Delete";

const colWidth = (index, total) => (index === total - 1 ? Math.ceil(10 / total) : Math.floor(10 / total));

const PropertyItem = ({ data, fields, onDelete, onChange }) => {
	return (
		<Grid container columnSpacing={4} sx={{ alignItems: "center" }}>
			{fields.map((field, index) => {
				let type = "text";
				let inputProps = {};
				if (field.type) {
					switch (field.type) {
						case "percentage":
							type = "number";
							inputProps = {
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
							};
							break;
						default:
							type = field.type;
							break;
					}
				}

				return (
					<Grid key={`${index}-${field.name}-body`} item xs={colWidth(index, fields.length)}>
						<TextField
							fullWidth
							type={type}
							margin="normal"
							name={field.name}
							defaultValue={data ? data[field.name] : ""}
							onChange={onChange}
							InputProps={inputProps}
						/>
					</Grid>
				);
			})}
			<Grid item xs={2}>
				<IconButton onClick={onDelete}>
					<RemoveIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
};

const MultilineField = ({ items, onChange, fields, addButtonText, addButtonAlignment }) => {
	const defaultItem = {};
	fields.map(field => (defaultItem[field.name] = ""));

	const [listItems, setListItems] = useState(
		!items || !items.length
			? [{ ...defaultItem, _id: Math.random() }]
			: [...items.map(item => ({ ...defaultItem, ...item, _id: Math.random() }))]
	);

	const handleDelete = index => {
		if (listItems.length <= 1) {
			setListItems([{ ...defaultItem, _id: Math.random() }]);
			return;
		}

		setListItems(current => current.filter((item, i) => i != index));

		onChange(listItems);
	};

	const handleAddMore = () => {
		setListItems([...listItems, { ...defaultItem, _id: Math.random() }]);
	};

	const handleChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...listItems];
		list[index][name] = value;
		setListItems(list);

		onChange(list);
	};

	return (
		<>
			<Grid container columnSpacing={4}>
				{fields.map((field, index) => (
					<Grid key={`${index}-${field.name}-title`} item xs={colWidth(index, fields.length)}>
						<Typography variant="body2">{field.title}</Typography>
					</Grid>
				))}
				<Grid item xs={2}></Grid>
			</Grid>

			{listItems.map((property, index) => (
				<PropertyItem
					data={property}
					onChange={e => handleChange(e, index)}
					onDelete={() => handleDelete(index)}
					fields={fields}
					key={`${index}-${property._id}`}
				/>
			))}
			<Box mt={2} sx={{ textAlign: addButtonAlignment }}>
				<Button variant="contained" color="success" onClick={handleAddMore}>
					{addButtonText}
				</Button>
			</Box>
		</>
	);
};

MultilineField.defaultProps = {
	fields: [
		{ name: "title", title: "Type", type: "text" },
		{ name: "value", title: "Name", type: "text" },
	],
	addButtonText: "Add More",
	addButtonAlignment: "center",
};

export default MultilineField;
