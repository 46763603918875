import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "layouts/authentication/hooks/useAuth";
import { setPaymentPopup, setErrorMessage, useSoftUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { CircularProgress, Grid, Card, Chip } from "@mui/material";
import { fetchCourses, getAutoLoginKey } from "services/courses";

import defaultImage from "assets/images/illustrations/image-coming-soon.jpg";

const Learn = () => {
	const [isProcessing, setIsProcessing] = useState(false);
	const [courses, setCourses] = useState([]);
	const [autologinKey, setAutologinKey] = useState([]);

	const { auth } = useAuth();
	const [controller, dispatch] = useSoftUIController();
	const { paymentPopup } = controller;

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		loadLearningCourses();
	}, []);

	const loadLearningCourses = async () => {
		setIsProcessing(true);
		const key = await getAutoLoginKey();
		const courses = await fetchCourses();
		setAutologinKey(key);
		setCourses(courses);
		setIsProcessing(false);
	};

	const accessCourse = async course => {
		// check if user already logged in
		if (!auth?.access_token || !autologinKey) {
			navigate("/dashboard/authentication/sign-in", { state: { from: location } });
			return;
		}
		if (course.paid && !auth.plan?.plan_features.available.includes("gem_ranking")) {
			setPaymentPopup(dispatch, { forStocks: true, on: !paymentPopup.on });
			return;
		}

		window.open(`${process.env.REACT_APP_LEARN_AUTOLOGIN_URL}?key=${autologinKey}&redirect_to=${encodeURI(course.url)}`);

		// redirect to wp autologin link to access the course
		//setErrorMessage(dispatch, "Redirecting to your course! Coming Soon!", "success");
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox mt={3} mb={20}>
				{isProcessing ? (
					<SoftBox style={{ justifyContent: "center", display: "flex" }}>
						<CircularProgress color="inherit" />
					</SoftBox>
				) : courses.length === 0 ? (
					<SoftTypography variant="h2">Sorry! No courses available at the moment</SoftTypography>
				) : (
					<Grid container spacing={2} sx={{ alignItems: "center" }}>
						{courses.map(course => (
							<Grid key={course.id} item xs={12} md={3} style={{ cursor: "pointer" }}>
								<Card onClick={() => accessCourse(course)}>
									<img
										src={course.thumbnail !== "" ? course.thumbnail : defaultImage}
										style={{ height: "166px", width: "100%", objectFit: "cover" }}
									/>
									<SoftBox p={2}>
										<SoftTypography variant="body2" style={{}}>
											{course.title}
										</SoftTypography>
										<Chip
											label={`${course.paid ? "Pro" : "Free"} Course`}
											color="secondary"
											size="small"
										/>
									</SoftBox>
								</Card>
							</Grid>
						))}
					</Grid>
				)}
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
};

export default Learn;
