/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Custom styles for the SoftSnackbar
import SoftSnackbarIconRoot from "components/SoftSnackbar/SoftSnackbarIconRoot";

function SoftSnackbar({ color, icon, title, dateTime, content, close, bgWhite, ...rest }) {
	const { size } = typography;
	let titleColor;
	let dateTimeColor;
	let dividerColor;

	if (bgWhite) {
		titleColor = color;
		dateTimeColor = "dark";
		dividerColor = false;
	} else if (color === "light") {
		titleColor = "dark";
		dateTimeColor = "text";
		dividerColor = false;
	} else {
		titleColor = "white";
		dateTimeColor = "white";
		dividerColor = true;
	}

	return (
		<Snackbar
			TransitionComponent={Fade}
			autoHideDuration={5000}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			{...rest}
			action={
				close ? (
					<IconButton size="small" aria-label="close" color="inherit" onClick={close}>
						<Icon fontSize="small">close</Icon>
					</IconButton>
				) : null
			}
		>
			<SoftBox
				variant={bgWhite ? "contained" : "gradient"}
				bgColor={bgWhite ? "white" : color}
				minWidth="21.875rem"
				maxWidth="100%"
				shadow="md"
				borderRadius="md"
				p={1}
			>
				<SoftBox display="flex" justifyContent="space-between" alignItems="center" color="dark" p={1.5}>
					<SoftBox display="flex" alignItems="center" lineHeight={0}>
						<SoftSnackbarIconRoot fontSize="small" ownerState={{ color, bgWhite }}>
							{icon}
						</SoftSnackbarIconRoot>
						<SoftTypography variant="button" fontWeight="medium" color={titleColor} textGradient={bgWhite}>
							{title}
						</SoftTypography>
					</SoftBox>
					<SoftBox display="flex" alignItems="center" lineHeight={0}>
						{dateTime && (
							<SoftTypography variant="caption" color={dateTimeColor}>
								{dateTime}
							</SoftTypography>
						)}
						<Icon
							sx={{
								color: ({ palette: { dark, white } }) =>
									bgWhite || color === "light" ? dark.main : white.main,
								fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
								cursor: "pointer",
								marginLeft: 2,
								transform: "translateY(-1px)",
							}}
							onClick={close}
						>
							close
						</Icon>
					</SoftBox>
				</SoftBox>
				<Divider sx={{ margin: 0 }} light={dividerColor} />
				<SoftBox p={1.5} color={bgWhite || color === "light" ? "text" : "white"} fontSize={size.sm}>
					{content}
				</SoftBox>
			</SoftBox>
		</Snackbar>
	);
}

// Setting default values for the props of SoftSnackbar
SoftSnackbar.defaultProps = {
	bgWhite: false,
	color: "info",
};

// Typechecking props for SoftSnackbar
SoftSnackbar.propTypes = {
	color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
	icon: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	dateTime: PropTypes.string,
	content: PropTypes.node.isRequired,
	close: PropTypes.func,
	bgWhite: PropTypes.bool,
};

export default SoftSnackbar;
