import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftProcessing from "components/SoftProcessing";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchCategories, fetchCollection } from "services/marketplace";
import { Grid, Typography } from "@mui/material";
import { toSlug } from "layouts/Helper/string.helper";
import { numberFormat } from "layouts/Helper/number.helper";
import { shortAccount } from "layouts/Helper/blockchain.helper";
import SoftAccordion from "components/SoftAccordion";
import { networkName, tokenLinkURL, addressLinkURL } from "layouts/Helper/blockchain.helper";
import ConnectWalletButton from "components/etc/ConnectWalletButton";
import { buy, approveFunds, gotSufficientBalance } from "plugins/Ethereum";
import { useWeb3 } from "context/web3";
import { setErrorMessage, useSoftUIController } from "context";
import { markSold } from "services/marketplace";
import { fetchCollections } from "services/marketplace";
import CollectionCard from "./components/CollectionCard";

const TokenTraits = ({ traits }) => (
	<SoftBox>
		<Grid container columns={3}>
			{traits.map((trait, index) =>
				trait.trait_type ? (
					<Grid key={index} item xs={12} sm={6} lg={1} p={1}>
						<SoftBox
							border={1}
							borderColor="#eee"
							borderRadius="15px"
							alignContent="center"
							display="flex"
							flexDirection="column"
							justifyContent="center"
							height="100%"
							py={3}
						>
							<SoftTypography variant="body2" style={{ textAlign: "center" }}>
								{trait.trait_type}
							</SoftTypography>
							<SoftTypography variant="h6" style={{ textAlign: "center" }}>
								{trait.value}
							</SoftTypography>
						</SoftBox>
					</Grid>
				) : null
			)}
		</Grid>
	</SoftBox>
);

const TokenDetails = ({ collection, token }) => {
	const DetailsRow = ({ title, value, linkTo }) => (
		<Grid container columns={12} mb={2} justifyContent="space-between">
			<Grid item xs={7}>
				<SoftTypography>{title}</SoftTypography>
			</Grid>
			<Grid item xz={4} textAlign="right">
				<SoftTypography style={{ textAlign: "right" }}>
					{linkTo ? (
						<a href={linkTo} target="_blank">
							{value}
						</a>
					) : (
						<>{value}</>
					)}
				</SoftTypography>
			</Grid>
		</Grid>
	);
	return (
		<SoftBox>
			<DetailsRow
				title="Contract Address"
				value={shortAccount(collection.contractAddress)}
				linkTo={tokenLinkURL(collection.chainID, collection.contractAddress, token.tokenID)}
			/>
			<DetailsRow title="Token ID" value={token.tokenID} />
			<DetailsRow title="Token Standard" value={"ERC 721"} />
			<DetailsRow title="Chain" value={networkName(collection.chainID)} />
		</SoftBox>
	);
};

const MarketplaceCollectionToken = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isProcessing, setIsProcessing] = useState(false);
	const [filterBy, setFilterBy] = useState("");
	const [selectedCollection, setSelectedCollection] = useState(null);
	const [otherCollections, setOtherCollections] = useState(null);
	const [selectedToken, setSelectedToken] = useState(null);

	const { web3Controller } = useWeb3();
	const [controller, dispatch] = useSoftUIController();
	const { connected: connectedToBlockchain, ...blockchainInfo } = web3Controller;

	const navigate = useNavigate();

	const { collectionID, tokenID } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		fetchCollections().then(collections => {
			const otherCollections = [];
			collections.map(collection => {
				if (collection.id !== parseInt(collectionID) && otherCollections.length < 2)
					otherCollections.push(collection);
			});
			setOtherCollections(otherCollections);
		});

		const selectedCollection = await fetchCollection(collectionID);
		let selectedToken = null;

		if (selectedCollection) {
			setSelectedCollection(selectedCollection);

			selectedToken = selectedCollection.tokens.find(token => token.id === parseInt(tokenID));
			setSelectedToken(selectedToken);
		}

		if (!selectedCollection || !selectedToken) {
			navigate("/dashboard/error/404");
		}

		setIsLoading(false);
	};

	const handleBuyButton = () => {
		setIsProcessing(true);

		try {
			gotSufficientBalance(selectedToken.listingInfo.price, "0xe097d6b3100777dc31b34dc2c58fb524c2e76921").then(
				haveSufficientBalance => {
					if (haveSufficientBalance)
						approveFunds(selectedToken.listingInfo.price, "0xe097d6b3100777dc31b34dc2c58fb524c2e76921")
							.then(approved => {
								if (approved)
									buy(
										721,
										selectedCollection.contractAddress,
										selectedToken.tokenID,
										selectedToken.listingInfo.price,
										"0xe097d6b3100777dc31b34dc2c58fb524c2e76921"
									)
										.then(async () => {
											markSold(selectedCollection.id, selectedToken.id, {
												purchased_by: blockchainInfo.wallet_address,
											});
										})
										.catch(error => console.log(error))
										.finally(() => {
											setIsProcessing(false);
										});
								else setIsProcessing(false);
							})
							.catch(error => setIsProcessing(false));
					else {
						setErrorMessage(dispatch, "Sorry! You do not have sufficient balance in your wallet.");
						setIsProcessing(false);
					}
				}
			);
		} catch (e) {
			setIsProcessing(false);
			console.log(e);
		}
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox my={3}>
				{isLoading ? (
					<SoftProcessing overlay={false} />
				) : (
					<Card className="main-content dark-background">
						<SoftBox p={3}>
							<Grid container columns={2} spacing={3}>
								<Grid item style={{ maxWidth: "500px" }}>
									<img
										src={`${
											process.env.REACT_APP_GCLOUD_STORAGE_BASE_URL
										}${selectedToken.imageURL.replace("ipfs://", "")}`}
										style={{
											width: "100%",
											// maxWidth: "500px",
											// height: "500px",
											objectFit: "cover",
											borderRadius: "15px",
										}}
									/>
									<SoftBox my={3} pt={3} pb={6} px={4} className="nft-details">
										<SoftAccordion
											sections={[
												{
													title: "Description",
													content: (
														<SoftTypography>
															{selectedCollection.description}
														</SoftTypography>
													),
												},
												{
													title: "Properties",
													content: (
														<TokenTraits traits={selectedToken.metadataCache?.attributes} />
													),
												},
												{
													title: "Details",
													content: (
														<TokenDetails
															collection={selectedCollection}
															token={selectedToken}
														/>
													),
												},
											]}
											style={{ backgroundColor: "transparent" }}
										/>
									</SoftBox>
								</Grid>
								<Grid item style={{ flexGrow: "1" }}>
									<SoftTypography variant="h5" mb={2} color="secondary">
										<Link
											to={`/dashboard/marketplace/collection/${selectedCollection.id}/${toSlug(
												selectedCollection.name
											)}`}
										>
											{selectedCollection.name}
										</Link>
									</SoftTypography>
									<SoftTypography variant="h3">Token #{selectedToken.tokenID}</SoftTypography>
									<SoftTypography variant="h5">
										Owned By{" "}
										<a
											href={addressLinkURL(selectedCollection.chainID, selectedToken.owner)}
											target="_blank"
										>
											{shortAccount(selectedToken.owner)}
										</a>
									</SoftTypography>
									{(selectedToken.listingInfo?.listed || selectedCollection.current_price > 0) && (
										<SoftBox my={3}>
											<SoftBox p={2} border={1} borderColor="#eee" borderRadius="15px">
												<Typography variant="h4" mb={2}>
													Buy Now
												</Typography>
												<Typography mb={2}>
													NFT Price: USDC{" "}
													{numberFormat(
														selectedToken.listingInfo.price ??
															selectedCollection.current_price
													)}
												</Typography>
												<ConnectWalletButton
													textUponConnected="Buy Now"
													actionAfterConnected={handleBuyButton}
													restrictedToNetwork={80001}
													size="large"
													fullWidth
													disabled
												/>
												<SoftTypography
													variant="body2"
													style={{ fontStyle: "italic" }}
													textAlign="center"
												>
													This NFT will be available soon to Buy!
												</SoftTypography>
											</SoftBox>
										</SoftBox>
									)}
									{otherCollections.length > 0 && (
										<SoftBox mt={6}>
											<SoftTypography mb={3} variant="h2" textAlign="center">
												More from this collection
											</SoftTypography>
											<Grid container spacing={3} sx={{ justifyContent: "center" }}>
												{otherCollections.map((collection, index) => (
													<Grid
														key={index}
														item
														sm={6}
														md={4}
														lg={3}
														className="collection-container"
													>
														<CollectionCard collection={collection} />
													</Grid>
												))}
											</Grid>
										</SoftBox>
									)}
								</Grid>
							</Grid>
						</SoftBox>
					</Card>
				)}
			</SoftBox>
			{isProcessing && <SoftProcessing />}
			<Footer />
		</DashboardLayout>
	);
};

export default MarketplaceCollectionToken;
