/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { get as getValue } from "lodash";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { planSettings } from "config/plan";

function DefaultPricingCard({ badge, price, mode, specifications, specificationsFromLowerPlans, isPopup, action }) {
	const navigate = useNavigate();
	const renderSpecificationsFromLowerPlans = specificationsFromLowerPlans.map((name, index) => (
		<SoftBox key={index} display="flex" alignItems="flex-start" p={1}>
			<SoftBox
				display="flex"
				justifyContent="center"
				alignItems="center"
				width="1rem"
				height="1rem"
				borderRadius="50%"
				shaodw="md"
				bgColor="success"
				variant="gradient"
				mr={2}
			>
				<SoftTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
					<Icon sx={{ fontWeight: "bold" }}>done</Icon>
				</SoftTypography>
			</SoftBox>
			<SoftTypography variant="body2" sx={{ fontSize: "12px", color: "#ECA231", fontWeight: "700" }}>
				All Features from BBV {name}
			</SoftTypography>
		</SoftBox>
	));
	const renderSpecifications = specifications.map(key => (
		<SoftBox key={key} display="flex" alignItems="flex-start" p={1}>
			<SoftBox
				display="flex"
				justifyContent="center"
				alignItems="center"
				width="1rem"
				height="1rem"
				borderRadius="50%"
				shaodw="md"
				bgColor="success"
				variant="gradient"
				mr={2}
			>
				<SoftTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
					<Icon sx={{ fontWeight: "bold" }}>done</Icon>
				</SoftTypography>
			</SoftBox>
			<SoftTypography variant="body2" color="text" sx={{ fontSize: "12px" }}>
				{getValue(planSettings, `${key}.description`, key)}
			</SoftTypography>
		</SoftBox>
	));

	return (
		<Card sx={{ height: "100%" }}>
			
			<SoftBox pt={3} pb={2} px={2} textAlign="center">
				{isPopup && (
					<SoftTypography variant="h5" className="helvetica greenText" style={{ marginBottom: "10px", fontWeight: "700" }}>
						Upgrade your plan to use PRO features
					</SoftTypography>
				)}
				<SoftTypography variant="h2" sx={{ fontSize: "20px", fontWeight: "700" }}>
					<SoftTypography variant="span" sx={{ color: "#ECA231" }}>
						BBV
					</SoftTypography>{" "}
					{badge.label}
				</SoftTypography>
				<SoftBox my={1}>
					<SoftTypography variant="h1" sx={{ fontSize: "46px", fontWeight: "700" }}>
						{price.currency}
						{Math.round(price.value / (mode === 1 ? 12 : 1))}
						<SoftTypography color="text" variant="small" sx={{ fontSize: "14px", fontWeight: "400" }}>
							{" "}
							/ month
						</SoftTypography>
					</SoftTypography>
				</SoftBox>
				<SoftBox>
					<p className="roboto" style={{fontSize:"14px",color:"#ECA231",textDecoration:"underline"}}>GET 20% DISCOUNT FOR THE ANNUAL SUBSCRIPTION</p>
				</SoftBox>
			</SoftBox>
			<SoftBox pb={3} px={3} sx={{ height: "100%", paddingBottom: isPopup ? "120px" : "98px" }}>
				{renderSpecificationsFromLowerPlans}
				{renderSpecifications}
				<SoftBox style={{}} sx={{ position: "absolute", width: "calc(100% - 48px)", bottom: "25px" }}>
					{action.type === "internal" ? (
						<SoftBox mt={3}>
							<SoftButton
								component={Link}
								to={action.route}
								sx={{
									background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
									color: "#FFF",
								}}
								fullWidth
							>
								{action.label}
							</SoftButton>
						</SoftBox>
					) : (
						<SoftBox mt={3}>
							{action.type === "callback" || action.type === "none" ? (
								<SoftButton
									onClick={action.type === "none" ? () => {} : action.callback}
									sx={{
										background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
										color: "#FFF",
									}}
									fullWidth
									style={{ marginTop: "20px" }}
								>
									{action.label}
								</SoftButton>
							) : (
								<SoftButton
									component="a"
									href={action.route}
									target="_blank"
									rel="noreferrer"
									sx={{
										background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
										color: "#FFF",
									}}
									fullWidth
								>
									{action.label}
								</SoftButton>
							)}
							{isPopup && (
								<SoftButton
									onClick={() => {
										navigate(`/dashboard/membership`);
									}}
									fullWidth
									className="bbvGrey"
									style={{ marginTop: "10px" }}
								>
									See all plans
								</SoftButton>
							)}
						</SoftBox>
					)}
				</SoftBox>
			</SoftBox>
		</Card>
	);
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
	badge: PropTypes.shape({
		color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "light", "dark"])
			.isRequired,
		label: PropTypes.string.isRequired,
	}).isRequired,
	price: PropTypes.shape({
		currency: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
	}).isRequired,
	specifications: PropTypes.arrayOf(PropTypes.string).isRequired,
	specificationsFromLowerPlans: PropTypes.arrayOf(PropTypes.string).isRequired,
	action: PropTypes.shape({
		type: PropTypes.oneOf(["none", "external", "internal", "callback"]).isRequired,
		route: PropTypes.string,
		label: PropTypes.string.isRequired,
	}).isRequired,
};

export default DefaultPricingCard;
