import * as Yup from "yup";
import { Formik, Form } from "formik";
import { FormControl, TextField, InputAdornment } from "@mui/material";
import Card from "@mui/material/Card";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useState } from "react";

const ListingForm = ({ onSave }) => {
	const [listingStep, setListingStep] = useState("");

	const continueListingWith = option => {
		setListingStep(option);
	};

	return (
		<>
			<SoftBox mb={2}>
				<SoftTypography variant="h5" mb={2}>
					Choose a type of sale
				</SoftTypography>
				<SoftButton
					color={listingStep === "fixed-price" ? "error" : "secondary"}
					style={{ marginBottom: 10 }}
					fullWidth={true}
					onClick={() => continueListingWith("fixed-price")}
				>
					Fixed Price
					<br />
					The Item is listed at the price you set.
				</SoftButton>
				<SoftButton
					color={listingStep === "auction" ? "error" : "secondary"}
					fullWidth={true}
					onClick={() => continueListingWith("auction")}
				>
					Auction
					<br />
					Coming Soon!
				</SoftButton>
			</SoftBox>
			{listingStep === "fixed-price" && (
				<>
					<Formik
						initialValues={{
							price: "",
						}}
						validationSchema={Yup.object().shape({
							price: Yup.number().required("Please specify a Price"),
						})}
						onSubmit={(values, actions) => {
                            actions.setSubmitting(false);

                            onSave && onSave(values)
                        }}
					>
						{({ values, errors, touched, isSubmitting, handleBlur, handleChange }) => (
							<Form autoComplete="off">
								<Card sx={{ height: "100%" }}>
									<SoftBox p={2}>
										<FormControl margin="normal" fullWidth>
											<SoftTypography variant="body2">Set a price</SoftTypography>
											<TextField
												error={Boolean(touched.price && errors.price)}
												fullWidth
												placeholder="Selling price"
												name="price"
												onBlur={handleBlur}
												onChange={handleChange}
												type="text"
												value={values.price}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">USDC</InputAdornment>
													),
												}}
											/>
										</FormControl>
										<SoftButton
											disabled={isSubmitting}
											type="submit"
											variant="gradient"
											color="dark"
											fullWidth
										>
											Save
										</SoftButton>
									</SoftBox>
								</Card>
							</Form>
						)}
					</Formik>
				</>
			)}
		</>
	);
};

export default ListingForm;
