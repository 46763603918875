import { axiosWithCache } from "layouts/authentication/api/axios";

export const fetchPlans = async () => {
	try {
		const res = await axiosWithCache(`/plans`, 'data');
		return res;
	} catch (error) {
		console.log(error);
	}
	return [];
};
