/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import PaymentPopup from "examples/Popup";
import InvalidAccess from "examples/InvalidAccess";
// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
import routes, { admin_routes } from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

import RequireAuth from "layouts/authentication/requireAuth";
import { fetchPlans } from "services/plan";

// Images
import brand from "assets/images/logo-ct.png";
import { Web3Provider } from "context/web3";
import ErrorMessage from "examples/ErrorMessage";
const brandName = "BBV Dashboard";

export default function App() {
	const [controller, dispatch] = useSoftUIController();
	const { miniSidenav, layout, openConfigurator } = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const { pathname } = useLocation();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Change the openConfigurator state
	const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

	useEffect(() => {
		fetchPlans();
	}, []);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = allRoutes =>
		allRoutes.map(route => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}

			if (route.route) {
				// auto protect all routes under /admin
				if (route.route.search("/admin") === 0) {
					route.protected = true;
					route.roles = ["admin"];
				}

				if (route.protected) {
					return (
						<Route element={<RequireAuth allowedRoles={route.roles} />} key={route.key}>
							<Route exact path={route.route} element={route.component} />
						</Route>
					);
				}
				return <Route exact path={route.route} element={route.component} key={route.key} />;
			}

			return null;
		});

	const configsButton = (
		<SoftBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="3.5rem"
			height="3.5rem"
			bgColor="white"
			shadow="sm"
			borderRadius="50%"
			position="fixed"
			right="2rem"
			bottom="2rem"
			zIndex={99}
			color="dark"
			sx={{ cursor: "pointer" }}
			onClick={handleConfiguratorOpen}
		>
			<Icon fontSize="default" color="inherit">
				settings
			</Icon>
		</SoftBox>
	);

	return (
		<Web3Provider>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{layout === "dashboard" && (
					<>
						<Sidenav
							// color={sidenavColor}
							color="error"
							brand={brand}
							brandName={brandName}
							routes={pathname.search("/admin") === 0 ? admin_routes : routes}
							onMouseEnter={handleOnMouseEnter}
							onMouseLeave={handleOnMouseLeave}
						/>
						<Configurator />
						<PaymentPopup />
						<InvalidAccess />
						<ErrorMessage />
						{configsButton}
					</>
				)}
				{layout === "vr" && <Configurator />}
				<Routes>
					{getRoutes(pathname.search("/admin") === 0 ? admin_routes : routes)}
					<Route path="*" element={<Navigate to="/dashboard" />} />
				</Routes>
			</ThemeProvider>
		</Web3Provider>
	);
}
