/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";
import bbvBackground from "assets/images/bbvBackground.jpg";

function Header() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardNavbar  pageTitle="profile" />
    // <SoftBox position="relative">
    //   <DashboardNavbar absolute light />
    //   <SoftBox
    //     display="flex"
    //     alignItems="center"
    //     position="relative"
    //     minHeight="139px"
    //     borderRadius="xl"
    //     className="backgroundImage"
    //     style = {{backgroundImage:`url('${bbvBackground}')`}}
    //     // sx={{
    //     //   backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
    //     //     `${linearGradient(
    //     //       rgba(gradients.info.main, 0.6),
    //     //       rgba(gradients.info.state, 0.6)
    //     //     )}, url(${bbvBackground})`,
    //     //   backgroundSize: "cover",
    //     //   backgroundPosition: "50%",
    //     //   overflow: "hidden",
    //     // }}
    //   />
    //   {/* <Card
    //     sx={{
    //       backdropFilter: `saturate(200%) blur(30px)`,
    //       backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
    //       boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
    //       position: "relative",
    //       mt: -8,
    //       mx: 3,
    //       py: 2,
    //       px: 2,
    //     }}
    //   >
    //     <Grid container spacing={3} alignItems="center">
    //       <Grid item>
    //         <SoftAvatar
    //           src={burceMars}
    //           alt="profile-image"
    //           variant="rounded"
    //           size="xl"
    //           shadow="sm"
    //         />
    //       </Grid>
    //       <Grid item>
    //         <SoftBox height="100%" mt={0.5} lineHeight={1}>
    //           <SoftTypography variant="h5" fontWeight="medium">
    //             Alex Thompson
    //           </SoftTypography>
    //           <SoftTypography variant="button" color="text" fontWeight="medium">
    //             CEO / Co-Founder
    //           </SoftTypography>
    //         </SoftBox>
    //       </Grid>
    //       <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
    //         <AppBar position="static">
    //           <Tabs
    //             orientation={tabsOrientation}
    //             value={tabValue}
    //             onChange={handleSetTabValue}
    //             sx={{ background: "transparent" }}
    //           >
    //             <Tab label="App" icon={<Cube />} />
    //             <Tab label="Message" icon={<Document />} />
    //             <Tab label="Settings" icon={<Settings />} />
    //           </Tabs>
    //         </AppBar>
    //       </Grid>
    //     </Grid>
    //   </Card> */}
    // </SoftBox>
  );
}

export default Header;
