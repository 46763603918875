import form from "./form";

const {
	formField: { name },
} = form;

const initialValues = {
	[name.name]: "",
};

export default initialValues;
