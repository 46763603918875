import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SoftAccordion({ sections, style }) {
	return (
		<>
			{sections.map((section, index) => (
				<Accordion key={index} style={style}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>{section.title}</Typography>
					</AccordionSummary>
					<AccordionDetails>{section.content}</AccordionDetails>
				</Accordion>
			))}
		</>
	);
}
