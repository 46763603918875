import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import mmmIMG from "assets/images/MMM.png";
import { getNewsData } from "../data-tables/data/getData";
import StockDetailsTable from "./StockDetailsTable";
import graphNew from "assets/images/graphNew.png";
import { Button, Card } from "@mui/material";
import { getSingleStockData } from "../data-tables/data/getData";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import BackToButton from "components/CustomComponents";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import { StockInfoPopup } from "components/CustomPopups/StockPopups";
import InfoIcon from "@mui/icons-material/Info";
const TechnicalAnalysis = ({ exchange, stockCode, handleInfoPop }) => {
	if (!exchange || !stockCode) return null;
	const source1 = `https://s.tradingview.com/embed-widget/symbol-info/?locale=en&symbol=${exchange}%3A${stockCode}#%7B%22symbol%22%3A%22NASDAQ%3AMSTR%22%2C%22width%22%3A%22100%25%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22height%22%3A205%2C%22utm_source%22%3A%22%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22symbol-info%22%2C%22page-uri%22%3A%22__NHTTP__%22%7D`;
	const source2 = `https://s.tradingview.com/embed-widget/technical-analysis/?locale=en#%7B%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22symbol%22%3A%22${stockCode}%22%2C%22interval%22%3A%221M%22%2C%22utm_source%22%3A%22%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22technical-analysis%22%2C%22page-uri%22%3A%22__NHTTP__%22%7D`;
	const source3 = `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_fc027&symbol=${stockCode}&interval=D&saveimage=0&toolbarbg=f1f3f6&details=1&studies=CCI%40tv-basicstudies%1FRSI%40tv-basicstudies&theme=light&style=2&timezone=Etc%2FUTC&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=&utm_medium=widget_new&utm_campaign=chart&utm_term=MSTR#%7B%22page-uri%22%3A%22__NHTTP__%22%7D`;

	return (
		<div
			style={{
				backgroundColor: "white",
				borderRadius: "16px",
				marginTop: "20px",
				padding: "20px",
			}}
		>
			<div style={{ marginTop: "10px", marginBottom: "8px", display: "flex", justifyContent: "left", textAlign: "center", alignItems: "center" }}>
				<p className="roboto" style={{ textAlign: "center", fontSize: "18px", marginRight: "5px" }}>
					TECHNICAL ANALYSIS
				</p>
				<InfoIcon onClick={() => { handleInfoPop("technicalAnalysis") }} className="icon"></InfoIcon>
			</div>
			<iframe title="technical" src={source1} className="fullWidth technicalAnalysisOne"></iframe>
			<iframe title="technical2" src={source2} className="fullWidth technicalAnalysisTwo"></iframe>
		</div>
	);
};

const StockDetails = ({ match }) => {
	const { stockCode } = useParams();
	const navigate = useNavigate();
	const [news, setNews] = useState([]);
	const [numNews, setNewsNum] = useState();
	const [current, setCurrent] = useState("GEM Ranking");
	const [theStockData, setStockData] = useState(0);
	const [exchange, setExchange] = useState("");
	const [error, setError] = useState(false);
	const [infoPopup, toggleInfoPopup] = useState(false)
	const [popupAccessor, setPopupAccessor] = useState("")

	useEffect(() => {
		if (stockCode) {
			getNews(stockCode);
			handleStock();
		}
	}, [stockCode]);

	useEffect(() => {
		console.log(exchange);
	}, [exchange]);

	const handleCurrent = name => {
		setCurrent(name);
	};
	const getNews = async stockCode => {
		const newsData = await getNewsData(stockCode);
		setNewsNum(newsData.length);
		setNews(newsData);
	};

	const trimmedWord = word => {
		const maxLength = 117;
		var trimmedString = word.substr(0, maxLength);
		trimmedString =
			trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + "....";
		return trimmedString;
	};

	const handleInfoPop = (popupAccessor) => {
		console.log("pressed")
		toggleInfoPopup(true)
		setPopupAccessor(popupAccessor)
	}

	const handleStock = async () => {
		const stocksData = await getSingleStockData(stockCode);
		console.log('stocks data is here ')
		console.log(stocksData)
		if (stocksData) {
			setError(false);
			setStockData(stocksData[0]);
			setExchange(stocksData[0].exchange);
		} else {
			setError(true);
			console.log('error')
		}
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{!error ?
				<Grid container spacing={3}>
					<Grid item xs={12} lg={8} xl={8}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ marginTop: "", display: "flex", justifyContent: "left", textAlign: "center", alignItems: "center" }}>
								<h3 style={{ textAlign: "center", fontSize: "18px", marginRight: "5px" }}>
									FUNDAMENTAL ANALYSIS
								</h3>
								<InfoIcon onClick={() => { handleInfoPop("fundamentalAnalysis") }} className="icon"></InfoIcon>
							</div>
							<BackToButton
								text="Back to Stock Rankings"
								destination="/dashboard/GEM/stocks-ranking"
							></BackToButton>
						</div>
						<div className="stocksDescDiv ">
							<SoftButton
								className="bbvGrey stockDescButtons"
								onClick={() => {
									handleCurrent("GEM Ranking");
								}}
							>
								GEM <br /> Ranking
							</SoftButton>
							<SoftButton
								className="bbvGrey stockDescButtons"
								onClick={() => {
									handleCurrent("Company Description");
								}}
							>
								Company <br /> Description
							</SoftButton>
							<SoftButton
								className="bbvGrey stockDescButtons"
								onClick={() => {
									handleCurrent("General Information");
								}}
							>
								General
								<br /> Information
							</SoftButton>
							<SoftButton
								className="bbvGrey stockDescButtons"
								onClick={() => {
									handleCurrent("Key Financial Ratios");
								}}
							>
								Key Financial <br />
								Ratios
							</SoftButton>
							<SoftButton
								className="bbvGrey stockDescButtons"
								onClick={() => {
									handleCurrent("Income Statement");
								}}
							>
								Income <br />
								Statement
							</SoftButton>
							<SoftButton
								className="bbvGrey stockDescButtons"
								onClick={() => {
									handleCurrent("Financial Health");
								}}
							>
								Financial <br />
								Health
							</SoftButton>
							<SoftButton
								className="bbvGrey stockDescButtons"
								onClick={() => {
									handleCurrent("Focus Ratio");
								}}
							>
								Focus <br /> Ratio
							</SoftButton>
						</div>

						<SoftBox mb={3}>
							<StockDetailsTable code={stockCode} current={current} fullData={theStockData} />
						</SoftBox>

						<Card>
							<SoftBox p={2}>
								<iframe
									title="technical3"
									src={`https://s.tradingview.com/widgetembed/?frameElementId=tradingview_fc027&symbol=${stockCode}&interval=D&saveimage=0&toolbarbg=f1f3f6&details=1&studies=CCI%40tv-basicstudies%1FRSI%40tv-basicstudies&theme=light&style=2&timezone=Etc%2FUTC&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=&utm_medium=widget_new&utm_campaign=chart&utm_term=MSTR#%7B%22page-uri%22%3A%22__NHTTP__%22%7D`}
									className="fullWidth"
									style={{ height: "500px" }}
								></iframe>
							</SoftBox>
						</Card>
						<div style={{ backgroundColor: "white", borderRadius: "16px", marginTop: "20px", padding: "20px" }}>
							<p className="roboto" style={{ paddingBottom: "20px", fontSize: "18px" }}>
								COMPANY NEWS
							</p>
							<div style={numNews > 3 ? { height: "480px", overflowY: "auto" } : {}}>
								{numNews < 1 ? (
									<div>
										<p>No news</p>
									</div>
								) : (
									news.map((row, key) => {
										return (
											<div key={key}>
												<p className="roboto_medium" style={{ fontSize: "16px", color: "#2D3748" }}>
													{" "}
													{row.title}{" "}
												</p>
												<p className="roboto_medium" style={{ fontSize: "12px", color: "#718096" }}>
													{row.pubDate}
												</p>
												<p className="" style={{ fontSize: "14px" }}>
													{trimmedWord(row.description)}{" "}
												</p>
												<a
													target="blank"
													href={row.link}
													className="helvetica anchorNews"
													style={{ fontSize: "14px", float: "right" }}
												>
													Read More
												</a>
												<br></br>
											</div>
										);
									})
								)}
							</div>
						</div>
						{/* <SoftButton onClick ={()=>{goBack()}}>Back</SoftButton> */}
						<Grid style={{ marginTop: "12px" }} spacing={2} container>

						</Grid>
					</Grid>
					<Grid item xs={12} lg={4} xl={4}>
						<TechnicalAnalysis handleInfoPop={handleInfoPop} exchange={exchange} stockCode={stockCode} />
						<Grid item xs={12} lg={12} xl={12}>
							<div className="stockGraphDiv">
								<div style={{ marginTop: "18px", display: "flex", justifyContent: "left", textAlign: "center", alignItems: "center" }}>
									<p className="roboto" style={{ textAlign: "center", fontSize: "18px", marginRight: "5px" }}>
										Profit/Loss
									</p>
									<InfoIcon onClick={() => { handleInfoPop("profitAndLoss") }} className="icon"></InfoIcon>
								</div>
								<img className="stockGraphs" alt="profit/loss" src={graphNew}></img>
							</div>
						</Grid>
						<Grid item xs={12} lg={12} xl={12}>
							<div className="stockGraphDiv">
								<div style={{ marginTop: "18px", display: "flex", justifyContent: "left", textAlign: "center", alignItems: "center" }}>
									<p className="roboto" style={{ textAlign: "center", fontSize: "18px", marginRight: "5px" }}>
										Cash Flow
									</p>
									<InfoIcon onClick={() => { handleInfoPop("cashFlowStatement") }} className="icon"></InfoIcon>
								</div>
								<img className="stockGraphs" alt="cash flow" src={graphNew}></img>
							</div>
						</Grid>
						<Grid item xs={12} lg={12} xl={12}>
							<div className="stockGraphDiv" style={{ marginBottom: "15px" }}>
								<div style={{ marginTop: "18px", display: "flex", justifyContent: "left", textAlign: "center", alignItems: "center" }}>
									<p className="roboto" style={{ textAlign: "center", fontSize: "18px", marginRight: "5px" }}>
										Balance Sheet
									</p>
									<InfoIcon onClick={() => { handleInfoPop("balanceSheet") }} className="icon"></InfoIcon>
								</div>
								<img className="stockGraphs" alt="balance sheet" src={graphNew}></img>
							</div>
						</Grid>
					</Grid>
				</Grid> :
				<SoftBox style={{ textAlign: "center", height: "400px" }}>
					<h1>We are sorry, but we do not seem to have information on stock {stockCode}</h1>
					<SoftButton style={{ marginTop: "20px" }} onClick={() => { navigate('/dashboard') }}>Back to dashboard</SoftButton>
				</SoftBox>}

			<Footer />

			{infoPopup ? <StockInfoPopup toggleInfoPopup={toggleInfoPopup} popupAccessor={popupAccessor} /> : <div></div>}
		</DashboardLayout>
	);
};

export default StockDetails;
