import { createContext, useContext, useEffect, useMemo, useReducer, useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import { detectNetworkAccount } from "plugins/Web3Connection";

function reducer(state, action) {
	switch (action.type) {
		case "CONNECT_WALLET": {
			return {
				...state,
				connected: true,
				wallet_address: action.value.account,
				network_id: action.value.networkId,
				provider: action.value.provider,
			};
		}
		case "SWITCHED_ACCOUNT": {
			return {
				...state,
				wallet_address: action.value,
			};
		}
		case "SWITCHED_NETWORK": {
			return {
				...state,
				network_id: action.value,
			};
		}
		case "DISCONNECT_WALLET": {
			return { ...state, connected: false, wallet_address: null, provider: null };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

const Web3Context = createContext(null);

export const Web3Provider = ({ children }) => {
	const initialState = {
		provider: null,
		connected: false,
		network_id: null,
		wallet_address: null,
	};

	const [processing, setProcessing] = useState(false);
	const [controller, dispatch] = useReducer(reducer, initialState);

	const value = useMemo(
		() => ({
			web3Controller: controller,
			dispatch,
		}),
		[controller, dispatch]
	);

	useEffect(() => {
		// connectToBlockchain()

		if (window.ethereum) {
			window.ethereum.on("accountsChanged", function (accounts) {
				dispatch({ type: "SWITCHED_ACCOUNT", value: accounts[0] });
			});

			window.ethereum.on("networkChanged", function (networkId) {
				dispatch({ type: "SWITCHED_NETWORK", value: parseInt(networkId) });
			});
		}
	}, []);

	const updateBlockchainInfo = () => {
		setProcessing(true);

		try {
			detectNetworkAccount()
				.then(info => {
					dispatch({ type: "CONNECT_WALLET", value: { ...info, provider: "metamask" } });
				})
				.catch(err => {
					console.log(err);
					// user disconnected the account
					dispatch({ type: "DISCONNECT_WALLET" });
				})
				.finally(() => {
					setProcessing(false);
				});
		} catch (e) {
			setProcessing(false);
			console.log(e);
		}
	};

	const connectToBlockchain = () => {
		updateBlockchainInfo();
	};

	const disconnectFromBlockchain = () => {
		dispatch({ type: "DISCONNECT_WALLET" });
	};

	return (
		<Web3Context.Provider value={{ ...value, connectToBlockchain, disconnectFromBlockchain }}>
			{children}
			{processing && (
				<div
					style={{
						position: "fixed",
						inset: 0,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						background: "rgba(0,0,0,0.5)",
						zIndex: 100000,
					}}
				>
					<CircularProgress color="inherit" />
				</div>
			)}
		</Web3Context.Provider>
	);
};

export const useWeb3 = () => {
	const context = useContext(Web3Context);

	if (!context) {
		throw new Error("useWeb3 should be used inside the Web3Provider.");
	}

	return context;
};

Web3Provider.propTypes = {
	children: PropTypes.node.isRequired,
};
