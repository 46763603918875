import React, { useContext, useEffect, useState } from "react";
import { getSingleStockData } from "../data-tables/data/getData";
import InfoIcon from "@mui/icons-material/Info";
import { style } from "@mui/system";
import { roundToTwo } from "layouts/Helper/helper";
import KeyIconOrData from "components/etc/KeyIconOrData";
import { TLSText } from "components/CustomComponents";
import { TLSColour } from "components/CustomComponents";
import { TLSGEMColour } from "components/CustomComponents";
import { StockInfoPopup } from "components/CustomPopups/StockPopups";



function StockDetailsTable({ code, current, fullData }) {
	const [theStockData, setStockData] = useState(0);
	const [infoPopup,toggleInfoPopup] = useState(false)
	const [popupAccessor,setPopupAccessor] = useState("")

	const handleInfoPop = (popupAccessor) =>{
		console.log("pressed")
		toggleInfoPopup(true)
		setPopupAccessor(popupAccessor)
	}

	useEffect(() => {
		setStockData(fullData);
	}, [fullData]);



	const GemRanking = () => {
		return (
			<div>
				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("greatReturns") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Great Return
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo(theStockData["roicTTM"] * 100)} green = {30} yellow = {15} red = {0} />
					
				</div>
				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("cashflow") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Efficient Cashflow
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo(theStockData["Earnings_Quality"])} green = {30}  red = {0} />
				</div>
				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("moneyvalue") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Money Value
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo((theStockData["ebit"] / theStockData["ev"]) * 100)} green = {10}  red = {10} />
				</div>
				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("gemMarket") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							GEM Stock Rank
						</p>
					</div>
					<p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>
						<KeyIconOrData value={theStockData["GEM_Rank"]} requiredFeature="gem_ranking" colour={TLSGEMColour(theStockData["GEM_Rank"],1500,2500)} />
					</p>
					{/* <p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>{theStockData["GEM_Rank"]}</p>  */}
				</div>
				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("gemPrevious") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							GEM Previous Quarter
						</p>
					</div>
					<p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>
						<KeyIconOrData value={theStockData["GEM_Rank_one"]} requiredFeature="gem_ranking" colour={TLSGEMColour(theStockData["GEM_Rank_one"],1500,2500)} />
					</p>
					{/* <p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>{theStockData["GEM_Rank_one"]}</p>  */}
				</div>
				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("gemTwoQuarters") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							GEM Two Quarters ago
						</p>
					</div>
					<p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>
						<KeyIconOrData value={theStockData["GEM_Rank_two"]} requiredFeature="gem_ranking" colour={TLSGEMColour(theStockData["GEM_Rank_two"],1500,2500)}/>
					</p>
					{/* <p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>{theStockData["GEM_Rank_two"]}</p>  */}
				</div>
				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("gemThreeQuarters") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							GEM Three Quarters ago
						</p>
					</div>
					{/* <p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>{theStockData["GEM_Rank_three"]}</p>  */}
					<p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>
						<KeyIconOrData value={theStockData["GEM_Rank_three"]} requiredFeature="gem_ranking" colour={TLSGEMColour(theStockData["GEM_Rank_three"],1500,2500)}/>
					</p>
				</div>
			</div>
		);
	};
	const CompanyDescription = () => {
		return (
			<div className="stockRow">
				<div className="detailHeading bbvGrey">
					<h3>Company Description</h3>
				</div>
				<p className="stockDesc">{theStockData["description"]}</p>
			</div>
		);
	};

	const GeneralInformation = () => {
		return (
			<div>
				<div className="detailHeading bbvGrey">
					<h3>General Information</h3>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("currency") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Currency
						</p>
					</div>
					<p className="inline helvetica" style={{ fontSize: "14px" }}>
						{theStockData["currency"]}
					</p>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("country") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Country
						</p>
					</div>
					<p className="inline helvetica" style={{ fontSize: "14px" }}>
						{theStockData["country"]}
					</p>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("exchange") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Exchange
						</p>
					</div>
					<p className="inline helvetica" style={{ fontSize: "14px" }}>
						{theStockData["exchange"]}
					</p>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("sector") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Sector
						</p>
					</div>
					<p className="inline helvetica" style={{ fontSize: "14px" }}>
						{theStockData["gsector"]}
					</p>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("industry") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Industry
						</p>
					</div>
					<p className="inline helvetica" style={{ fontSize: "14px" }}>
						{theStockData["finnhubIndustry"]}
					</p>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("marketCap") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Market Cap (Million)
						</p>
					</div>
					<TLSText higherBetter = {true} value ={theStockData["marketCapitalization"]} green = {10000} yellow = {2000}  red = {300} />
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("divYield") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Annual Dividend Yield
						</p>
					</div>
					<TLSText higherBetter = {true} value ={theStockData["currentDividendYieldTTM"]} green = {7} yellow = {4}  red = {4} />
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("recentQuarter") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Most Recent Quarter
						</p>
					</div>
					<p className="inline helvetica" style={{ fontSize: "14px" }}>
						{theStockData["mostRecentQuarter"]}
					</p>
				</div>
			</div>
		);
	};

	const KeyFinancialRatios = () => {
		return (
			<div>
				<div className="detailHeading bbvGrey">
					<h3>Key Financial Ratios</h3>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("PERatio") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Price to Equity (PE) Ratio
						</p>
					</div>
					<TLSText higherBetter = {false} value ={roundToTwo(theStockData["peTTM"])} green = {15} yellow = {roundToTwo(theStockData["sector_peTTM_a"])}  red = {roundToTwo(theStockData["sector_peTTM_a"])} />
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("PBRatio") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Price to Book Ratio
						</p>
					</div>
					<TLSText higherBetter = {false} value ={roundToTwo(theStockData["pb"])} green = {1} yellow = {1} red = {3} />
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("PEGRatio") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							PE Growth Ratio
						</p>
					</div>
					<TLSText higherBetter = {false} value ={roundToTwo(theStockData["PEG_Ratio"])} green = {1} red = {1} />
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("SPERatio") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Sector PE Ratio
						</p>
					</div>
					<p className="inline helvetica" style={{ fontSize: "14px" }}>
						{roundToTwo(theStockData["sector_peTTM_a"])}
					</p>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("ROE") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Return on Equity
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo(theStockData["roeTTM"])} green = {10} yellow = {0} red = {0} />
					
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("ROA") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Return on Asset
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo(theStockData["roaTTM"])} green = {10} yellow = {0} red = {0} />
				
				</div>

				

				<div className="detailHeading bbvGrey">
					<h3>General Earning Data</h3>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("EPS") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Earnings Per Share
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo(theStockData["epsEstimateAvg"])} green = {0}  red = {0} />
			
				</div>
			</div>
		);
	};

	const IncomeStatement = () => {
		return (
			<div>
				<div className="detailHeading bbvGrey">
					<h3>Income Statement</h3>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("GPM") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Gross Profit Margin %
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo(theStockData["netProfitMarginTTM"])} green = {30} yellow = {20} red = {0} />
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("OM") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Operating Margin %
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo(theStockData["operatingMarginTTM"])} green = {20} yellow = {10} red = {0} />
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("NPM") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Net Profit Margin %
						</p>
					</div>
					<p className="inline helvetica" style={{ fontSize: "14px" }}>
						<TLSText higherBetter = {true} value ={roundToTwo(theStockData["netProfitMarginTTM"])} green = {20} yellow = {5} red = {0} />
					</p>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("NBAT") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							NPAT {"<"} EBIT ?
						</p>
					</div>
					{/* <p className="inline helvetica" style={{fontSize:"14px"}}>{(theStockData["NBAT_Is_Less_Than_Ebit"])}</p>  */}
					<p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>
						<KeyIconOrData value={theStockData["NBAT_Is_Less_Than_Ebit"]} requiredFeature="gem_ranking" colour={theStockData["NBAT_Is_Less_Than_Ebit"]==="yes"?"green":"red"}/>
					</p>
				</div>
			</div>
		);
	};

	const FinancialHealth = () => {
		return (
			<div>
				<div className="detailHeading bbvGrey">
					<h3>Liquidity</h3>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("CurrentRatio") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Current Ratio
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo(theStockData["currentRatio"])} green = {2} yellow = {0} red = {0} />
				</div>

				<div className="detailHeading bbvGrey">
					<h3>Solvency</h3>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("DebtToEquity") }}  className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Debt / Equity
						</p>
					</div>
					<TLSText higherBetter = {false} value ={roundToTwo(theStockData["totalDebtToEquity"])} green = {0} yellow = {1} red = {1} />
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("DebtToAsset") }}  className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Debt / Asset
						</p>
					</div>
					<p className="inline helvetica" style={{ fontSize: "14px" }}>
						{roundToTwo(theStockData["totalDebtToTotalAsset"])}
					</p>
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("NetToNTA") }}  className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Net Debt to NTA
						</p>
					</div>
					{/* <p className="inline helvetica" style={{fontSize:"14px"}}>{roundToTwo(theStockData["Net_Debt_To_NTA"])}</p>  */}
					<p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>
						<KeyIconOrData value={theStockData["totalDebtToTotalAsset"]} requiredFeature="gem_ranking" colour = {theStockData["totalDebtToTotalAsset"] > 1?"red":"green"}/>
					</p>
				</div>
			</div>
		);
	};

	const FocusRatio = () => {
		return (
			<div>
				<div className="detailHeading bbvGrey">
					<h3>Focus Ratio</h3>
				</div>
				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("QualityIncome") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							Quality Income
						</p>
					</div>
					<TLSText higherBetter = {true} value ={roundToTwo(theStockData["Earnings_Quality_Percentile"])} green = {1} red = {1} />
				</div>

				<div className="stockRow flex">
					<div className="inline " style={{ paddingLeft: "18px", paddingRight: "18px" }}>
						<div className="inline  infoIcon">
							<InfoIcon onClick={()=>{ handleInfoPop("Orecta") }} className="icon" />
						</div>
						<p className="inline helvetica" style={{ fontSize: "14px" }}>
							ORecTa%
						</p>
					</div>
					{/* <p className="inline helvetica" style={{fontSize:"14px"}}>{roundToTwo(theStockData["Orecta_Percentage"])}</p>  */}
					<p className={current === "Company Description"?'stockDesc':"inline helvetica"} style={{fontSize:"14px"}}>
						<KeyIconOrData value={roundToTwo(theStockData["Orecta_Percentage"] * 100)} requiredFeature="gem_ranking" colour = {TLSGEMColour(theStockData["Orecta_Percentage"] * 100,30,50)}/>
					</p>
				</div>
			</div>
		);
	};
	const stockItems = [
		{
			title: "GEM Ranking",
			page: <GemRanking />,
		},
		{
			title: "Company Description",
			page: <CompanyDescription />,
			headings: ["Company Description"],
			name: ["General_Description"],
		},
		{
			title: "General Information",
			page: <GeneralInformation />,
			isHeading: ["yes", "no", "no", "no", "no", "no", "no", "no", "no"],
			headings: [
				"General Information",
				"Currency",
				"Country",
				"Exchange",
				"Sector",
				"Industry",
				"Market Cap",
				"Annual Dividend Yield",
				"Most Recent Quarter",
			],
			name: [
				"",
				"Financials_Income_Statement_currency_symbol",
				"General_CountryName",
				"General_Exchange",
				"General_Sector",
				"General_Industry",
				"Highlights_MarketCapitalization",
				"Highlights_DividendYield",
				"Highlights_MostRecentQuarter",
			],
		},
		{
			title: "Key Financial Ratios",
			page: <KeyFinancialRatios />,
			isHeading: ["yes", "no", "no", "no", "no", "no", "no", "yes", "no"],
			headings: [
				"Key Financial Ratios",
				"Price to Equity (PE) Ratio",
				"PE Growth Ratio",
				"Sector PE Ratio",
				"Return on Equity",
				"Return on Asset",
				"Price to Book Ratio",
				"General Earning Data",
				"Earnings Per Share",
			],
			name: [
				"",
				"Highlights_PERatio",
				"Highlights_PEGRatio",
				"Total_Sector_Highlights_PE",
				"Highlights_ReturnOnEquityTTM",
				"Highlights_ReturnOnAssetsTTM",
				"Valuation_PriceBookMRQ",
				"",
				"Highlights_EarningsShare",
			],
		},
		{
			title: "Income Statement",
			page: <IncomeStatement />,
			isHeading: ["yes", "no", "no", "no", "no"],
			headings: ["Income Statement", "Gross Profit Margin", "Operating Margin", "Net Profit", "NPAT < EBIT ?"],
			name: [
				"",
				"Highlights_ProfitMargin",
				"Highlights_OperatingMarginTTM",
				"Net_Income",
				"NBAT_Is_Less_Than_Ebit",
			],
		},
		{
			title: "Financial Health",
			page: <FinancialHealth />,
			isHeading: ["yes", "no", "yes", "no", "no", "no"],
			headings: ["Liquidity", "Current Ratio", "Solvency", "Debt / Equity", "Debt / Asset", "Net Debt to NTA"],
			name: ["", "Current_Ratio", "", "Debt_to_Equity", "Debt_To_Asset", "Net_Debt_To_NTA"],
		},
		{
			title: "Focus Ratio",
			page: <FocusRatio />,
			isHeading: ["yes", "no", "no"],
			headings: ["Focus Ratio", "Quality Income", "ORecTa%"],
			name: ["", "Earnings_Quality_Percentile", "Orecta_Percentage"],
		},
	];

	const currentPage = stockItems.find(curr => {
		return curr.title === current;
	});

	return (
		<div className="stockDetailsTable">
			<div style={{ display: "flex", justifyContent: "space-between", padding: "18px" }}>
				<h5 style={{ display: "inline", fontSize: "20px" }} className="roboto">
					{theStockData.name}
				</h5>
				<p
					className="roboto"
					style={{
						display: "inline",
						marginTop: "auto",
						marginBottom: "auto",
						fontSize: "12px",
						color: "#A0AEC0",
					}}
				>
					{theStockData.GEM_Rank} out of 7276 stocks
				</p>
			</div>
			{currentPage.page}
			{infoPopup?<StockInfoPopup toggleInfoPopup={toggleInfoPopup} popupAccessor = {popupAccessor}/>:<div></div>}	
			{/* {popup?
        <Popup>
                <CloseIcon className="close-btn icon" onClick={()=>{togglePopup(false)}} />
                <Payment forStocks={true}></Payment>
        </Popup>
        :
        <div></div>} */}
		</div>
	);
}

export default StockDetailsTable;
