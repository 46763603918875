const form = {
	formId: "new-category-form",
	formField: {
		name: {
			name: "name",
			label: "category name",
			type: "text",
			placeholder: "eg. Micheal",
			errorMsg: "Category Name is required.",
		},
	},
};

export default form;
