import axios from "layouts/authentication/api/axios";

export const validateTransaction = async invoiceNo => {
	try {
		const res = await axios().post(`/payment/verify`, {
			invoiceNo: invoiceNo,
		});
		return res.data?.success;
	} catch (error) {
		console.log(error);
	}
    
	return false;
};

export const validateStripeTransaction = async paymentIntent => {
	try {
		const res = await axios().post(`/payment/stripe/verify`, {
			intent_id: paymentIntent,
		});
		return res.data?.success;
	} catch (error) {
		console.log(error);
	}
    
	return false;
};