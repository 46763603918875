import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import { Grid, Button } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import trioImg from "assets/images/Frame369.png";
import bbvTrioNew from "assets/images/bbvDash.png";
// Custom styles for DashboardNavbar
import {
	navbar,
	navbarContainer,
	navbarRow,
	navbarIconButton,
	navbarDesktopMenu,
	navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setTransparentNavbar, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { SearchSymbolInput } from "components/CustomComponents";
import { RightSideNavBar } from "components/CustomComponents";

function DashboardNavbar({ absolute, light, isMini, pageTitle, type }) {
	const navigate = useNavigate()
	const [navbarType, setNavbarType] = useState("sticky");
	const [controller, dispatch] = useSoftUIController();
	const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
	const [openMenu, setOpenMenu] = useState(false);
	const route = useLocation().pathname.split("/").slice(1);

	// useEffect(() => {
	// 	// Setting the navbar type
	// 	if (fixedNavbar) {
	// 		setNavbarType("sticky");
	// 	} else {
	// 		setNavbarType("static");
	// 	}

	// 	// A function that sets the transparent state of the navbar.
	// 	function handleTransparentNavbar() {
	// 		setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
	// 	}

	// 	/**
	//  The event listener that's calling the handleTransparentNavbar function when
	//  scrolling the window.
	// */
	// 	window.addEventListener("scroll", handleTransparentNavbar);

	// 	// Call the handleTransparentNavbar function to set the state with the initial value.
	// 	handleTransparentNavbar();

	// 	// Remove event listener on cleanup
	// 	return () => window.removeEventListener("scroll", handleTransparentNavbar);
	// }, [dispatch, fixedNavbar]);

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
	const handleOpenMenu = event => setOpenMenu(event.currentTarget);
	const handleCloseMenu = () => setOpenMenu(false);
	const [inputText, setInputText] = useState("");

	const handleJoinNow = () => {
		navigate('/authentication/sign-up')
	}

	// Render the notifications menu
	const renderMenu = () => (
		<Menu
			anchorEl={openMenu}
			anchorReference={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			open={Boolean(openMenu)}
			onClose={handleCloseMenu}
			sx={{ mt: 2 }}
		>
			<NotificationItem
				image={<img src={team2} alt="person" />}
				title={["New message", "from Laur"]}
				date="13 minutes ago"
				onClick={handleCloseMenu}
			/>
			<NotificationItem
				image={<img src={logoSpotify} alt="person" />}
				title={["New album", "by Travis Scott"]}
				date="1 day"
				onClick={handleCloseMenu}
			/>
			<NotificationItem
				color="secondary"
				image={
					<Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
						payment
					</Icon>
				}
				title={["", "Payment successfully completed"]}
				date="2 days"
				onClick={handleCloseMenu}
			/>
		</Menu>
	);

	return (
		<div>
			<div
				className={`${type === "bbv" || type === "marketplace" ? "bbvNavbar" : ""} ${
					type === "marketplace" ? "marketplace" : ""
				}`}
				position={absolute ? "absolute" : navbarType}
				color="inherit"
				// sx={theme => navbar(theme, { transparentNavbar, absolute, light })}
				style={{ paddingTop: "20px" }}
			>
				{type === "bbv" && (
					<div style={{ postion: "relative" }}>
						<div className="overlay" />
					</div>
				)}

				{/* <div className="goldOutline"></div> */}
				<Toolbar sx={theme => navbarContainer(theme)}>
					<SoftBox
						className="navbar"
						color="inherit"
						mb={{ xs: 1, md: 0 }}
						sx={theme => navbarRow(theme, { isMini })}
					>
						<Breadcrumbs
							icon="home"
							title={pageTitle ? pageTitle : route[route.length - 1]}
							route={route}
							light={light}
						/>
					</SoftBox>
					<RightSideNavBar
						isMini={isMini}
						navbarRow={navbarRow}
						inputText={inputText}
						light={light}
						handleMiniSidenav={handleMiniSidenav}
						navbarIconButton={navbarIconButton}
						miniSidenav={miniSidenav}
						handleConfiguratorOpen={handleConfiguratorOpen}
						setInputText={setInputText}
						handleOpenMenu={handleOpenMenu}
						renderMenu={renderMenu}
					/>
				</Toolbar>
				<Grid className="dashboardNavbarText" container>
					{type === "marketplace" && (
						<>
							<Grid item md={3}></Grid>
							<Grid item md={6}>
								<h1 className="roboto textOneNav">Welcome to Bullbearworld.io</h1>
								<p className="roboto textTwoNav">
									BBW is a financial gamification platform on the Metaverse, a simulation of the
									global capital markets that engages new to seasoned investors in the world of
									economics, investing and personal finance in the mission to guide investors to reach
									for financially independent futures
								</p>
								<Button style={{ marginTop: "30px" }} variant="contained" className="bbvGold" onClick={handleJoinNow}>
									JOIN NOW
								</Button>
							</Grid>
						</>
					)}
					{type === "bbv" && (
						<Grid item xl={5} lg={6} md={6}>
							<h1 className="roboto textOneNav">
								Welcome to BBV! <br></br>It makes investing <br></br> a piece of cake
							</h1>
							<p className="roboto textTwoNav">
								Investing is not difficult if you have the right knowledge, tools and support. BBV is
								where you can learn, earn, own and share! Feel free to explore and head over to the
								Discord channel to make like-minded friends
							</p>
							<Button style={{ marginTop: "30px" }} variant="contained" className="bbvGold" onClick={handleJoinNow}>
								JOIN NOW
							</Button>
						</Grid>
					)}
					{type === "bbv" && (
						<Grid item xl={5} lg={4} md={4}>
							<img className="bbvCards" src={bbvTrioNew} alt="bbv cards"></img>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	);
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: true,
	isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
	absolute: PropTypes.bool,
	light: PropTypes.bool,
	isMini: PropTypes.bool,
};

export default DashboardNavbar;
