// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useWeb3 } from "context/web3";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { networkName, shortAccount } from "layouts/Helper/blockchain.helper";
import { useNavigate } from "react-router-dom";

const ConnectWalletButton = ({
	textUponConnected,
	actionAfterConnected,
	restrictedToAccount,
	restrictedToNetwork,
	metamaskOnly,
	...buttonProps
}) => {
	const { web3Controller, connectToBlockchain } = useWeb3();
	const { connected: connectedToBlockchain, ...blockchainInfo } = web3Controller;

	const navigate = useNavigate();

	const redirectToConnectPage = () => {
		navigate("/dashboard/marketplace/connect")
	}

	const handleButtonClick = () => {
		if (!connectedToBlockchain) {
			if (metamaskOnly) {
				return connectToBlockchain();
			} else
				return redirectToConnectPage();
		}

		actionAfterConnected && actionAfterConnected();
	};

	return (
		<>
			{(!connectedToBlockchain ||
				((!restrictedToAccount ||
					restrictedToAccount.toLowerCase() === blockchainInfo.wallet_address.toLowerCase()) &&
					(!restrictedToNetwork || restrictedToNetwork === blockchainInfo.network_id))) && (
				<SoftButton className="bbvGold" onClick={handleButtonClick} {...buttonProps}>
					{connectedToBlockchain ? textUponConnected : "Connect to Wallet"}
				</SoftButton>
			)}
			{restrictedToAccount &&
				connectedToBlockchain &&
				restrictedToAccount.toLowerCase() !== blockchainInfo.wallet_address.toLowerCase() && (
					<SoftTypography variant="body2">
						You are connected to a different wallet. Switch to {shortAccount(restrictedToAccount)}
					</SoftTypography>
				)}
			{restrictedToNetwork && connectedToBlockchain && restrictedToNetwork !== blockchainInfo.network_id && (
				<SoftTypography variant="body2">
					You are connected to wrong chain. Switch to {networkName(restrictedToNetwork)}
				</SoftTypography>
			)}
		</>
	);
};

ConnectWalletButton.propTypes = {
	textUponConnected: PropTypes.string.isRequired,
	actionAfterConnected: PropTypes.func,
	restrictedToAccount: PropTypes.string,
	restrictedToNetwork: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ConnectWalletButton.defaultProps = {
	actionAfterConnected: () => {},
	restrictedToAccount: null,
	restrictedToNetwork: null,
};

export default ConnectWalletButton;
