import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import BackToButton from "components/CustomComponents";
import { getEarningsRelease } from "../data-tables/data/getData";
import EarningReleaseTable from "../EarningsRelease-Tables";

const EarningsPage = () => {
    const economicCalendar = `
	<div class="tradingview-widget-container">
	  <div class="tradingview-widget-container__widget"></div>
	  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-events.js" async>
	  {
	  "width": "100%",
	  "height": "1200",
	  "colorTheme": "light",
	  "isTransparent": false,
	  "locale": "en",
	  "importanceFilter": "-1,0,1",
	  "currencyFilter": "USD,EUR,ITL,NZD,CHF,AUD,FRF,JPY,ZAR,TRL,CAD,DEM,MXN,ESP,GBP"
	}
	  </script>
	</div>
	`;
	useEffect(() => {
		// setLoading(true)

	}, []);



	

	return (
		<DashboardLayout>
			<DashboardNavbar pageTitle="Earnings Call" />
			<BackToButton text="Back to Research" destination="/dashboard/GEM/stocks-ranking"></BackToButton>
			<Grid
				className="news_sentiment_grid"
				justifyContent="space-between"
				style={{ textAlign: "center", marginTop: "20px" }}
				container
			>
				<Grid
					className=" earnings_earningsRelease"
					style={{ backgroundColor: "white", borderRadius: "15px" }}
					item
				>
					<div style={{marginTop:"18px", display:"flex",justifyContent:"center",textAlign:"center",alignItems:"center"}}>
						<p className="roboto" style={{textAlign:"center", fontSize: "18px" ,marginRight:"5px" }}>
							For Earning Release
						</p>
						<InfoIcon className="icon"></InfoIcon>
					</div>
					<EarningReleaseTable></EarningReleaseTable>
				</Grid>
				<Grid className="earnings_economicCalender" item>
					<div style={{marginTop:"18px", display:"flex",justifyContent:"center",textAlign:"center",alignItems:"center"}}>
						<p className="roboto" style={{textAlign:"center", fontSize: "18px" ,marginRight:"5px" }}>
							Economic Calendar
						</p>
						<InfoIcon className="icon"></InfoIcon>
					</div>
					<iframe
						className="iframeDashboard"
						style={{ width: "100%", height: "100%" }}
						title="economic calendar"
						srcDoc={economicCalendar}
					></iframe>
				</Grid>
			</Grid>
		</DashboardLayout>
	);
};

export default EarningsPage;