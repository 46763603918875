/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useNavigate, Link } from "react-router-dom";
import useAuth from "layouts/authentication/hooks/useAuth";
import { formatDate } from "layouts/Helper/helper";

function SubscriptionAndPoints() {
	const navigate = useNavigate();
	const { auth } = useAuth();

	const handleChangePlan = () => {
		navigate("/dashboard/membership");
	};

	return (
		<>
			<Card id="subscription-block" style={{ marginBottom: "24px" }}>
				<SoftBox p={3}>
					<SoftTypography variant="h5">Manage Subscription</SoftTypography>
				</SoftBox>
				<SoftBox component="form" pb={3} px={3}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<SoftTypography variant="body2" fontWeight="bold">
								You are currently on the {auth?.plan ? auth.plan.name : "Basic"} plan.
							</SoftTypography>
						</Grid>
						<Grid item xs={12}>
							<SoftTypography variant="body2">Subscription status: active.</SoftTypography>
						</Grid>
						<Grid item xs={12}>
							<SoftTypography variant="body2">
								Current period started:{" "}
								{formatDate(auth?.plan?.subscribed_at ? auth.plan.subscribed_at : auth?.created_at)}.
							</SoftTypography>
						</Grid>
					</Grid>
					<SoftBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
						<SoftBox ml="auto">
							<SoftButton onClick={handleChangePlan} variant="gradient" color="dark" size="small">
								Change Plan
							</SoftButton>
						</SoftBox>
					</SoftBox>
				</SoftBox>
			</Card>
			<Card id="points-block">
				<SoftBox p={3}>
					<SoftTypography variant="h5">Loyalty rewards overview</SoftTypography>
				</SoftBox>
				<SoftBox component="form" pb={3} px={3}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<SoftTypography variant="body2" fontWeight="bold">
								Points earned: 0
							</SoftTypography>
						</Grid>
						<Grid item xs={12}>
							<Grid container alignItems="center">
								<Grid item xs={6}>
									<SoftTypography variant="body2">
										Redeem points for The Trend cafe-bistro
									</SoftTypography>
								</Grid>
								<Grid item xs={6}>
									<SoftBox
										display="flex"
										justifyContent="space-between"
										alignItems="flex-end"
										flexWrap="wrap"
									>
										<SoftBox ml="auto">
											<SoftButton variant="gradient" color="dark" size="small" disabled>
												Redeem Now
											</SoftButton>
										</SoftBox>
									</SoftBox>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container alignItems="center">
								<Grid item xs={6}>
									<SoftTypography variant="body2">Redeem points for other products</SoftTypography>
								</Grid>
								<Grid item xs={6}>
									<SoftBox
										display="flex"
										justifyContent="space-between"
										alignItems="flex-end"
										flexWrap="wrap"
									>
										<SoftBox ml="auto">
											<SoftButton variant="outlined" color="dark" size="small">
												Learn More
											</SoftButton>
										</SoftBox>
									</SoftBox>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<SoftTypography variant="body2">
								<Link to="/" style={{ color: "inherit", textDecoration: "underline" }}>
									Loyalty program policy
								</Link>
							</SoftTypography>
						</Grid>
					</Grid>
				</SoftBox>
			</Card>
		</>
	);
}

export default SubscriptionAndPoints;
