import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getSingleOption, getStockData } from "./data-tables/data/getData";
import { CircularProgress } from "@mui/material";
import { roundToTwo } from "layouts/Helper/helper";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import BackToButton from "components/CustomComponents";
import KeyIconOrData from "components/etc/KeyIconOrData";
import useAuth from "layouts/authentication/hooks/useAuth";
import blurredOptionsGraph from "../../assets/images/blurredOptions.png";

const OptionsFullscreen = () => {
	const { optionType, stockCode } = useParams();
	const [data, setData] = useState();
	const [secondData, setSecondData] = useState();
	const [loading, setLoading] = useState(true);
	const ratio = 3;
	const { auth } = useAuth();
	const navigate = useNavigate();
	const source3 = `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_fc027&symbol=${stockCode}&interval=D&saveimage=0&toolbarbg=f1f3f6&details=1&studies=CCI%40tv-basicstudies%1FRSI%40tv-basicstudies&theme=light&style=2&timezone=Etc%2FUTC&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=&utm_medium=widget_new&utm_campaign=chart&utm_term=MSTR#%7B%22page-uri%22%3A%22__NHTTP__%22%7D`;
	const formatOptionType = option => {
		if (option === "FIS") {
			return "sput";
		}
		return option.toLowerCase();
	};

	useEffect(() => {
		handleOption();
	}, []);

	const handleOption = async () => {
		const optionDataList = await getSingleOption(formatOptionType(optionType), stockCode);
		const optionData = optionDataList[0];
		// console.log('optionData', optionData);
		setData(optionData);
		const fomrattedSecondData = await formatSecondRowData(optionType, optionData);
		setSecondData(fomrattedSecondData);

		setLoading(false);

		//create additional useState for extra information
	};

	const formatSecondRowData = async (name, data) => {
		var estimatedReturn = ((data.MaxProfit / data.MaxLoss) * 100).toFixed(2);
		let stockPrice = await getStockData(data.Symbol);

		if (Array.isArray(stockPrice) && stockPrice.length > 0) stockPrice = stockPrice[0];

		const formattedStockPrice = stockPrice.close;
		if (name === "FIS" || name === "BPS") {
			return {
				Rank: data.Rank,
				gemStockRank: data.GEM_Rank,
				symbol: data.Symbol,
				sector: data.IndustrySector,
				CapitalNeeded: data.MaxLoss,
				stockPrice: roundToTwo(formattedStockPrice),
				estimatedReturn: estimatedReturn,
			};
		} else if (name === "RBS") {
			return {
				Rank: data.Rank,
				gemStockRank: data.GEM_Rank,
				symbol: data.Symbol,
				sector: data.IndustrySector,
				CapitalNeeded: data.BC_Strike * 100,
				stockPrice: roundToTwo(formattedStockPrice),
				minReturn: data.OTM_ROC,
				maxReturn: data.ITM_ROC,
			};
		} else {
			//BCS
			return {
				Rank: data.Rank,
				gemStockRank: data.GEM_Rank,
				symbol: data.Symbol,
				sector: data.IndustrySector,
				CapitalNeeded: data.MaxLoss,
				stockPrice: roundToTwo(formattedStockPrice),
				minReturn: data.ROC,
			};
		}
	};
	return (
		<DashboardLayout>
			<DashboardNavbar />

			{loading ? (
				<div className="loadingIcon">
					<CircularProgress />
				</div>
			) : (
				<div>
					<BackToButton
						text="Back to Option Ranking"
						destination="/dashboard/GEM/options-ranking"
					></BackToButton>
					<div className="optionsPopupGroupOne">
						<Grid container>
							<Grid style={{ textAlign: "center" }} item xl={3} lg={3} md={3} sm={3} xs={3}>
								<p className="helvetica optionPopupHeaders">{optionType} Rank</p>
								<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
									{data.Rank}
								</p>
							</Grid>
							<Grid style={{ textAlign: "center" }} item xl={3} lg={3} md={3} sm={3} xs={3}>
								<p className="helvetica optionPopupHeaders">GEM Rank</p>
								<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
									<KeyIconOrData value={data.GEM_Rank} requiredFeature="gem_options_ranking" />
								</p>
							</Grid>
							<Grid style={{ textAlign: "center" }} item xl={3} lg={3} md={3} sm={3} xs={3}>
								<p className="helvetica optionPopupHeaders">SYM</p>
								<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
									<KeyIconOrData value={data.Symbol} requiredFeature="gem_options_ranking" />
								</p>
							</Grid>
							<Grid style={{ textAlign: "center" }} item xl={3} lg={3} md={3} sm={3} xs={3}>
								<p className="helvetica optionPopupHeaders">Sector</p>
								<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
									{data.IndustrySector}
								</p>
							</Grid>
						</Grid>
						<Grid container>
							<Grid
								style={{ textAlign: "center" }}
								item
								xl={ratio}
								lg={ratio}
								md={ratio}
								sm={ratio}
								xs={ratio}
							>
								<p className="helvetica optionPopupHeaders">Capital Needed</p>
								<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
									{secondData.CapitalNeeded}
								</p>
							</Grid>
							<Grid
								style={{ textAlign: "center" }}
								item
								xl={ratio}
								lg={ratio}
								md={ratio}
								sm={ratio}
								xs={ratio}
							>
								<p className="helvetica optionPopupHeaders">Stock Price</p>
								<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
									{secondData.stockPrice}
								</p>
							</Grid>

							{optionType === "FIS" ? (
								<Grid
									style={{ textAlign: "center" }}
									item
									xl={ratio}
									lg={ratio}
									md={ratio}
									sm={ratio}
									xs={ratio}
								>
									<p className="helvetica optionPopupHeaders">Est Return (%)</p>
									<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
										{secondData.estimatedReturn}
									</p>
								</Grid>
							) : optionType === "BPS" ? (
								<Grid
									style={{ textAlign: "center" }}
									item
									xl={ratio}
									lg={ratio}
									md={ratio}
									sm={ratio}
									xs={ratio}
								>
									<p className="helvetica optionPopupHeaders">Est Return (%)</p>
									<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
										{secondData.estimatedReturn}
									</p>
								</Grid>
							) : optionType === "RBS" ? (
								<Grid style={{ textAlign: "center" }} container xl={6} lg={6} md={6} sm={6} xs={6}>
									<Grid style={{ textAlign: "center" }} item xl={6} lg={6} md={6} sm={6} xs={6}>
										<p className="helvetica optionPopupHeaders">Min Return (%)</p>
										<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
											{secondData.minReturn}
										</p>
									</Grid>
									<Grid style={{ textAlign: "center" }} item xl={6} lg={6} md={6} sm={6} xs={6}>
										<p className="helvetica optionPopupHeaders">Max Return (%)</p>
										<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
											{secondData.maxReturn}
										</p>
									</Grid>
								</Grid>
							) : (
								<Grid
									style={{ textAlign: "center" }}
									item
									xl={ratio}
									lg={ratio}
									md={ratio}
									sm={ratio}
									xs={ratio}
								>
									<p className="helvetica optionPopupHeaders">Est Return (%)</p>
									<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
										{secondData.minReturn}
									</p>
								</Grid>
							)}
						</Grid>
					</div>
					<Button
						className="bbvGrey centerButton"
						style={{ color: "white", marginTop: "25px" }}
						onClick={() => {
							navigate(`/dashboard/GEM/stocks-ranking/${stockCode}`);
						}}
						variant="contained"
					>
						Stock Research
					</Button>
					{optionType === "BCS" ? (
						<div className="optionsInfo" style={{ width: "80%", marginBottom: "30px", marginTop: "15px" }}>
							<Grid style={{ marginBottom: "14px" }} container>
								<Grid item xl={3} lg={3} md={3} sm={4} xs={4}></Grid>
								<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
									<p className="roboto optionPopupCaptions">Sell Call</p>
								</Grid>
								<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
									<p className="roboto optionPopupCaptions">Buy Call</p>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500 " item xl={3} lg={3} md={3} sm={4} xs={4}>
									Date To Expiry
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.DTE}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.DTE}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Est Premium
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.L1_SellPrice}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.L2_BuyPrice}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Est Probab (%)
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.DPOTM}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>NIL</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Open Interest
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.OI}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>NIL</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Strike Price
								</Grid>
								<Grid style={{ marginLeft: "" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.L1_SellStrike}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.L2_BuyStrike}</p>
									</div>
								</Grid>
							</Grid>
						</div>
					) : optionType === "BPS" ? (
						<div className="centerButton" style={{ width: "80%", marginBottom: "30px", marginTop: "15px" }}>
							<Grid style={{ marginBottom: "14px" }} container>
								<Grid item xl={3} lg={3} md={3} sm={4} xs={4}></Grid>
								<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
									<p className="roboto optionPopupCaptions">Sell Put</p>
								</Grid>
								<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
									<p className="roboto optionPopupCaptions ">Buy Put</p>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Date To Expiry
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.DTE}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.DTE}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Est Premium
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.L1_SellPrice}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.L2_BuyPrice}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Est Probab (%)
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.DPOTM}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>NIL</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Open Interest
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.OI}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>NIL</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Strike Price
								</Grid>
								<Grid style={{ marginLeft: "" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.L1_SellStrike}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.L2_BuyStrike}</p>
									</div>
								</Grid>
							</Grid>
						</div>
					) : optionType === "RBS" ? (
						<div className="centerButton" style={{ width: "80%", marginBottom: "30px", marginTop: "15px" }}>
							<Grid style={{ marginBottom: "14px" }} container>
								<Grid item xl={3} lg={3} md={3} sm={4} xs={4}></Grid>
								<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
									<p className="roboto">Buy Call</p>
								</Grid>
								<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
									<p className="roboto">Sell Call</p>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Date To Expiry
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.BC_Expiry}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.SC_Expiry}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
								<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
									Est Premium
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.BC_Price}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.SC_Price}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" container>
								<Grid
									className="roboto_500"
									style={{ marginBottom: "14px" }}
									item
									xl={3}
									lg={3}
									md={3}
									sm={4}
									xs={4}
								>
									Est Probab (%)
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										{/* <p>{data.BC_Price}</p> */}
										<p>NIL</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.SC_DPOTM}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" container>
								<Grid
									className="roboto_500"
									style={{ marginBottom: "14px" }}
									item
									xl={3}
									lg={3}
									md={3}
									sm={4}
									xs={4}
								>
									Open Interest
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.BC_OI}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.SC_OI}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" container>
								<Grid
									className="roboto_500"
									style={{ marginBottom: "14px" }}
									item
									xl={3}
									lg={3}
									md={3}
									sm={4}
									xs={4}
								>
									Strike Price
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.BC_Strike}</p>
									</div>
								</Grid>
								<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
									<div className="whiteBox">
										<p>{data.SC_Strike}</p>
									</div>
								</Grid>
							</Grid>
						</div>
					) : (
						<div className="centerButton" style={{ width: "80%", marginBottom: "30px", marginTop: "15px" }}>
							<Grid container>
								<Grid style={{ marginBottom: "14px" }} item xl={3} lg={3} md={3} sm={4} xs={4}></Grid>
								<Grid style={{ textAlign: "center" }} item xl={6} lg={6} md={6} sm={6} xs={6}>
									<p className="roboto optionPopupCaptions">Sell Put</p>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" container>
								<Grid
									className="roboto_500"
									style={{ marginBottom: "14px" }}
									item
									xl={3}
									lg={3}
									md={3}
									sm={4}
									xs={4}
								>
									Date To Expiry
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
									<div className="whiteBox">
										<p>{data.Expiry}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" container>
								<Grid
									className="roboto_500"
									style={{ marginBottom: "14px" }}
									item
									xl={3}
									lg={3}
									md={3}
									sm={4}
									xs={4}
								>
									Est Premium
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
									<div className="whiteBox">
										<p>{data.TradePrice}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" container>
								<Grid
									className="roboto_500"
									style={{ marginBottom: "14px" }}
									item
									xl={3}
									lg={3}
									md={3}
									sm={4}
									xs={4}
								>
									Est Probab (%)
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
									<div className="whiteBox">
										<p>{data.DPOTM}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" container>
								<Grid
									className="roboto_500"
									style={{ marginBottom: "14px" }}
									item
									xl={3}
									lg={3}
									md={3}
									sm={4}
									xs={4}
								>
									Open Interest
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
									<div className="whiteBox">
										<p>{data.OI}</p>
									</div>
								</Grid>
							</Grid>
							<Grid className="optionPopupHeaders" container>
								<Grid
									className="roboto_500"
									style={{ marginBottom: "14px" }}
									item
									xl={3}
									lg={3}
									md={3}
									sm={4}
									xs={4}
								>
									Strike Price
								</Grid>
								<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
									<div className="whiteBox">
										<p>{data.Strike}</p>
									</div>
								</Grid>
							</Grid>
						</div>
					)}
					{false && (!auth || !auth.plan?.plan_features.available.includes("gem_options_ranking")) ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<img
								src={blurredOptionsGraph}
								style={{
									width: "75%",
									border: "none",
									height: "55vh",
									minWidth: "300px",
									objectFit: "fill",
									borderRadius: "2%",
									marginBottom: "10px",
								}}
								alt="blurred chart"
							/>
						</div>
					) : (
						<iframe
							title="technical3"
							src={source3}
							style={{ width: "100%", border: "none", height: "35vh", minWidth: "300px" }}
						></iframe>
					)}
				</div>
			)}
		</DashboardLayout>
	);
};

export default OptionsFullscreen;
