//import Md5Hash from "src/plugins/Md5Hash";

import moment from "moment";
import { numberFormat } from "./number.helper";

export function truncate(fullStr, strLen, separator) {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || "...";

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    "0x" +
    fullStr.replace("0x", "").substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
}

export function toSnakeCase(camleCaseString) {
  return camleCaseString.replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`
  );
}

export function toTitleText(snakeCaseString) {
  return snakeCaseString
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function toSlug(string) {
  return string.replace(/ /g, '-').toLowerCase();
}

export function initial(string) {
  return string.split("")[0].toUpperCase()
}

export function attributeValueFormatter(value, key) {
  if (key === 'commencement_date') {
    return moment.unix(value).format("DD MMM YYYY");
  } else if (['capital_request', 'offering_size'].includes(key)) {
    return numberFormat(parseFloat(value), 0) + ' USD';
  } else if (['sales_return_on_capital'].includes(key)) {
    return value + '%'
  }

  return value;
}

export function sprintf(string, variables) {
  return string.replace(/{(\d+)}/g, function(match, number) { 
    return typeof variables[number] != 'undefined'
      ? variables[number]
      : match
    ;
  });
};

// export function toHash(string) {
//   // for now useing md5 hash, can be changed
//   // to sha256 later if needed

//   return Md5Hash(string);
// }