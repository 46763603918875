/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTagInput from "components/SoftTagInput";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import { getMonthDateFromDate, getMonthFromDate, getYearFromDate } from "layouts/Helper/helper";
import { saveUserProfile } from "services/user";
import { setMonthInDate, setMonthDateInDate, setYearInDate } from "layouts/Helper/helper";

function BasicInfo({ data }) {
	const [skills, setSkills] = useState(["react", "angular"]);
	const [updatedProfile, setUpdatedProfile] = useState({ ...data });

	const handleChange = event => {
		const name = event.target.name;
		const value = event.target.value;
		const currentState = { ...updatedProfile };
		currentState[name] = value;
		setUpdatedProfile(currentState);
	};

	const handleSelectChange = (name, value) => {
		const currentState = { ...updatedProfile };

		if (name === "dob-day" || name === "dob-month" || name === "dob-year") {
			let dob_value = currentState["dob"];
			if (!dob_value) dob_value = "2000-01-01";

			if (name === "dob-day") {
				dob_value = setMonthDateInDate(dob_value, value);
			} else if (name === "dob-month") {
				dob_value = setMonthInDate(dob_value, value);
			} else if (name === "dob-year") {
				dob_value = setYearInDate(dob_value, value);
			}

			currentState["dob"] = dob_value;

			console.log(name, dob_value, value);
		} else currentState[name] = value;

		setUpdatedProfile(currentState);
	};

	const hanldeUpdateProfile = () => {
		console.log(updatedProfile);
		saveUserProfile(updatedProfile);
	};

	return (
		<Card id="basic-info" sx={{ overflow: "visible" }}>
			<SoftBox p={3}>
				<SoftTypography variant="h5">Basic Info</SoftTypography>
			</SoftBox>
			<SoftBox component="form" pb={3} px={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<FormField
							name="first_name"
							label="first name"
							placeholder="Alec"
							defaultValue={data?.first_name}
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormField
							name="last_name"
							label="last name"
							placeholder="Thompson"
							defaultValue={data?.last_name}
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={4}>
								<SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
									<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
										<SoftTypography
											component="label"
											variant="caption"
											fontWeight="bold"
											textTransform="capitalize"
										>
											I&apos;m
										</SoftTypography>
									</SoftBox>
									<SoftSelect
										name="gender"
										placeholder="Male"
										options={selectData.gender}
										defaultValue={
											data?.gender
												? selectData.gender[
														selectData.gender.findIndex(item => item.value === data.gender)
												  ]
												: null
										}
										onChange={selected => handleSelectChange("gender", selected.value)}
									/>
								</SoftBox>
							</Grid>
							<Grid item xs={12} sm={8}>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={5}>
										<SoftBox
											display="flex"
											flexDirection="column"
											justifyContent="flex-end"
											height="100%"
										>
											<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
												<SoftTypography
													component="label"
													variant="caption"
													fontWeight="bold"
													textTransform="capitalize"
												>
													birth date
												</SoftTypography>
											</SoftBox>
											<SoftSelect
												options={selectData.birthDate}
												defaultValue={
													data?.dob ? selectData.birthDate[getMonthFromDate(data.dob)] : null
												}
												onChange={selected => handleSelectChange("dob-month", selected.value)}
											/>
										</SoftBox>
									</Grid>
									<Grid item xs={12} sm={4}>
										<SoftBox
											display="flex"
											flexDirection="column"
											justifyContent="flex-end"
											height="100%"
										>
											<SoftSelect
												options={selectData.days}
												defaultValue={
													data?.dob
														? selectData.days[getMonthDateFromDate(data.dob) - 1]
														: null
												}
												onChange={selected => handleSelectChange("dob-day", selected.value)}
											/>
										</SoftBox>
									</Grid>
									<Grid item xs={12} sm={3}>
										<SoftBox
											display="flex"
											flexDirection="column"
											justifyContent="flex-end"
											height="100%"
										>
											<SoftSelect
												options={selectData.years}
												defaultValue={
													data?.dob
														? selectData.years[
																selectData.years.findIndex(
																	i => i.value === getYearFromDate(data.dob)
																)
														  ]
														: null
												}
												onChange={selected => handleSelectChange("dob-year", selected.value)}
											/>
										</SoftBox>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormField
							label="email"
							placeholder="example@email.com"
							inputProps={{ type: "email" }}
							defaultValue={data?.email}
							readOnly
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						{/* <FormField
              label="confirmation email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
            /> */}
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormField
							name="location"
							label="your location"
							placeholder="Singapore"
							onChange={handleChange}
							defaultValue={data?.location}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormField
							name="phone"
							label="phone number"
							placeholder="+65 9356 3162"
							inputProps={{ type: "number" }}
							onChange={handleChange}
							defaultValue={data?.phone}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
							<SoftTypography
								component="label"
								variant="caption"
								fontWeight="bold"
								textTransform="capitalize"
							>
								Marital Status
							</SoftTypography>
						</SoftBox>
						<SoftSelect
							options={selectData.maritalStatus}
							onChange={selected => handleSelectChange("marital_status", selected.value)}
							defaultValue={
								data?.marital_status
									? selectData.maritalStatus[
											selectData.maritalStatus.findIndex(item => item.value === data.marital_status)
									  ]
									: null
							}
						/>
					</Grid>
					{/* <Grid item xs={12} md={6}>
						<SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
							<SoftTypography
								component="label"
								variant="caption"
								fontWeight="bold"
								textTransform="capitalize"
								mb={1}
							>
								Hobbies
							</SoftTypography>
							<SoftTagInput
								tags={skills}
								placeholder=" "
								onChange={newSkill => setSkills(newSkill)}
								removeOnBackspace
							/>
						</SoftBox>
					</Grid> */}
				</Grid>
			</SoftBox>
			<SoftBox p={3} ml="auto" mr="auto">
				<SoftButton variant="gradient" color="dark" size="small" onClick={hanldeUpdateProfile}>
					Update Profile
				</SoftButton>
			</SoftBox>
		</Card>
	);
}

export default BasicInfo;
