import Slider from "react-slick";

import React from 'react';
import imgOne from "assets/images/crazy.png"
import imgTwo from "assets/images/risky.png"
import imgThree from "assets/images/volatile.png"
function SimpleSlider (){
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   };
    var settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToScroll: 1,
        autoplay:true,
        arrows:false,
        pauseOnHover:true,
        pauseOnFocus:true,
        autoplaySpeed:3000
  
      };
   
    return (

            <Slider className="slider" {...settings}>
                <div >
                    <img className="sliderImg" src={imgOne} alt="one"></img>
                </div>
                <div >
                    <img className="sliderImg"  src={imgTwo} alt="one"></img>
                </div>
                <div >
                    <img className="sliderImg"  src={imgThree} alt="one"></img>
                </div>
            </Slider>

    );
};

export default SimpleSlider;