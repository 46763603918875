/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export const dividendTableData = {
  columns: [
    { Header: "Name", accessor: "name", },
    { Header: "Exchange", accessor: "exchange" },
    { Header: "Market Cap", accessor: "marketcap" },
    { Header: "Period", accessor: "peiod" },
    { Header: "Div Yield", accessor: "divYield" },
    { Header: "Declaration Date", accessor: "declarDate" },
    { Header: "Record Date", accessor: "recordDate" },
    { Header: "Payment Date", accessor: "paymentDate" },
  ], rows: [
    {
    },
  ],
}

export const earningsReleaseData = {
  columns: [
    { Header: "Name", accessor: 'name' },
    { Header: "Release Date", accessor: 'releaseDate' },
    { Header: "Estimate", accessor: 'estimate' },
  ], rows: [
    {
    },
  ],
}




export const portfolioStockTableData = {
  columns: [
    { Header: "SYMBOL", accessor: "symbol", type: "text" },
    { Header: "STOCK TYPE", accessor: "stockType", type: "dropdown" },
    { Header: "TRADE PRICE", accessor: "tradePrice", type: "text" },
    { Header: "QUANTITY", accessor: "quantity", type: "text" },
    { Header: "CAPITAL", accessor: "capital", type: "text" },
    { Header: "CURRENT MKT PRICE", accessor: "currentMktPrice", type: "text" },
    { Header: "P/L", accessor: "pAndL", type: "number" },
    { Header: "P/L%", accessor: "pAndLPercentage", type: "number" },
    { Header: "ACTION", accessor: "action", type: "text" },
    { Header: "REMARKS", accessor: "remarks", type: "text" },

  ], rows: [

  ],
}

export const portfolioOptionTableData = {
  columns: [
    { Header: "SYMBOL", accessor: "symbol", type: "search" },
    { Header: "OPTION TYPE", accessor: "optionType", type: "dropdown" },
    { Header: "DATE TO EXPIRY", accessor: "dateToExpiry", type: "date" },
    { Header: "STRIKE 1", accessor: "strike_1", type: "number" },
    { Header: "STRIKE 2", accessor: "strike_2", type: "number" },
    { Header: "DELTA", accessor: "delta", type: "number" },
    { Header: "OPEN INTEREST", accessor: "openInterest", type: "number" },
    { Header: "PREMIUM PER QTY", accessor: "premiumPerQty", type: "number" },
    { Header: "ENTRY MKT PRICE", accessor: "entryMktPrice", type: "number" },
    { Header: "QUANTITY", accessor: "quantity", type: "number" },
    { Header: "BUYING POWER", accessor: "buyingPower", type: "number" },
    { Header: "DAYS LEFT", accessor: "daysLeft", type: "number" },
    { Header: "CURRENT MKT PRICE", accessor: "currentMktPrice", type: "number" },
    { Header: "CURRENT DELTA", accessor: "currentDelta", type: "number" },
    { Header: "P/L", accessor: "pAndL", type: "number" },
    { Header: "P/L%", accessor: "pAndLPercentage", type: "number" },
    { Header: "MONEYNESS", accessor: "moneyness", type: "text" },
    { Header: "MONEYNESS ALERT", accessor: "moneynessAlert", type: "text" },
    { Header: "DECAY ALERT", accessor: "decayAlert", type: "text" },
    { Header: "ACTION", accessor: "action", type: "text" },
    { Header: "REMARKS", accessor: "remarks", type: "text" },

  ], rows: [
  ],
}

const dataTableData = {
  columns: [
    { Header: "Rank", accessor: "name" },
    { Header: "GEM Stock Rank", accessor: "position" },
    { Header: "SYM", accessor: "office" },
    { Header: "Sector", accessor: "age" },
    { Header: "Capital Needed", accessor: "startDate" },
    { Header: "Stock Price", accessor: "salary" },
    { Header: "Est Return (%)", accessor: "salfasdfaary" },
    { Header: "How To Apply", accessor: "notSalary" },
  ],

  rows: [
    {
    },
  ],
};


export default dataTableData;
