import React from "react";
import PricingCards from "layouts/pages/pricing-page/components/PricingCards";
const Payment = forStocks => {
	return (
		<div className="paymentPopupDiv">
			<PricingCards isPopup={true} forStocks={forStocks}></PricingCards>
		</div>
	);
};

export default Payment;
