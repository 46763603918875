import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Icon, Card } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import FaqCollapse from "layouts/pages/pricing-page/components/FaqCollapse";
import useAuth from "layouts/authentication/hooks/useAuth";
import useAxiosPrivate from "layouts/authentication/hooks/useAxiosPrivate";

import {
	planSettings,
	nftCollectionSettings,
	eLearningSettings,
	onlineWorkshopSettings,
	postTrainingSettings,
} from "config/plan";
import { get as getValue, map as pluck } from "lodash";

const TickIcon = ({ bgColor }) => (
	<SoftBox
		display="flex"
		justifyContent="center"
		alignItems="center"
		width="1.5rem"
		height="1.5rem"
		borderRadius="50%"
		shaodw="md"
		bgColor={bgColor ? bgColor : "success"}
		variant={bgColor ? null : "gradient"}
		mr={2}
	>
		<SoftTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
			<Icon sx={{ fontWeight: "bold" }}>done</Icon>
		</SoftTypography>
	</SoftBox>
);

const BulletSoftTypography = styled(SoftTypography)(({ theme }) => ({
	display: "flex",
	"&:before": {
		content: '"•"',
		display: "block",
		paddingRight: "15px",
	},
}));

const CollapsiblePlanInfo = ({ title, optionGroup, options }) => {
	const [collapse, setCollapse] = useState(true);

	return (
		<FaqCollapse title={title} variant="h6" open={!collapse} onClick={() => setCollapse(!collapse)}>
			{options.map((option, index) => {
				const extra = getValue(optionGroup, `${option}.extra`, null);
				return (
					<SoftBox key={`${title}-${index}`}>
						<BulletSoftTypography variant="body2">
							{getValue(optionGroup, `${option}.description`, "")}
						</BulletSoftTypography>
						{extra && (
							<SoftTypography variant="body2" sx={{ paddingLeft: "23px" }}>
								{extra}
							</SoftTypography>
						)}
					</SoftBox>
				);
			})}
		</FaqCollapse>
	);
};

export default () => {
	const { auth } = useAuth();
	const axiosPrivate = useAxiosPrivate();

	const location = useLocation();
	const navigate = useNavigate();

	const [isPreparingPayment, setIsPreparingPayment] = useState(false);

	const buyBundle = async bundle => {
		if (auth?.access_token) {
			setIsPreparingPayment(true);

			try {
				// get the payment URL
				const response = await axiosPrivate.post(
					"/payment/bundle_checkout_url",
					{ bundle },
					{
						withCredentials: true,
					}
				);

				if (!response.data?.success) {
					setIsPreparingPayment(false);
					return;
				}

				if (response.data?.payment_url) {
					window.location.replace(response.data.payment_url);
				}
			} catch (e) {
				setIsPreparingPayment(false);
			}

			return;
		}

		navigate("/dashboard/authentication/sign-in", {
			state: { from: location },
			replace: true,
		});
	};

	return (
		<Container sx={{ xs: { padding: "0 24px" } }}>
			<SoftBox sx={{ display: { xs: "none", sm: "block" } }}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<SoftTypography color="secondary" variant="h3" align="left" fontWeight="bold" gutterBottom>
							Sensational bundles,
							<br />
							Sensible price
						</SoftTypography>
					</Grid>
					<Grid item xs={2}>
						<SoftTypography
							color="highlight"
							variant="h4"
							align="center"
							fontWeight="bold"
							textTransform="uppercase"
							gutterBottom
						>
							Emerald
						</SoftTypography>
						<SoftTypography variant="h3" align="center" fontWeight="bold" gutterBottom>
							S$ 675
						</SoftTypography>
						<SoftBox mt={3}>
							<SoftButton color="secondary" onClick={() => buyBundle("emerald")} fullWidth>
								Join Now
							</SoftButton>
						</SoftBox>
					</Grid>
					<Grid item xs={2}>
						<SoftTypography
							color="highlight"
							variant="h4"
							align="center"
							fontWeight="bold"
							textTransform="uppercase"
							gutterBottom
						>
							Ruby
						</SoftTypography>
						<SoftTypography variant="h3" align="center" fontWeight="bold" gutterBottom>
							S$ 2,400
						</SoftTypography>
						<SoftBox mt={3}>
							<SoftButton color="secondary" onClick={() => buyBundle("ruby")} fullWidth>
								Join Now
							</SoftButton>
						</SoftBox>
					</Grid>
					<Grid item xs={2}>
						<SoftTypography
							color="highlight"
							variant="h4"
							align="center"
							fontWeight="bold"
							textTransform="uppercase"
							gutterBottom
						>
							Sapphire
						</SoftTypography>
						<SoftTypography variant="h3" align="center" fontWeight="bold" gutterBottom>
							S$ 5,040
						</SoftTypography>
						<SoftBox mt={3}>
							<SoftButton color="secondary" onClick={() => buyBundle("saphire")} fullWidth>
								Join Now
							</SoftButton>
						</SoftBox>
					</Grid>
					<Grid item xs={2}>
						<SoftTypography
							color="highlight"
							variant="h4"
							align="center"
							fontWeight="bold"
							textTransform="uppercase"
							gutterBottom
						>
							Diamond
						</SoftTypography>
						<SoftTypography variant="h3" align="center" fontWeight="bold" gutterBottom>
							S$ 10,080
						</SoftTypography>
						<SoftBox mt={3}>
							<SoftButton color="secondary" onClick={() => buyBundle("diamond")} fullWidth>
								Join Now
							</SoftButton>
						</SoftBox>
					</Grid>
				</Grid>
				{/* <SoftBox bgColor="rgba(184, 193, 211, 0.18);" px={2} py={0.5} my={3}>
					<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
						NFT Collection
					</SoftTypography>
					<SoftTypography color="text" variant="h5" fontWeight="bold">
						(Membership NFT)
					</SoftTypography>
				</SoftBox>
				{Object.entries(nftCollectionSettings).map((config, index) => (
					<Grid key={index} container spacing={3} mb={2}>
						<Grid item xs={4}>
							<BulletSoftTypography pl={1} variant="body2" color="text">
								{config[1].description}
							</BulletSoftTypography>
						</Grid>
						<Grid item xs={2} align="center">
							<TickIcon />
						</Grid>
						<Grid item xs={2} align="center">
							<TickIcon />
						</Grid>
						<Grid item xs={2} align="center">
							<TickIcon />
						</Grid>
						<Grid item xs={2} align="center">
							<TickIcon />
						</Grid>
					</Grid>
				))} */}
				<SoftBox bgColor="rgba(184, 193, 211, 0.18);" px={2} py={0.5} my={3}>
					<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
						BBV Basic
					</SoftTypography>
					<SoftTypography color="text" variant="h5" fontWeight="bold">
						(Lifetime Subscription)
					</SoftTypography>
				</SoftBox>
				{Object.entries(planSettings).map((config, index) =>
					config[1].level === 1 ? (
						<Grid key={index} container spacing={3} mb={2}>
							<Grid item xs={4}>
								<BulletSoftTypography pl={1} variant="body2" color="text">
									{config[1].description}
								</BulletSoftTypography>
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
						</Grid>
					) : null
				)}
				<SoftBox bgColor="rgba(184, 193, 211, 0.18);" px={2} py={0.5} my={3}>
					<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
						BBV Pro Stock - GEM Ranking System
					</SoftTypography>
					<SoftTypography color="text" variant="h5" fontWeight="bold">
						(1 Year Subscription)
					</SoftTypography>
				</SoftBox>
				{Object.entries(planSettings).map((config, index) =>
					config[1].level === 2 ? (
						<Grid key={index} container spacing={3} mb={2}>
							<Grid item xs={4}>
								<BulletSoftTypography pl={1} variant="body2" color="text">
									{config[1].description}
								</BulletSoftTypography>
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
						</Grid>
					) : null
				)}
				<SoftBox bgColor="rgba(184, 193, 211, 0.18);" px={2} py={0.5} my={3}>
					<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
						BBV Pro Options + GEM Ranking System
					</SoftTypography>
					<SoftTypography color="text" variant="h5" fontWeight="bold">
						(Complimentary 1 Year Subscription)
					</SoftTypography>
				</SoftBox>
				{Object.entries(planSettings).map((config, index) =>
					config[1].level === 3 ? (
						<Grid key={index} container spacing={3} mb={2}>
							<Grid item xs={4}>
								<BulletSoftTypography pl={1} variant="body2" color="text">
									{config[1].description}
								</BulletSoftTypography>
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
							</Grid>
							<Grid item xs={2} align="center">
								{["options_scanner_rbs", "options_scanner_bps"].includes(config[0]) ? (
									<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
								) : (
									<TickIcon />
								)}
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
							<Grid item xs={2} align="center">
								<TickIcon />
							</Grid>
						</Grid>
					) : null
				)}
				<SoftBox bgColor="rgba(184, 193, 211, 0.18);" px={2} py={2} my={3}>
					<SoftTypography color="highlight" variant="h5" fontWeight="bold">
						e-LEARNING (Learn &amp; Take Action)
					</SoftTypography>
				</SoftBox>
				{Object.entries(eLearningSettings).map((config, index) => (
					<Grid key={index} container spacing={3} mb={2}>
						<Grid item xs={4}>
							<BulletSoftTypography pl={1} variant="body2" color="text">
								{config[1].description}
							</BulletSoftTypography>
						</Grid>
						<Grid item xs={2} align="center">
							{["ecpo_basic", "ecpo_intermediate", "ecpo_advanced"].includes(config[0]) ? (
								<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
							) : (
								<TickIcon />
							)}
						</Grid>
						<Grid item xs={2} align="center">
							{["ecpo_intermediate", "ecpo_advanced"].includes(config[0]) ? (
								<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
							) : (
								<TickIcon />
							)}
						</Grid>
						<Grid item xs={2} align="center">
							{["ecpo_advanced"].includes(config[0]) ? (
								<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
							) : (
								<TickIcon />
							)}
						</Grid>
						<Grid item xs={2} align="center">
							<TickIcon />
						</Grid>
					</Grid>
				))}
				<SoftBox bgColor="rgba(184, 193, 211, 0.18);" px={2} py={2} my={3}>
					<Grid container spacing={3}>
						<Grid item xs={4}>
							<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
								Live On-Line Workshop
							</SoftTypography>
						</Grid>
						<Grid item xs={2} align="center">
							<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
								1 Day
							</SoftTypography>
						</Grid>
						<Grid item xs={2} align="center">
							<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
								1.5 Days
							</SoftTypography>
						</Grid>
						<Grid item xs={2} align="center">
							<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
								2 Days
							</SoftTypography>
						</Grid>
						<Grid item xs={2} align="center">
							<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
								3 Days
							</SoftTypography>
						</Grid>
					</Grid>
				</SoftBox>
				{Object.entries(onlineWorkshopSettings).map((config, index) => (
					<Grid key={index} container spacing={3} mb={2}>
						<Grid item xs={4}>
							<BulletSoftTypography pl={1} variant="body2" color="text">
								{config[1].description}
							</BulletSoftTypography>
							<SoftTypography sx={{ paddingLeft: "28px", fontSize: "12px" }} variant="body2" color="text">
								{config[1].extra}
							</SoftTypography>
						</Grid>
						<Grid item xs={2} align="center">
							{["basic_options", "intermediate_options", "risk_analysis"].includes(config[0]) ? (
								<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
							) : (
								<TickIcon />
							)}
						</Grid>
						<Grid item xs={2} align="center">
							{["intermediate_options", "risk_analysis"].includes(config[0]) ? (
								<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
							) : (
								<TickIcon />
							)}
						</Grid>
						<Grid item xs={2} align="center">
							{["risk_analysis"].includes(config[0]) ? (
								<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
							) : (
								<TickIcon />
							)}
						</Grid>
						<Grid item xs={2} align="center">
							<TickIcon />
						</Grid>
					</Grid>
				))}
				<SoftBox bgColor="rgba(184, 193, 211, 0.18);" px={2} py={2} my={3}>
					<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
						Post-Training Support
					</SoftTypography>
				</SoftBox>
				{Object.entries(postTrainingSettings).map((config, index) => (
					<Grid key={index} container spacing={3} mb={2}>
						<Grid item xs={4}>
							<BulletSoftTypography pl={1} variant="body2" color="text">
								{config[1].description}
							</BulletSoftTypography>
						</Grid>
						<Grid item xs={2} align="center">
							{["live_personal", "live"].includes(config[0]) ? (
								<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
							) : (
								<TickIcon />
							)}
						</Grid>
						<Grid item xs={2} align="center">
							{["live_personal"].includes(config[0]) ? (
								<TickIcon bgColor="rgba(103, 116, 142, 0.2);" />
							) : (
								<TickIcon />
							)}
						</Grid>
						<Grid item xs={2} align="center">
							<TickIcon />
						</Grid>
						<Grid item xs={2} align="center">
							<TickIcon />
						</Grid>
					</Grid>
				))}
				<SoftBox bgColor="rgba(184, 193, 211, 0.18);" px={2} py={2} my={3}>
					<SoftTypography color="highlight" variant="h5" fontWeight="bold" textTransform="uppercase">
						Community
					</SoftTypography>
				</SoftBox>
				<Grid container spacing={3} mb={2}>
					<Grid item xs={4}>
						<BulletSoftTypography pl={1} variant="body2" color="text">
							Community Membership (Discord - Lifetime)
						</BulletSoftTypography>
					</Grid>
					<Grid item xs={2} align="center">
						<TickIcon />
					</Grid>
					<Grid item xs={2} align="center">
						<TickIcon />
					</Grid>
					<Grid item xs={2} align="center">
						<TickIcon />
					</Grid>
					<Grid item xs={2} align="center">
						<TickIcon />
					</Grid>
				</Grid>
				<Grid container spacing={3} mb={2}>
					<Grid item xs={4}>
						<BulletSoftTypography pl={1} variant="body2" color="text">
							Annual Online Market Outlook Meeting & Top 30 Shortlist Stocks (January)
						</BulletSoftTypography>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftTypography variant="body2" color="text">
							Discount
						</SoftTypography>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftTypography variant="body2" color="text">
							Discount
						</SoftTypography>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftTypography variant="body2" color="text">
							Discount
						</SoftTypography>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftTypography variant="body2" color="text">
							Discount
						</SoftTypography>
					</Grid>
				</Grid>
				<Grid container spacing={3} mb={2}>
					<Grid item xs={4}>
						<BulletSoftTypography pl={1} variant="body2" color="text">
							Annual Overseas Investment Retreat Workshops (June)
						</BulletSoftTypography>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftTypography variant="body2" color="text">
							Discount
						</SoftTypography>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftTypography variant="body2" color="text">
							Discount
						</SoftTypography>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftTypography variant="body2" color="text">
							Discount
						</SoftTypography>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftTypography variant="body2" color="text">
							Discount
						</SoftTypography>
					</Grid>
				</Grid>
				<Grid container spacing={5} my={2}>
					<Grid item xs={4}></Grid>
					<Grid item xs={2} align="center">
						<SoftButton
							sx={{
								background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
								color: "#FFF",
							}}
							onClick={() => buyBundle("emerald")}
							fullWidth
						>
							Join Now
						</SoftButton>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftButton
							sx={{
								background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
								color: "#FFF",
							}}
							onClick={() => buyBundle("ruby")}
							fullWidth
						>
							Join Now
						</SoftButton>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftButton
							sx={{
								background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
								color: "#FFF",
							}}
							onClick={() => buyBundle("saphire")}
							fullWidth
						>
							Join Now
						</SoftButton>
					</Grid>
					<Grid item xs={2} align="center">
						<SoftButton
							sx={{
								background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
								color: "#FFF",
							}}
							onClick={() => buyBundle("diamond")}
							fullWidth
						>
							Join Now
						</SoftButton>
					</Grid>
				</Grid>
			</SoftBox>
			<SoftBox sx={{ display: { xs: "block", sm: "none" } }}>
				<SoftBox mb={3}>
					<Card>
						<SoftBox pt={3} pb={2} px={2} textAlign="center">
							<SoftTypography variant="h4" color="highlight" textTransform="uppercase">
								Emerald
							</SoftTypography>
							<SoftBox my={1}>
								<SoftTypography variant="h2" fontWeight="bold">
									S$ 675
								</SoftTypography>
							</SoftBox>
						</SoftBox>
						<CollapsiblePlanInfo
							title={["NFT Collection", <br />, "(Memebership NFT)"]}
							optionGroup={nftCollectionSettings}
							options={["nft", "metaverse", "airdrops"]}
						/>
						<CollapsiblePlanInfo
							title={["BBV Basic", <br />, "(Lifetime Subscription)"]}
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 1)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title="BBV Pro Stock - GEM Ranking System (1 Year Subscription)"
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 2)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title={["e-LEARNING", <br />, "(Learn & Take Action)"]}
							optionGroup={eLearningSettings}
							options={["ecis"]}
						/>
						<CollapsiblePlanInfo
							title="Live Online Workshop (1 Day)"
							optionGroup={onlineWorkshopSettings}
							options={["fundamental"]}
						/>
						<CollapsiblePlanInfo
							title="Post Training Support"
							optionGroup={postTrainingSettings}
							options={["live_bimonthly", "online"]}
						/>
						<SoftBox p={3} pt={0}>
							<SoftButton
								sx={{
									background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
									color: "#FFF",
								}}
								fullWidth
								onClick={() => buyBundle("emerald")}
							>
								Join Now
							</SoftButton>
						</SoftBox>
					</Card>
				</SoftBox>
				<SoftBox mb={3}>
					<Card>
						<SoftBox pt={3} pb={2} px={2} textAlign="center">
							<SoftTypography variant="h4" color="highlight" textTransform="uppercase">
								Ruby
							</SoftTypography>
							<SoftBox my={1}>
								<SoftTypography variant="h2" fontWeight="bold">
									S$ 2,400
								</SoftTypography>
							</SoftBox>
						</SoftBox>
						<CollapsiblePlanInfo
							title={["NFT Collection", <br />, "(Memebership NFT)"]}
							optionGroup={nftCollectionSettings}
							options={["nft", "metaverse", "airdrops"]}
						/>
						<CollapsiblePlanInfo
							title={["BBV Basic", <br />, "(Lifetime Subscription)"]}
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 1)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title="BBV Pro Stock - GEM Ranking System (1 Year Subscription)"
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 2)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title="BBV Pro Options + GEM Ranking System (1 Year Subscription)"
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(
									config =>
										config[1].level === 3 &&
										!["options_scanner_rbs", "options_scanner_bps"].includes(config[0])
								)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title={["e-LEARNING", <br />, "(Learn & Take Action)"]}
							optionGroup={eLearningSettings}
							options={["ecis", "ecpo_basic"]}
						/>
						<CollapsiblePlanInfo
							title="Live Online Workshop (1.5 Days)"
							optionGroup={onlineWorkshopSettings}
							options={["fundamental", "basic_options"]}
						/>
						<CollapsiblePlanInfo
							title="Post Training Support"
							optionGroup={postTrainingSettings}
							options={["live_bimonthly", "live", "online"]}
						/>
						<SoftBox p={3} pt={0}>
							<SoftButton
								sx={{
									background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
									color: "#FFF",
								}}
								fullWidth
								onClick={() => buyBundle("ruby")}
							>
								Join Now
							</SoftButton>
						</SoftBox>
					</Card>
				</SoftBox>
				<SoftBox mb={3}>
					<Card>
						<SoftBox pt={3} pb={2} px={2} textAlign="center">
							<SoftTypography variant="h4" color="highlight" textTransform="uppercase">
								Sapphire
							</SoftTypography>
							<SoftBox my={1}>
								<SoftTypography variant="h2" fontWeight="bold">
									S$ 5,040
								</SoftTypography>
							</SoftBox>
						</SoftBox>
						<CollapsiblePlanInfo
							title={["NFT Collection", <br />, "(Memebership NFT)"]}
							optionGroup={nftCollectionSettings}
							options={["nft", "metaverse", "airdrops"]}
						/>
						<CollapsiblePlanInfo
							title={["BBV Basic", <br />, "(Lifetime Subscription)"]}
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 1)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title="BBV Pro Stock - GEM Ranking System (1 Year Subscription)"
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 2)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title="BBV Pro Options + GEM Ranking System (1 Year Subscription)"
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 3)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title={["e-LEARNING", <br />, "(Learn & Take Action)"]}
							optionGroup={eLearningSettings}
							options={["ecis", "ecpo_basic", "ecpo_intermediate"]}
						/>
						<CollapsiblePlanInfo
							title="Live Online Workshop (2 Days)"
							optionGroup={onlineWorkshopSettings}
							options={["fundamental", "basic_options", "intermediate_options"]}
						/>
						<CollapsiblePlanInfo
							title="Post Training Support"
							optionGroup={postTrainingSettings}
							options={["live_personal", "live_bimonthly", "live", "online"]}
						/>
						<SoftBox p={3} pt={0}>
							<SoftButton
								sx={{
									background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
									color: "#FFF",
								}}
								fullWidth
								onClick={() => buyBundle("saphire")}
							>
								Join Now
							</SoftButton>
						</SoftBox>
					</Card>
				</SoftBox>
				<SoftBox mb={3}>
					<Card>
						<SoftBox pt={3} pb={2} px={2} textAlign="center">
							<SoftTypography variant="h4" color="highlight" textTransform="uppercase">
								Diamond
							</SoftTypography>
							<SoftBox my={1}>
								<SoftTypography variant="h2" fontWeight="bold">
									S$ 10,800
								</SoftTypography>
							</SoftBox>
						</SoftBox>
						<CollapsiblePlanInfo
							title={["NFT Collection", <br />, "(Memebership NFT)"]}
							optionGroup={nftCollectionSettings}
							options={["nft", "metaverse", "airdrops"]}
						/>
						<CollapsiblePlanInfo
							title={["BBV Basic", <br />, "(Lifetime Subscription)"]}
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 1)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title="BBV Pro Stock - GEM Ranking System (1 Year Subscription)"
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 2)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title="BBV Pro Options + GEM Ranking System (1 Year Subscription)"
							optionGroup={planSettings}
							options={Object.entries(planSettings)
								.filter(config => config[1].level === 3)
								.map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title={["e-LEARNING", <br />, "(Learn & Take Action)"]}
							optionGroup={eLearningSettings}
							options={Object.entries(eLearningSettings).map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title="Live Online Workshop (3 Days)"
							optionGroup={onlineWorkshopSettings}
							options={Object.entries(onlineWorkshopSettings).map(config => config[0])}
						/>
						<CollapsiblePlanInfo
							title="Post Training Support"
							optionGroup={postTrainingSettings}
							options={Object.entries(postTrainingSettings).map(config => config[0])}
						/>
						<SoftBox p={3} pt={0}>
							<SoftButton
								sx={{
									background: "linear-gradient(145.56deg, #17AD37 0.3%, #0E6620 94.23%);",
									color: "#FFF",
								}}
								fullWidth
								onClick={() => buyBundle("diamond")}
							>
								Join Now
							</SoftButton>
						</SoftBox>
					</Card>
				</SoftBox>
			</SoftBox>

			{isPreparingPayment && (
				<SoftBox
					px={{
						display: "flex",
						justifyContent: "center",
						position: "fixed",
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						alignItems: "center",
					}}
				>
					<CircularProgress color="inherit" />
				</SoftBox>
			)}
		</Container>
	);
};
