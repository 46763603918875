export const planSettings = {
	basic_dashboard: {
		description: "Basic Dashboard for Market Overview and Sentiments",
		tooltip: "",
		level: 1
	},
	risk_analysis: {
		description: "User Risk Profile Analysis",
		tooltip: "",
		level: 1
	},
	market_analysis: {
		description: "Global Market Analysis",
		tooltip: "",
		level: 1
	},
	global_news: {
		description: "Global Economic News and Calendar",
		tooltip: "",
		level: 1
	},
	fundamental_analysis: {
		description: "Fundamental Analysis of more than 7,000 stocks",
		tooltip: "",
		level: 1
	},
	technical_analysis: {
		description: "Technical Analysis with a Sentiment Barometer",
		tooltip: "",
		level: 1
	},
	guru_analysis: {
		description: "Guru Portfolio Analysis",
		tooltip: "",
		level: 1
	},
	unlimited_watchlist: {
		description: "Unlimited Watchlist",
		tooltip: "",
		level: 1
	},
	simulated_portfolio: {
		description: "Customizable Simulated Portfolio",
		tooltip: "",
		level: 1
	},
	top_10_crypto: {
		description: "Top 10 Crypto Watchlist",
		tooltip: "",
		level: 1
	},
	community_support: {
		description: "100% Real Support Community ",
		tooltip: "",
		level: 2
	},
	advanced_dashboard: {
		description: "Advanced Dashboard for Market Overview and Sentiments ",
		tooltip: "",
		level: 2
	},
	mind_analysis: {
		description: "Mind Analysis of Macro Economics",
		tooltip: "",
		level: 2
	},
	gem_ranking: {
		description: "GEM Stock Ranking Scanner ",
		tooltip: "",
		level: 2
	},
	gem_traffic_light: {
		description: "GEM Traffic Light System",
		tooltip: "",
		level: 2
	},
	gem_stock_ranking: {
		description: "GEM Stock Ranking by Defensive and Cyclical Sectors ",
		tooltip: "",
		level: 2
	},

	gem_options_ranking: {
		description: "GEM Options Ranking Scanner",
		tooltip: "",
		level: 3
	},
	automated_stocks_shortlist: {
		description: "Automated Stocks Shortlist for Options",
		tooltip: "",
		level: 3
	},
	options_scanner_fis: {
		description: "Options Scanner FIS Strategy",
		tooltip: "",
		level: 3
	},
	options_scanner_rbs: {
		description: "Options Scanner RBS Strategy",
		tooltip: "",
		level: 3
	},
	options_scanner_bps: {
		description: "Options Scanner Bull Put Strategy",
		tooltip: "",
		level: 3
	},
	options_scanner_bcs: {
		description: "Options Scanner Bear Call Strategy",
		tooltip: "",
		level: 3
	},
	entry_setup_options_strategies: {
		description: "Entry Set-up for Options Strategies Execution (hands-on)",
		tooltip: "",
		level: 3
	},
};

export const nftCollectionSettings = {
	nft: {
		description: "BullBear NFT",
		tooltip: "",
	},
	metaverse: {
		description: "1st rights to Metaverse ",
		tooltip: "",
	},
	airdrops: {
		description: "Airdrops (Bonus, Rewards, NFTs)",
		tooltip: "",
	},
};

export const eLearningSettings = {
	ecis: {
		description:
			"eCIS (Profitable Stocks Investing - Fundamental & Technical Analysis Shortlisting with Entry/Exit set up)",
		tooltip: "",
	},
	ecpo_basic: {
		description: "eCPO Basic (eCIS + Profitable Basic Options Strategies with Options Entry/Exit set up) ",
		tooltip: "",
	},
	ecpo_intermediate: {
		description:
			"eCPO Intermediate (eCPO Basic + High ROI Intermediate Options Strategies with Management & Repair Strategies) ",
		tooltip: "",
	},
	ecpo_advanced: {
		description:
			"eCPO Advanced (eCPO Intermediate + Professional Options Applications, Day Trading & Scalping Strategies",
		tooltip: "",
	},
};

export const onlineWorkshopSettings = {
	fundamental: {
		description: "1 Day",
		extra: "Fundamental & Technical Analysis + Hands-On + Live Trade",
		tooltip: "",
	},
	basic_options: {
		description: "0.5 Day",
		extra: "Basic Options Strategies + Hands-On + Live Trade ",
		tooltip: "",
	},
	intermediate_options: {
		description: "0.5 Day",
		extra: "Intermediate Options Strategies + Hands-On + Live Trade",
		tooltip: "",
	},
	risk_analysis: {
		description: "1 Day",
		extra: "Risk Analysis & Mind Analysis + Hands-On + Live Trade",
		tooltip: "",
	},
};

export const postTrainingSettings = {
	live_personal: {
		description: "Live Personal Mentorship (1 Year)",
		tooltip: "",
	},
	live_bimonthly: {
		description: "Live Bi-Monthly Online Stock Tutorial (Lifetime)",
		tooltip: "",
	},
	live: {
		description: "Live Online Tutorial (Lifetime)",
		tooltip: "",
	},
	online: {
		description: "Online Q&A Channel (Lifetime)",
		tooltip: "",
	},
};
