
import React, { useEffect, useState } from 'react';
import { getEarningsRelease } from '../data-tables/data/getData';
import DataTable from 'examples/Tables/DataTable';
import { earningsReleaseData } from '../data-tables/data/dataTableData';

const EarningReleaseTable = () => {
    const [inputData,setData] = useState(earningsReleaseData);
    const [isLoading, setLoading] = useState(true);

    useEffect(() =>{
        handleEarningsRelease();
    },[]);

    const handleEarningsRelease= async() =>{
        const earningsReleaseData = await getEarningsRelease();
        const formattedEarningsRelease = formatEarningsRelease(earningsReleaseData);
        setTimeout(() => {
			setData(formattedEarningsRelease);
			// setLoading(false);
		}, 100)

    }

    const columns = [
        {Header: "Name", accessor:'name'},
        {Header: "Release Date", accessor:'releaseDate'},
        {Header: "Estimate", accessor:'estimate'},

    ]

    const formatEarningsRelease = (earningsReleaseData) =>{
        let theTableData = earningsReleaseData.map((earnings,index)=>{
            let name = `${earnings.name} (${earnings.symbol})`
            let estimate = earnings.epsEstimate;
            if(!earnings.name){
                name = `${earnings.symbol}`
            }

            if(estimate == null){
                estimate = 'N.A.'
            }
            
            return{
                estimate:estimate,releaseDate:earnings.date,name:name
            }

            
        })
        const earningsReleaseDataTable = {
			columns: columns.map(header => {
				return { Header: header.Header, accessor: header.accessor };
			}),
			rows: theTableData,
		};

        return earningsReleaseDataTable;
    }


    return (
        <div>
            <DataTable table={inputData} canSearch entriesPerPage={{defaultValue: 25}}></DataTable>
        </div>
    );
};

export default EarningReleaseTable;