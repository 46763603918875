import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useWeb3 } from "context/web3";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Popup from "layouts/applications/popup/Popup";

import { fetchCollection } from "services/marketplace";
import SoftProcessing from "components/SoftProcessing";
import ConnectWalletButton from "components/etc/ConnectWalletButton";
import { Card } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import { tokenLinkURL } from "layouts/Helper/blockchain.helper";
import ListingForm from "components/Marketplace/ListingForm";
import { list, markApprovedForAll } from "plugins/Ethereum";
import { setCollectionTokenListed } from "services/marketplace";

export default () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isProcessing, setIsProcessing] = useState(false);
	const [collection, setCollection] = useState(null);
	const [showListToken, setShowListToken] = useState(false);

	const { collectionID } = useParams();
	const navigate = useNavigate();

	const { web3Controller, connectToBlockchain } = useWeb3();
	const { connected: connectedToBlockchain, ...blockchainInfo } = web3Controller;

	useEffect(() => {
		loadCollectionData();
	}, [collectionID]);

	const loadCollectionData = async () => {
		const data = await fetchCollection(collectionID);
		if (data?.id) {
			setCollection(data);
		} else {
			navigate("/dashboard/error/404", { replace: true });
		}
		setIsLoading(false);
	};

	const handleAddNewButton = () => {
		navigate(`/admin/marketplace/collections/${collectionID}/tokens/mint`);
	};

	const handleViewNFT = selectedToken => {
		const token = collection.tokens.find(token => token.id === selectedToken.id);
		window.open(tokenLinkURL(collection.chainID, collection.contractAddress, token.tokenID));
	};

	const handleListNFT = token => {
		setShowListToken(token);
	};

	const handleAddListing = values => {
		setIsProcessing(true);

		try {
			markApprovedForAll(721, collection.contractAddress, process.env.REACT_APP_MARKETPLACE_CONTRACT).then(() => {
				list(
					721,
					collection.contractAddress,
					showListToken.tokenID,
					values.price,
					"0xe097d6b3100777dc31b34dc2c58fb524c2e76921"
				)
					.then(async () => {
						await setCollectionTokenListed(collectionID, showListToken.id, {
							listingInfo: {
								listed: true,
								price: values.price,
							},
						});

						loadCollectionData();
					})
					.catch(error => console.log(error))
					.finally(() => {
						setShowListToken(false);
						setIsProcessing(false);
					});
			});
		} catch (e) {
			setIsProcessing(false);
			console.log(e);
		}
	};

	const handleClosePopup = () => {
		setShowListToken(false);
	};

	const columns = [
		{ Header: "Thumb", accessor: "image", type: "image" },
		{ Header: "Name", accessor: "name" },
		{ Header: "Minted By", accessor: "minter" },
		{ Header: "Owned By", accessor: "owner" },
		{ Header: "Listing Price", accessor: "price", type: "currency", symbol: "USDC" },
		{
			Header: "Actions",
			accessor: "action_buttons",
			align: "right",
			buttons: [
				{ key: "view", text: "View", action: handleViewNFT, color: "info" },
				{ key: "list", text: "List", action: handleListNFT, color: "error" },
			],
		},
	];

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{isLoading ? (
				<SoftProcessing overlay={false} />
			) : (
				<SoftBox pb={3}>
					<SoftBox mb={3} style={{ textAlign: "right" }}>
						<ConnectWalletButton
							textUponConnected="Mint New Token"
							actionAfterConnected={handleAddNewButton}
							restrictedToAccount={collection.ownerAddress}
							restrictedToNetwork={collection.chainID}
						/>
					</SoftBox>
					<SoftBox mb={3}>
						{collection.tokens?.length > 0 ? (
							<DataTable
								table={{
									columns,
									rows: collection.tokens.map(token => ({
										...token,
										...token.metadataCache,
										...token.listingInfo,
										hideActionButtons: [
											token.listingInfo?.listed ||
											!connectedToBlockchain ||
											blockchainInfo.wallet_address !== token.owner ||
											!token.tokenID
												? "list"
												: "",
										],
									})),
								}}
								entriesPerPage={null}
								loading={isLoading}
							/>
						) : (
							<Card>
								<SoftTypography align="center">No tokens have been Minted yet!</SoftTypography>
							</Card>
						)}
					</SoftBox>
				</SoftBox>
			)}
			{showListToken && (
				<Popup>
					<CloseIcon
						className="close-btn icon"
						onClick={() => {
							handleClosePopup();
						}}
					/>
					<SoftBox mt={3} sx={{ minWidth: "300px" }}>
						<ListingForm onSave={handleAddListing} />
					</SoftBox>
				</Popup>
			)}
			{isProcessing && <SoftProcessing />}
			<Footer />
		</DashboardLayout>
	);
};
