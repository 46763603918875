/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import { useNavigate } from "react-router-dom";
import { getOptionsData } from "../data-tables/data/getData";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";

import { roundToTwo } from "layouts/Helper/helper";
import BackToButton from "components/CustomComponents";
import { OptionInfoPopup } from "components/CustomPopups/OptionPopups";
import { DisclaimerPopup } from "components/CustomPopups/InfoPopups";

const OptionsTableMenu = type => {
	const sector_defensive = [
		{
			name: "Communication Services (XLC)",
			exchange: "AMEX",
			Ticker: "XLC",
		},
		{
			name: "Consumer Staples (XLP)",
			exchange: "AMEX",
			Ticker: "XLP",
		},
		{
			name: "Energy (XLE)",
			exchange: "AMEX",
			Ticker: "XLE",
		},
		{
			name: "Health Care (XLV)",
			exchange: "AMEX",
			Ticker: "XLV",
		},
		{
			name: "Utilities (XLU)",
			exchange: "AMEX",
			Ticker: "XLU",
		},
	];
	const sector_cyclical = [
		{
			name: "Consumer Discretionary (XLY)",
			exchange: "AMEX",
			Ticker: "XLY",
		},
		{
			name: "Financials (XLF)",
			exchange: "AMEX",
			Ticker: "XLF",
		},
		{
			name: "Industrials (XLI)",
			exchange: "AMEX",
			Ticker: "XLI",
		},
		{
			name: "Materials (XLB)",
			exchange: "AMEX",
			Ticker: "XLB",
		},
		{
			name: "Real Estate (XLRE)",
			exchange: "AMEX",
			Ticker: "XLRE",
		},
		{
			name: "Technology (XLK)",
			exchange: "AMEX",
			Ticker: "XLK",
		},
	];

	// console.log(type)
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	let currentData;
	if (type.type === "defensive") {
		currentData = sector_defensive;
	} else {
		currentData = sector_cyclical;
	}

	return (
		<div>
			{/* <Button
				className="sectorButtons greyDropdown"
				id="basic-button"
				variant="contained"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				style={{ width: "80%", marginBottom: "10px", maxWidth: "190px" }}
			>
				{type.type === "bullish" ? "BULLISH" : "BEARISH"}
				<KeyboardArrowDownIcon style={{ marginLeft: "6px" }}></KeyboardArrowDownIcon>
			</Button> */}
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{currentData.map((sector, index) => {
					return (
						<div key={index}>
							<MenuItem>{sector.name}</MenuItem>
						</div>
					);
				})}
			</Menu>
		</div>
	);
};

function DataTables() {
	// const [isLoading,setLoading] = useState(true);
	const [inputOption, setOption] = useState("Funded Income Strategy (FIS)");
	const [initialData, setData] = useState(dataTableData);
	const [firstLoad, setLoad] = useState(true);
	const [loading, setLoading] = useState(false);
	const [fullData, setFullData] = useState();
	const [infoPopup, toggleInfoPopup] = useState(false);
	const [popupAccessor, setPopupAccessor] = useState("");
	const [disclaimerPopup, setDisclaimerPopup] = useState(true);
	useEffect(() => {
		setLoading(true);
		handleOption(inputOption);
		setDisclaimerPopup(true);
	}, []);
	const handleOption = async option => {
		if (firstLoad || inputOption !== option) {
			setLoad(false);
			setLoading(true);
			setOption(option);
			const optionData = await getOptionsData(option);

			// added to avoid inifite update to DOM
			setTimeout(() => {
				setFullData(optionData);
				setData(formatOptions(option, optionData));
				// setData(await formatOptions(option,optionData))
				setLoading(false);
			}, 100);
		}
	};
	const handleInfoPop = () => {
		let popupAccessor;
		if (inputOption === "Funded Income Strategy (FIS)") {
			popupAccessor = "fis";
		} else if (inputOption === "Multiple Tight Vertical Strategy (BPS)") {
			popupAccessor = "bps";
		} else if (inputOption === "Rolling Boat Strategy (RBS)") {
			popupAccessor = "rbs";
		} else {
			//bcs
			popupAccessor = "bcs";
		}
		toggleInfoPopup(true);
		setPopupAccessor(popupAccessor);
	};
	const formatOptions = (name, optionData) => {
		var final = [{}];
		if (name === "Funded Income Strategy (FIS)" || name === "Multiple Tight Vertical Strategy (BPS)") {
			final = [
				{ Header: "Rank", accessor: "Rank" },
				{ Header: "GEM Stock Rank", accessor: "gemStockRank" },
				{ Header: "SYM", accessor: "symbol" },
				{ Header: "Sector", accessor: "sector" },
				{ Header: "Stock Price", accessor: "stockPrice" },
				{ Header: "Capital Needed", accessor: "CapitalNeeded" },
				{ Header: "Premium", accessor: "premium" },
				{ Header: "Est Return (%)", accessor: "estimatedReturn" },
				{ Header: "How To Apply", accessor: "applyButton" },
			];
		} else if (name === "Rolling Boat Strategy (RBS)") {
			final = [
				{ Header: "Rank", accessor: "Rank" },
				{ Header: "GEM Stock Rank", accessor: "gemStockRank" },
				{ Header: "SYM", accessor: "symbol" },
				{ Header: "Sector", accessor: "sector" },
				{ Header: "Stock Price", accessor: "stockPrice" },
				{ Header: "Capital Needed", accessor: "CapitalNeeded" },
				{ Header: "Premium", accessor: "premium" },
				{ Header: "Min Return (%)", accessor: "minReturn" },
				{ Header: "Max Return (%)", accessor: "maxReturn" },
				{ Header: "How To Apply", accessor: "applyButton" },
			];
		} else {
			//BCS
			final = [
				{ Header: "Rank", accessor: "Rank" },
				{ Header: "GEM Stock Rank", accessor: "gemStockRank" },
				{ Header: "SYM", accessor: "symbol" },
				{ Header: "Sector", accessor: "sector" },
				{ Header: "Stock Price", accessor: "stockPrice" },
				{ Header: "Capital Needed", accessor: "CapitalNeeded" },
				{ Header: "Premium", accessor: "premium" },
				{ Header: "Est Return (%)", accessor: "minReturn" },
				{ Header: "How To Apply", accessor: "applyButton" },
			];
		}

		const theTableData = optionData.map(options => {
			var estimatedReturn = ((options.MaxProfit / options.MaxLoss) * 100).toFixed(2);

			if (name === "Funded Income Strategy (FIS)") {
				return {
					Rank: options.Rank,
					gemStockRank: options.GEM_Rank,
					symbol: options.Symbol,
					sector: options.IndustrySector,
					CapitalNeeded: options.MaxLoss,
					premium: options.MaxProfit,
					stockPrice: options.TodayPrice,
					estimatedReturn: estimatedReturn,
				};
			} else if (name === "Multiple Tight Vertical Strategy (BPS)") {
				return {
					Rank: options.Rank,
					gemStockRank: options.GEM_Rank,
					symbol: options.Symbol,
					sector: options.IndustrySector,
					CapitalNeeded: options.MaxLoss,
					premium: options.Premium * 100,
					stockPrice: options.TodayPrice,
					estimatedReturn: estimatedReturn,
				};
			} else if (name === "Rolling Boat Strategy (RBS)") {
				return {
					Rank: options.Rank,
					gemStockRank: options.GEM_Rank,
					symbol: options.Symbol,
					sector: options.IndustrySector,
					CapitalNeeded: options.BC_Price * 100,
					premium: options.SC_Price * 100,
					stockPrice: roundToTwo(options.TodayPrice),
					minReturn: options.OTM_ROC,
					maxReturn: options.ITM_ROC,
				};
			} else {
				//BCS
				return {
					Rank: options.Rank,
					gemStockRank: options.GEM_Rank,
					symbol: options.Symbol,
					sector: options.IndustrySector,
					CapitalNeeded: options.MaxLoss,
					premium: options.Premium * 100,
					stockPrice: roundToTwo(options.TodayPrice),
					minReturn: options.ROC,
				};
			}
		});

		const optionsDataTable = {
			columns: final.map(header => {
				return { Header: header.Header, accessor: header.accessor };
			}),
			rows: theTableData,
		};
		return optionsDataTable;
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div
					style={{
						marginTop: "",
						display: "flex",
						justifyContent: "left",
						textAlign: "center",
						alignItems: "center",
					}}
				>
					<h3 style={{ textAlign: "center", fontSize: "18px", marginRight: "5px" }}>{inputOption}</h3>
					<InfoIcon
						onClick={() => {
							handleInfoPop();
						}}
						className="icon"
					></InfoIcon>
				</div>
				<BackToButton text="Back to Stock Ranking" destination="/dashboard/GEM/stocks-ranking"></BackToButton>
			</div>
			<Grid container justifyContent="space-between">
				<Grid item>
					<OptionsTableMenu type="defensive" />
				</Grid>
			</Grid>
			<Grid container justifyContent="space-between">
				<Grid item>
					<SoftButton
						onClick={() => handleOption("Funded Income Strategy (FIS)")}
						className="greenOutline optionButtons lg-view"
						variant="outlined"
						// color="info"
					>
						FUNDED INCOME STRATEGY <br></br>(Sell Put/Sell Call)
					</SoftButton>
					<SoftButton
						onClick={() => handleOption("Funded Income Strategy (FIS)")}
						className="greenOutline smallFont optionButtons sm-view"
						variant="outlined"
						color="info"
					>
						FIS <br></br>(Sell Put/Sell Call)
					</SoftButton>
				</Grid>
				<Grid item>
					<SoftButton
						onClick={() => handleOption("Rolling Boat Strategy (RBS)")}
						className="greenOutline optionButtons lg-view"
						variant="outlined"
						color="info"
					>
						ROLLING BOAT STRATEGY <br></br>(Buy call/Sell Call)
					</SoftButton>
					<SoftButton
						onClick={() => handleOption("Rolling Boat Strategy (RBS)")}
						className="greenOutline smallFont optionButtons sm-view"
						variant="outlined"
						color="info"
					>
						RBS <br></br>(Buy call/Sell Call)
					</SoftButton>
				</Grid>
				<Grid item>
					<SoftButton
						onClick={() => handleOption("Multiple Tight Vertical Strategy (BPS)")}
						className="greenOutline optionButtons lg-view"
						variant="outlined"
						color="info"
					>
						MULTIPLE TIGHT VERTICAL SPREAD <br></br>(Bull Put Spread)
					</SoftButton>
					<SoftButton
						onClick={() => handleOption("Multiple Tight Vertical Strategy (BPS)")}
						className="greenOutline smallFont optionButtons sm-view"
						variant="outlined"
						color="info"
					>
						BPS <br></br>(Bull Put Spread)
					</SoftButton>
				</Grid>
				<Grid item>
					<SoftButton
						onClick={() => handleOption("Multiple Tight Vertical Strategy (BCS)")}
						className="redOutline optionButtons lg-view"
						variant="outlined"
						color="info"
					>
						MULTIPLE TIGHT VERTICAL SPREAD <br></br>(Bear Call Spread)
					</SoftButton>
					<SoftButton
						onClick={() => handleOption("Multiple Tight Vertical Strategy (BCS)")}
						className="redOutline smallFont optionButtons sm-view"
						variant="outlined"
						color="info"
					>
						BCS <br></br>(Bear Call Spread)
					</SoftButton>
				</Grid>
			</Grid>

			<SoftBox pt={3} pb={3}>
				<SoftBox mb={3}>
					<Card>
						{/* {isLoading?<h1>not ready</h1>:<DataTable table={initialData} /> } */}
						<DataTable fullData={fullData} optionType={inputOption} table={initialData} loading={loading} />
					</Card>
				</SoftBox>
			</SoftBox>
			<Footer />

			{disclaimerPopup ? <DisclaimerPopup toggleInfoPopup={setDisclaimerPopup}></DisclaimerPopup> : <div></div>}
			{infoPopup ? (
				<OptionInfoPopup toggleInfoPopup={toggleInfoPopup} popupAccessor={popupAccessor} />
			) : (
				<div></div>
			)}
		</DashboardLayout>
	);
}

export default DataTables;
