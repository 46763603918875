import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import BackToButton from "components/CustomComponents";
import { Grid, Button } from "@mui/material";
import SalesTable from "examples/Tables/SalesTable";
import salesTableData from "layouts/ecommerce/overview/data/salesTableData";
import ChannelsChart from "layouts/ecommerce/overview/components/ChannelsChart";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend, Line, Tooltip } from "recharts";
import { getStockData } from "layouts/applications/data-tables/data/getData";
import PortfolioSummary from "./PortfolioSummary";
//imports for line chart
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import SoftBox from "components/SoftBox";
import SoftBadgeDot from "components/SoftBadgeDot";
// import Tooltip from "@mui/material/Tooltip";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Icon from "@mui/material/Icon";
import defaultLineChartData from "layouts/ecommerce/overview/data/defaultLineChartData";
import PortfolioStockTable from "examples/Tables/PortfolioStockTable";
import PortfolioOptionTable from "examples/Tables/PortfolioOptionTable";

import { portfolioStockTableData, portfolioOptionTableData } from "../data-tables/data/dataTableData";
import { item } from "examples/Sidenav/styles/sidenavItem";
import { getSingleOption, getUserCapital, getUserPortfolioOptions, getUserPortfolioStocks } from "../data-tables/data/getData";
import useAuth from "layouts/authentication/hooks/useAuth";



//iterate through each stock and place them in cateogry object
//once done, we iterate through category object and place them in second object


const dummyLineChartData = {
	labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
	datasets: [
		{
			label: "Income",
			color: "dark",
			data: [130000, 160000, 170000, 160000, 150000, 140000, 146000, 130000, 150000],
		},
	],
	// labels: ["Jun",],
	// datasets: [
	// 	{
	// 		label: "Income",
	// 		color: "dark",
	// 		data: [-1000],
	// 	},
	// ],
	//compute profit/loss. sum of all the data
	//add this to backend
};

const PortfolioChart = ({ diversificationStocks, diversificationSectors }) => {
	let colors = ["#3C4B68", "#889AB7", "#983F3F", "#99984E", "#BA9A50"];
	let colorLength = colors.length;
	return (
		<PieChart margin={10} width={285} height={400}>
			<Legend verticalAlign="top" height={36} />
			<Tooltip></Tooltip>
			<Pie data={diversificationSectors} legendType="circle" nameKey="sector" dataKey="value" outerRadius={60} fill="#8884d8">
				{diversificationSectors.map((data, index) => {
					//although data is not used, it has to be the first parameter and index has to be the second parameter
					let relativeIndex = index;
					if (index > colorLength - 1) {
						//go back to first number
						relativeIndex = index % colorLength;
					}
					return <Cell key={index} fill={colors[relativeIndex]} />;
				})}
			</Pie>

			<Pie
				data={diversificationStocks}
				legendType="none"
				nameKey="ticker"
				dataKey="totalValue"
				innerRadius={70}
				outerRadius={90}
				fill="#82ca9d"
				text="hello"
				label
			>
				{diversificationStocks.map((data, index) => {
					//although data is not used, it has to be the first parameter and index has to be the second parameter
					let relativeIndex = index;
					if (index > colorLength - 1) {
						//go back to first number
						relativeIndex = index % colorLength;
					}
					return <Cell key={index} fill={colors[relativeIndex]} />;
				})}
			</Pie>
		</PieChart>
	);
};

const PortfolioPage = () => {
	const [initialCapital, setInitialCapital] = useState(0);
	const [userStockTableInfo, setUserStockTableInfo] = useState(portfolioStockTableData);
	const [userOptionTableInfo, setUserOptionTableInfo] = useState(portfolioOptionTableData);
	const [renderUseEffect, setRender] = useState(0)
	const [currentType, setCurrentType] = useState("stocks")
	const [diversificationStocks, setDiversificationStocks] = useState([]);
	const [diversificationSectors, setDiversificationSectors] = useState([]);
	const [chartProfit, setChartProfit] = useState({ profit: 0, profitPercentage: 0 });
	const { auth } = useAuth();
	//first parameter is the modified row, then the type: "stocks"/"options" and the index to modify
	const modifyTable = () => {
		setRender(renderUseEffect + 1);

	}

	const setTable = (item, type) => {
		if (type === "stocks") {
			let newData = [...userStockTableInfo.rows];
			newData.push(item);
			setUserStockTableInfo({ ...userStockTableInfo, rows: newData });
			setRender(renderUseEffect + 1);
		} else {
			let newData = [...userOptionTableInfo.rows];
			newData.push(item);
			setUserOptionTableInfo({ ...userOptionTableInfo, rows: newData });
			setRender(renderUseEffect + 1);
		}
	};

	useEffect(() => {
		fetchUserInfo();
	}, [renderUseEffect]);

	const fetchUserInfo = async () => {
		handleStockInfo();
		handleOptionInfo();
		fetchUserCapital();
		setInitialCapital(20000);
	};

	const fetchUserCapital = async () => {
		const userCapital = await getUserCapital()
		if (userCapital !== null) {
			setInitialCapital(userCapital)
		} else {
			setInitialCapital(0)
		}
	}

	const handleStockInfo = async () => {
		const portfolioStockRows = await getUserPortfolioStocks();
		sortForDiversificationChart(portfolioStockRows)

		if (portfolioStockRows !== null) {
			setUserStockTableInfo(prevState => {
				return { ...prevState, rows: portfolioStockRows };
			});
		}
	}
	const findCategory = (sectorArray, sector) => {
		let lengthOfArray = sectorArray.length
		for (let i = 0; i < lengthOfArray; i++) {
			if (sectorArray[i]["sector"] === sector) {
				return i
			}
		}
		return -1;
	}

	const sortForDiversificationChart = (portfolioItems) => {
		let sectorArray = []
		let stockArray = []
		for (let i = 0; i < portfolioItems.length; i++) {
			let currentSectorArrayLength = sectorArray.length
			let item = portfolioItems[i]
			const sector = item.companySector
			const ticker = item.symbol
			const totalValue = parseInt(item.quantity * item.currentMktPrice)
			const itemObject = { sector: sector, ticker: ticker, totalValue: (totalValue) }
			let index = findCategory(sectorArray, sector);
			if (index === -1) {
				//create new sectory in array
				sectorArray[currentSectorArrayLength] = { sector: sector, value: (totalValue), stocks: [itemObject] }
			} else {
				sectorArray[index].stocks.push(itemObject)
				sectorArray[index] = { ...sectorArray[index], value: (sectorArray[index].value + totalValue) }
				//append existing array
			}
		}

		let lengthOfSector = sectorArray.length
		for (let i = 0; i < lengthOfSector; i++) {
			let lengthOfStocks = sectorArray[i].stocks.length
			for (let j = 0; j < lengthOfStocks; j++) {
				stockArray.push(sectorArray[i].stocks[j])
			}
		}

		setDiversificationSectors(sectorArray)
		setDiversificationStocks(stockArray)
	}

	const handleOptionInfo = async () => {
		const portfolioOptionRows = await getUserPortfolioOptions();
		if (portfolioOptionRows !== null) {
			setUserOptionTableInfo(prevState => {
				return { ...prevState, rows: portfolioOptionRows };
			});
		}

	}

	return (
		<DashboardLayout>
			<DashboardNavbar pageTitle="Portfolio page"></DashboardNavbar>
			<BackToButton text="Back to Dashboard" destination="/"></BackToButton>

			<Grid container justifyContent="space-between" style={{ textAlign: "center", marginTop: "20px" }}>
				<Grid className="portfolioPageCardOne" item>
					<PortfolioSummary setChartProfit={setChartProfit} chartProfit={chartProfit} renderUseEffect={renderUseEffect} />
				</Grid>
				<Grid className="portfolioPageCardTwo" item>
					<DefaultLineChart
						// title="Income"
						description={
							<SoftBox textAlign="left">
								<h1 className="roboto" style={{ fontSize: "18px" }}>
									Profit/Loss
								</h1>
								<h1 className="roboto" style={{ fontSize: "36px" }}>
									${chartProfit.profit} <span style={{ color: chartProfit.profit >= 0 ? "#48BB78" : "red", fontSize: "30px" }}>{chartProfit.profitPercentage}%</span>
								</h1>
							</SoftBox>
						}
						//to change color, go to default line chart and edit borderColor

						//add if statement here for jeremy

						chart={auth?.email && auth.email.toLowerCase() === "jeremytan@coreinvest.me" ? dummyLineChartData: {
							labels: ["Jun",],
							datasets: [
								{
									label: "Income",
									color: "dark",
									data: [chartProfit.profit],
								},
							],
						}}
					/>
				</Grid>
				<Grid justifyContent="center" className="portfolioPageCardThree" item>
					<h1 style={{ paddingLeft: "20px", textAlign: "left", paddingTop: "20px" }} className="roboto">
						Diversification
					</h1>
					<div className="centerPieChart pieChart">
						<PortfolioChart diversificationSectors={diversificationSectors} diversificationStocks={diversificationStocks} />
					</div>
				</Grid>
			</Grid>

			<Grid style={{ marginTop: "20px" }} container className="bbvWhiteCard">
				<SoftBox p={3} width="100%" textAlign="center">
					<SoftTypography variant="h3">Portfolio Management</SoftTypography>
				</SoftBox>

				<Grid style={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }} container>
					<SoftButton
						variant="outlined"
						className={currentType === "stocks" ? "portfolioButtonSelected" : "portfolioButtonUnselected"}
						onClick={() => setCurrentType("stocks")}
						style={{ marginRight: "20px" }}
					>
						STOCKS MANAGEMENT
					</SoftButton>
					<SoftButton
						variant="outlined"
						className={currentType === "options" ? "portfolioButtonSelected" : "portfolioButtonUnselected"}
						onClick={() => setCurrentType("options")}
						style={{ marginRight: "30px" }}
					>
						OPTIONS MANAGEMENT
					</SoftButton>
				</Grid>
				{currentType === "stocks" ? (
					<PortfolioStockTable
						table={userStockTableInfo}
						setInitialCapital={setInitialCapital}
						setTable={setTable}
						initialCapital={initialCapital}
						canSearch={true}
						modifyTable={modifyTable}
					/>
				) : (
					<PortfolioOptionTable
						table={userOptionTableInfo}
						setInitialCapital={setInitialCapital}
						setTable={setTable}
						initialCapital={initialCapital}
						canSearch={true}
						modifyTable={modifyTable}
					/>
				)}
			</Grid>

		</DashboardLayout>

	);
};

export default PortfolioPage;
