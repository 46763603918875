import * as Yup from "yup";
import form from "./form";

const {
	formField: { name },
} = form;

const validations = Yup.object().shape({
	[name.name]: Yup.string().required(name.errorMsg),
});

export default validations;
