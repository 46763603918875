/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Payment from "layouts/applications/popup/Payment";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";
import SoftButton from "components/SoftButton";
// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import buttonImage from "../../../assets/images/how-to-question 1.png";
import CloseIcon from "@mui/icons-material/Close";
import Popup from "layouts/applications/popup/Popup";
import Close from "@mui/icons-material/Close";
import popUP from "../../../assets/images/popUP.png";
import KeyIconOrData from "components/etc/KeyIconOrData";
import { formatDate } from "layouts/Helper/helper";
import { numberFormat } from "layouts/Helper/number.helper";
import blurredOptionsGraph from "../../../assets/images/blurredOptions.png"
import useAuth from "layouts/authentication/hooks/useAuth";
function DataTable({
	entriesPerPage,
	canSearch,
	showTotalEntries,
	table,
	pagination,
	isSorted,
	noEndBorder,
	loading,
	isDividend,
	exchange,
	setExchange,
	optionType,
	fullData,
	nameMaxWidth
}) {
	const defaultValue = entriesPerPage?.defaultValue ? entriesPerPage.defaultValue : 10;
	const entries = entriesPerPage?.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
	const columns = useMemo(() => table.columns, [table]);
	const data = useMemo(() => table.rows, [table]);
	const [popup, togglePopup] = useState(false);
	const { auth } = useAuth();
	const [isModalOpen, setModal] = useState(false);
	const [ratio, setRatio] = useState();
	const [currentOption, setOption] = useState({});
	const [CurrentOptionType, setOptionType] = useState("");
	const [filteredData, setFiltered] = useState();
	const [stockCode, setStockCode] = useState("");
	const navigate = useNavigate();

	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0 } },
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		pageOptions,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,

		state: { pageIndex, pageSize, globalFilter },
	} = tableInstance;

	// Set the default value for the entries per page when component mounts
	useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
	const source3 = `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_fc027&symbol=${stockCode}&interval=D&saveimage=0&toolbarbg=f1f3f6&details=1&studies=CCI%40tv-basicstudies%1FRSI%40tv-basicstudies&theme=light&style=2&timezone=Etc%2FUTC&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=&utm_medium=widget_new&utm_campaign=chart&utm_term=MSTR#%7B%22page-uri%22%3A%22__NHTTP__%22%7D`;

	// Set the entries per page value based on the select value
	const setEntriesPerPage = ({ value }) => setPageSize(value);
	const configureOption = option => {
		if (option === "Funded Income Strategy (FIS)") {
			setOptionType("FIS");
			setRatio(4);
		} else if (option === "Multiple Tight Vertical Strategy (BPS)") {
			setOptionType("BPS");
			setRatio(4);
		} else if (option === "Rolling Boat Strategy (RBS)") {
			setOptionType("RBS");
			setRatio(3);
		} else {
			//BCS
			setOptionType("BCS");
			setRatio(4);
		}
	};

	const parseData = (name, data) => {
		const theElement = data.find(element => element.Symbol === name);

		setFiltered(theElement);
	};

	// Render the paginations
	const renderPagination = pageOptions.map(option => (
		<SoftPagination
			item
			key={option}
			onClick={() => gotoPage(Number(option))}
			active={pageIndex === option}
			className={pageIndex === option ? "bbvRed" : ""}
		>
			{option + 1}
		</SoftPagination>
	));

	// Handler for the input to set the pagination index
	const handleInputPagination = ({ target: { value } }) =>
		value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

	// Customized page options starting from 1
	const customizedPageOptions = pageOptions.map(option => option + 1);

	// Setting value for the pagination input
	const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

	// Search input value state
	const [search, setSearch] = useState(globalFilter);

	// Search input state handle
	const onSearchChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 100);

	// A function that sets the sorted value for the table
	const setSortedValue = column => {
		let sortedValue;

		if (isSorted && column.isSorted) {
			sortedValue = column.isSortedDesc ? "desc" : "asce";
		} else if (isSorted) {
			sortedValue = "none";
		} else {
			sortedValue = false;
		}

		return sortedValue;
	};

	// Setting the entries starting point
	const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

	// Setting the entries ending point
	let entriesEnd;

	if (pageIndex === 0) {
		entriesEnd = Math.min(rows.length, pageSize);
	} else if (pageIndex === pageOptions.length - 1) {
		entriesEnd = rows.length;
	} else {
		entriesEnd = pageSize * (pageIndex + 1);
	}

	const DividendButtons = inline => {
		let className;
		if (inline.inline) {
			className = "dividendButtons";
		} else {
			className = "dividendButtonsOne";
		}
		return (
			<div className={className}>
				{exchange === "NYSE" ? (
					<SoftButton
						onClick={() => {
							handleDividendClick("NYSE");
						}}
						className="bbvGrey"
					>
						NYSE
					</SoftButton>
				) : (
					<SoftButton
						onClick={() => {
							handleDividendClick("NYSE");
						}}
						variant="text"
						style={{ color: "black" }}
					>
						NYSE
					</SoftButton>
				)}
				{exchange !== "NYSE" ? (
					<SoftButton
						onClick={() => {
							handleDividendClick("NASDAQ");
						}}
						className="bbvGrey"
					>
						NASDAQ
					</SoftButton>
				) : (
					<SoftButton
						onClick={() => {
							handleDividendClick("NASDAQ");
						}}
						variant="text"
						style={{ color: "black" }}
					>
						NASDAQ
					</SoftButton>
				)}
			</div>
		);
	};
	const handleDividendClick = exchange => {
		setExchange(exchange);
	};

	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			{entriesPerPage || canSearch ? (
				<SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
					{entriesPerPage && (
						<SoftBox display="flex" alignItems="center">
							<SoftSelect
								defaultValue={{ value: defaultValue, label: defaultValue }}
								options={entries.map(entry => ({ value: entry, label: entry }))}
								onChange={setEntriesPerPage}
								size="small"
							/>
							<SoftTypography variant="caption" color="secondary">
								&nbsp;&nbsp;entries per page
							</SoftTypography>
						</SoftBox>
					)}
					{isDividend === "yes" ? <DividendButtons inline={true} /> : <div></div>}
					{canSearch && (
						<SoftBox width="12rem" ml="auto">
							<SoftInput
								placeholder="Search..."
								value={search}
								onChange={({ currentTarget }) => {
									setSearch(search);
									onSearchChange(currentTarget.value);
								}}
							/>
						</SoftBox>
					)}
				</SoftBox>
			) : null}
			{isDividend === "yes" ? <DividendButtons inline={false} /> : <div></div>}
			{/* <DividendButtons inline={false} /> */}

			{loading ? (
				<div className="loadingIcon">
					<CircularProgress />
				</div>
			) : (
				<>
					<Table {...getTableProps()}>
						<SoftBox className="bbvGrey" component="thead">
							{headerGroups.map((headerGroup, key) => (
								<TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, key) => (
										<DataTableHeadCell
											key={key}
											{...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
											width={column.width ? column.width : "auto"}
											// align={column.align ? column.align : "center"}

											//align used to align the data content
											align={
												column.align
													? column.align
													: column.Header === "Name"
														? "left"
														: "center"
											}
											sorted={setSortedValue(column)}
										>
											{column.id !== "action_buttons" ? column.render("Header") : ""}
										</DataTableHeadCell>
									))}
								</TableRow>
							))}
						</SoftBox>

						<TableBody {...getTableBodyProps()}>
							{page.map((row, key) => {
								prepareRow(row);
								return (
									<TableRow className="alignCenter" key={key} {...row.getRowProps()}>
										{row.cells.map((cell, key) => (
											<DataTableBodyCell
												key={key}
												className="alignCenter"
												noBorder={noEndBorder && rows.length - 1 === key}
												// align={cell.column.align ? cell.column.align : "center"}

												//align used to align the data content
												align={
													cell.column.align
														? cell.column.align
														: cell.column.Header === "Name"
															? "left"
															: "center"
												}
												{...cell.getCellProps()}
											>
												<div className="leftAlign" style={{ maxWidth: "450px" }}>
													{/* {cell.render("Cell")} */}
													{/* For options ranking table */}
													{cell.column.Header === "How To Apply" ? (
														<div>
															<input
																type="image"
																id="saveform"
																src={popUP}
																className="icon"
																style={{ width: "30px" }}
																alt="Submit Form"
																onClick={() => {
																	const symbol = row.cells[2].value;
																	setStockCode(symbol);

																	parseData(symbol, fullData);
																	configureOption(optionType);
																	setOption(row.cells);
																	console.log("row cells is here")
																	console.log(row.cells)
																	//uncomment togglePopup and comment togglePaymentpopup below
																	togglePopup(true);
																	// togglePaymentpopup(true)
																
																}}
															></input>
														</div>
													) : ["GEM Stock Rank", "SYM"].includes(cell.column.Header) ? (
														<KeyIconOrData
															value={cell.render("Cell")}
															requiredFeature="gem_options_ranking"
														/>
													) : cell.column.id === "action_buttons" ? (
														<p style={{ display: "flex", gap: "10px" }}>
															{cell.column.buttons.map(button =>
																!row.original.hideActionButtons ||
																	!row.original.hideActionButtons.includes(button.key) ? (
																	<SoftButton
																		onClick={() =>
																			button.action({ ...row.original })
																		}
																		color={button.color ? button.color : "warning"}
																	>
																		{button.text}
																	</SoftButton>
																) : null
															)}
														</p>
													) : cell.column.type === "length" ? (
														<p style={{ maxWidth: "110px" }}>
															{cell.row?.original[cell.column.id]
																? (cell.column.filterby
																	? cell.row.original[cell.column.id].filter(
																		cell.column.filterby
																	).length
																	: cell.row.original[cell.column.id].length)
																: "000"}
														</p>
													) : cell.column.type === "date" ? (
														<p style={{ maxWidth: "110px" }}>{formatDate(cell.value)}</p>
													) : cell.column.type === "currency" ? (
														<p style={{ maxWidth: "110px" }}>
															{cell.value ? (
																<>
																	{cell.column.symbol ? cell.column.symbol : "USD"}{" "}
																	{numberFormat(cell.value)}
																</>
															) : null}
														</p>
													) : cell.column.type === "image" ? (
														<p style={{ maxWidth: "110px" }}>
															<img
																src={cell.value.replace(
																	"ipfs://",
																	process.env.REACT_APP_GCLOUD_STORAGE_BASE_URL
																)}
																width="100%"
															/>
														</p>
													) : (
														<p style={{ maxWidth: cell.column.Header === "Name" ? nameMaxWidth : '' }}>{isNaN(cell.value) ? cell.render("Cell") : parseInt(cell.value)}</p>
													)}
												</div>
											</DataTableBodyCell>
										))}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					{rows.length > 0 ? (
						<SoftBox
							display="flex"
							flexDirection={{ xs: "column", sm: "row" }}
							justifyContent="space-between"
							alignItems={{ xs: "flex-start", sm: "center" }}
							p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
						>
							{showTotalEntries && (
								<SoftBox mb={{ xs: 3, sm: 0 }}>
									<SoftTypography variant="button" color="secondary" fontWeight="regular">
										Showing {entriesStart} to {entriesEnd} of {rows.length} entries
									</SoftTypography>
								</SoftBox>
							)}
							{pageOptions.length > 1 && (
								<SoftPagination
									variant={pagination.variant ? pagination.variant : "gradient"}
									color={pagination.color ? pagination.color : "info"}
								>
									{canPreviousPage && (
										<SoftPagination item onClick={() => previousPage()}>
											<Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
										</SoftPagination>
									)}
									{renderPagination.length > 6 ? (
										<SoftBox width="5rem" mx={1}>
											<SoftInput
												inputProps={{
													type: "number",
													min: 1,
													max: customizedPageOptions.length,
												}}
												value={customizedPageOptions[pageIndex]}
												onChange={(handleInputPagination, handleInputPaginationValue)}
											/>
										</SoftBox>
									) : (
										renderPagination
									)}
									{canNextPage && (
										<SoftPagination item onClick={() => nextPage()}>
											<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
										</SoftPagination>
									)}
								</SoftPagination>
							)}
						</SoftBox>
					) : (
						<SoftBox display="flex" style={{ justifyContent: "center" }} py={3}>
							<SoftTypography variant="p">No Data Found</SoftTypography>
						</SoftBox>
					)}
				</>
			)}
			{/* {paymentPopup?<Popup>
				<CloseIcon className="close-btn icon" style={{}} onClick={()=>{togglePaymentpopup(false)}} />
                <Payment forStocks={false}></Payment>
			</Popup>:<div></div>} */}
			{popup && (
				<Popup transparent={true}>
					<CloseIcon
						className="close-btn icon"
						style={{ color: "white" }}
						onClick={() => {
							togglePopup(false);
							setModal(false);
						}}
					/>
					<Card className="optionPopupCard popupLargeWidth">
						{/* FIS estimated return, RBS min return max return, BPS estimated return, BCS MIN return  */}
						<div className="optionsPopupGroupOne">
							<Grid container>
								<Grid style={{ textAlign: "center" }} item xl={3} lg={3} md={3} sm={3} xs={3}>
									<p className="helvetica optionPopupHeaders">{CurrentOptionType} Rank</p>
									<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
										{currentOption[0].value}
									</p>
								</Grid>
								<Grid style={{ textAlign: "center" }} item xl={3} lg={3} md={3} sm={3} xs={3}>
									<p className="helvetica optionPopupHeaders">GEM Rank</p>
									<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
										<KeyIconOrData
											value={currentOption[1].value}
											requiredFeature="gem_options_ranking"
										/>
									</p>
								</Grid>
								<Grid style={{ textAlign: "center" }} item xl={3} lg={3} md={3} sm={3} xs={3}>
									<p className="helvetica optionPopupHeaders">SYM</p>
									<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
										<KeyIconOrData
											value={currentOption[2].value}
											requiredFeature="gem_options_ranking"
										/>
									</p>
								</Grid>
								<Grid style={{ textAlign: "center" }} item xl={3} lg={3} md={3} sm={3} xs={3}>
									<p className="helvetica optionPopupHeaders">Sector</p>
									<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
										{currentOption[3].value}
									</p>
								</Grid>
							</Grid>
							<Grid container>
								<Grid
									style={{ textAlign: "center" }}
									item
									xl={ratio}
									lg={ratio}
									md={ratio}
									sm={ratio}
									xs={ratio}
								>
									<p className="helvetica optionPopupHeaders">Capital Needed</p>
									<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
										{currentOption[5].value}
									</p>
								</Grid>
								<Grid
									style={{ textAlign: "center" }}
									item
									xl={ratio}
									lg={ratio}
									md={ratio}
									sm={ratio}
									xs={ratio}
								>
									<p className="helvetica optionPopupHeaders">Stock Price</p>
									<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
										{currentOption[4].value}
									</p>
								</Grid>

								{CurrentOptionType === ("FIS" || "BPS") ? (
									<Grid
										style={{ textAlign: "center" }}
										item
										xl={ratio}
										lg={ratio}
										md={ratio}
										sm={ratio}
										xs={ratio}
									>
										<p className="helvetica optionPopupHeaders" style={{whiteSpace: "nowrap"}}>Est Return (%)</p>
										<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
											{((currentOption[6].value / currentOption[5].value) * 100).toFixed(2)}
										</p>
									</Grid>
								) : CurrentOptionType === "RBS" ? (
									<Grid style={{ textAlign: "center" }} container xl={6} lg={6} md={6} sm={6} xs={6}>
										<Grid style={{ textAlign: "center" }} item xl={6} lg={6} md={6} sm={6} xs={6}>
											<p className="helvetica optionPopupHeaders">Min Return (%)</p>
											<p
												className="roboto_medium optionPopupHeaders"
												style={{ textAlign: "center" }}
											>
												{currentOption[7].value}
											</p>
										</Grid>
										<Grid style={{ textAlign: "center" }} item xl={6} lg={6} md={6} sm={6} xs={6}>
											<p className="helvetica optionPopupHeaders">Max Return (%)</p>
											<p
												className="roboto_medium optionPopupHeaders"
												style={{ textAlign: "center" }}
											>
												{currentOption[8].value}
											</p>
										</Grid>
									</Grid>
								) : (
									<Grid
										style={{ textAlign: "center" }}
										item
										xl={ratio}
										lg={ratio}
										md={ratio}
										sm={ratio}
										xs={ratio}
									>
										<p className="helvetica optionPopupHeaders">Est Return (%)</p>
										<p className="roboto_medium optionPopupHeaders" style={{ textAlign: "center" }}>
											{currentOption[7].value}
										</p>
									</Grid>
								)}
							</Grid>
						</div>

						<Grid
							container
							style={{ marginTop: "12px" }}
							alignItems="center"
							justifyContent="space-between"
						>
							<Button
								className="greyOutline"
								style={{}}
								onClick={() => {
									navigate(`./${CurrentOptionType}/${currentOption[2].value}`);
								}}
								variant="outlined"
							>
								Full Screen
							</Button>
							<Button
								className="bbvGrey"
								style={{ color: "white" }}
								onClick={() => {
									navigate(`/dashboard/GEM/stocks-ranking/${currentOption[2].value}`);
								}}
								variant="contained"
							>
								Stock Research
							</Button>

						</Grid>
						<div>
							<InfoIcon className="redIcon optionPopupIcon icon"></InfoIcon>
							{CurrentOptionType === "BCS" ? (
								<div
									className="optionsInfo"
									style={{ width: "80%", marginBottom: "5px", marginTop: "15px" }}
								>
									<Grid style={{ marginBottom: "14px" }} container>
										<Grid item xl={3} lg={3} md={3} sm={4} xs={4}></Grid>
										<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
											<p className="roboto optionPopupCaptions">Sell Call</p>
										</Grid>
										<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
											<p className="roboto optionPopupCaptions">Buy Call</p>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500 " item xl={3} lg={3} md={3} sm={4} xs={4}>
											Date To Expiry
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.DTE}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.DTE}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Est Premium
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.L1_SellPrice}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.L2_BuyPrice}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Est Probab (%)
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.DPOTM}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>NIL</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Open Interest
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.OI}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>NIL</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Strike Price
										</Grid>
										<Grid style={{ marginLeft: "" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.L1_SellStrike}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.L2_BuyStrike}</p>
											</div>
										</Grid>
									</Grid>
								</div>
							) : CurrentOptionType === "BPS" ? (
								<div
									className="centerButton"
									style={{ width: "80%", marginBottom: "5px", marginTop: "15px" }}
								>
									<Grid style={{ marginBottom: "14px" }} container>
										<Grid item xl={3} lg={3} md={3} sm={4} xs={4}></Grid>
										<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
											<p className="roboto optionPopupCaptions ">Sell Put</p>
										</Grid>
										<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
											<p className="roboto optionPopupCaptions">Buy Put</p>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Date To Expiry
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.DTE}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.DTE}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Est Premium
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.L1_SellPrice}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.L2_BuyPrice}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Est Probab (%)
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.DPOTM}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>NIL</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Open Interest
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.OI}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>NIL</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Strike Price
										</Grid>
										<Grid style={{ marginLeft: "" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.L1_SellStrike}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.L2_BuyStrike}</p>
											</div>
										</Grid>
									</Grid>
								</div>
							) : CurrentOptionType === "RBS" ? (
								<div
									className="centerButton"
									style={{ width: "80%", marginBottom: "5px", marginTop: "15px" }}
								>
									<Grid style={{ marginBottom: "14px" }} container>
										<Grid item xl={3} lg={3} md={3} sm={4} xs={4}></Grid>
										<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
											<p className="roboto">Buy Call</p>
										</Grid>
										<Grid style={{ textAlign: "center" }} item xl={4} lg={4} md={4} sm={4} xs={4}>
											<p className="roboto">Sell Call</p>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Date To Expiry
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.BC_Expiry}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.SC_Expiry}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" style={{ marginBottom: "14px" }} container>
										<Grid className="roboto_500" item xl={3} lg={3} md={3} sm={4} xs={4}>
											Est Premium
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.BC_Price}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.SC_Price}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" container>
										<Grid
											className="roboto_500"
											style={{ marginBottom: "14px" }}
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={4}
										>
											Est Probab (%)
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												{/* <p>{filteredData.BC_Price}</p> */}
												<p>NIL</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.SC_DPOTM}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" container>
										<Grid
											className="roboto_500"
											style={{ marginBottom: "14px" }}
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={4}
										>
											Open Interest
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.BC_OI}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.SC_OI}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" container>
										<Grid
											className="roboto_500"
											style={{ marginBottom: "14px" }}
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={4}
										>
											Strike Price
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.BC_Strike}</p>
											</div>
										</Grid>
										<Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
											<div className="whiteBox">
												<p>{filteredData.SC_Strike}</p>
											</div>
										</Grid>
									</Grid>
								</div>
							) : (
								<div
									className="centerButton"
									style={{ width: "80%", marginBottom: "5px", marginTop: "15px" }}
								>
									<Grid container>
										<Grid
											style={{ marginBottom: "14px" }}
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={4}
										></Grid>
										<Grid style={{ textAlign: "center" }} item xl={6} lg={6} md={6} sm={6} xs={6}>
											<p className="roboto optionPopupCaptions">Sell Put</p>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" container>
										<Grid
											className="roboto_500"
											style={{ marginBottom: "14px" }}
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={4}
										>
											Date To Expiry
										</Grid>
										<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
											<div className="whiteBox">
												<p>{filteredData.Expiry}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" container>
										<Grid
											className="roboto_500"
											style={{ marginBottom: "14px" }}
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={4}
										>
											Est Premium
										</Grid>
										<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
											<div className="whiteBox">
												<p>{filteredData.TradePrice}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" container>
										<Grid
											className="roboto_500"
											style={{ marginBottom: "14px" }}
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={4}
										>
											Est Probab (%)
										</Grid>
										<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
											<div className="whiteBox">
												<p>{filteredData.DPOTM}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" container>
										<Grid
											className="roboto_500"
											style={{ marginBottom: "14px" }}
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={4}
										>
											Open Interest
										</Grid>
										<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
											<div className="whiteBox">
												<p>{filteredData.OI}</p>
											</div>
										</Grid>
									</Grid>
									<Grid className="optionPopupHeaders" container>
										<Grid
											className="roboto_500"
											style={{ marginBottom: "14px" }}
											item
											xl={3}
											lg={3}
											md={3}
											sm={4}
											xs={4}
										>
											Strike Price
										</Grid>
										<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
											<div className="whiteBox">
												<p>{filteredData.Strike}</p>
											</div>
										</Grid>
									</Grid>
								</div>
							)}
						</div>
						<SoftBox px={2} mb={2}>
							<strong style={{ fontSize: "12px", fontFamily: "Inter" }}>SAFETY REMINDER:</strong>
							<SoftTypography style={{ fontSize: "12px", fontFamily: "Inter" }}>
								Output on "How to Apply" is extracted permutation of 8-42 days to expiry (typically monthly) on programmed probabilities, open interest, spread & stock ranking, based on data from last closing. Users are suggested to select contracts customised to your
								own trading preferences & risk profile based on current price action & dynamics with prior due diligence.
							</SoftTypography>
						</SoftBox>
						{false && (!auth || !auth.plan?.plan_features.available.includes("gem_options_ranking")) ? (
							<img
								src={blurredOptionsGraph}
								style={{ maxWidth: "100%", objectFit: "fill" }}
								alt="blurred chart"
							/>
						) : (
							<iframe
								title="technical3"
								src={source3}
								style={{ width: "100%", border: "none", height: "35vh", minWidth: "300px" }}
							></iframe>
						)}

					</Card>
				</Popup>
			)}
		</TableContainer>
	);
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
	entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
	canSearch: false,
	showTotalEntries: true,
	pagination: { variant: "gradient", color: "info" },
	isSorted: true,
	noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
	entriesPerPage: PropTypes.oneOfType([
		PropTypes.shape({
			defaultValue: PropTypes.number,
			entries: PropTypes.arrayOf(PropTypes.number),
		}),
		PropTypes.bool,
	]),
	canSearch: PropTypes.bool,
	showTotalEntries: PropTypes.bool,
	table: PropTypes.objectOf(PropTypes.array).isRequired,
	pagination: PropTypes.shape({
		variant: PropTypes.oneOf(["contained", "gradient"]),
		color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
	}),
	isSorted: PropTypes.bool,
	noEndBorder: PropTypes.bool,
};

export default DataTable;
