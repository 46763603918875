import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import SoftBox from "../SoftBox";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { planSettings } from "config/plan";

export default function SoftDialog({
	isPayment,
	stocks,
	title,
	description,
	isOpen,
	showLoading,
	actions,
	hideCloseIcon,
	handleClose,
	children,
}) {
	return (
		<Dialog open={isOpen} onClose={handleClose}>
			{!hideCloseIcon && (
				<CloseIcon
					className="close-btn icon"
					onClick={() => {
						handleClose(false);
					}}
				/>
			)}
			{showLoading && (
				<SoftBox pt={3} sx={{ display: "flex", justifyContent: "center" }}>
					<CircularProgress color="inherit" />
				</SoftBox>
			)}
			<DialogTitle style={{ marginTop: hideCloseIcon ? "0" : "15px" }} align="center">
				{title}
			</DialogTitle>
			<DialogContent align="center">
				<DialogContentText>{description}</DialogContentText>
				{children}
			</DialogContent>
			{actions && actions.length > 0 && (
				<DialogActions>
					{actions.map((action, index) => (
						<Button key={index} onClick={action.handle}>
							{action.label}
						</Button>
					))}
				</DialogActions>
			)}
			{/* {isPayment?<DefaultPricingCard
								badge={{ color: "secondary", label: plan.name }}
								price={{
									currency: "US$ ",
									value: plan.price_monthly,
								}}
								mode={tabValue}
								specifications={plan.plan_features.available}
								specificationsFromLowerPlans={getLowerPlanSpecifications(plan)}
								action={{
									type: "callback",
									callback: () => join(plan.id),
									label: "join now",
								}}
				/>:<div></div>} */}
		</Dialog>
	);
}

SoftDialog.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	isOpen: PropTypes.bool,
	showLoading: PropTypes.bool,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			handle: PropTypes.func,
			label: PropTypes.string.isRequired,
		})
	),
	handleClose: PropTypes.func,
};
